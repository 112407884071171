import { layersInBlocks } from "./components/illustrations/utils"
import { cloneDeep } from "lodash"
import {
    onePerLayerColors,
    optmizeColor,
} from "./layouts/NeosColorsAndPatterns"

function cleanBlocks(bs) {
    return bs.filter((b) => b.layers !== 0)
}

function realtiveLayers(b) {
    if (b.type === "light") {
        return 3
    }
    return b.layers
}

export default function realizeLayers(blocks, config) {
    const shape = config ? config.shape : "N"
    const length = layersInBlocks(blocks)
    if (shape === "NnN") {
        //For now a hack
        blocks = onePerLayerColors(1, blocks)[0]

        console.log(blocks)
        const bSize = Math.floor(length / 4)
        let pos = 0
        const nb = []
        for (const bc of blocks) {
            const b = cloneDeep(bc)

            if (pos + b.layers < bSize) {
                pos = pos + b.layers
                b.diameter = 8
                nb.push(b)
            } else if (pos + b.layers >= bSize && pos < bSize) {
                const remain = bSize - pos
                const layers = b.layers
                b.layers = remain
                b.diameter = 8
                nb.push({ ...b, foo: 1 })
                nb.push({ ...b, layers: layers - remain, diameter: 5, foo: 2 })
                pos = pos + layers
            } else if (pos > length - bSize) {
                pos = pos + b.layers
                b.diameter = 8
                nb.push({ ...b, foo: 3 })
            } else if (
                pos <= length - bSize &&
                pos + b.layers > length - bSize
            ) {
                const boundry = length - bSize
                const ogLayers = b.layers
                b.layers = boundry - pos
                b.diameter = 5
                nb.push({ ...b, foo: 4 })

                nb.push({
                    ...b,
                    layers: ogLayers - b.layers,
                    diameter: 8,
                    foo: 5,
                })
                pos = pos + b.layers
            } else {
                pos = pos + b.layers
                b.diameter = 5
                nb.push({ ...b, foo: 6 })
            }
        }

        return optmizeColor(1, cleanBlocks(nb))[0]
    }

    if (shape === "nNn") {
        const bSize = Math.ceil(length / 4)
        let pos = 0
        const nb = []
        //For now a hack
        blocks = onePerLayerColors(1, blocks)[0]
        for (const bc of blocks) {
            const b = cloneDeep(bc)
            if (pos + b.layers < bSize) {
                pos = pos + b.layers
                b.diameter = 5
                nb.push(b)
            } else if (pos + b.layers >= bSize && pos < bSize) {
                const remain = bSize - pos
                const layers = b.layers
                b.layers = remain
                b.diameter = 5
                nb.push(b)
                nb.push({ ...b, layers: layers - remain, diameter: 8 })
                pos = pos + layers
            } else if (pos > length - bSize) {
                pos = pos + b.layers
                b.diameter = 5
                nb.push(b)
            } else if (
                pos <= length - bSize &&
                pos + b.layers > length - bSize
            ) {
                const boundry = length - bSize
                const ogLayers = b.layers
                b.layers = boundry - pos
                b.diameter = 8
                nb.push(b)

                nb.push({ ...b, layers: ogLayers - b.layers, diameter: 5 })
                pos = pos + b.layers
            } else {
                pos = pos + b.layers
                b.diameter = 8
                nb.push(b)
            }
        }
        return optmizeColor(1, cleanBlocks(nb))[0]
    }

    if (shape === "nN") {
        const bSize = Math.floor(length / 3)
        let pos = 0
        const nb = []
        for (const bc of blocks) {
            const b = cloneDeep(bc)

            if (pos + b.layers < bSize) {
                pos = pos + b.layers
                b.diameter = 5

                nb.push(b)
            } else if (pos > length - bSize) {
                pos = pos + b.layers
                b.diameter = 8

                nb.push(b)
            } else if (
                pos <= length - bSize &&
                pos + b.layers >= length - bSize
            ) {
                const boundry = length - bSize
                const ogLayers = b.layers
                b.layers = boundry - pos

                b.diameter = 5
                if (b.layers !== 0) {
                    nb.push(b)
                }
                nb.push({ ...b, layers: ogLayers - b.layers, diameter: 8 })
                pos = pos + b.layers
            } else {
                pos = pos + b.layers
                b.diameter = 5

                nb.push(b)
            }
        }
        return optmizeColor(1, cleanBlocks(nb))[0]
    }

    return blocks
}
