import React, { createContext, useReducer, useContext } from "react"
import {
    initialState as armChairInitialState,
    emptyState as armChairEmptyState,
    reducer as armChairReducer,
    options as armChairOptions,
} from "./arm-chair"

import {
    initialState as cornerChairInitialState,
    emptyState as cornerChairEmptyState,
    reducer as cornerChairReducer,
    options as cornerChairOptions,
} from "./corner-chair"

import {
    initialState as benchInitialState,
    emptyState as benchEmptyState,
    reducer as benchReducer,
    options as benchOptions,
} from "./bench"

import {
    initialState as armBenchbenchInitialState,
    emptyState as armBenchEmptyState,
    reducer as armBenchReducer,
    options as armBenchOptions,
} from "./arm-bench"

import {
    initialState as sideChairInitialState,
    emptyState as sideChairEmptyState,
    reducer as sideChairReducer,
    options as sideChairOptions,
} from "./side-chair"

import {
    initialState as dayBedInitialState,
    emptyState as dayBedEmptyState,
    reducer as dayBedReducer,
    options as dayBedOptions,
} from "./day-bed"

import {
    initialState as stoolInitialState,
    emptyState as stoolEmptyState,
    reducer as stoolReducer,
    options as stoolOptions,
} from "./stool"

import {
    initialState as progressInitialState,
    reducer as progressReducer,
} from "./progress"
import {
    initialState as cartInitialState,
    reducer as cartReducer,
} from "./cart"

const reducers = [
    armChairReducer,
    benchReducer,
    sideChairReducer,
    progressReducer,
    armBenchReducer,
    cartReducer,
]
let initialState = {
    armChair: armChairInitialState,
    cornerChair: cornerChairInitialState,
    bench: benchInitialState,
    sideChair: sideChairInitialState,
    dayBed: dayBedInitialState,
    stool: stoolInitialState,
    progress: progressInitialState,
    armBench: armBenchbenchInitialState,
    cart: cartInitialState,
}

export const emptyState = {
    armChair: armChairEmptyState,
    cornerChair: cornerChairEmptyState,
    bench: benchEmptyState,
    sideChair: sideChairEmptyState,
    stool: stoolEmptyState,
    dayBed: dayBedEmptyState,
    armBench: armBenchEmptyState,
}

export const options = {
    armChair: armChairOptions,
    cornerChairChair: cornerChairOptions,
    sideChair: sideChairOptions,
    bench: benchOptions,
    dayBed: dayBedOptions,
    stool: stoolOptions,
    armBench: armBenchOptions,
}

/**
 *
 * Logic stuff that should not need changing
 */

const combineReducers =
    (reducers) =>
    (state = initialState, action) => {
        for (let i = 0; i < reducers.length; i++) {
            state = reducers[i](state, action)
        }
        window.sessionStorage.setItem("state", JSON.stringify(state))
        return state
    }

let store = null

if (window.sessionStorage.getItem("state")) {
    //initialState = JSON.parse(window.sessionStorage.getItem("state"))
}
store = createContext(initialState)

const { Provider } = store

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        combineReducers(reducers),
        initialState
    )
    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

const useGlobalState = () => useContext(store)
export { store, StateProvider, useGlobalState }
