import React from "react"
import { Stage, Layer, Rect, Group } from "react-konva"
import {
    inch,
    thicknessOfBlocks,
    RectForBlocks,
    layersInBlocks,
    Leg,
    fivemm,
} from "./utils"

export default function PendentFront(props) {
    const chair = props.chair
    let height = thicknessOfBlocks(chair.tube.blocks) + 1 //Tuft height
    let extraPush = 0
    let tuftStart = 0

    const largestInch = Math.max(height, chair.tube.diameter)
    const _in = props.in
        ? props.in
        : (i) => {
              return inch(i, largestInch, props.size)
          }

    return (
        <Stage width={_in(chair.tube.diameter) + 1} height={_in(height) + 1}>
            <Layer>
                <Group x={0} y={_in(chair.tuft.height + extraPush)}>
                    <RectForBlocks
                        x={0}
                        y={0}
                        blocks={chair.tube.blocks}
                        inch={(i) => {
                            return _in(i)
                        }}
                        width={_in(chair.tube.diameter)}
                        chair={chair}
                    />
                </Group>
            </Layer>
        </Stage>
    )
}
