import React from "react"
import { Stage, Layer, Rect, Circle, Group } from "react-konva"
import { inch, cornerRadius } from "./utils"

export default function BenchTop(props) {
    const chair = props.chair
    const largestInch = Math.max(chair.seat.depth, chair.seat.width)

    const _in = props.in
        ? props.in
        : (i) => {
              return inch(i, largestInch, props.size)
          }

    return (
        <Stage
            width={_in(chair.seat.width) + 1}
            height={_in(chair.seat.depth) + 1}
        >
            <Layer>
                <Rect
                    x={0}
                    y={0}
                    width={_in(chair.seat.width)}
                    height={_in(chair.seat.depth)}
                    fill={chair.seat.blocks[0].color}
                    stroke="black"
                    strokeWidth={1}
                    cornerRadius={cornerRadius(chair, _in)}
                />
                {chair.type === "armBench" && (
                    <Group>
                        <Rect
                            x={0}
                            y={0}
                            width={_in(chair.leftArm.width)}
                            height={_in(chair.seat.depth)}
                            fill={chair.leftArm.blocks[0].color}
                            stroke="black"
                            strokeWidth={1}
                            cornerRadius={cornerRadius(chair, _in)}
                        />

                        <Rect
                            x={
                                _in(chair.seat.width) -
                                _in(chair.rightArm.width)
                            }
                            y={0}
                            width={_in(chair.rightArm.width)}
                            height={_in(chair.seat.depth)}
                            fill={chair.rightArm.blocks[0].color}
                            stroke="black"
                            strokeWidth={1}
                            cornerRadius={cornerRadius(chair, _in)}
                        />
                    </Group>
                )}

                {/*Tufts*/}
                {chair.type !== "stool" && (
                    <>
                        <Circle
                            x={_in((chair.legs.width + 1) / 2)}
                            y={_in(chair.legs.width / 2)}
                            radius={inch(1, largestInch, props.size)}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />
                        <Circle
                            x={
                                _in(chair.seat.width) -
                                _in((chair.legs.width + 1) / 2)
                            }
                            y={_in(
                                chair.legs.width / 2,
                                largestInch,
                                props.size
                            )}
                            radius={_in(chair.tuft.radius)}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />

                        <Circle
                            x={_in((chair.legs.width + 1) / 2)}
                            y={_in(chair.seat.depth - chair.legs.width / 2)}
                            radius={_in(chair.tuft.radius)}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />

                        <Circle
                            x={
                                _in(chair.seat.width) -
                                _in((chair.legs.width + 1) / 2)
                            }
                            y={_in(chair.seat.depth - chair.legs.width / 2)}
                            radius={_in(
                                chair.tuft.radius,
                                largestInch,
                                props.size
                            )}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />
                    </>
                )}
                {chair.type === "stool" && (
                    <>
                        <Circle
                            x={_in(chair.legs.width / 2)}
                            y={_in(chair.seat.depth / 2)}
                            radius={_in(chair.tuft.radius)}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />
                        <Circle
                            x={
                                _in(chair.seat.width) -
                                _in(chair.legs.width / 2)
                            }
                            y={_in(chair.seat.depth / 2)}
                            radius={_in(
                                chair.tuft.radius,
                                largestInch,
                                props.size
                            )}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />
                    </>
                )}
                {chair.type === "armBench" && (
                    <>
                        <Group>
                            <Circle
                                x={_in(
                                    chair.seat.width / 2 + chair.tuft.radius / 2
                                )}
                                y={_in(chair.legs.width / 2)}
                                radius={inch(1, largestInch, props.size)}
                                fill={chair.tuft.color}
                                stroke="black"
                                strokeWidth={1}
                            />
                            <Circle
                                x={_in(
                                    chair.seat.width / 2 + chair.tuft.radius / 2
                                )}
                                y={_in(chair.seat.depth - chair.legs.width / 2)}
                                radius={inch(1, largestInch, props.size)}
                                fill={chair.tuft.color}
                                stroke="black"
                                strokeWidth={1}
                            />
                        </Group>
                    </>
                )}
            </Layer>
        </Stage>
    )
}
