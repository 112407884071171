import React, { useState, useEffect } from "react"
import Config from "../Config"
import { ReactComponent as A } from "../images/rightArrow.svg"
function genreateColors() {
    let i = 0
    const colors = [
        Config.palette.almostWhite,
        Config.palette.green,
        Config.palette.blue,
        Config.palette.yellow,
        Config.palette.red,
    ]
    const result = []
    while (i <= 8) {
        result.push(colors[Math.floor(Math.random() * colors.length)])
        i++
    }
    return result
}

export default function FlashArrow(props) {
    const [colors, setColors] = useState(genreateColors())
    useEffect(() => {
        const f = function sc() {
            setColors(genreateColors())
        }
        const i = setInterval(f, 2000)

        return () => {
            clearInterval(i)
        }
    }, [setColors])

    const style = `
    .one {
      fill: ${colors[0]};
      transition: fill 1s;
    }
    rect.two{
        fill: ${colors[1]};
        transition: fill 1s;

    }
    .three{
        fill: ${colors[2]};
        transition: fill 1s;

    }
    .four{
        fill: ${colors[3]};
        transition: fill 1s;

    }
    .five{
        fill: ${colors[4]};
        transition: fill 1s;

    }
    .six{
        fill: ${colors[5]};
        transition: fill 1s;

    }
    .seven{
        fill: ${colors[6]};
        transition: fill 1s;

    }
    `
    return (
        <span className="flashArrow">
            <style>{style}</style>
            <A />
        </span>
    )
}
