import React, { useState } from "react"

import Collection, { CollectionCarasol } from "./Collection"

import Cluster from "../images/productImages/lightsms/cluster.jpg"

import Colony from "../images/productImages/lightsms/colony.jpg"
import Colony2 from "../images/productImages/lightsms/colony_2.jpg"
import Colony3 from "../images/productImages/lightsms/colony_cluster.jpg"

import Cardozo1_1 from "../images/productImages/lightsms/cardonzo1.jpg"
import Cardozo2_1 from "../images/productImages/lightsms/cardonzo2.jpg"

import CardozoGroup from "../images/productImages/lightsms/cardonzo_group.jpg"

import Tropic from "../images/productImages/lightsms/tropic.jpg"

import Leslie from "../images/productImages/lightsms/leslie.jpg"

import ParkCentral from "../images/productImages/lightsms/park_central.jpg"

import Marlin from "../images/productImages/lightsms/marlin.jpg"

import Waldorf from "../images/productImages/lightsms/waldorf.jpg"

import Bear from "../images/productImages/lightsms/baer_one.jpg"

const collectionItems = [
    {
        key: "colony",
        icon: "585",
        name: "The Colony",
        unit: "Colony_AP",
        image: Colony,
        images: [Colony2, Colony3],
        type: "585",
        configured: "Miami, FL | 4:42:23 PM on July 11, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/colony.pdf",
        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Colony
                pendant celebrates the namesake 1947 hotel with bold blocks of
                60 percent recycled salmon PET board, white Merino wool offcuts,
                and an intermittent pattern of recessed LED lights. Hung
                vertically or horizontally, the fixture is ideal for contract as
                well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Height: 16 – 1/2” <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                FELT 150 WollweiB: 46
                <br />
                PET 07 Salmon: 18
                <br />
                <br />
                Lights
                <br />
                8” dia light: 2
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Off White
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Champagne
                <br /> Cable length: Varies
                <br />
            </>
        ),
        chair: {
            org: "MS",
            unit: "TheColony",
            form: "585",
            skipRealizer: true,

            tube: {
                blocks: [
                    {
                        color: "#d8d8d4",
                        layers: 46 / 2,
                        colorName: "150 Weiß",
                        diameter: 5,
                    },

                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 6,
                        colorName: "salmon",
                        diameter: 8,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 8,
                    },

                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 6,
                        colorName: "salmon",
                        diameter: 8,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 8,
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 6,
                        colorName: "salmon",
                        diameter: 8,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 46 / 2,
                        colorName: "150 Weiß",
                        diameter: 5,
                    },
                ],
                length: 24,
                diameter: 8,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },

            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "8",
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: [],
            boardColors: [],
            blockColorsRGB: {},
        },
        autoPrice: true,

        //price: "$3,900 USD",
    },
    {
        key: "cardozo1",
        icon: "58",
        name: "Cardozo 1",
        unit: "Cardozo1_AP",
        image: Cardozo1_1,
        images: [CardozoGroup],
        type: "58",
        configured: "Miami, FL | 1:20:21 PM on July 10, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/cordozo1.pdf",

        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Cardozo 1
                pendant celebrates the namesake 1939 hotel with bold blocks of
                60 percent recycled lilac and orange PET board, light pale blue
                Merino wool offcuts, and an intermittent pattern of recessed LED
                lights. Hung vertically or horizontally, the fixture is ideal
                for contract as well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Length: 25-3/4” <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                FELT 613 Gletscher: 86
                <br />
                PET 13 Lilac: 10
                <br />
                PET 16 Orange: 9
                <br />
                <br />
                Lights
                <br />
                5” dia light: 2
                <br />
                8” dia light: 1
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Blue
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),
        chair: {
            org: "MS",
            unit: "Cardozo1",
            form: "58",
            skipRealizer: true,
            altCablePosition: true,
            tube: {
                blocks: [
                    {
                        color: "#5C7084",

                        layers: 44,
                        colorName: "613 Gletscher",
                        diameter: 5,
                    },

                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#5C7084",

                        layers: 22,
                        colorName: "613 Gletscher",
                        diameter: 5,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#5C7084",

                        layers: 17,
                        colorName: "613 Gletscher",
                        diameter: 5,
                    },
                    {
                        layers: 3,
                        colorName: "613 Gletscher",
                        diameter: 8,
                        color: "#5C7084",
                    },
                    {
                        color: "#AB391F",
                        layers: 9,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 8,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 8,
                    },
                    {
                        color: "#874972",
                        layers: 10,
                        colorName: "lilac",
                        type: "designerBoard",
                        diameter: 8,
                    },
                ],
                length: 24,
                diameter: 5,
            },
            tuft: {
                color: "#8C8990",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#fffff",
                colorName: "black",
            },

            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "58",
            subType: "horizontalPendent",
            lightsOn: 0,
            blockColors: [],
            boardColors: [],
            blockColorsRGB: {},
        },
        autoPrice: true,
        //price: "$4,800 USD",
    },
    {
        key: "cardozo2",
        icon: "58",
        name: "Cardozo 2",
        unit: "Cardozo2_AP",
        image: Cardozo2_1,
        images: [CardozoGroup],
        type: "58",
        configured: "Miami, FL | 12:47:43 PM On July 13, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/cordozo2.pdf",

        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Cardozo 2
                pendant celebrates the namesake 1939 hotel with bold blocks of
                60 percent recycled hot pink PET board, pale blue Merino wool
                offcuts, and an intermittent pattern of recessed LED lights.
                Hung vertically or horizontally, the fixture is ideal for
                contract as well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Length: 25-3/4” <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                FELT 613 Gletscher: 82
                <br />
                PET 12 Pink: 21
                <br />
                <br />
                Lights
                <br />
                5” dia light: 2
                <br />
                8” dia light: 1
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Blue
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),
        chair: {
            org: "MS",
            unit: "Cardozo2",
            form: "58",
            skipRealizer: true,
            altCablePosition: true,
            tube: {
                blocks: [
                    {
                        color: "#5C7084",

                        layers: 44,
                        colorName: "613 Gletscher",
                        diameter: 5,
                    },

                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#5C7084",

                        layers: 22,
                        colorName: "613 Gletscher",
                        diameter: 5,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#5C7084",

                        layers: 17,
                        colorName: "613 Gletscher",
                        diameter: 5,
                    },

                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 10,
                        diameter: 8,
                        colorName: "pink",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 8,
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 11,
                        diameter: 8,
                        colorName: "pink",
                    },
                ],
                length: 24,
                diameter: 5,
            },
            tuft: {
                color: "#8C8990",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#fffff",
                colorName: "black",
            },

            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "58",
            subType: "horizontalPendent",
            lightsOn: 0,
            blockColors: [],
            boardColors: [],
            blockColorsRGB: {},
        },
        autoPrice: true,
        //price: "$4,775 USD",
    },
    {
        key: "tropic",
        icon: "8",
        name: "The Tropic",
        unit: "Tropic",
        image: Tropic,
        images: [Cluster],
        type: "8",
        configured: "Miami, FL | 6:54:03 PM On July 12, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/tropic.pdf",
        autoPrice: true,
        chair: {
            org: "MS",
            unit: "TheTropic",

            tube: {
                blocks: [
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 5,
                        colorName: "pink",
                        diameter: 8,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 11,
                        diameter: 8,
                        colorName: "pink",
                    },
                ],
                length: 24,
                diameter: 8,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            descr: "Our configurator provides a platform for users to convert regionalsupplies of off-cut felt, recycled aluminum and lights into custom fixtures.",
            light: {
                number: "4",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "8",
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: ["150 Weiß", "170 Asche", "175 Graphit"],
            boardColors: [],
            blockColorsRGB: {
                "150 Weiß": "#d8d8d4",
                "170 Asche": "#9f9b9f",
                "175 Graphit": "#453a3e",
            },
        },
        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Tropic
                pendant celebrates the namesake hotel with bold blocks of 60
                percent recycled hot pink PET board divided by a recessed LED
                light. Hung vertically or horizontally, the fixture is ideal for
                contract as well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Height: 6” <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                PET 12 Pink: 16
                <br />
                <br />
                Lights
                <br />
                8” dia light: 1
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),

        //price: "$2,250 USD",
    },
    {
        key: "leslie",
        icon: "5",
        name: "The Leslie",
        unit: "Leslie",
        image: Leslie,
        images: [Cluster],
        type: "8",
        configured: "Miami, FL | 6:39:52 PM On July 12, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/leslie.pdf",

        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Leslie
                pendant celebrates the 1937 namesake hotel with bold blocks of
                60 percent recycled lemon-coloured PET board, white Merino wool
                offcuts, and divided by a single, recessed LED light. Hung
                vertically or horizontally, the fixture is ideal for contract as
                well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Height: 12” <br />
                Diameter: 5”
                <br />
                <br />
                Layers
                <br />
                FELT 150 WollweiB: 40
                <br />
                PET 08 Lemon: 11
                <br />
                <br />
                Lights
                <br />
                5” dia light: 1
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),
        //price: "$2,350 USD",
        autoPrice: true,
        chair: {
            org: "MS",
            unit: "TheLeslie",

            tube: {
                blocks: [
                    {
                        color: "#d8d8d4",
                        layers: 40,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 11,
                        colorName: "lemon",
                    },
                ],
                length: 24,
                diameter: 8,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            descr: "Our configurator provides a platform for users to convert regionalsupplies of off-cut felt, recycled aluminum and lights into custom fixtures.",
            light: {
                number: "1",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "8",
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: [],
            boardColors: [],
            blockColorsRGB: {},
        },
    },
    {
        key: "park_central",
        icon: "8",
        name: "The Park Central",
        unit: "ParkCentral",
        image: ParkCentral,
        images: [Cluster],
        type: "5",
        configured: "Miami, FL | 3:19:35 PM on July 10, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/park_central.pdf",

        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Park Central
                pendant celebrates the 1937 namesake hotel with bold blocks of
                60 percent recycled periwinkle PET board, white Merino wool
                offcuts, and an intermittent pattern of recessed LED lights.
                Hung vertically or horizontally, the fixture is ideal for
                contract as well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Height: 18” <br />
                Diameter: 5”
                <br />
                <br />
                Layers
                <br />
                FELT 150 WollweiB: 31
                <br />
                PET 06 Periwinkle: 33
                <br />
                <br />
                Lights
                <br />
                5” dia light: 2
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),
        autoPrice: true,

        //price: "$2,885 USD",
        chair: {
            org: "MS",
            unit: "ParkCentral",

            tube: {
                blocks: [
                    {
                        color: "#6F7192",
                        layers: 21,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 10,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 31,
                        colorName: "150 Weiß",
                        diameter: 5,
                    },
                ],
                length: 24,
                diameter: 5,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            descr: "Our configurator provides a platform for users to convert regionalsupplies of off-cut felt, recycled aluminum and lights into custom fixtures.",
            light: {
                number: "1",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "8",
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: [],
            boardColors: [],
            blockColorsRGB: {},
        },
    },
    {
        key: "marlin",
        icon: "58",
        name: "The Marlin",
        unit: "Marlin_AP",
        image: Marlin,
        images: [Cluster],
        type: "58",
        configured: "Miami, FL |  3:19:52 PM on July 10, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/marlin.pdf",

        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Marlin
                pendant celebrates the 1939 namesake hotel with bold blocks of
                60 percent recycled periwinkle and orange-coloured PET board,
                and an intermittent pattern of recessed LED lights. Hung
                vertically or horizontally, the fixture is ideal for contract as
                well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Height: 25-3/4” <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                PET 06 Periwinkle: 44
                <br />
                PET 16 Orange: 17
                <br />
                <br />
                Lights
                <br />
                8” dia light: 2
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),
        chair: {
            org: "MS",
            unit: "TheMarlin",
            form: "58",
            skipRealizer: true,
            tube: {
                blocks: [
                    {
                        color: "#6F7192",
                        layers: 44,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 5,
                    },

                    {
                        color: "#AB391F",
                        layers: 5,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 8,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 8,
                    },
                    {
                        color: "#AB391F",
                        layers: 6,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 8,
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 8,
                    },
                    {
                        color: "#AB391F",
                        layers: 6,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 8,
                    },
                ],
                length: 24,
                diameter: 8,
            },
            tuft: {
                color: "#8C8990",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#fffff",
                colorName: "black",
            },

            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "58",
            subType: "horizontalPendent",
            lightsOn: 0,
            blockColors: [],
            boardColors: [],
            blockColorsRGB: {},
        },
        autoPrice: true,
        // price: "$4,500 USD",
    },
    {
        key: "waldorf",
        icon: "5",
        name: "The Waldorf",
        unit: "Waldorf_AP",
        image: Waldorf,
        images: [Cluster],
        type: "5",
        configured: "Miami, FL |  3:20:12 PM on July10, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/waldorf_tower.pdf",

        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all of which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The Waldorf Tower
                pendant celebrates the 1937 namesake hotel with a multi-coloured
                stack of 60 percent recycled PET board, white Merino wool
                offcuts, and an intermittent pattern of recessed LED lights.
                Hung vertically or horizontally, the fixture is ideal for
                contract as well as residential environments.
            </>
        ),
        dimensions: (
            <>
                Height: 60” <br />
                Diameter: 5”
                <br />
                <br />
                Layers
                <br />
                FELT 150 WollweiB: 162
                <br />
                PET 06 Periwinkle: 18
                <br />
                PET 07 Salmon: 23
                <br />
                PET 08 Lemon: 20
                <br />
                PET 16 Orange: 17
                <br />
                <br />
                Lights
                <br />
                5” dia light: 3
                <br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),
        chair: {
            org: "MS",
            unit: "TheWaldorf",
            form: "5",
            skipRealizer: true,
            tube: {
                blocks: [
                    {
                        color: "#d8d8d4",
                        layers: 9,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 8,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 5,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 2,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 11,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 2,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 1,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 12,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 10,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 2,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 2,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 20,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 19,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 1,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#AB391F",
                        layers: 3,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 11,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 5,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 1,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 1,
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 9,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                        diameter: 1,
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 7,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                        diameter: 5,
                    },
                    {
                        color: "#d8d8d4",
                        layers: 8,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 2,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 3,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 3,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        color: "#AB391F",
                        layers: 1,
                        colorName: "orange",
                        type: "designerBoard",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 1,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        type: "designerBoard",
                        color: "#BDB93A",
                        layers: 1,
                        colorName: "lemon",
                    },
                    {
                        color: "#6F7192",
                        layers: 3,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                    {
                        color: "#6F7192",
                        layers: 3,
                        colorName: "periwinkle",
                        type: "designerBoard",
                    },
                    {
                        type: "designerBoard",
                        color: "#B08B79",
                        layers: 1,
                        colorName: "salmon",
                        diameter: 5,
                    },
                ],
                length: 24,
                diameter: 5,
            },
            tuft: {
                color: "#8C8990",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#fffff",
                colorName: "black",
            },

            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "58",
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: [],
            boardColors: [],
            blockColorsRGB: {},
        },
        autoPrice: true,
        //price: "$4,500 USD",
    },
    {
        key: "baer",
        icon: "5",
        name: "The Baer Cluster",
        unit: "baer_AP",
        image: Bear,
        images: [],
        type: "5",
        configured: "Miami, FL | 10:37:07AM on July 11, 2022",
        designed: "STACKABL X MINDY SOLOMON",
        tear: "/tear/baer.pdf",

        blurb: (
            <>
                Stackabl’s second pendant collection is a nod to a time and
                place: America’s roaring 1920s, exuberant ‘40s, and their Art
                Deco backdrops. In these promising and progressive eras, the
                decorative art style influenced everything from general
                household objects to architecture. The movement was about
                craftsmanship and rich materials, as well as bold geometries,
                sun-saturated colours, and linear details – all which Miami
                Beach fully embraced.
                <br />
                <br />
                This collection, in these cautiously optimistic, post pandemic
                times, salutes Miami Beach’s on-going vitality, which is
                especially noted in its hospitality landmarks. The pendants can
                be hung vertically or horizontally and are ideal for contract as
                well as residential environments. Clusters are available, too,
                including the show-stopping Baer. The medley of five pendants
                honours the late Barbara Baer Capitan, the community activist
                and author who led the effort to preserve Miami Beach's historic
                art deco district in the 1970s.
            </>
        ),
        dimensions: (
            <>
                The Tropic | 8<br />
                Dimensions
                <br />
                Height: 6”
                <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                PET 12 Pink: 16
                <br />
                <br />
                Lights
                <br />
                8” dia light: 1<br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
                <br />
                The Marlin | 58
                <br />
                Dimensions:
                <br />
                <br />
                Height: 25-3/4”
                <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                PET 06 Periwinkle: 44
                <br />
                PET 16 Orange: 17
                <br />
                <br />
                Lights
                <br />
                8” dia light: 2<br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
                <br />
                <br />
                The Leslie | 8<br />
                Dimensions:
                <br />
                <br />
                Height: 12”
                <br />
                Diameter: 5”
                <br />
                <br />
                Layers
                <br />
                FELT 150 WollweiB: 40
                <br />
                PET 08 Lemon: 11
                <br />
                <br />
                Lights
                <br />
                5” dia light: 1<br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
                <br />
                The Park Central | 5<br />
                Dimensions:
                <br />
                <br />
                Height: 18”
                <br />
                Diameter: 5”
                <br />
                <br />
                Layers
                <br />
                FELT 150 WollweiB: 31
                <br />
                PET 06 Periwinkle: 33
                <br />
                <br />
                Lights
                <br />
                5” dia light: 2<br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
                <br />
                The Waldorf | 5<br />
                Dimensions:
                <br />
                <br />
                Height: 60”
                <br />
                Diameter: 5”
                <br />
                <br />
                Layers
                <br />
                FELT 150 WollweiB: 162
                <br />
                PET 06 Periwinkle: 18
                <br />
                PET 07 Salmon: 23v PET 08 Lemon: 20
                <br />
                PET 16 Orange: 17
                <br />
                <br />
                Lights
                <br />
                5” dia light: 3<br />
                <br />
                Parts
                <br />
                Canopy color: White
                <br />
                Wire color: Clear
                <br />
                Hanging hardware: Stainless steel
                <br />
                Plate and tufts: Clear
                <br />
                Cable length: Varies
                <br />
            </>
        ),

        autoPrice: false,
        price: "$16,000.00 USD",
    },
]

function expandOption(option) {
    if (!option) {
        return null
    }
    const options = [option]
    for (const o of option.images) {
        options.push({ ...option, image: o })
    }
    return options
}

export default function ShowcaseMS(props) {
    return <CollectionCarasol items={collectionItems} />
}

export function LightGalleryMS(props) {
    return (
        <Collection
            baseUrl={"/lightcollectionms"}
            items={collectionItems}
            onClickCarasol={(item) => {
                return expandOption(item)
            }}
        />
    )
}
