import { useState, useEffect } from "react";

export default function useWidth() {
    const [size, setSize] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setSize(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
    });

    return size;
}
