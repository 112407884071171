import React, { useEffect, useState } from "react"
import { useGlobalState } from "../state/index"
import { cloneDeep, difference } from "lodash"
import {
    capitalizeFirstLetter,
    UrlColorSelector,
} from "../components/SimpleColorSelector"
import Config from "../Config"
import classNames from "classnames"
import { ColorPalette, getAverageRGB } from "../components/ColorPalette"
import { Front } from "../components/illustrations/Illustrations"
import { Side } from "../components/illustrations/Illustrations"
import YesNoModal from "../components/YesNoModal"

import seedrandom from "seedrandom"

import NumericInput, { contextType } from "react-numeric-input"

import Pattern from "../images/patterns.svg"
import Custom from "../images/custom.svg"
import Trash from "../images/trash.png"
import Up from "../images/up.svg"
import Down from "../images/down.svg"

import axios from "axios"

import Info from "../images/iconInfo.svg"

import ReactTooltip from "react-tooltip"

import "../css/neos/customColor.css"
import { validate } from "uuid"
import { BooleanKeyframeTrack, TorusBufferGeometry } from "three"

import LightBulb from "../images/lightBulb.svg"
import { SliderControl } from "./DimensionControls"
import { fivemm } from "../components/illustrations/utils"
import realizeLayers from "../lightRealize"
import { pendentConfig } from "../state/mg"

const boardColors = [
    "sand dollar",
    "oat",
    "periwinkle",
    "lilac",
    "midnight blue",
    "orange",
    "salmon",
    "pink",
    "red",
    "maroon",
    "heather green",
    "pear",
    "stone",
    "lemon",
    "yellow",
]

function useColors() {
    const [colors, setColors] = useState([])
    useEffect(() => {
        axios
            .get(Config.apiBasePath + "remnants/colors")
            .then((res) => {
                setColors(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return colors
}
function ColorModel(props) {
    const colors = useColors()
    const d = difference(
        props.designerBoard ? boardColors : colors,
        props.exclude
    )
    return (
        <div className={classNames("color-model")}>
            <ColorPalette
                colors={d}
                baseUrl={
                    props.designerBoard
                        ? "/designer_board/"
                        : Config.swatchBaseUrl
                }
                colorSelected={props.colorSelected}
                extenstion={props.designerBoard ? "jpg" : ""}
                args="?doubleHeight=1"
            />
            <div className="model-overlay" onClick={props.close} />
        </div>
    )
}

function AddColor(props) {
    const [showColors, setShowColors] = useState(false)
    const [color, setColors] = useState([])

    return (
        <div className={classNames("toggle compact simple-color rounded")}>
            <div
                className="inside-color"
                style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClick={() => {
                    setShowColors(true)
                }}
            >
                {showColors && (
                    <ColorModel
                        exclude={props.exclude}
                        close={(e) => {
                            setShowColors(false)
                            e.stopPropagation()
                        }}
                        colorSelected={(c, rgb, e) => {
                            props.colorSelected(c, rgb)
                            e.stopPropagation()
                            setShowColors(false)
                        }}
                        designerBoard={props.designerBoard}
                    />
                )}
                <div style={{ fontSize: "4vw" }}>+</div>
            </div>
        </div>
    )
}

export function NeosColors(props) {
    const state = useGlobalState()
    let colors = state.state.progress.chair.blockColors
    if (props.designerBoard) {
        colors = state.state.progress.chair.boardColors
    }

    return (
        <UrlColorSelector
            addWidget={
                <AddColor
                    designerBoard={props.designerBoard}
                    exclude={colors}
                    colorSelected={(c, rgb, e) => {
                        const chair = cloneDeep(state.state.progress.chair)
                        console.log(chair)
                        if (props.designerBoard) {
                            chair.boardColors.push(c)
                            chair.boardColorsRGB[c] = rgb
                        } else {
                            chair.blockColors.push(c)
                            chair.blockColorsRGB[c] = rgb
                        }
                        state.dispatch({
                            type: "CHAIR",
                            supressEvent: true,
                            payload: {
                                chair: chair,
                            },
                        })
                    }}
                />
            }
            showAddWidget={
                props.designerBoard && !props.lights
                    ? colors.length < 1
                    : colors.length < 5
            }
            colors={colors}
            baseUrl={
                props.designerBoard ? "/designer_board/" : Config.swatchBaseUrl
            }
            imageExtension={props.designerBoard ? "jpg" : ""}
            onClick={(value) => {
                const c = difference(colors, [value])

                const chair = cloneDeep(state.state.progress.chair)
                if (props.designerBoard) {
                    chair.boardColors = c
                    delete chair.boardColorsRGB[value]
                } else {
                    chair.blockColors = c
                    delete chair.blockColorsRGB[value]
                }
                state.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: chair,
                    },
                })
            }}
            args="?doubleHeight=1"
        />
    )
}

//[{ layers: 32, color: "white" },]
//Color object
//{rgb, name}

function totalBlockSize(blocks) {
    let count = 0
    try {
        for (const b of blocks) {
            if (b.type === "light") {
                count = count + b.layers * 3
            } else if (b.type === "designerBoard") {
                count = count + b.layers * 2
            } else {
                count = count + b.layers
            }
        }
    } catch (e) {
        return 0
    }
    return count
}

export function onePerLayerColors(arg, blocks, colorObjs, blockSize, chair) {
    const b = []

    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i]
        if (arg === "strip_lights") {
            if (block.color === "light") {
                b.push({
                    layers: 1,
                    color: blocks[i - 1].color,
                    colorName: blocks[i - 1].colorName,
                    type: blocks[i - 1].type,
                })
                b.push({
                    layers: 1,
                    color: blocks[i - 1].color,
                    colorName: blocks[i - 1].colorName,
                    type: blocks[i - 1].type,
                })
                i++
                continue
            }
        }
        for (let c = 0; c < block.layers; c++) {
            if (block.type == "designerBoardDISABLER") {
                b.push({
                    layers: 1,
                    color: block.color,
                    colorName: block.colorName,
                    type: "db_opt",
                })
                b.push({
                    layers: 1,
                    color: block.color,
                    colorName: block.colorName,
                    type: "db_opt",
                })
            } else {
                b.push({
                    layers: 1,
                    color: block.color,
                    colorName: block.colorName,
                    type: block.type,
                })
            }
        }
    }
    return [b, colorObjs, blockSize, chair]
}

function autoLightPlacement(arg, blocks, colorObjs, blockSize, chair) {
    const l = onePerLayerColors(
        "strip_lights",
        blocks,
        colorObjs,
        blockSize,
        chair
    )
    const r = placeLightsColor(arg, ...l)

    const f = optmizeColor(1, ...r)
    return f
}
//Assumes onePerLayerColors was just run
export function placeLightsColor(arg, blocks, colorObjs, blockSize, chair) {
    const b = []
    const div = Math.ceil(blockSize / (Number(arg) + 1))
    let lights = []

    if (
        chair.config.lights &&
        chair.config.lights[chair.tube.length] &&
        chair.config.lights[chair.tube.length][arg]
    ) {
        lights = [...chair.config.lights[chair.tube.length][arg]]
    } else {
        for (let l = 1; l <= arg; l++) {
            lights.push({
                start: l * div - 2 + 0,
                end: l * div + 1,
                set: true,
            })
        }
    }
    let adder = 0
    const setLights = []

    for (let bc = 0; bc < blockSize; bc++) {
        const found = lights.find((l) => {
            return l.start <= bc + adder && !setLights.includes(l.start)
        })

        if (found !== undefined) {
            if (!blocks[bc]) {
                continue
            }
            found.set = false
            //Light start
            b.push({
                layers: 1,
                color: "light",
                colorName: "light",
                type: "light",
            })
            setLights.push(found.start)

            if (blocks[bc].type === "designerBoard") {
                ///adder++
                // bc++
            } else {
                bc++
                bc++
            }
        } else {
            if (!blocks[bc]) {
                continue
            }
            if (blocks[bc].type === "designerBoard") {
                adder++
            }
            b.push(blocks[bc])
        }
    }

    return [b, colorObjs, blockSize, chair]
}

// Algo format
// arg, blocks, colors, blockSize(for optmization), chair

function splitColors(number, blocks, colorObjs, blockSize, chair) {
    const size = Math.floor(blockSize / number)
    let leftover = blockSize - size * number
    const b = []
    for (let i = 0; i < number; i++) {
        let s = size
        if (leftover > 0) {
            s++
            leftover--
        }
        b.push({
            layers: s,
            color: colorObjs[i].rgb,
            colorName: colorObjs[i].name,
        })
    }
    if (leftover > 0) {
        b[b.length - 1].layers = b[b.length - 1].layers + leftover
    }
    return [b, colorObjs, blockSize, chair]
}

function reverseBlocks(forward, blocks, colorObjs, blockSize, chair) {
    return [cloneDeep(blocks).reverse(), colorObjs, blockSize, chair]
}

function extractColor(int, blocks, colorObjs, blockSize, chair) {
    return [blocks, [cloneDeep(colorObjs)[int]], blockSize, chair]
}
function pushColor(int, blocks, colorObjs, blockSize, chair) {
    let color = cloneDeep(colorObjs)
    color.shift()
    return [blocks, color, blockSize, chair]
}
function removeColor(int, blocks, colorObjs, blockSize, chair) {
    let color = cloneDeep(colorObjs)
    color.splice(int, 1)
    return [blocks, color, blockSize, chair]
}
function popColor(int, blocks, colorObjs, blockSize, chair) {
    let color = cloneDeep(colorObjs)
    color.pop()
    return [blocks, color, blockSize, chair]
}

function reverseColors(noop, blocks, colorObjs, blockSize, chair) {
    return [blocks, cloneDeep(colorObjs).reverse(), blockSize, chair]
}

function doubleColors(reverse, blocks, colorObjs, blockSize, chair) {
    let o = []
    colorObjs = cloneDeep(colorObjs)
    if (reverse) {
        o = colorObjs.concat(colorObjs.reverse())
    } else {
        o = colorObjs.concat(colorObjs)
    }
    return [blocks, o, blockSize, chair]
}

function scatterColors(seed, blocks, colorObjs, blockSize, chair) {
    const b = []

    for (let i = 0; i < blockSize; i++) {
        const rng = seedrandom(i + seed)
        const c = colorObjs[Math.floor(rng() * colorObjs.length)]

        b.push({
            layers: 1,
            color: c.rgb,
            colorName: c.name,
        })
    }
    return optmizeColor(null, b, colorObjs, blockSize, chair)
}

function totalChairLayers(chair) {
    let size = 0
    let numParts = 2
    if (chair.seat) {
        size = totalBlockSize(chair.seat.blocks)
    }
    if (chair.tube) {
        size = totalBlockSize(chair.tube.blocks)
    }
    if (chair.type === "armChair") {
        size = size + totalBlockSize(chair.leftArm.blocks)
        numParts = 3
    }
    if (chair.back) {
        size = size + totalBlockSize(chair.back.blocks)
    }
    return [size, numParts]
}

function proportionedColorPart(colorList, start, end) {
    const blocks = []
    let currentBlock = {
        layers: 0,
        color: colorList[start].rgb,
        colorName: colorList[start].name,
    }
    for (let i = start; i <= end; i++) {
        if (currentBlock.color !== colorList[i].rgb) {
            blocks.push(currentBlock)
            currentBlock = {
                layers: 0,
                color: colorList[i].rgb,
                colorName: colorList[i].name,
            }
        }
        currentBlock.layers++
    }
    blocks.push(currentBlock)

    return blocks
}

// 37 * 1

function proportionedColor(arg, blocks, colorObjs, blockSize, chair) {
    const [totalLayers, numParts] = totalChairLayers(chair)
    let singleBlockSize = Math.ceil(totalLayers / colorObjs.length)
    const colors = []

    //Create a contious list of colors
    for (let i = 0; i <= totalLayers; i++) {
        colors.push(colorObjs[Math.floor(i / singleBlockSize)])
    }

    if (arg === "back") {
        return [
            proportionedColorPart(
                colors,
                0,
                totalBlockSize(chair.back.blocks) - 1
            ),
            colorObjs,
            blockSize,
            chair,
        ]
    }
    if (arg === "arm" && chair.type === "armChair") {
        return [
            proportionedColorPart(
                colors,
                totalBlockSize(chair.back.blocks),
                totalBlockSize(chair.back.blocks) +
                    totalBlockSize(chair.leftArm.blocks) -
                    1
            ),
            colorObjs,
            blockSize,
            chair,
        ]
    }
    if (arg === "arm" && chair.type === "cornerChair") {
        return [
            proportionedColorPart(
                colors,
                totalBlockSize(chair.back.blocks) -
                    totalBlockSize(chair.leftArm.blocks),
                totalBlockSize(chair.back.blocks) - 1
            ),
            colorObjs,
            blockSize,
            chair,
        ]
    }
    if (arg === "seat") {
        return [
            proportionedColorPart(
                colors,
                totalLayers - totalBlockSize(chair.seat.blocks),
                totalLayers - 1
            ),
            colorObjs,
            blockSize,
            chair,
        ]
    }

    return [blocks, colorObjs, blockSize, chair]
}

function middleLineColor(top, blocks, colorObjs, blockSize, chair) {
    let b = cloneDeep(blocks)
    const middle = Math.floor(b.length / 2)

    b[b.length - 1].layers--
    if (!top) {
        b.splice(middle, 0, {
            layers: 1,
            color: colorObjs[colorObjs.length - 1].rgb,
            colorName: colorObjs[colorObjs.length - 1].name,
        })
    } else {
        b.unshift({
            layers: 1,
            color: colorObjs[colorObjs.length - 1].rgb,
            colorName: colorObjs[colorObjs.length - 1].name,
        })
    }
    return [b, colorObjs, blockSize, chair]
}

export function optmizeColor(arg, blocks, colorObjs, blockSize, chair) {
    let b = cloneDeep(blocks)
    let lastBlock = null
    const newBlocks = []
    for (const l of b) {
        if (l.color === "light") {
            newBlocks.push(lastBlock)
            lastBlock = l
            continue
        }
        if (lastBlock !== null) {
            if (
                lastBlock.color === l.color &&
                lastBlock.diameter === l.diameter
            ) {
                lastBlock.layers = lastBlock.layers + l.layers
            } else {
                newBlocks.push(lastBlock)
                lastBlock = l
            }
        } else {
            lastBlock = l
        }
    }
    if (lastBlock) {
        newBlocks.push(lastBlock)
    }
    for (const b of newBlocks) {
        if (b.type === "db_opt") {
            b.type = "designerBoard"
            b.layers = b.layers / 2
        }
    }
    return [newBlocks, colorObjs, blockSize, chair]
}

function designerBoardColor(arg, blocks, colorObjs, blockSize, chair) {
    if (chair.seat && !chair.seat.supportsBoard) {
        return [blocks, colorObjs, blockSize, chair]
    }
    if (chair.boardColors.length < 1) {
        return [blocks, colorObjs, blockSize, chair]
    }

    const minDepth =
        chair.seat && chair.seat.minBoardDepth ? chair.seat.minBoardDepth : 3
    let b = cloneDeep(blocks)

    let layers = 0
    let raw = arg
    let offset = Math.floor(arg)
    for (let i = 1; i < b.length; i++) {
        layers = layers + blocks[i - 1].layers

        if (i % offset === 0) {
            if (layers > minDepth) {
                if (b[i].layers > 1) {
                    b[i].colorName = chair.boardColors[0]
                    b[i].color = chair.boardColorsRGB[chair.boardColors[0]]
                    b[i].type = "designerBoard"
                    if (b[i].layers % 2 !== 0) {
                        b[0].layers++
                    }
                    b[i].layers = Math.floor(b[i].layers / 2)
                }
            }
            raw = raw + arg
            offset = Math.floor(raw)
        }
    }
    return [b, colorObjs, blockSize, chair]
}

const lightPatternConfig = [
    [],
    [
        {
            tube: [
                { func: splitColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: splitColors, arg: 1 },
                { func: autoLightPlacement, arg: 2 },
            ],
        },
        {
            tube: [
                { func: splitColors, arg: 1 },
                { func: autoLightPlacement, arg: 1 },
            ],
        },
    ],
    [
        /* 2 colors */
        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [
                { func: reverseColors, arg: 1 },

                { func: splitColors, arg: 1 },
            ],
            tube_2: [
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
                { func: reverseBlocks, arg: 1 },
            ],
            tube_3: [{ func: splitColors, arg: 1 }],
        },
        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 1 }],
            tube_2: [
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_3: [{ func: splitColors, arg: 1 }],
        },
        {
            tube: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 0 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
                { func: reverseBlocks, arg: 0 },
                { func: autoLightPlacement, arg: 4 },
            ],
        },
        {
            tube: [
                { func: splitColors, arg: 2 },
                { func: autoLightPlacement, arg: 2 },
            ],
            tube_1: [{ func: splitColors, arg: 1 }],
            tube_2: [
                { func: reverseColors, arg: 1 },
                { func: splitColors, arg: 1 },
            ],
            tube_3: [
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
            ],
        },
        {
            tube: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 4 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: doubleColors, arg: false },
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 8 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },

        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: scatterColors, arg: 1 }],
            tube_2: [
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_3: [{ func: scatterColors, arg: 1 }],
        },

        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: scatterColors, arg: 1 }],
            tube_2: [{ func: scatterColors, arg: 1 }],
            tube_3: [{ func: scatterColors, arg: 1 }],
        },
    ],
    [
        /* 3 colors */
        {
            tube: [
                { func: onePerLayerColors, arg: "1" },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 3 }],
            tube_2: [
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_3: [
                { func: reverseColors, arg: 1 },
                { func: splitColors, arg: 3 },
            ],
        },
        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 2 }],
            tube_2: [
                { func: reverseColors, arg: 1 },

                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 2 },
            ],
            tube_3: [{ func: splitColors, arg: 1 }],
        },
        {
            tube: [
                { func: splitColors, arg: 2 },
                { func: middleLineColor, arg: true },
                { func: reverseBlocks, arg: 0 },

                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: splitColors, arg: 3 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: splitColors, arg: 2 },
                { func: middleLineColor, arg: false },
                { func: reverseBlocks, arg: 0 },
                { func: middleLineColor, arg: true },
                { func: autoLightPlacement, arg: 4 },
            ],
        },
        {
            tube: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
                { func: autoLightPlacement, arg: 4 },
            ],
        },
        {
            tube: [
                { func: reverseColors, arg: true },
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: onePerLayerColors, arg: true },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 1 }],
            tube_2: [
                { func: scatterColors, arg: 1 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_3: [{ func: splitColors, arg: 1 }],
        },
        {
            tube: [
                { func: scatterColors, arg: 12 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
    ],
    [
        /* 4 colors */
        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 2 }],
            tube_2: [{ func: splitColors, arg: 4 }],
            tube_3: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 1 },
            ],
        },
        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 3 }],
            tube_2: [
                { func: reverseColors, arg: 1 },
                { func: splitColors, arg: 1 },
            ],
            tube_3: [{ func: splitColors, arg: 2 }],
        },
        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 3 }],
            tube_2: [
                { func: reverseColors, arg: 1 },
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_3: [{ func: splitColors, arg: 3 }],
        },
        {
            tube: [
                { func: splitColors, arg: 2 },
                { func: middleLineColor, arg: true },
                { func: reverseColors, arg: true },
                { func: middleLineColor, arg: true },
                { func: reverseBlocks, arg: 1 },

                { func: autoLightPlacement, arg: 4 },
            ],
        },
        {
            tube: [
                { func: doubleColors, arg: 2 },
                { func: splitColors, arg: 8 },
                { func: middleLineColor, arg: true },
                { func: reverseColors, arg: true },
                { func: middleLineColor, arg: false },
                { func: autoLightPlacement, arg: 2 },
            ],
        },
        {
            tube: [
                { func: splitColors, arg: 4 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 8 },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
        {
            tube: [
                { func: onePerLayerColors, arg: true },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [
                { func: splitColors, arg: 1 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_2: [
                { func: scatterColors, arg: 1 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_3: [{ func: splitColors, arg: 1 }],
        },
        {
            tube: [
                { func: doubleColors, arg: false },

                { func: doubleColors, arg: false },

                { func: scatterColors, arg: true },
                { func: autoLightPlacement, arg: 3 },
            ],
        },
    ],
    [
        /* 5 colors */
        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
            ],
            tube_2: [{ func: splitColors, arg: 5 }],
            tube_3: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 1 },
            ],
        },

        {
            tube: [
                { func: onePerLayerColors, arg: 1 },
                { func: autoLightPlacement, arg: 3 },
            ],
            tube_1: [{ func: splitColors, arg: 5 }],
            tube_2: [
                { func: reverseColors, arg: 1 },
                { func: splitColors, arg: 2 },
            ],
            tube_3: [{ func: splitColors, arg: 5 }],
        },

        {
            tube: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 10 },
                { func: autoLightPlacement, arg: 4 },
            ],
        },
    ],
]

const patternConfig = [
    [],
    [
        {
            seat: [{ func: splitColors, arg: 1 }],
            leftArm: [{ func: splitColors, arg: 1 }],
            rightArm: [{ func: splitColors, arg: 1 }],
            back: [{ func: splitColors, arg: 1 }],
        },
        {
            seat: [
                { func: doubleColors, arg: 2 },
                { func: splitColors, arg: 2 },
                { func: designerBoardColor, arg: 1 },
            ],
            leftArm: [{ func: splitColors, arg: 1 }],
            rightArm: [{ func: splitColors, arg: 1 }],
            back: [{ func: splitColors, arg: 1 }],
        },
        {
            seat: [
                { func: doubleColors, arg: 2 },
                { func: splitColors, arg: 2 },
                { func: doubleColors, arg: 2 },
                { func: doubleColors, arg: 2 },
                { func: splitColors, arg: 2 },
                { func: splitColors, arg: 8 },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [{ func: splitColors, arg: 1 }],
            rightArm: [{ func: splitColors, arg: 1 }],
            back: [{ func: splitColors, arg: 1 }],
        },
    ],

    [
        /* 2 colors */
        {
            seat: [{ func: splitColors, arg: 1 }],
            leftArm: [{ func: splitColors, arg: 2 }],
            rightArm: [{ func: splitColors, arg: 2 }],
            back: [{ func: splitColors, arg: 1 }],
        },
        {
            seat: [
                { func: reverseColors, arg: 0 },
                { func: splitColors, arg: 1 },
                { func: onePerLayerColors, arg: 1 },
            ],
            leftArm: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 0 },
            ],
            rightArm: [{ func: splitColors, arg: 2 }],
            back: [
                { func: reverseColors, arg: 0 },
                { func: splitColors, arg: 1 },
            ],
        },
        {
            seat: [
                { func: splitColors, arg: 2 },

                { func: reverseBlocks, arg: 0 },
            ],
            leftArm: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 0 },
            ],
            rightArm: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 0 },
            ],
            back: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 0 },
            ],
        },
        {
            seat: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            rightArm: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            back: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
        },
        {
            seat: [
                { func: proportionedColor, arg: "seat" },
                { func: designerBoardColor, arg: 1 },
            ],
            leftArm: [{ func: proportionedColor, arg: "arm" }],
            rightArm: [{ func: proportionedColor, arg: "arm" }],
            back: [{ func: proportionedColor, arg: "back" }],
        },
        {
            seat: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 4 },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 4 },
            ],
            rightArm: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 4 },
            ],
            back: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 4 },
            ],
        },
        {
            seat: [
                { func: doubleColors, arg: false },
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 8 },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [
                { func: doubleColors, arg: true },
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 8 },
            ],
            rightArm: [
                { func: doubleColors, arg: false },
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 8 },
            ],
            back: [
                { func: doubleColors, arg: false },
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 8 },
            ],
        },
        {
            seat: [
                { func: scatterColors, arg: 1 },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [{ func: splitColors, arg: 1 }],
            rightArm: [{ func: splitColors, arg: 1 }],
            back: [{ func: scatterColors, arg: 0 }],
        },
        {
            seat: [
                { func: scatterColors, arg: 10 },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [{ func: scatterColors, arg: 10 }],
            rightArm: [{ func: scatterColors, arg: 10 }],
            back: [{ func: scatterColors, arg: 10 }],
        },
    ],
    [
        /* 3 colors */
        {
            seat: [{ func: splitColors, arg: 1 }],
            leftArm: [
                { func: reverseColors, arg: 0 },
                { func: splitColors, arg: 1 },
            ],
            rightArm: [
                { func: reverseColors, arg: 0 },
                { func: splitColors, arg: 1 },
            ],
            back: [{ func: splitColors, arg: 1 }],
        },
        {
            seat: [
                { func: extractColor, arg: 1 },
                { func: splitColors, arg: 1 },
            ],
            leftArm: [
                { func: extractColor, arg: 2 },
                { func: splitColors, arg: 1 },
            ],
            rightArm: [
                { func: extractColor, arg: 0 },
                { func: splitColors, arg: 1 },
            ],
            back: [
                { func: extractColor, arg: 1 },
                { func: splitColors, arg: 1 },
            ],
        },
        {
            seat: [{ func: splitColors, arg: 2 }],
            leftArm: [
                { func: pushColor, arg: 2 },
                { func: splitColors, arg: 2 },
            ],
            rightArm: [
                { func: pushColor, arg: 2 },
                { func: splitColors, arg: 2 },
            ],
            back: [{ func: splitColors, arg: 2 }],
        },
        {
            seat: [{ func: proportionedColor, arg: "seat" }],
            leftArm: [{ func: proportionedColor, arg: "arm" }],
            rightArm: [{ func: proportionedColor, arg: "arm" }],
            back: [{ func: proportionedColor, arg: "back" }],
        },
        {
            seat: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            leftArm: [
                { func: popColor, arg: 1 },
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: 1 },
            ],
            rightArm: [
                { func: popColor, arg: 1 },
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: 1 },
            ],
            back: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
        },
        {
            seat: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
            ],
            rightArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
            ],
            back: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
            ],
        },
        {
            seat: [
                { func: reverseColors, arg: true },
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 6 },
            ],
            rightArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
            ],
            back: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 6 },
            ],
        },
        {
            seat: [
                { func: scatterColors, arg: true },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 6 },
            ],
            rightArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
            ],
            back: [{ func: scatterColors, arg: false }],
        },
        {
            seat: [
                { func: scatterColors, arg: 12 },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [{ func: scatterColors, arg: 12 }],
            rightArm: [{ func: scatterColors, arg: 12 }],
            back: [{ func: scatterColors, arg: 12 }],
        },
    ],
    [
        /* 4 colors */
        {
            seat: [
                { func: extractColor, arg: 0 },
                { func: splitColors, arg: 1 },
            ],
            leftArm: [
                { func: extractColor, arg: 1 },
                { func: splitColors, arg: 1 },
            ],
            rightArm: [
                { func: extractColor, arg: 2 },
                { func: splitColors, arg: 1 },
            ],
            back: [
                { func: extractColor, arg: 3 },
                { func: splitColors, arg: 1 },
            ],
        },
        {
            seat: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: 2 },
            ],
            leftArm: [
                { func: pushColor, arg: false },
                { func: pushColor, arg: false },
                { func: splitColors, arg: 2 },
            ],
            rightArm: [
                { func: pushColor, arg: false },
                { func: pushColor, arg: false },
                { func: splitColors, arg: 2 },
            ],
            back: [{ func: splitColors, arg: 2 }],
        },
        {
            seat: [{ func: splitColors, arg: 2 }],
            leftArm: [
                { func: popColor, arg: false },
                { func: popColor, arg: false },
                { func: reverseColors, arg: false },
                { func: splitColors, arg: 2 },
            ],
            rightArm: [
                { func: removeColor, arg: 2 },
                { func: removeColor, arg: 1 },
                { func: reverseColors, arg: 2 },

                { func: splitColors, arg: 2 },
            ],
            back: [
                { func: reverseColors, arg: 2 },
                { func: splitColors, arg: 2 },
            ],
        },
        {
            seat: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            leftArm: [
                { func: popColor, arg: 1 },
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            rightArm: [
                { func: popColor, arg: 1 },
                { func: popColor, arg: 1 },
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            back: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
        },
        {
            seat: [{ func: proportionedColor, arg: "seat" }],
            leftArm: [{ func: proportionedColor, arg: "arm" }],
            rightArm: [{ func: proportionedColor, arg: "arm" }],
            back: [{ func: proportionedColor, arg: "back" }],
        },
        {
            seat: [
                { func: splitColors, arg: 4 },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [{ func: splitColors, arg: 4 }],
            rightArm: [{ func: splitColors, arg: 4 }],
            back: [{ func: splitColors, arg: 4 }],
        },
        {
            seat: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 8 },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 8 },
            ],
            rightArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 8 },
            ],
            back: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 8 },
            ],
        },
        {
            seat: [
                { func: scatterColors, arg: true },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 6 },
            ],
            rightArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 6 },
            ],
            back: [{ func: scatterColors, arg: false }],
        },
        {
            seat: [
                { func: scatterColors, arg: true },
                { func: designerBoardColor, arg: 1.25 },
            ],
            leftArm: [{ func: scatterColors, arg: false }],
            rightArm: [{ func: scatterColors, arg: true }],
            back: [{ func: scatterColors, arg: false }],
        },
    ],
    [
        /* 5 colors */
        {
            seat: [
                { func: reverseColors, arg: 2 },
                { func: splitColors, arg: 2 },
            ],
            leftArm: [
                { func: extractColor, arg: 1 },
                { func: splitColors, arg: 1 },
            ],
            rightArm: [
                { func: extractColor, arg: 2 },
                { func: splitColors, arg: 1 },
            ],
            back: [
                { func: extractColor, arg: 3 },
                { func: splitColors, arg: 1 },
            ],
        },
        {
            seat: [
                { func: removeColor, arg: 0 },
                { func: removeColor, arg: 1 },
                { func: removeColor, arg: 1 },
                { func: splitColors, arg: 2 },
            ],
            leftArm: [
                { func: pushColor, arg: 0 },
                { func: pushColor, arg: 0 },
                { func: splitColors, arg: 2 },
            ],
            rightArm: [
                { func: pushColor, arg: 0 },
                { func: pushColor, arg: 0 },
                { func: splitColors, arg: 2 },
            ],
            back: [{ func: splitColors, arg: 2 }],
        },
        {
            seat: [
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: false },
            ],
            leftArm: [
                { func: pushColor, arg: false },
                { func: pushColor, arg: false },
                { func: splitColors, arg: 2 },
                { func: reverseBlocks, arg: false },
            ],
            rightArm: [
                { func: pushColor, arg: false },
                { func: pushColor, arg: false },
                { func: splitColors, arg: 2 },
            ],
            back: [
                { func: removeColor, arg: 1 },
                { func: removeColor, arg: 1 },
                { func: removeColor, arg: 1 },
                { func: splitColors, arg: 2 },
            ],
        },
        {
            seat: [{ func: proportionedColor, arg: "seat" }],
            leftArm: [{ func: proportionedColor, arg: "arm" }],
            rightArm: [{ func: proportionedColor, arg: "arm" }],
            back: [{ func: proportionedColor, arg: "back" }],
        },
        {
            seat: [
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            leftArm: [
                { func: popColor, arg: 1 },
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            rightArm: [
                { func: popColor, arg: 1 },
                { func: popColor, arg: 1 },
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
            back: [
                { func: popColor, arg: 1 },
                { func: popColor, arg: 1 },
                { func: popColor, arg: 1 },
                { func: splitColors, arg: 1 },
                { func: middleLineColor, arg: true },
            ],
        },
        {
            seat: [
                { func: splitColors, arg: 5 },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [{ func: splitColors, arg: 5 }],
            rightArm: [{ func: splitColors, arg: 5 }],
            back: [{ func: splitColors, arg: 5 }],
        },
        {
            seat: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 10 },
                { func: designerBoardColor, arg: 2 },
            ],
            leftArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 10 },
            ],
            rightArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 10 },
            ],
            back: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 10 },
            ],
        },
        {
            seat: [
                { func: scatterColors, arg: true },
                { func: designerBoardColor, arg: 1.5 },
            ],
            leftArm: [
                { func: doubleColors, arg: false },
                { func: splitColors, arg: 10 },
            ],
            rightArm: [
                { func: doubleColors, arg: true },
                { func: splitColors, arg: 10 },
            ],
            back: [{ func: scatterColors, arg: false }],
        },
        {
            seat: [
                { func: scatterColors, arg: true },
                { func: designerBoardColor, arg: 1.2 },
            ],
            leftArm: [{ func: scatterColors, arg: false }],
            rightArm: [{ func: scatterColors, arg: true }],
            back: [{ func: scatterColors, arg: false }],
        },
    ],
]

function applyBlock(pattern, blocks, colorObjs, chair) {
    const size = totalBlockSize(blocks)
    let b = [blocks, colorObjs, size, chair]

    for (const p of pattern) {
        if (p.func === autoLightPlacement) {
            p.arg = chair.light.number
        }
        b = p.func(p.arg, b[0], b[1], b[2], b[3])
    }
    return b[0]
}
function splitTube(blocks) {
    const bSets = []
    let bIndex = 0
    let currentDiameter = null
    for (const b of blocks) {
        if (b.layers === 0) {
            continue
        }
        if (!currentDiameter) {
            currentDiameter = b.diameter
        }
        if (currentDiameter !== b.diameter) {
            currentDiameter = b.diameter
            bIndex++
        }
        if (!bSets[bIndex]) {
            bSets[bIndex] = []
        }
        bSets[bIndex].push(b)
    }
    return cloneDeep(bSets)
}

function combineTube(blocks) {
    return [].concat(...blocks)
}

function applyPattern(pattern, colorObjs, chair, lightNum) {
    if (chair.tube) {
        const newBlock = realizeLayers(
            chair.tube.blocks,
            pendentConfig[chair.form]
        )

        if (pattern["tube_1"]) {
            const newTube = []
            const tubes = splitTube(newBlock)

            for (const t in tubes) {
                const patternIndex = Number(t) + 1
                newTube[t] = applyBlock(
                    pattern["tube_" + patternIndex],
                    tubes[t],
                    colorObjs,
                    chair
                )
            }

            chair.tube.blocks = combineTube(newTube)
        }
        chair.tube.blocks = applyBlock(
            pattern.tube,
            chair.tube.blocks,
            colorObjs,
            chair
        )
    }
    if (chair.seat) {
        chair.seat.blocks = applyBlock(
            pattern.seat,
            chair.seat.blocks,
            colorObjs,
            chair
        )
    }
    if (chair.back) {
        chair.back.blocks = applyBlock(
            pattern.back,
            chair.back.blocks,
            colorObjs,
            chair
        )
    }
    if (chair.leftArm) {
        chair.leftArm.blocks = applyBlock(
            pattern.leftArm,
            chair.leftArm.blocks,
            colorObjs,
            chair
        )
    }
    if (chair.rightArm) {
        chair.rightArm.blocks = applyBlock(
            pattern.rightArm,
            chair.rightArm.blocks,
            colorObjs,
            chair
        )
    }
    return chair
}

function colorObjectsFromChair(chair) {
    const colors = []
    for (const c in Object.values(chair.blockColors)) {
        colors.push({
            rgb: chair.blockColorsRGB[chair.blockColors[c]],
            name: chair.blockColors[c],
        })
    }
    return colors
}

export function NeosPatterns(props) {
    const state = useGlobalState()
    const [chairPattern, setChairPattern] = useState([])
    const [items, setItems] = useState([])

    const selected = state.state.progress.chair.pattern
    const pc =
        state.state.progress.chair.type === "pendent"
            ? lightPatternConfig
            : patternConfig

    //Ek copy and paste
    let mobile = 10
    if (window.innerWidth < 850) {
        mobile = 25
    }
    const [vw, setVw] = useState(mobile)

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 850) {
                setVw(25)
            } else {
                setVw(10)
            }
        }
        window.addEventListener("resize", handleResize)
    })

    useEffect(() => {
        const func = (e) => {
            if (!selected) {
                return true
            }

            const colors = colorObjectsFromChair(state.state.progress.chair)
            if (!pc[colors.length][selected]) {
                return
            }
            const chair = applyPattern(
                pc[colors.length][selected],
                colors,
                cloneDeep(state.state.progress.chair)
            )
            if (!props.noSet) {
                state.dispatch({
                    type: "CHAIR",
                    supressEvent: true,
                    payload: {
                        chair: chair,
                    },
                })
            } else {
                chair.pattern = null
            }
        }
        window.addEventListener("updateProgressChair", func, true)

        return () => {
            window.removeEventListener("updateProgressChair", func, true)
        }
    }, [selected, state, pc])

    useEffect(() => {
        const cp = []
        const colors = colorObjectsFromChair(state.state.progress.chair)

        const patterns = pc[colors.length]
        for (const p of patterns) {
            const c = applyPattern(
                p,
                colors,
                cloneDeep(state.state.progress.chair)
            )
            cp.push({ chair: c, pattern: p, colors: colors })
        }
        setChairPattern(cp)
    }, [state.state.progress.chair.blockColorsRGB, state.state.progress.chair])

    useEffect(() => {
        const i = []
        let counter = 0
        for (const c in chairPattern) {
            const cp = chairPattern[c]
            counter++
            i.push(
                <div
                    className={classNames("toggle chair", "clickable", {
                        selected: selected === c,
                    })}
                    onClick={(e) => {
                        const chair = applyPattern(
                            cp.pattern,
                            cp.colors,
                            cloneDeep(state.state.progress.chair)
                        )
                        chair.pattern = c
                        state.dispatch({
                            supressEvent: true,
                            type: "CHAIR",
                            payload: {
                                chair: chair,
                            },
                        })
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "default",
                            })
                        )
                    }}
                >
                    <span className="image">
                        {state.state.progress.chair.type === "dayBed" && (
                            <Side
                                type={cp.chair.type}
                                chair={cp.chair}
                                vw={vw}
                            />
                        )}
                        {state.state.progress.chair.type !== "dayBed" && (
                            <Front
                                type={cp.chair.type}
                                chair={cp.chair}
                                vw={vw}
                            />
                        )}
                    </span>
                </div>
            )
        }
        setItems(i)
    }, [chairPattern, selected, state, vw])

    let minBoardDepth = 0
    if (state.state.progress.chair.type !== "pendent") {
        minBoardDepth = state.state.progress.chair.seat.minBoardDepth
            ? state.state.progress.chair.seat.minBoardDepth
            : 3
    }

    return (
        <div className="toggle-platform">
            {props.hideOptions !== true && (
                <>
                    <div className="platform-colors">
                        <NeosColors />
                    </div>
                    {state.state.progress.chair.type === "pendent" &&
                        state.state.progress.chair.tube.blocks[0].color !==
                            "white" && (
                            <>
                                <h4>Number of Light Discs</h4>
                                <NumLights
                                    dispatch={state.dispatch}
                                    chair={state.state.progress.chair}
                                />
                            </>
                        )}
                    {state.state.progress.chair.type !== "pendent" &&
                        state.state.progress.chair.blockColors.length >= 1 &&
                        state.state.progress.chair.seat.supportsBoard &&
                        minBoardDepth <
                            totalBlockSize(
                                state.state.progress.chair.seat.blocks
                            ) && <PETSelect />}
                    {state.state.progress.chair.type === "pendent" &&
                        state.state.progress.chair.blockColors.length >= 1 && (
                            <PETSelect lights />
                        )}
                    <h4>Patterns</h4>
                </>
            )}
            {state.state.progress.chair.blockColors.length +
                state.state.progress.chair.boardColors.length >
                1 && items}
            {state.state.progress.chair.blockColors.length +
                state.state.progress.chair.boardColors.length <=
                1 && <b>Select more than one felt color to see patterns</b>}
        </div>
    )
}

function PETSelect(props) {
    return (
        <>
            <h4>
                Designer Board (
                <img
                    alt="info"
                    data-tip={`Replace 5mm felt with 10mm recycled <br /> Polyethylene Terephthalate (PET). <br />
            PET can be used anywhere below  <br /> the first 3 felt seat layers.<br />
            Note:<br/> that 1 PET layer is equal to 2 felt  <br />  layers and is approximately <br/> 1/2 the cost`}
                    className="info"
                    src={Info}
                />
                )
            </h4>
            <NeosColors designerBoard />
        </>
    )
}

function NumLightsTube(props) {
    const chair = props.chair
    const max = chair.form === "tube" && chair.tube.diameter === 5 ? 8 : 6

    useEffect(() => {
        if (chair.light.number > max) {
            const c = cloneDeep(chair)
            c.light.number = max
            props.dispatch({
                type: "CHAIR",
                payload: {
                    chair: c,
                },
            })
        }
    }, [chair.tube.diameter, max, chair, props])

    return (
        <SliderControl
            step={1}
            name="length"
            label="Lights"
            value={Number(chair.light.number)}
            display={chair.light.number}
            callback={(e) => {
                const c = cloneDeep(chair)
                c.light.number = e.target.value
                props.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: c,
                    },
                })
            }}
            range={[1, max]}
            discrete
        />
    )
}
function NumLights(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair

    return <NumLightsTube {...props} />

    function setSize(size) {
        const chair = cloneDeep(globalState.state.progress.chair)
        chair.light.number = size

        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: chair,
            },
        })
    }
}
function NeosCustomColor(props) {
    const color = props.color
    const number = props.number
    const colorName = props.colorName
    const baseUrl = props.baseUrl
    const min = props.min
    const max = props.max
    const [id, setId] = useState(Math.random() * 1000)
    const [editColor, setEditColor] = useState(false)
    const ext = props.extension ? "." + props.extension : ""
    return (
        <>
            {" "}
            <ReactTooltip
                multiline={true}
                type="dark"
                effect="float"
                place="top"
            />
            <div
                id={id}
                style={{ cursor: "grab" }}
                draggable={true}
                data-tip={capitalizeFirstLetter(colorName)}
                onDragStart={(e) => {
                    props.onDrag(e, document.getElementById(id))
                }}
                onDragEnd={(e) => {
                    props.onDragEnd(e, document.getElementById(id))
                }}
                onDragOver={(e) => {
                    e.preventDefault()

                    props.onDragOver(e, document.getElementById(id))
                }}
                onDrop={(e) => {
                    e.preventDefault()

                    props.onDrop(props.item, document.getElementById(id))
                }}
            >
                {editColor && (
                    <ColorModel
                        designerBoard={props.item.type === "designerBoard"}
                        exclude={[colorName]}
                        close={(e) => {
                            setEditColor(false)
                            e.stopPropagation()
                        }}
                        colorSelected={(c, rgb, e) => {
                            e.stopPropagation()
                            props.onColorChange(c, rgb, e)
                            setEditColor(false)
                        }}
                    />
                )}
                <div className="custom-block">
                    <span style={{ width: "25px" }}>
                        <img
                            onClick={() => {
                                props.moveDown(props.item)
                            }}
                            style={{ paddingTop: "10px", width: "20px" }}
                            src={Down}
                            alt="Down"
                        />
                    </span>
                    <span style={{ width: "25px" }}>
                        <img
                            onClick={() => {
                                props.moveUp(props.item)
                            }}
                            style={{ paddingTop: "10px", width: "20px" }}
                            src={Up}
                            alt="Up"
                        />
                    </span>
                    {props.item.type === "light" && (
                        <>
                            <div className="block-color light-item">
                                <img
                                    src={LightBulb}
                                    alt="lightbulb"
                                    style={{ width: "30px", margin: "auto" }}
                                />
                            </div>
                            <div style={{ width: "40px" }}> </div>
                        </>
                    )}

                    {props.item.type !== "light" && (
                        <>
                            <img
                                crossOrigin="Anonymous"
                                alt={color}
                                id={"color-" + colorName}
                                src={props.baseUrl + colorName + ext}
                                className="block-color"
                                onClick={() => {
                                    // if (props.item.type !== "designerBoard") {
                                    setEditColor(true)
                                    //}
                                }}
                            ></img>
                            <div
                                style={{ width: "40px" }}
                                onClick={() => {
                                    setEditColor(true)
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.94 5 19 10.06 9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L13.938 5Zm7.09-2.03a3.578 3.578 0 0 1 0 5.06l-.97.97L15 3.94l.97-.97a3.578 3.578 0 0 1 5.06 0Z"
                                        fill="#212121"
                                    />
                                </svg>
                            </div>
                        </>
                    )}

                    <div style={{ width: "60px" }}>
                        {props.typeLabel && (
                            <span className="label">{props.typeLabel}</span>
                        )}
                    </div>
                    <div style={{ width: "60px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {props.item.type !== "light" && (
                                <NumericInput
                                    onChange={(v) => {
                                        props.onNumberChange(v)
                                    }}
                                    min={min}
                                    max={max}
                                    value={number}
                                />
                            )}
                        </div>
                    </div>
                    <span style={{ width: "2vw" }}>&nbsp;</span>
                    <img
                        onClick={() => {
                            props.onDelete()
                        }}
                        className="trash"
                        src={Trash}
                        alt="trash"
                    />
                </div>
                {props.showSpacer && (
                    <div
                        style={{
                            height: "2.2vw",
                            width: "100%",
                            backgroundColor: "lightgrey",
                            marginBottom: "1vh",
                            marginTop: "0",
                        }}
                    ></div>
                )}
            </div>
        </>
    )
}

function validateBoard(blocks, deletedBlock = null, minBoardDepth = 3) {
    let pos = 0
    if (deletedBlock && deletedBlock.type === "designerBoard") {
        return true
    }
    let count = 0
    for (const b of blocks) {
        if (b === deletedBlock) {
            continue
        }
        if (b.type === "light" && b.layers !== 1) {
            return false
        }

        if (b.type === "light" && count === blocks.length - 1) {
            return false
        }
        if (b.type === "light" && count === 0) {
            return false
        }

        if (pos < minBoardDepth && b.type === "designerBoard") {
            return false
        }
        if (pos > minBoardDepth) {
            //return true
        }
        pos = pos + b.layers
        ++count
    }
    return true
}

function NeosCustomSection(props) {
    const newColors = useColors()
    const [dragging, setDragging] = useState(null)
    const [draggedOver, setDraggedOver] = useState(null)

    const blocks = props.blocks
    const colors = props.colors
    const colorsRGB = props.blockColorsRGB
    const items = []

    const min = 1
    const max = totalBlockSize(blocks) - blocks.length + 1

    for (const item of blocks) {
        items.push(
            <NeosCustomColor
                onDrag={(e, target) => {
                    setDragging(item)
                    window.requestAnimationFrame(function () {
                        if (dragging != null) {
                            target.style.display = "none"
                        }
                    })
                }}
                onDragEnd={(e, target) => {
                    window.requestAnimationFrame(function () {
                        target.style.display = ""
                    })
                    setDragging(null)
                    setDraggedOver(null)
                }}
                onDrop={(referenceItem) => {
                    const ri = blocks.indexOf(referenceItem)
                    const ti = blocks.indexOf(dragging)
                    const b = cloneDeep(blocks)
                    if (ri > -1 && ti > -1) {
                        b.splice(ti, 1)
                        b.splice(ri < ti ? ri + 1 : ri, 0, dragging)
                    }
                    setDragging(null)
                    setDraggedOver(null)
                    if (validateBoard(b, null, props.minBoardDepth)) {
                        props.onUpdateAll(b)
                    }
                }}
                moveUp={(referenceItem) => {
                    const ri = blocks.indexOf(referenceItem)
                    if (ri <= 0) {
                        return
                    }
                    const b = cloneDeep(blocks)
                    if (ri > -1) {
                        b.splice(ri, 1)
                        b.splice(ri - 1, 0, referenceItem)
                    }
                    setDraggedOver(null)
                    if (validateBoard(b, null, props.minBoardDepth)) {
                        props.onUpdateAll(b)
                    }
                }}
                moveDown={(referenceItem) => {
                    const ri = blocks.indexOf(referenceItem)
                    if (ri > blocks.length) {
                        return
                    }
                    const b = cloneDeep(blocks)
                    if (ri > -1) {
                        b.splice(ri, 1)
                        b.splice(ri + 1, 0, referenceItem)
                    }
                    setDraggedOver(null)
                    if (validateBoard(b, null, props.minBoardDepth)) {
                        props.onUpdateAll(b)
                    }
                }}
                onDragOver={(e) => {
                    setDraggedOver(item)
                }}
                showSpacer={draggedOver === item}
                item={item}
                color={item.color}
                number={item.layers}
                colorName={item.colorName}
                data-tip={item.colorName}
                typeLabel={
                    item.type === "designerBoard"
                        ? "PET"
                        : item.type === "light"
                        ? "Light"
                        : "Felt"
                }
                baseUrl={
                    item.type === "designerBoard"
                        ? Config.boardBaseUrl
                        : Config.swatchBaseUrl
                }
                extension={item.type === "designerBoard" ? "jpg" : ""}
                min={min}
                max={max}
                onNumberChange={(num) => {
                    props.onChange({ layers: num }, item)
                }}
                onDelete={(num) => {
                    props.onChange({ delete: true }, item)
                }}
                onColorChange={(c, rgb, e) => {
                    props.onChange(
                        { updateColor: { color: c, rgb: rgb } },
                        item
                    )
                }}
            />
        )
    }
    return (
        <>
            <div
                id={props.for}
                style={{
                    padding: dragging ? "1px" : "2px",
                    border: dragging ? "1px dashed black" : "none",
                }}
            >
                {items}
            </div>
            <div className="toggle-platform"></div>
            <h5>felt colors</h5>

            <UrlColorSelector
                halfHeight
                colors={newColors}
                baseUrl={Config.swatchBaseUrl}
                onClick={(value, rgb) => {
                    props.onChange(
                        { add: true, color: rgb, colorName: value },
                        null
                    )
                }}
                badge="+"
            />
            {props.light && (
                <>
                    <h5>add light discs</h5>
                    <div className="simple-color-selector toggle-platform">
                        <div
                            onClick={() => {
                                props.onChange(
                                    {
                                        add: true,
                                        color: "light",
                                        colorName: "light",
                                        type: "light",
                                    },
                                    null
                                )
                            }}
                            className="toggle compact simple-color rounded halfheight"
                        >
                            <img
                                src={LightBulb}
                                alt="light bulb"
                                style={{ width: "40px", cursor: "pointer" }}
                            />
                            <div className="badge">+</div>
                        </div>
                    </div>
                </>
            )}
            {props.supportsBoard &&
                props.minBoardDepth < totalBlockSize(blocks) && (
                    <>
                        <h5> PET (water bottle plastic) colors</h5>
                        <UrlColorSelector
                            halfHeight
                            colors={boardColors}
                            baseUrl={"/designer_board/"}
                            imageExtension="jpg"
                            onClick={(value, rgb) => {
                                props.onChange(
                                    {
                                        add: true,
                                        color: rgb,
                                        colorName: value,
                                        type: "designerBoard",
                                    },
                                    null
                                )
                            }}
                            badge="+"
                        />
                    </>
                )}
        </>
    )
}

function hasDecimal(num) {
    return !!(num % 1)
}
function rlCount(block, comp) {
    if (!comp) {
        if (block.type === "designerBoard") {
            return block.layers * 2
        }
        if (block.type === "light") {
            return block.layers * 3
        }
    }
    if (block.type === "light") {
        return block.layers * 3
    }

    if (block.type === "designerBoard" && comp.type === "designerBoard") {
        return block.layers
    }

    if (block.type === "designerBoard" && comp.type !== "designerBoard") {
        return block.layers * 2
    }

    if (block.type !== "designerBoard" && comp.type === "designerBoard") {
        return block.layers / 2
    }

    return block.layers
}

function rlIsChageOk(blocks, block, layers, minDepth = 3) {
    let size = 0
    for (let b of blocks) {
        if (b.type === "designerBoard" && size < minDepth) {
            console.log("HERERERERERER")
            return false
        }
        if (b !== block) {
            size = size + b.layers
        } else {
            size = size + layers
        }
    }
    return true
}

function numLightsInBlocks(blocks) {
    let num = 0
    for (const b of blocks) {
        if (b.type === "light") {
            num++
        }
    }
    return num
}

function updateBlocks(kv, _block, b, chair, position, dispatch) {
    const _bi = b.indexOf(_block)
    console.log(kv)
    let blocks = cloneDeep(b)
    const block = blocks[_bi]
    if (!blocks) {
        //No idea why this happens
        return
    }
    const minBoardDepth =
        chair.type === "pendent"
            ? 0
            : chair.seat && chair.seat.minBoardDepth
            ? chair.seat.minBoardDepth
            : 3
    const index = blocks.findIndex((x) => x === block)
    if (kv.layers && blocks.length > 1) {
        const ogtotal = totalBlockSize(blocks)
        const oldLayers = block.layers
        block.layers = kv.layers
        const dif = totalBlockSize(blocks) - ogtotal

        let pos = index + 1
        let good = false
        while (true) {
            if (blocks[pos] === block) {
                break
            }

            if (blocks[pos]) {
                if (blocks[pos].type === "light") {
                    pos++
                    continue
                }

                if (dif < 0) {
                    if (blocks[pos].type === "designerBoard") {
                        if (chair.type !== "pendent" && block.layers < 4) {
                            good = false
                            break
                        }
                        block.layers = block.layers - 1
                    }
                    blocks[pos].layers = blocks[pos].layers - dif
                    good = true
                    break
                } else {
                    if (blocks[pos].layers > 3) {
                        if (blocks[pos].layers - dif > 1) {
                            if (
                                !rlIsChageOk(
                                    blocks,
                                    blocks[pos],
                                    blocks[pos].layers - dif,
                                    chair.type === "pendent"
                                        ? 0
                                        : chair.seat && chair.seat.minBoardDepth
                                        ? chair.seat.minBoardDepth
                                        : 3
                                )
                            ) {
                                pos++
                                continue
                            }
                            if (blocks[pos].type === "designerBoard") {
                                block.layers = block.layers + 1
                            }
                            blocks[pos].layers = blocks[pos].layers - dif
                            good = true
                            break
                        }
                    }
                }
            }
            if (pos > blocks.length) {
                pos = 0
            } else {
                pos++
            }
        }
        if (!good) {
            block.layers = oldLayers
        }
    }
    let deletedBlock = null
    if (kv.delete) {
        let cb = null
        deletedBlock = block
        let index2 = index

        if (
            !(
                index2 === 0 &&
                blocks[1].type === "designerBoard" &&
                chair.type !== "pendent"
            )
        ) {
            if (validateBoard(blocks, blocks[index2], minBoardDepth)) {
                if (
                    blocks.length - 1 === index2 &&
                    blocks[index2 - 1].type === "light"
                ) {
                    return b
                }
                if (index2 === 0 && blocks[1].type === "light") {
                    return b
                }

                while (
                    blocks[index2 + 1] &&
                    blocks[index2 + 1].type === "light"
                ) {
                    index2++
                }

                if (blocks.length > 1) {
                    if (blocks[index2 + 1]) {
                        blocks[index2 + 1].layers =
                            blocks[index2 + 1].layers +
                            rlCount(block, blocks[index2 + 1])
                        cb = blocks[index2 + 1]
                    } else {
                        blocks[0].layers =
                            blocks[0].layers + rlCount(block, blocks[0])
                        cb = blocks[0]
                    }

                    //This happens if an off number of felt is being deleted
                    // and the replacement is designer board
                    if (hasDecimal(cb.layers)) {
                        cb.layers = Math.floor(cb.layers)
                        //we can assume this is felt
                        blocks[0].layers++
                    }
                    blocks.splice(index, 1)
                }
            }
        }
    }

    if (kv.add) {
        const newLayer = {
            layers: 1,
            color: kv.color,
            colorName: kv.colorName,
            type: kv.type,
        }

        for (let i = blocks.length - 1; i >= 0; i--) {
            if (kv.type === "designerBoard") {
                if (
                    blocks[i].type !== "designerBoard" &&
                    blocks[i].layers > 2
                ) {
                    blocks[i].layers = blocks[i].layers - 2
                    blocks.push(newLayer)
                    break
                }
                if (
                    blocks[i].type === "designerBoard" &&
                    blocks[i].layers > 1
                ) {
                    blocks[i].layers = blocks[i].layers - 1
                    blocks.push(newLayer)
                    break
                }
            } else if (kv.type === "light") {
                if (numLightsInBlocks(blocks) >= 6) {
                    break
                }
                if (blocks[i].layers >= 4) {
                    blocks[i].layers = blocks[i].layers - 3
                    blocks = realizeLayers(blocks, pendentConfig[chair.form])

                    const b1 = blocks.pop()

                    blocks.push(newLayer)
                    blocks.push(b1)
                    break
                }
            } else {
                if (blocks[i].layers > 1) {
                    if (blocks[i].type === "designerBoard") {
                        newLayer.layers = 2
                    }
                    blocks[i].layers--
                    blocks.push(newLayer)
                    break
                }
            }
        }
    }
    if (kv.updateColor) {
        blocks[index].color = kv.updateColor.rgb
        blocks[index].colorName = kv.updateColor.color
    }

    if (validateBoard(blocks, deletedBlock, minBoardDepth)) {
        const c = cloneDeep(chair)
        c[position].blocks = blocks
        dispatch({
            type: "CHAIR",
            payload: {
                chair: c,
            },
        })

        return blocks
    } else {
        console.error("Could not validate block")
        return b
    }
}

export function NeosCustom(props) {
    const state = useGlobalState()
    const chair = cloneDeep(state.state.progress.chair)
    const colors = useColors()
    return (
        <div>
            {chair.back && (
                <>
                    <h4>Back</h4>
                    <NeosCustomSection
                        light={chair.type === "pendant"}
                        blocks={chair.back.blocks}
                        blockColors={chair.blockColors}
                        blockColorsRGB={chair.blockColorsRGB}
                        colors={colors}
                        supportsBoard={chair.back.supportsBoard}
                        minBoardDepth={
                            chair.type === "pendent"
                                ? 0
                                : chair.seat.minBoardDepth
                                ? chair.seat.minBoardDepth
                                : 3
                        }
                        onChange={(kv, block) => {
                            chair.back.blocks = updateBlocks(
                                kv,
                                block,
                                chair.back.blocks,
                                chair,
                                "back",
                                state.dispatch
                            )
                        }}
                        onUpdateAll={(blocks) => {
                            const c = chair
                            c.back.blocks = blocks
                            state.dispatch({
                                type: "CHAIR",
                                payload: {
                                    chair: c,
                                },
                            })
                        }}
                    />
                </>
            )}
            {chair.leftArm && (
                <>
                    <h4>Left Arm</h4>
                    <NeosCustomSection
                        blocks={chair.leftArm.blocks}
                        blockColors={chair.blockColors}
                        blockColorsRGB={chair.blockColorsRGB}
                        colors={colors}
                        supportsBoard={chair.leftArm.supportsBoard}
                        minBoardDepth={
                            chair.type === "pendent"
                                ? 0
                                : chair.seat.minBoardDepth
                                ? chair.seat.minBoardDepth
                                : 3
                        }
                        onChange={(kv, block) => {
                            chair.leftArm.blocks = updateBlocks(
                                kv,
                                block,
                                chair.leftArm.blocks,
                                chair,
                                "leftArm",
                                state.dispatch
                            )
                        }}
                        onUpdateAll={(blocks) => {
                            const c = chair
                            c.leftArm.blocks = blocks
                            state.dispatch({
                                type: "CHAIR",
                                payload: {
                                    chair: c,
                                },
                            })
                        }}
                    />
                </>
            )}
            {chair.rightArm && (
                <>
                    <h4>Right Arm</h4>
                    <NeosCustomSection
                        blocks={chair.rightArm.blocks}
                        blockColors={chair.blockColors}
                        blockColorsRGB={chair.blockColorsRGB}
                        colors={colors}
                        supportsBoard={chair.rightArm.supportsBoard}
                        minBoardDepth={
                            chair.type === "pendent"
                                ? 0
                                : chair.seat.minBoardDepth
                                ? chair.seat.minBoardDepth
                                : 3
                        }
                        onChange={(kv, block) => {
                            chair.rightArm.blocks = updateBlocks(
                                kv,
                                block,
                                chair.rightArm.blocks,
                                chair,
                                "rightArm",
                                state.dispatch
                            )
                        }}
                        onUpdateAll={(blocks) => {
                            const c = chair
                            c.rightArm.blocks = blocks
                            state.dispatch({
                                type: "CHAIR",
                                payload: {
                                    chair: c,
                                },
                            })
                        }}
                    />
                </>
            )}
            {chair.seat && (
                <>
                    <h4>Seat</h4>
                    <NeosCustomSection
                        blocks={chair.seat.blocks}
                        blockColors={chair.blockColors}
                        blockColorsRGB={chair.blockColorsRGB}
                        colors={colors}
                        supportsBoard={chair.seat.supportsBoard}
                        minBoardDepth={
                            chair.type === "pendent"
                                ? 0
                                : chair.seat.minBoardDepth
                                ? chair.seat.minBoardDepth
                                : 3
                        }
                        onChange={(kv, block) => {
                            chair.seat.blocks = updateBlocks(
                                kv,
                                block,
                                chair.seat.blocks,
                                chair,
                                "seat",
                                state.dispatch
                            )
                        }}
                        onUpdateAll={(blocks) => {
                            const c = chair
                            c.seat.blocks = blocks
                            state.dispatch({
                                type: "CHAIR",
                                payload: {
                                    chair: c,
                                },
                            })
                        }}
                    />
                </>
            )}
            {chair.tube && (
                <>
                    <h4>Customize felt (5mm), PET (10mm), and lights</h4>
                    <NeosCustomSection
                        blocks={chair.tube.blocks}
                        blockColors={chair.blockColors}
                        blockColorsRGB={chair.blockColorsRGB}
                        colors={colors}
                        light
                        supportsBoard={true}
                        minBoardDepth={
                            chair.type === "pendent"
                                ? 0
                                : chair.tube.minBoardDepth
                                ? chair.tube.minBoardDepth
                                : 3
                        }
                        onChange={(kv, block) => {
                            chair.tube.blocks = updateBlocks(
                                kv,
                                block,
                                chair.tube.blocks,
                                chair,
                                "tube",
                                state.dispatch
                            )
                        }}
                        onUpdateAll={(blocks) => {
                            const c = chair
                            c.tube.blocks = blocks
                            state.dispatch({
                                type: "CHAIR",
                                payload: {
                                    chair: c,
                                },
                            })
                        }}
                    />
                </>
            )}
        </div>
    )
}

export function NeosPatternSelector(props) {
    const [component, setComponent] = useState("Patterns")
    const [model, setModel] = useState(false)
    const state = useGlobalState()
    const chair = state.state.progress.chair
    const pc =
        state.state.progress.chair.type === "pendent"
            ? lightPatternConfig
            : patternConfig

    useEffect(() => {
        if (chair.type === "pendent") {
            setComponent("Custom")
        }
    }, [chair.type])

    useEffect(() => {
        if (chair.blockColors.length) {
            const colors = colorObjectsFromChair(state.state.progress.chair)

            const chair = applyPattern(
                pc[1][0],
                colors,
                cloneDeep(state.state.progress.chair)
            )
            state.dispatch({
                type: "CHAIR",
                supressEvent: true,
                payload: {
                    chair: chair,
                },
            })
        }
    }, [chair.blockColors.length])

    const showCustom =
        chair.type === "pendent" || chair.seat.blocks[0].color !== "white"
    return (
        <>
            {model && (
                <YesNoModal
                    message="Selection patterns will cause your custom pattern to be lost. Continue to custom and lose your pattern?"
                    onYes={() => {
                        setComponent("Patterns")
                        setModel(false)
                    }}
                    onNo={() => {
                        setModel(false)
                    }}
                />
            )}
            {chair.blockColors.length > 0 && chair.type !== "pendent" && (
                <div className="toggle-platform">
                    <div
                        onClick={() => {
                            if (component !== "Patterns") {
                                setModel(true)
                            }
                        }}
                        className={classNames("toggle", {
                            selected: component === "Patterns",
                        })}
                    >
                        <span className="label">Patterns</span>
                        <span className="image">
                            <img
                                style={
                                    component === "Patterns"
                                        ? { filter: "invert(100%)" }
                                        : {}
                                }
                                alt="patterns"
                                src={Pattern}
                            />
                        </span>
                    </div>
                    {showCustom && (
                        <div
                            className={classNames("toggle", {
                                selected: component === "Custom",
                            })}
                            onClick={() => {
                                setComponent("Custom")
                            }}
                        >
                            <span className="label">Custom</span>
                            <span className="image">
                                <img
                                    style={
                                        component === "Custom"
                                            ? { filter: "invert(100%)" }
                                            : {}
                                    }
                                    alt="custom"
                                    src={Custom}
                                />
                            </span>
                        </div>
                    )}
                </div>
            )}
            {chair.blockColors.length === 0 && chair.type !== "pendent" && (
                <div>
                    <b>Select your first color to begin</b>
                </div>
            )}
            <div>
                {chair.type === "pendent" && (
                    <>
                        <h4>Pattern Presets</h4>
                        <NeosPatterns hideOptions noSet />
                    </>
                )}

                {component === "Patterns" && <NeosPatterns />}
                {component === "Custom" && <NeosCustom />}
            </div>
        </>
    )
}
