import React, { useEffect, useState } from "react"
import styles from "../css/home/splash.module.css"
import FlashArrow from "./FlashArrow"
import AnimatedLogo from "../images/logoAnimatedWhite.gif"
import AnimatedLogoStop from "../images/logoAnimatedWhiteStop.gif"
import Config from "../Config"
import { Link, useHistory } from "react-router-dom"
import userEvent from "@testing-library/user-event"

export default function Splash(props) {
    const history = useHistory()
    const [Logo, setLogo] = useState(AnimatedLogoStop)
    const [rightClass, setRightClass] = useState(styles.splashRight)
    const [arrowClass, setArrowClass] = useState(styles.flashArrow)
    const [byLine, setByline] = useState("Turn waste into wonders")
    useEffect(() => {
        const oldMinHeight = document.body.style.minHeight
        const oldMaxHeight = document.body.style.maxHeight
        document.body.style.minHeight = "100vh"
        document.body.style.maxHeight = "100vh"

        return () => {
            document.body.style.minHeight = oldMinHeight
            document.body.style.maxHeight = oldMaxHeight
        }
    }, [])

    function doTransition() {
        setLogo(AnimatedLogo)
        setRightClass(styles.splashRightVanish)
        setArrowClass(styles.flashArrowLarge)

        setTimeout(() => {
            if (Config.comingSoon) {
                setByline(
                    <div style={{ color: "black", marginTop: "-20vw" }}>
                        Coming soon
                    </div>
                )
            } else {
                history.push("/collections")
            }
        }, [3000])
    }
    return (
        <div className={styles.splash}>
            <div className={styles.splashLeft}>
                <div className={styles.logoBlock}>
                    <img src={Logo} />
                </div>
            </div>
            <div
                onClick={() => {
                    doTransition()
                }}
                className={rightClass}
            >
                <span className={styles.splashRightText}>
                    <span>{byLine}</span>
                    <span className={arrowClass}>
                        <FlashArrow />
                    </span>
                </span>
            </div>
        </div>
    )
}
