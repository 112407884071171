import React, { useState, useEffect } from "react"
import { useGlobalState } from "../state/index"
import { cloneDeep } from "lodash"
import Description from "../components/Description"
import Legs from "./NeosLegs"
import Corners from "./NeosCorners"
import DimensionControls, { DimensionControlsLight } from "./DimensionControls"
import { SimpleColorSelector } from "../components/SimpleColorSelector"
import WoodLegs from "./WoodLegs"
import { Stage, Layer } from "react-konva"

import {
    NeosColors,
    NeosPatterns,
    NeosPatternSelector,
    NeosCustom,
} from "./NeosColorsAndPatterns"
import classNames from "classnames"
import Config from "../Config"

import Wood from "../images/WoodMahoganyAfricanSanded0WEB.jpg"
import RoundedRect from "../images/roundedRect.svg"

import "../css/neos/fonts.css"
import "../css/neos/layout.css"
import "../css/neos/carousel.css"

function Tufts(props) {
    const state = props.state
    return (
        <SimpleColorSelector
            colors={Config.powderCoatColors}
            selected={state.state.progress.chair.tuft.color}
            showLabels
            onClick={(value, name) => {
                const chair = cloneDeep(state.state.progress.chair)
                const color = Object.values(value)[0]
                chair.tuft.color = color
                chair.tuft.colorName = name

                state.dispatch({
                    type: "CHAIR",
                    supressEvent: true,
                    payload: {
                        chair: chair,
                    },
                })
                window.dispatchEvent(
                    new CustomEvent("viewChairSide", {
                        detail: "top",
                    })
                )
            }}
        />
    )
}

function Canopy(props) {
    const state = props.state
    return (
        <SimpleColorSelector
            colors={Config.canopyColors}
            selected={state.state.progress.chair.canopy.color}
            showLabels
            onClick={(value, name) => {
                const chair = cloneDeep(state.state.progress.chair)
                const color = Object.values(value)[0]
                chair.canopy.color = color
                chair.canopy.colorName = name

                state.dispatch({
                    type: "CHAIR",
                    supressEvent: true,
                    payload: {
                        chair: chair,
                    },
                })
                window.dispatchEvent(
                    new CustomEvent("viewChairSide", {
                        detail: "top",
                    })
                )
            }}
        />
    )
}

function Cable(props) {
    const state = props.state
    return (
        <SimpleColorSelector
            colors={Config.cableColors}
            selected={state.state.progress.chair.cable.color}
            showLabels
            onClick={(value, name) => {
                const chair = cloneDeep(state.state.progress.chair)
                const color = Object.values(value)[0]
                chair.cable.color = color
                chair.cable.colorName = name
                chair.cable.braided = Config.cableBraided[name]

                state.dispatch({
                    type: "CHAIR",
                    supressEvent: true,
                    payload: {
                        chair: chair,
                    },
                })
                window.dispatchEvent(
                    new CustomEvent("viewChairSide", {
                        detail: "top",
                    })
                )
            }}
        />
    )
}

//A bit of copy and paste is ok right?
function LegColor(props) {
    const state = props.state
    return (
        <>
            <SimpleColorSelector
                colors={Config.powderCoatColors}
                selected={state.state.progress.chair.legs.color}
                onClick={(value, name) => {
                    const chair = cloneDeep(state.state.progress.chair)
                    const color = Object.values(value)[0]
                    chair.legs.color = color
                    chair.legs.colorName = name

                    state.dispatch({
                        type: "CHAIR",
                        payload: {
                            chair: chair,
                        },
                    })
                    window.dispatchEvent(
                        new CustomEvent("viewChairSide", {
                            detail: "default",
                        })
                    )
                }}
                addWidget={
                    <div
                        className={classNames(
                            "toggle compact simple-color rounded",
                            {
                                selected:
                                    state.state.progress.chair.legs
                                        .colorName === "wood",
                            }
                        )}
                    >
                        <div
                            className="inside-color"
                            style={{
                                backgroundColor: "brown",
                                backgroundImage: "url(" + Wood + ")",
                                backgroundPosition: "center",
                            }}
                            onClick={() => {
                                const chair = cloneDeep(
                                    state.state.progress.chair
                                )
                                chair.legs.color = "#B68042"
                                chair.legs.colorName = "wood"

                                state.dispatch({
                                    type: "CHAIR",
                                    payload: {
                                        chair: chair,
                                    },
                                })
                                window.dispatchEvent(
                                    new CustomEvent("viewChairSide", {
                                        detail: "default",
                                    })
                                )
                            }}
                        >
                            {""}
                        </div>
                    </div>
                }
            />
        </>
    )
}

function LegsTypes(props) {
    return (
        <div className="toggle-platform">
            <div className="toggle clickable">
                <span className="label">Type One</span>
            </div>
            <div className="toggle clickable">
                <span className="label">Type Two</span>
            </div>
        </div>
    )
}

function NeosLightCableLength(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair

    function setSize(size) {
        const chair = cloneDeep(globalState.state.progress.chair)
        chair.wireLength = size

        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: chair,
            },
        })
    }

    const lengths = [5, 10, 15]
    const items = []
    for (const l of lengths) {
        items.push(
            <div
                onClick={() => {
                    setSize(l)
                }}
                className={classNames("toggle", "clickable", "compact", {
                    selected: chair.wireLength == l,
                })}
            >
                <span className="label">{l}'</span>
            </div>
        )
    }
    return <div className="toggle-platform">{items}</div>
}
function NeosLightOrentation(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair

    function setHorizontal(bool) {
        const chair = cloneDeep(globalState.state.progress.chair)
        if (bool) {
            chair.subType = "horizontalPendent"
        } else {
            chair.subType = "verticalPendent"
        }
        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: chair,
            },
        })
        window.dispatchEvent(
            new CustomEvent("viewChairSide", { detail: "top" })
        )
    }

    return (
        <div className="toggle-platform">
            <div
                onClick={() => {
                    setHorizontal(false)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.subType === "verticalPendent",
                })}
            >
                <span className="label">Vertical</span>
                <span className="image">
                    <img
                        alt="Vertical"
                        style={
                            chair.subType === "verticalPendent"
                                ? {
                                      transform: "rotate(90deg)",
                                      filter: "invert(100%)",
                                  }
                                : { transform: "rotate(90deg)" }
                        }
                        src={RoundedRect}
                    />
                </span>
            </div>
            <div
                onClick={() => {
                    setHorizontal(true)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.subType === "horizontalPendent",
                })}
            >
                <span className="label">Horizontal</span>
                <span className="image">
                    <img
                        alt="Squeared"
                        style={
                            chair.subType === "horizontalPendent"
                                ? { filter: "invert(100%)" }
                                : {}
                        }
                        src={RoundedRect}
                    />
                </span>
            </div>
        </div>
    )
}

function NeosLightTools(props) {
    const state = props.state.state
    //<NeosCustom />
    return (
        <div>
            <Description
                type={state.progress.chair.type}
                chair={state.progress.chair}
            />
            <hr />
            <h2>Orientation</h2>
            <NeosLightOrentation />
            <h2>Dimensions</h2>
            <DimensionControlsLight />
            <h2>Color Temperature</h2>
            <NeosLightColorTempature />
            <h2>Colors and Patterns</h2>
            <NeosPatternSelector />
            <h2>Colors and Patterns</h2>
            <h4>Metal Colors</h4>
            <Tufts state={props.state} />
            <h4>Canopy Colors</h4>
            <Canopy state={props.state} />
            <h4>Cable Colors</h4>
            <Cable state={props.state} />
            <h4>Cable Length</h4>
            <NeosLightCableLength state={props.state} />
        </div>
    )
}

function NeosLightColorTempature(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair

    function setTemp(temp) {
        const chair = cloneDeep(globalState.state.progress.chair)

        chair.light.temp = temp

        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: chair,
            },
        })
    }

    return (
        <div className="toggle-platform">
            <div
                onClick={() => {
                    setTemp(2200)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.light.temp === 2200,
                })}
            >
                <span className="label">2200K</span>
            </div>
            <div
                onClick={() => {
                    setTemp(3000)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.light.temp === 3000,
                })}
            >
                <span className="label">3000K</span>
            </div>
        </div>
    )
}

export default function NeosTools(props) {
    const state = useGlobalState()

    if (state.state.progress.chair.type === "pendent") {
        return <NeosLightTools state={state} />
    }

    return (
        <div>
            <Description
                type={state.state.progress.chair.type}
                chair={state.state.progress.chair}
            />
            <hr />
            <h2>Dimensions</h2>
            {!state.state.progress.chair.legs.widthFixed && (
                <>
                    <h4>Leg Diameter</h4>
                    <Legs />
                </>
            )}
            <h4>Corners</h4>
            <Corners />
            <h4>Size</h4>
            <DimensionControls />
            <h2>Colors + Patterns</h2>

            <NeosPatternSelector />

            <h2>Finishes</h2>
            <h4>Metal Colors</h4>
            <Tufts state={state} />

            <WoodLegs />
        </div>
    )
}
