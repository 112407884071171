import React from "react"
import { icons, iconsDoubleWidth } from "./Icons"
import styles from "../css/home/types.module.css"
import FlashArrow from "./FlashArrow"
import { useHistory, useLocation } from "react-router-dom"
import { ReactComponent as Logo } from "../images/Logo.svg"

export default function LightsFurnishing(props) {
    const location = useLocation()
    const history = useHistory()
    if (location.search === "?furnishings") {
        return FurnishingTypes(props)
    }
    if (location.search === "?lights") {
        return LightingTypes(props)
    }
    return (
        <div id="types" className={styles.types}>
            <div
                className={styles.typeLight}
                onClick={() => {
                    history.push("?lights")
                }}
            >
                <img src={icons["585White"]} alt="lights" />
                <div className={styles.onTypeText}>lighting</div>
            </div>
            <div className={styles.lhr}>a</div>
            <div
                className={styles.typeFurnature}
                onClick={() => {
                    history.push("?furnishings")
                }}
            >
                <img
                    style={{ fill: "white" }}
                    src={icons.loungeChairWhite}
                    alt="furnishings"
                />
                <div className={styles.onTypeTextRight}>furniture</div>
            </div>
        </div>
    )
}

function Type(props) {
    console.log(props)
    const history = useHistory()
    return (
        <div
            className={
                iconsDoubleWidth.indexOf(props.icon) !== -1
                    ? styles.typeDouble
                    : styles.type
            }
            onClick={() => {
                window.location = props.link
                //history.push(props.link)
            }}
        >
            <img
                className={
                    (props.noInvert ? null : styles.invert) +
                    " " +
                    (props.shrink ? styles.shrink : "") +
                    " " +
                    (props.halfshrink ? styles.halfshrink : "")
                }
                alt="icon"
                src={props.icon}
            ></img>
            <div className={styles.typeText}>{props.text}</div>
        </div>
    )
}

export function LightingTypes(props) {
    return (
        <Types>
            <Type
                noInvert
                halfshrink
                icon={icons["5White"]}
                text="8 pendant and 5 pendant "
                link="/mg/pendant"
            />
            <Type
                noInvert
                shrink
                icon={icons["58White"]}
                text="58 pendant"
                link="/mg/pendant?58"
            />
            <Type
                noInvert
                shrink
                icon={icons["858White"]}
                text="858 pendant"
                link="/mg/pendant?858"
            />
            <Type
                noInvert
                shrink
                icon={icons["585White"]}
                text="585 pendant"
                link="/mg/pendant?585"
            />
        </Types>
    )
}
export function FurnishingTypes(props) {
    return (
        <Types>
            <Type
                icon={icons.headChair}
                text="Head Chair"
                link="/2021/headchair"
            />
            <Type
                icon={icons.diningChair}
                text="Dining Chair"
                link="/2021/diningchair"
            />
            <Type
                icon={icons.slipperChair}
                text="Slipper Chair"
                link="/2021/slipperchair"
            />
            <Type
                icon={icons.loungeChair}
                text="Lounge Chair"
                link="/2021/loungechair"
            />
            <Type
                icon={icons.tonsOfFun}
                text="Club Chair | Tons of Fun"
                link="/2021/tonsoffun"
            />
            <Type
                icon={icons.cornerChair}
                text="Corner Chair"
                link="/2021/cornerchair"
            />
            <Type icon={icons.dayBed} text="Day Bed" link="/2021/daybed" />
            <Type icon={icons.bench} text="Bench" link="/2021/bench" />
            <Type
                icon={icons.ticTac}
                text="Stool | Tic Tac"
                link="/2021/tictac"
            />
            <Type icon={icons.chaise} text="Chaise" link="/2021/chaise" />
        </Types>
    )
}

export function JailType(props) {
    const history = useHistory()

    return (
        <div
            style={{
                overflow: "hidden",
                height: "100vh",
                width: "100vw",
                backgroundColor: "black",
                position: "fixed",
            }}
        >
            <div
                styles={{
                    height: "100px",
                    potition: "fixed",
                    top: "15px",
                    left: "15px",
                }}
                onClick={() => {
                    history.push("/enter")
                }}
            >
                <Logo
                    style={{ filter: "invert(100%)" }}
                    height={"100px"}
                    width={"100px"}
                />
            </div>
            <LightingTypes {...props} />
        </div>
    )
}

export function Types(props) {
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2
        if (t < 1) return (c / 2) * t * t + b
        t--
        return (-c / 2) * (t * (t - 2) - 1) + b
    }

    function scrollLeft(element, change, duration) {
        change = change * window.innerWidth
        var start = element.scrollLeft,
            currentTime = 0,
            increment = 20

        var animateScroll = function () {
            currentTime += increment
            var val = Math.easeInOutQuad(currentTime, start, change, duration)
            element.scrollLeft = val
            if (currentTime < duration) {
                setTimeout(animateScroll, increment)
            }
        }
        animateScroll()
    }

    return (
        <>
            <div id="types" className={styles.types}>
                {props.children}
            </div>
            <div className={styles.arrows}>
                <div
                    className={styles.arrowLeft}
                    onClick={() => {
                        scrollLeft(document.getElementById("types"), -0.25, 200)
                    }}
                >
                    <FlashArrow />
                </div>
                <div
                    className={styles.arrowRight}
                    onClick={() => {
                        scrollLeft(document.getElementById("types"), 0.25, 200)
                    }}
                >
                    <FlashArrow />
                </div>
            </div>
        </>
    )
}
