import axios from "axios"
import { useEffect, useLayoutEffect } from "react"
import Config from "../Config"
import { ToolsView } from "../pages/Neos"
import { useGlobalState } from "../state"

export default function SaveRestore(props) {
    const state = useGlobalState()
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get("id")
    useLayoutEffect(() => {
        const f = async () => {
            const r = await axios.get(Config.apiBasePath + "purchase?id=" + id)
            console.log(r)
            state.dispatch({
                type: "CHAIR",
                payload: {
                    chair: r.data.chairs.chair,
                    step: "legs",
                },
            })
            setTimeout(() => {
                console.log("erer")
                state.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: r.data.chairs.chair,
                        step: "legs",
                    },
                })
            }, 1000)
        }
        f()
    }, [])

    console.log(state.state.progress)

    return (
        <div className="main-view">
            {state.state.progress.chair && <ToolsView />}
            {!state.state.progress.chair && <b>Loading please wait</b>}
        </div>
    )
}
//            {state.state.progress.chair && <ToolsView />}
