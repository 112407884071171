import React, { useState } from "react"
import Collection from "./Collection"

import AD from "../images/company_images/ad.png"
import azure from "../images/company_images/azure.jpeg"
import core77 from "../images/company_images/core77.png"
import curbed from "../images/company_images/curbed.png"
import elle_deco from "../images/company_images/elle-deco.png"
import galerie from "../images/company_images/galerie.svg"
import isola from "../images/company_images/isola.png"
import pepup from "../images/company_images/pepup.png"
import gam from "../images/company_images/globe-and-mail.png"
import yd from "../images/company_images/yd.jpeg"
import id from "../images/company_images/id.jpg"
import id_2022 from "../images/company_images/id_2022.png"

import gd from "../images/company_images/gd.jpg"
import th from "../images/company_images/th.png"
import sp from "../images/company_images/sp.png"
import fd from "../images/company_images/1std.png"
import darc from "../images/company_images/darc.png"
import ga from "../images/company_images/logo-galerie.svg"
import hc from "../images/company_images/hc.png"
import lpi from "../images/company_images/lpi.svg"
import yuzu from "../images/company_images/yuzu.svg"
import dll from "../images/company_images/dll.jpg"
import ci from "../images/company_images/ci.png"
import de from "../images/company_images/de.jpg"
import dm from "../images/company_images/dm.png"
import dp from "../images/company_images/dp.jpg"
import gdn from "../images/company_images/gdn.png"
import gm from "../images/company_images/gm.jpg"
import inh from "../images/company_images/inh.png"
import idd from "../images/company_images/idd.png"
import idr from "../images/company_images/idr.png"
import sw from "../images/company_images/sw.jpeg"

import styles from "../css/home/collection.module.css"

const collectionItems = [
    {
        key: "-9",
        icon: false,
        name: "Yuzu magazine",
        image: yuzu,

        type: "Stackabl: Could this be a Revolution",
        designed: "Winter 2022/2023",
        showPrefix: false,
        link: "https://www.luxuryportfolio.com/blog/stackabl-customized-furnishings-pack-a-sustainable-design-punch/",
    },
    {
        key: "-7",
        icon: false,
        name: "Luxury Portfolio International",
        image: lpi,

        type: "Stackabl customized furnishings pack a sustainable design punch",
        designed: "October 20, 2022",
        showPrefix: false,
        link: "https://www.luxuryportfolio.com/blog/stackabl-customized-furnishings-pack-a-sustainable-design-punch/",
    },
    {
        key: "-6",
        icon: false,
        name: "Homecrux",
        image: hc,

        type: "Stackabl Brings Environmentally-Friendly Pendent Lights to Salone del Mobile",
        designed: "June 2022",
        showPrefix: false,
        link: "https://www.homecrux.com/stackabl-recycled-lighting-collection/172119/",
    },
    {
        key: "-5",
        icon: false,
        name: "Galerie",
        image: ga,

        type: "Creative Minds",
        designed: "March 2022",
        showPrefix: false,
        link: "https://drive.google.com/file/d/1-qA1M1EexLNV0ojdFmZw7tt0j14BLNBh/view?usp=sharing",
    },
    {
        key: "-4",
        icon: false,
        name: "darc magazine",
        image: darc,

        type: "In Focus",
        designed: "November 2022",
        showPrefix: false,
        link: "https://www.darcmagazine.com/latest-issue/",
    },
    {
        key: "-3",
        icon: false,
        name: "1st Dibs",
        image: fd,

        type: "Colorful, Customizable STACKABL Furniture Is Good for the Planet, Too",
        designed: "June 2022",
        showPrefix: false,
        link: "https://www.1stdibs.com/introspective-magazine/stackabl/",
    },
    {
        key: "-2",
        icon: false,
        name: "Trendhunter",
        image: th,

        type: "Sustainable Cylindrical Ceiling Lights",
        designed: "June 2022",
        showPrefix: false,
        link: "https://www.trendhunter.com/trends/stackabl-lighting-collection",
    },

    {
        key: "0",
        icon: false,
        name: "Interior Design",
        image: id,

        type: "Maison Gerard and Stacklab Launch Tool That Turns ‘Waste into Wonders’",
        designed: "June 2022",
        showPrefix: false,
        link: "https://interiordesign.net/products/maison-gerard-and-stacklab-launch-tool-that-turns-waste-into-wonders/",
    },
    {
        key: "-3",
        icon: false,
        name: "Stir Pad",
        image: sp,

        type: "Stacklab x Maison Gerard’s ‘Stackabl’ system enables virtual customisation of furniture",
        designed: "May 2022",
        showPrefix: false,
        link: "https://www.stirpad.com/news/stir-news/stacklab-x-maison-gerard-s-stackabl-system-enables-virtual-customisation-of-furniture/",
    },
    {
        key: "1",
        icon: false,
        name: "Architectural Digest",
        image: AD,

        type: "15 Eco-Friendly Finds We’re Loving Now",
        designed: "April 2022",
        showPrefix: false,
        link: "https://www.architecturaldigest.com/story/15-eco-friendly-finds-were-loving-now",
    },
    {
        key: "2",
        icon: false,
        name: "Azure Magazine",
        image: azure,

        type: "Best in Show – All Show Request",
        designed: "March 2022",
        showPrefix: false,
        noLink: true,
    },
    {
        key: "3",
        icon: false,
        name: "Azure Magazine",
        image: azure,

        type: "Stacklab Debuts a Tool to Build Fully Customized Felt Furniture",
        designed: "March 2022",
        showPrefix: false,
        link: "https://www.azuremagazine.com/article/stacklab-debuts-a-tool-to-build-fully-customized-felt-furniture/",
    },
    {
        key: "4",
        icon: false,
        name: "Core77",
        image: core77,

        type: "“Stackabl” System Lets You Design Furniture Made from Locally-Sourced Factory Off-Cuts",
        designed: "January 2022",
        showPrefix: false,
        link: "https://www.core77.com/posts/111793/Stackabl-System-Lets-You-Design-Furniture-Made-from-Locally-Sourced-Factory-Off-Cuts",
    },
    {
        key: "5",
        icon: false,
        name: "CURBED",
        image: curbed,

        type: "At the Salon of Art + Design, I Fell In Love With Stackabl’s Colorful, Sustainable Furniture",
        designed: "November 2021",
        showPrefix: false,
        link: "https://www.curbed.com/2021/11/i-love-stackabls-colorful-sustainable-furniture.html",
    },
    {
        key: "6",
        icon: false,
        name: "Elle Décor",
        image: elle_deco,

        type: "The Family Issue",
        designed: "May 2022 ",
        showPrefix: false,
        noLink: true,
    },

    {
        key: "8",
        icon: false,
        name: "Isola Design",
        image: isola,

        type: "Discover how to turn waste into wonders with STACKABL",
        designed: "December 2021 ",
        showPrefix: false,
        noLink: true,
    },
    {
        key: "9",
        icon: false,
        name: "PEP UP HOME",
        image: pepup,

        type: "Stackabl System Creates Aesthetic Furniture with Factory Off-Cuts",
        designed: "February 2022",
        showPrefix: false,
        link: "https://pepuphome.com/stackabl-system-creates-aesthetic-furniture-with-factory-off-cuts/",
    },
    {
        key: "10",
        icon: false,
        name: "THE GLOBE AND MAIL",
        image: gam,

        type: "Innovative furniture designers harness discarded fabrics for a sustainable future for home goods",
        designed: "February 2022",
        showPrefix: false,
        link: "https://www.theglobeandmail.com/life/home-and-design/article-innovative-furniture-designers-harness-discarded-fabrics-for-a/",
    },
    {
        key: "11",
        icon: false,
        name: "YANKO DESIGN",
        image: yd,

        type: "SUSTAINABLE, BEAUTIFUL AND COMFY CHAIRS THAT ARE MADE FROM STACKS OF FACTORY OFFCUT FELT",
        designed: "January 2022",
        showPrefix: false,
        link: "https://www.yankodesign.com/2022/01/28/sustainable-beautiful-and-comfy-chairs-that-are-made-from-stacks-of-factory-offcut-felt/",
    },
    {
        key: "12",
        icon: false,
        name: "Azure Magazine",
        image: azure,

        type: "6 Major Moments We’re Looking Forward to at Salone del Mobile 2022",
        designed: "May 2022",
        showPrefix: false,
        link: "https://www.azuremagazine.com/article/salone-del-mobile-2022-preview/",
    },
    {
        key: "13",
        icon: false,
        name: "Azure Magazine",
        image: azure,

        type: "Milan Design Week 2022: Highlights from our Camera Roll",
        designed: "June 2022",
        showPrefix: false,
        link: "https://www.azuremagazine.com/article/milan-design-week-2022/",
    },
    {
        key: "14",
        icon: false,
        name: "Azure Magazine",
        image: azure,

        type: "O, Canada! 10 Canadian Designers Making it Big Abroad",
        designed: "July 2022",
        showPrefix: false,
        link: "https://www.azuremagazine.com/article/international-canadian-designers/",
    },
    {
        key: "15",
        icon: false,
        name: "Canadian Interiors",
        image: ci,

        type: "STACKLAB launches a new pendant lighting collection using upcycled felt",
        designed: "September 2022",
        showPrefix: false,
        link: "https://www.canadianinteriors.com/2022/08/31/stacklab-launches-a-new-pendant-lighting-collection-using-upcycled-felt/",
    },
    {
        key: "16",
        icon: false,
        name: "Core77",
        image: core77,

        type: "Design Your Own Pendant Lighting Out of Upcycled Felt, Using Stackabl's Configurator",
        designed: "August 2022",
        showPrefix: false,
        link: "https://www.core77.com/posts/116730/Design-Your-Own-Pendant-Lighting-Out-of-Upcycled-Felt-Using-Stackabls-Configurator?utm_source=core77&utm_medium=from_title",
    },
    {
        key: "17",
        icon: false,
        name: "Design Edit",
        image: de,

        type: "Benoist Drut",
        designed: "May 2022",
        showPrefix: false,
        link: "https://thedesignedit.com/benoist-drut/",
    },
    {
        key: "18",
        icon: false,
        name: "Design Milk",
        image: dm,

        type: "Stackabl Felt Furniture Is Turning Waste Into Wonder",
        designed: "May 2022",
        showPrefix: false,
        link: "https://design-milk.com/stackabl-felt-furniture-is-turning-waste-into-wonder/",
    },
    {
        key: "19",
        icon: false,
        name: "Designer Pages",
        image: dp,

        type: "Clever Felt Furniture from Stackabl",
        designed: "July 2022",
        showPrefix: false,
        link: "http://media.designerpages.com/2022/06/clever-felt-furniture-from-stacklab/",
    },
    {
        key: "20",
        icon: false,
        name: "Global Design Awards",
        image: gdn,

        type: "Jeffrey Forrest from STACKLAB and Maison Gerard turn waste into inspired furniture aiming for a more sustainable future",
        designed: "July 2022",
        showPrefix: false,
        link: "https:/globaldesignnews.com/jeffrey-forrest-from-stacklab-and-maison-gerard-turn-waste-into-inspired-furniture-aiming-for-a-more-sustainable-future/?utm_source=rss&utm_medium=rss&utm_campaign=jeffrey-forrest-from-stacklab-and-maison-gerard-turn-waste-into-inspired-furniture-aiming-for-a-more-sustainable-future",
    },
    {
        key: "21",
        icon: false,
        name: "Gray Magazine",
        image: gm,

        type: "Configure it out",
        designed: "August 2022",
        showPrefix: false,
    },
    {
        key: "22",
        icon: false,
        name: "Index Design",
        image: idd,

        type: "Objets choisis: forme et fantaise",
        designed: "September 2022",
        showPrefix: false,
        link: "https://www.index-design.ca/article/objets-choisis-forme-et-fantaisie?nl",
    },
    {
        key: "23",
        icon: false,
        name: "Inhabitat",
        image: inh,

        type: "Stackabl system connects designers with manufacturing waste",
        designed: "September 2022",
        showPrefix: false,
        link: "https://inhabitat.com/stackabl-system-connects-designers-with-manufacturing-waste/",
    },
    {
        key: "24",
        icon: false,
        name: "Interior Design (Russia)",
        image: idr,

        type: "Trends 2022/2023: recycled felt furniture",
        designed: "October 2022",
        showPrefix: false,
        link: "https://inhabitat.com/stackabl-system-connects-designers-with-manufacturing-waste/",
    },
    {
        key: "25",
        icon: false,
        name: "Stirworld",
        image: sw,

        type: "Gabriele Cavallaro discusses the genesis and curation of Isola District District",
        designed: "July 2022",
        showPrefix: false,
        link: "https://www.stirworld.com/inspire-people-gabriele-cavallaro-discusses-the-genesis-and-curation-of-the-isola-district-district",
    },
]

const awardItems = [
    {
        key: "0",
        icon: false,
        name: "Designlines Magazine",
        image: dll,

        type: "Arcilla pendant collection DL Loves 2023 Award Winner",
        designed: "January 2023",
        showPrefix: false,
        link: "https://www.designlinesmagazine.com/love-tags-2023/",
    },
    {
        key: "-1",
        icon: false,
        name: "Interior Design magazine Best of Year Awards",
        image: id_2022,

        type: "Pendant Lighting Honoree",
        designed: "November 2022",
        showPrefix: false,
        link: "https://interiordesign.net/award/interior-designs-2022-best-of-year-award-winners/",
    },
    {
        key: "0",
        icon: false,
        name: "Azure Magazine  ",
        image: azure,

        type: "Furniture Systems and Collections AZ Awards 2022 Winner",
        designed: "June 2022",
        showPrefix: false,
        link: "https://awards.azuremagazine.com/categories/furniture-systems-and-collections",
    },
    {
        key: "1",
        icon: false,
        name: "Green GOOD DESIGN Award Winner ",
        image: gd,

        type: "Stackabl, 2022",
        designed: "June 2022",
        showPrefix: false,
        link: "https://www.good-designawards.com/green-good-design-award-details-page.html?award=43067",
    },
]
export default function Press(props) {
    collectionItems.sort((a, b) => {
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
            return -1
        }
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
            return 1
        }
        return 0
    })
    return (
        <div className={styles.press}>
            <h1 className={styles.h1}>Awards</h1>
            <Collection
                baseUrl={"/press"}
                items={awardItems.reverse()}
                onClickCarasol={(item) => {
                    //return expandOption(item)
                }}
            />
            <h1 className={styles.h1}>Press</h1>
            <Collection
                baseUrl={"/press"}
                items={collectionItems}
                onClickCarasol={(item) => {
                    //return expandOption(item)
                }}
            />
        </div>
    )
}
