import React, { useEffect, useState } from "react"
import { cloneDeep } from "lodash"
import { useGlobalState } from "../state/index"
import classNames from "classnames"
import "../css/slider.css"
import "../css/controls.css"
import "../css/flex.css"
import { Front } from "../components/illustrations/Illustrations"
import { Side } from "../components/illustrations/Illustrations"
import RoundedRect from "../images/roundedRect.svg"
import { pendentConfig } from "../state/mg"
import Config from "../Config"

import { emptyState } from "../state/index"

function Find16th(n) {
    const dec = 0.25
    const values = [
        [
            dec * 1,
            <span>
                <sup>1</sup>
                &frasl;
                <sub>4</sub>
            </span>,
        ],

        [
            dec * 2,
            <span>
                <sup>1</sup>
                &frasl;
                <sub>2</sub>
            </span>,
        ],
        [
            dec * 3,
            <span>
                <sup>3</sup>
                &frasl;
                <sub>4</sub>
            </span>,
        ],
    ]

    for (const value of values) {
        if (n <= value[0]) {
            return value[1]
        }
    }
    return null
}

export function FracInch(i) {
    const feet = Math.floor(i / 12)
    const inches = Math.floor(i - feet * 12)
    const dec = i - Math.floor(i)
    const totalInches = Math.floor(i)
    let disFeet = ""
    if (feet > 0) {
        disFeet = feet + "' "
    }

    let disInches = ""
    if (inches > 0) {
        disInches = inches
        if (dec === 0) {
            disInches = disInches + " " + Find16th(dec)
        }
        disInches = disInches + '" '
    }

    return (
        <span>
            <span className="inches">
                {Find16th(dec) == null && <span>{totalInches + 1}</span>}
                {Find16th(dec) !== null && (
                    <span>
                        <span>{totalInches}</span>
                        {dec !== 0 && <span> {Find16th(dec)}</span>}
                    </span>
                )}
                &quot;
            </span>
        </span>
    )
}

function TemplateControl(props) {
    const [items, setItems] = useState([])
    useEffect(() => {
        const items = []
        const data = props.data
        for (const i of data.selections) {
            const funcs = []
            const chair = cloneDeep(props.chair)
            if (i.width) {
                chair.seat.width = i.width
                funcs.push((c) => {
                    c.seat.width = i.width
                })
            }
            if (i.depth) {
                chair.seat.depth = i.depth
                funcs.push((c) => {
                    c.seat.depth = i.depth
                })
            }
            if (i.height) {
                chair.legs.height = i.height
                funcs.push((c) => {
                    c.legs.height = i.height
                })
            }
            if (i.backHeight) {
                chair.back.blocks = updateBackHeight(
                    chair,
                    i.backHeight
                ).back.blocks
                funcs.push((c) => {
                    c.back.blocks = updateBackHeight(
                        chair,
                        i.backHeight
                    ).back.blocks
                })
            }
            if (i.armHeight) {
                const armBlocks = updateArmHeight(chair, i.armHeight)

                chair.leftArm.blocks = armBlocks.leftArm.blocks
                if (chair.rightArm) {
                    chair.rightArm.blocks = armBlocks.rightArm.blocks
                }

                funcs.push((c) => {
                    const ab = updateArmHeight(chair, i.armHeight)
                    c.leftArm.blocks = ab.leftArm.blocks
                    if (c.rightArm) {
                        c.rightArm.blocks = ab.rightArm.blocks
                    }
                })
            }
            if (i.seatHeight) {
                const seatBlocks = updateSeatHeight(chair, i.seatHeight)
                chair.legs.height =
                    chair.legs.height +
                    sizeOfBlock(chair.seat.blocks) -
                    sizeOfBlock(seatBlocks.seat.blocks)

                chair.seat.blocks = seatBlocks.seat.blocks

                funcs.push((c) => {
                    const ab = updateSeatHeight(chair, i.seatHeight)
                    c.seat.blocks = ab.seat.blocks
                    c.legs.height =
                        chair.legs.height +
                        sizeOfBlock(chair.seat.blocks) -
                        sizeOfBlock(seatBlocks.seat.blocks)
                })
            }

            let counter = 1
            counter++
            items.push(
                <div
                    key={"thing-" + counter}
                    className={classNames("toggle", "compact", {
                        selected: props.selected === i.label,
                    })}
                    onClick={() => {
                        const c = cloneDeep(props.chair)
                        for (const f of funcs) {
                            f(c)
                        }
                        if (props.callback) {
                            props.callback(i.label)
                        }
                        props.dispatch({
                            type: "CHAIR",
                            payload: {
                                chair: c,
                            },
                        })
                    }}
                >
                    <div className="label">{i.label}</div>
                    <div
                        style={
                            props.selected === i.label
                                ? { filter: "invert(100%)" }
                                : {}
                        }
                        className="image"
                    >
                        <data.Illustration
                            type={chair.type}
                            chair={chair}
                            vw={4}
                        />
                    </div>
                </div>
            )
        }
        setItems(items)
    }, [props.selected, props.chair])

    return <div className="toggle-platform">{items}</div>
}

export function SliderControl(props) {
    return (
        <span className="control" style={{ width: "100%" }}>
            <div className="flex-h spread">
                <label style={{ width: "50%" }} htmlFor={props.name}>
                    {props.label}
                </label>
                <div style={{ width: "50%" }} className="value">
                    {!props.display && FracInch(props.value)}
                    {props.display && props.display}
                </div>
            </div>

            <input
                step={props.step ? props.step : "0.25"}
                name={props.name}
                value={props.value}
                onChange={props.callback}
                type="range"
                min={props.range[0]}
                max={props.range[1]}
            />
        </span>
    )
}

const fivemm = 0.19685
function sizeOfBlock(block) {
    let height = 0
    if (block == null) {
        return 0
    }
    try {
        for (let l of block) {
            if (l.type === "designerBoard") {
                height = height + l.layers * fivemm * 2
            } else if (l.type === "light") {
                height = height + fivemm * 3
            } else {
                height = height + l.layers * fivemm
            }
        }
    } catch (e) {
        return 0
    }

    return height
}

function changeBlockSize(b, reduction) {
    let block = cloneDeep(b)
    const size = sizeOfBlock(block)
    if (size + reduction <= 0) {
        return block
    }

    if (reduction > 0) {
        let add = Math.floor(reduction / fivemm)
        block.reverse()
        block[0].layers = block[0].layers + add
        block.reverse()
    } else if (reduction < 0) {
        //Ineffectent but easy and clear
        const r = sizeOfBlock(block) + reduction
        block.reverse()
        while (sizeOfBlock(block) > r) {
            if (block[0].layers > 1) {
                block[0].layers = block[0].layers - 1
            } else {
                if (block.length > 1) {
                    block.shift()
                } else {
                    block[0].layers = 0
                }
            }
        }
        block.reverse()
    }

    return block
}

function Height(props) {
    let chair = props.chair
    const range = props.range
    return (
        <SliderControl
            range={range}
            name="height"
            label="Seat Height"
            value={props.chair.legs.height + sizeOfBlock(chair.seat.blocks)}
            callback={(e) => {
                if (
                    Number(e.target.value) - sizeOfBlock(chair.seat.blocks) <
                    2
                ) {
                    chair = updateSeatHeight(
                        chair,
                        sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2
                    )
                }
                chair.legs.height =
                    Number(e.target.value) - sizeOfBlock(chair.seat.blocks)

                props.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: chair,
                    },
                })
            }}
        />
    )
}

function Width(props) {
    return (
        <SliderControl
            range={props.range}
            name="width"
            label="Width"
            value={props.chair.seat.width}
            callback={(e) => {
                const chair = props.chair
                chair.seat.width = e.target.value
                if (chair.back && chair.subType !== "cornerChair") {
                    chair.back.width = chair.legs.width + chair.seat.width / 2
                }
                props.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: chair,
                    },
                })
            }}
        />
    )
}

function Depth(props) {
    return (
        <SliderControl
            range={props.range}
            name="depth"
            label="Depth"
            value={props.chair.seat.depth}
            callback={(e) => {
                const chair = props.chair
                chair.seat.depth = e.target.value
                props.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: chair,
                    },
                })
            }}
        />
    )
}

export function useOverallDimensions(chair) {
    const props = {}
    props.chair = chair
    let height = 0
    let width = 0
    let depth = 0
    let seatWidth = 0
    let seatDepth = 0

    if (chair.type === "basic") {
        return {}
    }
    if (chair.type === "pendent") {
        const layers = {}
        const tube = props.chair.tube
        let layerDescrFull = ""

        for (const l of tube.blocks) {
            layerDescrFull += l.colorName + " " + l.layers + ", "
            layers[l.colorName] =
                (layers[l.colorName] ? layers[l.colorName] : 0) + l.layers
        }
        let layerDescr = ""
        for (const i in layers) {
            layerDescr += i + " " + layers[i] + ", "
        }

        const diameter = chair.form && chair.form.includes("8") ? 8 : 5
        return {
            layerDescr: layerDescr,
            layerDescrFull: layerDescrFull,
            height: sizeOfBlock(props.chair.tube.blocks),
            width: diameter,
            depth: diameter,
            seatWidth: 0,
            seatDepth: 0,
        }
    }

    width = props.chair.seat.width
    depth = props.chair.seat.depth
    if (props.chair.type === "armChair") {
        height =
            props.chair.legs.height +
            sizeOfBlock(props.chair.seat.blocks) +
            sizeOfBlock(props.chair.leftArm.blocks) +
            sizeOfBlock(props.chair.back.blocks)
        seatWidth = width - props.chair.leftArm.width * 2
    }
    if (
        props.chair.type === "sideChair" ||
        props.chair.type === "cornerChair" ||
        props.chair.type === "dayBed" ||
        props.chair.type === "bench" ||
        props.chair.type === "stool"
    ) {
        height =
            props.chair.legs.height +
            sizeOfBlock(props.chair.seat.blocks) +
            0.25
        if (props.chair.leftArm && !props.chair.back) {
            height = height + sizeOfBlock(props.chair.leftArm.blocks)
        }
        if (props.chair.back) {
            height =
                props.chair.legs.height +
                sizeOfBlock(props.chair.seat.blocks) +
                sizeOfBlock(props.chair.back.blocks)
        }
        if (props.chair.back && props.chair.leftArm) {
            height =
                props.chair.legs.height +
                sizeOfBlock(props.chair.back.blocks) +
                sizeOfBlock(props.chair.leftArm.blocks) +
                0.25
        }

        if (props.chair.leftArm) {
            seatWidth = width - props.chair.leftArm.width
        } else {
            seatWidth = width
        }
    }

    if (props.chair.type === "armBench") {
        height =
            props.chair.legs.height +
            sizeOfBlock(props.chair.seat.blocks) +
            sizeOfBlock(props.chair.leftArm.blocks) / 2 +
            0.25
        seatWidth = width
    }
    if (props.chair.type === "cornerChair") {
        height =
            props.chair.legs.height +
            sizeOfBlock(props.chair.seat.blocks) +
            sizeOfBlock(props.chair.leftArm.blocks)

        seatWidth = width - props.chair.leftArm.width
    }

    seatDepth = depth - (props.chair.back ? props.chair.back.depth : 0)
    const seatHeight =
        props.chair.legs.height + sizeOfBlock(props.chair.seat.blocks)

    return { seatHeight, seatDepth, seatWidth, depth, width, height }
}

export function OverallDimensions(props) {
    const {
        seatHeight,
        seatDepth,
        seatWidth,
        depth,
        width,
        height,
        layerDescr,
        layerDescrFull,
    } = useOverallDimensions(props.chair)
    if (props.chair.type === "basic") {
        const infos = []

        for (const i in props.chair.infos) {
            infos.push(
                <div className="flex-h spread">
                    <b>{i}</b> {props.chair.infos[i]}
                </div>
            )
        }
        return (
            <span className="control">
                {infos}
                {props.children}
            </span>
        )
    }
    if (props.chair.type === "pendent") {
        const canopyColor = Object.keys(Config.canopyColors).find((key) => {
            const kk = Object.keys(Config.canopyColors[key])[0]
            return Config.canopyColors[key][kk] === props.chair.canopy.color
        })
        const cableColor = Object.keys(Config.cableColors).find((key) => {
            const kk = Object.keys(Config.cableColors[key])[0]
            return Config.cableColors[key][kk] === props.chair.cable.color
        })

        const metalColor = Object.keys(Config.powderCoatColors).find((key) => {
            const kk = Object.keys(Config.powderCoatColors[key])[0]
            return Config.powderCoatColors[key][kk] === props.chair.tuft.color
        })
        const wordColor = Object.keys(Config.canopyColors[canopyColor])[0]
        const wordColorCable = Object.keys(Config.cableColors[cableColor])[0]
        const wordColorMetal = Object.keys(
            Config.powderCoatColors[metalColor]
        )[0]

        return (
            <span className="control">
                <div className="flex-h spread">
                    <b>Orientation</b>{" "}
                    {props.chair.subType === "horizontalPendent"
                        ? "Horizontal"
                        : "Vertical"}
                </div>
                <div className="flex-h spread">
                    <b>Height</b> {FracInch(height)}
                </div>

                <div className="flex-h spread">
                    <b>Diameter</b> {FracInch(depth)}
                </div>
                <div className="flex-h spread">
                    <b>Color Temperature</b> {props.chair.light.temp}K
                </div>
                <div className="flex-h spread">
                    <b>Canopy Color</b>
                    {wordColor}
                </div>
                <div className="flex-h spread">
                    <b>Cable Color</b>
                    {wordColorCable}
                </div>
                <div className="flex-h spread">
                    <b>Cable Length</b>
                    {props.chair.wireLength}"
                </div>
                <div className="flex-h spread">
                    <b>Metal Colors</b>
                    {wordColorMetal}
                </div>
                <div className="flex-h spread">
                    <b>Layers</b>
                    <div>
                        {props.fullLayersDescr ? layerDescrFull : layerDescr}
                    </div>
                </div>
                {props.children}
            </span>
        )
    }
    return (
        <span className="control">
            <div className="flex-h spread">
                <b>Height</b> {FracInch(height)}
            </div>
            <div className="flex-h spread">
                <b>Width</b> {FracInch(width)}
            </div>
            <div className="flex-h spread">
                <b>Depth</b> {FracInch(depth)}
            </div>
            <div className="flex-h spread">
                <b>Seat Width</b> {FracInch(seatWidth)}
            </div>
            <div className="flex-h spread">
                <b>Seat Depth</b> {FracInch(seatDepth)}
            </div>
            <div className="flex-h spread">
                <b>Seat Height</b> {FracInch(seatHeight)}
            </div>
            {props.children}
        </span>
    )
}

function updateBackHeight(chair, value) {
    const diff = value - sizeOfBlock(chair.back.blocks)
    chair.back.blocks = changeBlockSize(chair.back.blocks, diff)
    if (chair.type === "cornerChair") {
        const adiff =
            Math.min(12, sizeOfBlock(chair.back.blocks) / 1.5) -
            sizeOfBlock(chair.leftArm.blocks)
        chair.leftArm.blocks = changeBlockSize(chair.leftArm.blocks, adiff)
    }
    return chair
}

function BackHeight(props) {
    return (
        <SliderControl
            range={props.range}
            name="backHeight"
            label="Back Height"
            value={Number(sizeOfBlock(props.chair.back.blocks))}
            callback={(e) => {
                props.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: updateBackHeight(props.chair, e.target.value),
                    },
                })
            }}
        />
    )
}

function updateArmHeight(chair, value) {
    const diff = value - sizeOfBlock(chair.leftArm.blocks)
    chair.leftArm.blocks = changeBlockSize(chair.leftArm.blocks, diff)
    if (chair.rightArm) {
        chair.rightArm.blocks = changeBlockSize(chair.rightArm.blocks, diff)
    }
    return chair
}

function ArmHeight(props) {
    if (props.range === "display_only") {
        return (
            <span className="control">
                <div className="flex-h spread">
                    <b>Arm Height</b>{" "}
                    {FracInch(sizeOfBlock(props.chair.leftArm.blocks))}
                </div>
            </span>
        )
    }
    return (
        <SliderControl
            range={props.range}
            name="Arm Height"
            label="Arm Height"
            value={sizeOfBlock(props.chair.leftArm.blocks)}
            callback={(e) => {
                props.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: updateArmHeight(props.chair, e.target.value),
                    },
                })
            }}
        />
    )
}

function updateSeatHeight(chair, value) {
    const diff = value - sizeOfBlock(chair.seat.blocks)
    const chairSize = chair.legs.height + sizeOfBlock(chair.seat.blocks)

    chair.seat.blocks = changeBlockSize(chair.seat.blocks, diff)

    chair.legs.height = chairSize - sizeOfBlock(chair.seat.blocks)

    return chair
}

function SeatHeight(props) {
    return (
        <SliderControl
            range={props.range}
            name="Seat Thickness"
            label="Seat Thickness"
            value={sizeOfBlock(props.chair.seat.blocks)}
            callback={(e) => {
                props.dispatch({
                    type: "CHAIR",
                    payload: {
                        chair: updateSeatHeight(props.chair, e.target.value),
                    },
                })
            }}
        />
    )
}

function updateTubeLength(chair, value) {
    const diff = value - sizeOfBlock(chair.tube.blocks)
    chair.tube.blocks = changeBlockSize(chair.tube.blocks, diff)

    return chair
}

function DiameterControl(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair
    if (!pendentConfig[chair.form].allowDiameterChange) {
        return null
    }

    function setDimensions(number) {
        const chair = cloneDeep(globalState.state.progress.chair)
        chair.tube.diameter = number
        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: chair,
            },
        })
    }

    return (
        <div className="toggle-platform">
            <div
                onClick={() => {
                    setDimensions(5)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.tube.diameter == "5",
                })}
            >
                <span className="label">5"</span>
                <span className="image">
                    <img
                        alt="5 inch"
                        style={
                            chair.tube.diameter == "5"
                                ? {
                                      transform: "rotate(90deg)  ",
                                      filter: "invert(100%)",
                                  }
                                : { transform: "rotate(90deg) " }
                        }
                        src={RoundedRect}
                    />
                </span>
            </div>
            <div
                onClick={() => {
                    setDimensions(8)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.tube.diameter == "8",
                })}
            >
                <span className="label">8"</span>
                <span className="image">
                    <img
                        alt="8 inch"
                        style={
                            chair.tube.diameter == "8"
                                ? {
                                      transform: " rotate(90deg) scaleY(2)",

                                      filter: "invert(100%)",
                                  }
                                : { transform: " rotate(90deg) scaleY(2) " }
                        }
                        src={RoundedRect}
                    />
                </span>
            </div>
        </div>
    )
}

function DimensionControlsLightDiscrete(props) {
    const globalState = useGlobalState()
    const light = globalState.state.progress.chair
    const lengthSteps = { ...pendentConfig[light.form].lengths }

    function setSize(size) {
        const chair = cloneDeep(globalState.state.progress.chair)
        chair.tube.length = size
        updateTubeLength(chair, chair.tube.length)

        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: chair,
            },
        })
    }

    const lengths = Object.values(lengthSteps)
    const items = []
    for (const l of lengths) {
        items.push(
            <div
                onClick={() => {
                    setSize(l)
                }}
                className={classNames("toggle", "clickable", "compact", {
                    selected: light.tube.length == l,
                })}
            >
                <span className="label">{l}"</span>
            </div>
        )
    }
    return <div className="toggle-platform">{items}</div>
}

export function DimensionControlsLight(props) {
    const globalState = useGlobalState()
    const light = globalState.state.progress.chair
    const [lengthStep, setLenghtStep] = useState(0)
    const [diameterStep, setDiameterStep] = useState(1)
    const diameterSteps = { 1: 5, 2: 8 }
    const count = pendentConfig[light.form].lengths.length
    const lengthSteps = { ...pendentConfig[light.form].lengths }

    if (Object.keys(lengthSteps).length <= 4) {
        return <DimensionControlsLightDiscrete {...props} />
    }

    return (
        <div>
            <SliderControl
                step={1}
                name="length"
                label="Length"
                value={lengthStep}
                display={light.tube.length + '"'}
                callback={(e) => {
                    setLenghtStep(e.target.value)
                    const chair = globalState.state.progress.chair
                    chair.tube.length = lengthSteps[e.target.value]
                    updateTubeLength(chair, chair.tube.length)
                    globalState.dispatch({
                        type: "CHAIR",
                        payload: {
                            chair: chair,
                        },
                    })
                }}
                range={[0, count - 1]}
                discrete
            />
            <h2>Diameter</h2>

            <DiameterControl />
        </div>
    )
}

export default function DimensionControls(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair

    const [template, setTemplate] = useState(null)

    useEffect(() => {
        const func = (e) => {
            setTemplate(null)
        }
        window.addEventListener("invalidateTemplate", func, true)

        return () => {
            window.removeEventListener("invalidateTemplate", func, true)
        }
    }, [])

    let withSide = ""
    const controls = {
        MG: {
            slipperChair: {
                Illustration: Front,
                backHeight: [7.87, 19.5],
                seatHeight: [3.15, 4.4],
            },
            sideChair: {
                Illustration: Front,
                seatHeight: [3.15, 4.4],
            },
            sideChairRake: {
                Illustration: Front,
                seatHeight: [3.15, 4.4],
            },
            headChair: {
                Illustration: Front,
                seatHeight: [3.15, 4.4],
            },
            headChairRake: {
                Illustration: Front,
                seatHeight: [3.15, 4.4],
            },
            loungeChair: {
                Illustration: Front,
                seatHeight: [7, 15],
                backHeight: [11, 15],
            },
            tonsOfFun: {
                Illustration: Front,
                width: [20, 28],
                depth: [28, 30],
                backHeight: [14, 20],
            },
            cornerChair: {
                Illustration: Front,
                width: [24, 27],
                depth: [24, 27],
                armHeight: [6.5, 9.75],
                seatHeight: [3.15, 4.4],
            },
            dayBed: {
                Illustration: Front,
                width: [26, 30],
                depth: [68, 80],
                seatHeight: [4.25, 6.5],
                backHeight: [4.5, 8.5],
            },
            bench: {
                Illustration: Front,
                width: [50, 60],
                depth: [16, 20],
                seatHeight: [4.5, 8.5],
            },
            tickTack: {
                Illustration: Front,
                width: [18.5, 22.5],
                depth: [10, 14],
                seatHeight: [5.75, 15.75],
            },
            chaise: {
                Illustration: Front,
                width: [68, 80],
                depth: [24, 27],
                armHeight: [4.75, 6.75],
                seatHeight: [3.15, 4.4],
            },
        },

        stool: {
            Illustration: Front,
            seatHeight: [
                2,
                sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2,
            ],

            height: [10, 15.25],
        },
        bench: {
            Illustration: Front,
            seatHeight: [
                2,
                sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2,
            ],
            height: [12, 20],
            width: [chair.legs.width > 4 ? 17.5 : 9.5, 40],
            depth: [chair.legs.width > 4 ? 17.5 : 9.5, 40],
        },
        cornerChair: {
            template: {
                chair: emptyState.cornerChair,
                Illustration: Front,
                selections: [
                    {
                        label: "Dining",
                        width: 19.75 + chair.legs.width,
                        depth: 17.5 + chair.back ? chair.back.depth : 0,
                        height: 18 - sizeOfBlock(chair.seat.blocks),

                        backHeight: 10.5,
                        armHeight: 7,
                        seatHeight: 2,
                    },
                    {
                        label: "Casual",
                        width: chair.legs.width > 4 ? 33 : 30,
                        depth: chair.legs.width > 4 ? 28 : 32,
                        height: 17 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 10.5,
                        armHeight: 7,
                        seatHeight: 8.25,
                    },
                    {
                        label: "Lounge",
                        width: chair.legs.width > 4 ? 38 : 33,
                        depth: chair.legs.width > 4 ? 28 : 32,
                        height: 17 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 10.5,
                        armHeight: 7,
                        seatHeight: 14.75,
                    },
                ],
            },
            height: [14, 20],
            seatHeight: [
                2,
                sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2,
            ],
            width: [chair.legs.width > 4 ? 27 : 23, 40],
            depth: [chair.legs.width > 4 ? 24 : 20, 40],
            backHeight: [6, 36],
            armHeight: "display_only",
        },
        armChair: {
            template: {
                chair: emptyState.armChair,
                Illustration: Front,
                selections: [
                    {
                        label: "Dining",
                        width: chair.legs.width > 4 ? 36.75 : 28.75,
                        depth: chair.legs.width > 4 ? 25.5 : 21.5,
                        height: 18 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 8,
                        armHeight: 7,
                        seatHeight: 2,
                    },
                    {
                        label: "Casual",
                        width: chair.legs.width > 4 ? 38 : 35,
                        depth: chair.legs.width > 4 ? 32 : 28,
                        height: 17 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 20,
                        armHeight: 7,
                        seatHeight: 8.25,
                    },

                    {
                        label: "Lounge",
                        width: chair.legs.width > 4 ? 40 : 38,
                        depth: chair.legs.width > 4 ? 32 : 28,
                        height: 17 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 20,
                        armHeight: 7,
                        seatHeight: 10,
                    },
                ],
            },
            height: [14, 20],
            seatHeight: [
                2,
                sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2,
            ],
            width: [chair.legs.width > 4 ? 36 : 28, 40],
            depth: [chair.legs.width > 4 ? 24 : 20, 40],
            backHeight: [8, 36],
            armHeight: [4, 12],
        },
        sideChair: {
            template: {
                chair: emptyState.sideChair,
                Illustration: Front,
                selections: [
                    {
                        label: "DINING",
                        width: 18.75,
                        depth: chair.legs.width > 4 ? 25.5 : 21.5,
                        height: 18 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 14,
                        seatHeight: 2,
                    },
                    {
                        label: "CASUAL",
                        width: 30,
                        depth: chair.legs.width > 4 ? 32 : 28,
                        height: 17 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 20,
                        seatHeight: 8.25,
                    },
                    {
                        label: "LOUNGE",
                        width: 40,
                        depth: chair.legs.width > 4 ? 32 : 28,
                        height: 17 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 14,
                        seatHeight: 14.75,
                    },
                ],
            },
            height: [14, 20],
            seatHeight: [
                2,
                sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2,
            ],
            width: [17, 40],
            depth: [chair.legs.width > 4 ? 24 : 20, 40],
            backHeight: [12, 36],
        },
        dayBed: {
            template: {
                chair: emptyState.sideChair,
                Illustration: Side,
                selections: [
                    {
                        label: "SMALL",
                        width: 18.75,
                        depth: chair.legs.width > 4 ? 4 * 12 + 4 : 4 * 12,
                        height: 12 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 14,
                        seatHeight: 2,
                    },
                    {
                        label: "CASUAL",
                        width: 30,
                        depth: chair.legs.width > 4 ? 5 * 12 + 4 : 5 * 12 + 4,
                        height: 14 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 20,
                        seatHeight: 8.25,
                    },
                    {
                        label: "LOUNGE",
                        width: 40,
                        depth: chair.legs.width > 4 ? 6 * 12 + 4 : 6 * 12,
                        height: 16 - sizeOfBlock(chair.seat.blocks),
                        backHeight: 14,
                        seatHeight: 14.75,
                    },
                ],
            },
            height: [12, 20],
            seatHeight: [
                2,
                sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2,
            ],
            width: [17, 40],
            depth: [chair.legs.width > 4 ? 4 * 12 + 4 : 4 * 12, 8 * 12 + 4],
            backHeight: [4, 36],
        },
        armBench: {
            Illustration: Front,
            seatHeight: [
                2,
                sizeOfBlock(chair.seat.blocks) + chair.legs.height - 2,
            ],
            height: [12, 20],
            width: [30, 96],
            depth: [chair.legs.width > 4 ? 17.5 : 9.5, 40],
            armHeight: [2, 12],
        },
    }

    function dispatch(obj) {
        setTemplate(null)
        globalState.dispatch(obj)
    }

    let metaTools = controls[chair.type]
    if (chair.org && controls[chair.org]) {
        metaTools = controls[chair.org][chair.subType]
    }
    const itmes = []
    for (const [key, value] of Object.entries(metaTools)) {
        if (key === "height") {
            itmes.push(
                <Height
                    key="height"
                    dispatch={(e) => {
                        dispatch(e)
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "default",
                            })
                        )
                    }}
                    range={value}
                    chair={chair}
                />
            )
        } else if (key === "width") {
            itmes.push(
                <Width
                    key="width"
                    dispatch={(e) => {
                        dispatch(e)
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "front",
                            })
                        )
                    }}
                    range={value}
                    chair={chair}
                />
            )
        } else if (key === "seatHeight") {
            itmes.push(
                <SeatHeight
                    key="seatheight"
                    dispatch={(e) => {
                        dispatch(e)
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "default",
                            })
                        )
                    }}
                    range={value}
                    chair={chair}
                />
            )
        } else if (key === "depth") {
            itmes.push(
                <Depth
                    key="depth"
                    dispatch={(e) => {
                        dispatch(e)
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "side",
                            })
                        )
                    }}
                    range={value}
                    chair={chair}
                />
            )
        } else if (key === "backHeight") {
            itmes.push(
                <BackHeight
                    key="backheight"
                    dispatch={(e) => {
                        dispatch(e)
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "default",
                            })
                        )
                    }}
                    range={value}
                    chair={chair}
                />
            )
        } else if (key === "armHeight") {
            itmes.push(
                <ArmHeight
                    key="armheight"
                    dispatch={(e) => {
                        dispatch(e)
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "front",
                            })
                        )
                    }}
                    range={value}
                    chair={chair}
                />
            )
        } else if (key === "template") {
            itmes.push(
                <TemplateControl
                    key="template"
                    dispatch={(e) => {
                        globalState.dispatch(e)
                        window.dispatchEvent(
                            new CustomEvent("viewChairSide", {
                                detail: "default",
                            })
                        )
                    }}
                    chair={chair}
                    data={value}
                    selected={template}
                    callback={setTemplate}
                />
            )
        }
    }

    return (
        <>
            {itmes}
            <h4>Overall Dimensions</h4>
            <OverallDimensions chair={chair} />
        </>
    )
}
