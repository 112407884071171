import React, { useEffect, useState, useMemo } from "react"
import useDebouncedEffect from "use-debounced-effect"
import ChairMultiView from "../layouts/ChairMultiView"
import {
    layersInBlocks,
    layersInBlocksByType,
    thicknessOfBlocks,
} from "../components/illustrations/utils"
import { useGlobalState } from "../state"
import ErrorModel from "../components/ErrorModel"
import { OverallDimensions } from "./DimensionControls"
import { Front, Side, Top } from "../components/illustrations/Illustrations"
import { Light3D } from "../components/illustrations/SideChair3D"

import axios from "axios"
import Config from "../Config"
import { v4 as uuidv4 } from "uuid"

import { cloneDeep } from "lodash"
import isEmail from "validator/lib/isEmail"
import { useHistory } from "react-router-dom"
import realizeLayers from "../lightRealize"
import { pendentConfig } from "../state/mg"

const UDS_TO_CAD = 1.25

function validateChair(chair) {
    if (chair.tube && chair.tube.blocks[0].color === "white") {
        return false
    }
    if (chair.seat && chair.seat.blocks[0].color === "white") {
        return false
    }
    if (chair.leftArm && chair.leftArm.blocks[0].color === "white") {
        return false
    }
    if (chair.rightArm && chair.rightArm.blocks[0].color === "white") {
        return false
    }
    if (chair.back && chair.back.blocks[0].color === "white") {
        return false
    }
    if (chair.tuft && chair.tuft.color === "black") {
        return false
    }
    if (chair.legs && chair.legs.color === "black") {
        return false
    }
    return true
}

function ShippedModal(props) {
    return (
        <ErrorModel
            save
            message={
                <>
                    <b>Thank you!</b>
                    <p>
                        Your Felt _2.0 order has been saved. Stacklab has been
                        notified and will contact you with details.
                    </p>
                </>
            }
            onClose={() => {
                props.onClose(null)
            }}
            closeButtonLabel="CLOSE"
            /*
            [
                <button
                    className="model-button"
                    onClick={() => {
                        window.sessionStorage.removeItem("state")
                        window.location.reload()
                    }}
                >
                    START OVER
                </button>*/
            buttons={[
                <button
                    className="model-button"
                    onClick={() => {
                        window.location = "/"
                    }}
                >
                    GO TO HOME
                </button>,
            ]}
        />
    )
}

export function CheckoutModal(props) {
    const [code, setCode] = useState("")
    const [state, setState] = useState("start")
    const history = useHistory()
    const [name, setName] = useState(
        props.chair.contact && props.chair.contact.name
            ? props.chair.contact.name
            : ""
    )
    const [email, setEmail] = useState(
        props.chair.contact && props.chair.contact.email
            ? props.chair.contact.email
            : ""
    )

    const [phoneNumber, setPhoneNumber] = useState(
        props.chair.contact && props.chair.contact.phoneNumber
            ? props.chair.contact.phoneNumber
            : ""
    )
    const [price, setPrice] = useState("")
    const [agreeToTerms, setAgreeToTerms] = useState(0)
    const [showApplyDesigner, setShowApplyDesigner] = useState(
        props.chair.codeRequestSent && props.chair.contact.codeRequestSent
            ? props.chair.contact.codeRequestSent
            : ""
    )
    const [studioSite, setStudioSite] = useState(
        props.chair.contact && props.chair.contact.studioSite
            ? props.chair.contact.studioSite
            : ""
    )
    const [studioName, setStudioName] = useState(
        props.chair.contact && props.chair.contact.studioName
            ? props.chair.contact.studioName
            : ""
    )

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })

    let mobile = false
    if (window.innerWidth < 850) {
        mobile = true
    }
    const [isTabletOrMobile, setIsTabletOrMobile] = useState(mobile)

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 850) {
                setIsTabletOrMobile(true)
            } else {
                setIsTabletOrMobile(false)
            }
        }
        window.addEventListener("resize", handleResize)
    })

    useEffect(() => {
        fetchPrice(props.chair, code, setPrice)
    }, [code, props.chair])
    function shipIt() {
        setState("shipping")
        const c = cloneDeep(props.chair)
        let id = null

        if (c.purchaseId) {
            id = c.purchaseId
        } else {
            id = uuidv4()
        }
        c.saveName = name

        c.contact = {
            ...c.contact,
            name,
            email,
            phoneNumber,
            studioSite,
            studioName,
        }
        let path = Config.apiBasePath + "purchase"
        if (Config.stripe) {
            path = Config.apiBasePath + "purchase?takePayment=1"
        }
        axios
            .post(path, {
                chair: c,
                chairType: props.chair.type,
                code: code.trim(),
                id: id,
                status: "new",
                update: c.purchaseId ? true : false,
            })
            .then((res) => {
                if (res.data.error) {
                    setState("codeError")
                    console.log("Code error")
                    console.log(res.data.error)
                } else {
                    setState("shipped")
                }
                if (res.headers["x-payment-url"]) {
                    window.location.href = res.headers["x-payment-url"]
                }
            })
            .catch((err) => {
                console.log({ message: "Other error", err })
                setState("error")
            })
    }
    if (state === "shipped") {
        return (
            <ShippedModal
                onClose={() => {
                    props.onClose(null)
                }}
            />
        )
    }

    return (
        <ErrorModel
            fullscreen={props.fullscreen}
            noImage
            hideButtons={state === "shipping"}
            closeButtonLabel="Continue Shopping"
            message={
                <>
                    <b>Item Summary</b>

                    <div className="item-summary-model">
                        <div>
                            <OverallDimensions chair={props.chair} />

                            <div className="control">
                                <div
                                    className="flex-h spread"
                                    style={{ textAlign: "right" }}
                                >
                                    <b>Price </b>
                                    {price == 0
                                        ? "..."
                                        : "USD" + formatter.format(price / 100)}
                                    <br />+ Shipping & Handling
                                </div>
                                <div className="flex-h spread">
                                    <b>Lead Time:</b>
                                    8-12 weeks
                                </div>
                            </div>
                        </div>
                        <div>
                            {props.chair.type === "basic" && (
                                <div
                                    className="flex spread"
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    <img
                                        style={{
                                            maxWidth: "80vw",
                                            maxWidth: "30vh",
                                        }}
                                        src={props.chair.cartImage}
                                    />
                                </div>
                            )}
                            {props.chair.type !== "pendent" &&
                                props.chair.type !== "basic" && (
                                    <div
                                        className="flex spread"
                                        style={{ alignItems: "center" }}
                                    >
                                        <Front
                                            chair={props.chair}
                                            type={props.chair.type}
                                            vw={mobile ? 12 : 7}
                                        />
                                        <Side
                                            chair={props.chair}
                                            type={props.chair.type}
                                            vw={mobile ? 14 : 7}
                                        />
                                        <Side
                                            right
                                            chair={props.chair}
                                            type={props.chair.type}
                                            vw={mobile ? 14 : 7}
                                        />
                                        <Top
                                            right
                                            chair={props.chair}
                                            type={props.chair.type}
                                            vw={mobile ? 14 : 7}
                                        />
                                    </div>
                                )}

                            {props.chair.type === "pendent" &&
                                props.chair.type !== "basic" && (
                                    <div
                                        className="flex "
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "30vw",
                                                height: "30vh",
                                            }}
                                        >
                                            <Light3D
                                                style={{
                                                    width: "30vw",
                                                    height: "30vh",
                                                }}
                                                chair={props.chair}
                                            />
                                        </div>
                                    </div>
                                )}

                            {state === "codeError" && (
                                <div
                                    style={{
                                        margin: "2vw",
                                        textAlign: "center",
                                    }}
                                >
                                    <b>
                                        There was an error with the form. Please
                                        check the entry and try again
                                    </b>
                                </div>
                            )}
                            {state === "error" && (
                                <div
                                    style={{
                                        margin: "2vw",
                                        textAlign: "center",
                                    }}
                                >
                                    <b>An error occured. Please try again</b>
                                </div>
                            )}
                            {state === "terms" && (
                                <div
                                    style={{
                                        margin: "2vw",
                                        textAlign: "center",
                                    }}
                                >
                                    <b>You must agree to the terms</b>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            }
            onClose={() => {
                props.onClose(null)
                history.push("/enter")
            }}
            leftButtons={[
                <button
                    onClick={() => {
                        props.onDelete()
                        props.onClose()
                    }}
                    className="model-button model-button-warning"
                >
                    DELETE
                </button>,
            ]}
            buttons={[
                <button
                    onClick={() => {
                        //shipIt()
                        history.push("/cart")
                    }}
                    className="model-button"
                >
                    CHECKOUT
                </button>,
            ]}
        />
    )
}

function CheckoutForm(props) {
    return (
        <div>
            <div style={{ margin: "2vw", textAlign: "left" }}>
                <b>Price: </b>
                {(props.price / 100).toLocaleString("en-US", {
                    style: "currency",
                    currency: "CAD",
                })}
            </div>
        </div>
    )
}

export function fetchCartPrice(chair, code, setFunc) {
    axios
        .post(Config.apiBasePath + "getPriceFromCart", {
            cart: chair,
            chairType: "__CART__",
            code: code,
            id: "FORPRICE",
        })
        .then((res) => {
            if (res.data.error) {
                console.log("Code error")
            } else {
                setFunc(res.data.price)
            }
        })
        .catch((err) => {
            console.log("Other error")
        })
}

export function fetchPriceForCart(cart, code, setFunc) {
    axios
        .post(Config.apiBasePath + "getPriceFromCart", {
            cart: cart,
            chair: null,
            chairType: "__CART__",
            code: code,
            id: "FORPRICE",
        })
        .then((res) => {
            if (res.data.error) {
                console.log("Code error")
            } else {
                setFunc(res.data.price)
            }
        })
        .catch((err) => {
            console.log("Other error")
        })
}

export function fetchPrice(chair, code, setFunc) {
    axios
        .post(Config.apiBasePath + "getPriceFromPurchase", {
            chair: {
                ...chair,
                tube: chair.tube
                    ? {
                          ...chair.tube,
                          blocks: realizeLayers(
                              chair.tube.blocks,
                              pendentConfig[chair.form]
                          ),
                      }
                    : undefined,
            },
            chairType: chair.type,
            code: code,
            id: "FORPRICE",
        })
        .then((res) => {
            if (res.data.error) {
                console.log("Code error")
            } else {
                setFunc(res.data.price)
            }
        })
        .catch((err) => {
            console.log("Other error")
        })
}

function CostView(props) {
    const [price, setPrice] = useState(null)
    const [model, setModel] = useState(null)

    const [hasEmail, setHasEmail] = useState(false)

    const [checkoutChair, setCheckoutChair] = useState(null)

    const state = useGlobalState()
    useEffect(() => {
        let block = []
        if (state.state.progress.chair.type === "pendent") {
            block = state.state.progress.chair.tube.blocks[0]
        } else {
            block = state.state.progress.chair.seat.blocks[0]
        }

        if (block.color !== "white") {
            setPrice("...")
        } else {
            if (Config.prePurchaseEmail) {
                if (
                    !state.state.progress.chair.contact ||
                    !state.state.progress.chair.contact.email
                ) {
                    setModel("emailCapture")
                }
            }
            setPrice(null)
        }
    }, [state.state.progress.chair])

    function updateIt(update = true, codeRequest = false, email = false) {
        const c = cloneDeep(state.state.progress.chair)

        let path = Config.apiBasePath + "purchase"

        if (codeRequest) {
            path = path + "?codeRequest=1"
        }

        axios
            .post(path, {
                chair: c,
                chairType: c.type,
                id: c.purchaseId,
                status: "prenew",
                update: update,
                sendSaveEmail: email ? true : false,
            })
            .then((res) => {
                if (codeRequest) {
                    c.contact.codeRequestSent = true
                    state.dispatch({
                        type: "CHAIR",
                        supressEvent: true,
                        payload: {
                            chair: c,
                        },
                    })
                }
            })
            .catch((err) => {
                console.log({ message: "Other error", err })
            })
    }
    useDebouncedEffect(
        () => {
            if (
                state.state.progress.chair.seat &&
                state.state.progress.chair.seat.blocks[0].color !== "white"
            ) {
                fetchPrice(state.state.progress.chair, "", setPrice)
                if (Config.prePurchaseEmail && hasEmail) {
                    updateIt()
                }
            }

            if (
                state.state.progress.chair.tube &&
                state.state.progress.chair.tube.blocks[0].color !== "white"
            ) {
                fetchPrice(state.state.progress.chair, "", setPrice)
                if (Config.prePurchaseEmail && hasEmail) {
                    updateIt()
                }
            }
        },
        500,
        [state.state.progress.chair]
    )
    if (price) {
        return (
            <div className="pricing-view">
                <div className="first-child">
                    {price != "..." && (
                        <>
                            <div>
                                {"USD" +
                                    (price / 100).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}
                            </div>
                            <div className="small">+shipping and handling</div>
                        </>
                    )}
                    {price === "..." && (
                        <>
                            <div>...</div>
                            <div className="small"></div>
                        </>
                    )}
                </div>
                {price && (
                    <div style={{ display: "flex", width: "100%", gap: "2px" }}>
                        {Config.saveFeature && (
                            <button
                                onClick={() => {
                                    setModel("emailCaptureSave")
                                }}
                                style={{ width: "50%", margin: 0 }}
                            >
                                Save
                            </button>
                        )}
                        <button
                            onClick={() => {
                                if (validateChair(state.state.progress.chair)) {
                                    state.state.progress.chair.key = uuidv4()
                                    state.dispatch({
                                        type: "CART_ADD",
                                        payload: {
                                            chair: state.state.progress.chair,
                                            qty: 1,
                                        },
                                    })
                                    setCheckoutChair(state.state.progress.chair)
                                    setModel("checkout")
                                } else {
                                    setModel("error")
                                }
                            }}
                            style={{
                                width: Config.saveFeature ? "50%" : "100%",
                                margin: 0,
                            }}
                        >
                            Add To Cart
                        </button>
                    </div>
                )}
                {model === "error" && (
                    <ErrorModel
                        message={
                            <>
                                <b>An Error Occured</b>
                                <p>Please select your finishes</p>
                            </>
                        }
                        onClose={() => {
                            setModel(null)
                        }}
                    />
                )}
                {model === "emailCapture" && (
                    <EmailCaptureDialog
                        setModel={setModel}
                        updateIt={updateIt}
                        setHasEmail={setHasEmail}
                    />
                )}
                {model === "emailCaptureSave" && (
                    <EmailCaptureDialog
                        setModel={setModel}
                        updateIt={(update, codeRequest) => {
                            updateIt(update, codeRequest, true)
                        }}
                        setHasEmail={setHasEmail}
                        save
                    />
                )}
                {model === "checkout" && (
                    <CheckoutModal
                        onClose={() => {
                            setModel(null)
                        }}
                        fullscreen
                        price={price}
                        payments={Config.stripe}
                        chair={checkoutChair}
                        onDelete={(chair) => {
                            state.dispatch({
                                type: "CART_REMOVE",
                                payload: {
                                    chair: state.state.progress.chair,
                                },
                            })
                        }}
                    />
                )}
            </div>
        )
    } else {
        return null
    }
}

function EmailCaptureDialog(props) {
    const [newsLetter, setNewsLetter] = useState(1)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [email, setEmail] = useState("")
    const [extraContact, setExtraContact] = useState({})
    const [studio, setStudio] = useState(false)
    const state = useGlobalState()

    return (
        <ErrorModel
            noImage
            closeButtonLabel={props.save ? "Send me my save link" : "Continue"}
            message={
                <>
                    <br />
                    <b>
                        {props.save
                            ? "Please enter your email to save this build"
                            : "Please enter your email for your build process"}
                    </b>

                    {invalidEmail && (
                        <>
                            <br />
                            <br />
                            <b>You must enter a valid email</b>
                        </>
                    )}

                    <br />
                    <br />
                    <div
                        style={{ margin: "2vw", textAlign: "left" }}
                        className="form-element"
                    >
                        <b>Email:</b>
                        <input
                            className="input-padded"
                            type="text"
                            onChange={(e) => {
                                setEmail(e.target.value.toUpperCase())
                            }}
                            value={email}
                        />
                    </div>
                    <br />
                    <div className="form-element">
                        <input
                            className="terms-check"
                            type="checkbox"
                            checked={newsLetter}
                            onChange={(e) => {
                                console.log(e.target.checked)
                                if (e.target.checked) {
                                    setNewsLetter(1)
                                } else {
                                    setNewsLetter(0)
                                }
                            }}
                        />
                        Send me emails about future offers and Stackabl news
                    </div>
                    {!studio && (
                        <div
                            style={{ textAlign: "right" }}
                            className="form-element"
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    width: "100%",
                                    textAlign: "right",
                                }}
                                onClick={() => {
                                    setStudio(true)
                                }}
                            >
                                Request designer code
                            </span>
                        </div>
                    )}
                    {studio && (
                        <>
                            <div
                                style={{ textAlign: "right" }}
                                className="form-element"
                            >
                                <span
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        width: "100%",
                                        textAlign: "right",
                                    }}
                                    onClick={() => {
                                        setStudio(false)
                                    }}
                                >
                                    Don't request designer code
                                </span>
                            </div>
                            <div
                                style={{
                                    margin: "2vw",
                                    textAlign: "left",
                                }}
                                className="form-element"
                            >
                                <b>Full Name:</b>
                                <input
                                    className="input-padded"
                                    type="text"
                                    onChange={(e) => {
                                        extraContact.name =
                                            e.target.value.toUpperCase()
                                        setExtraContact(extraContact)
                                    }}
                                    value={extraContact.name}
                                />
                            </div>
                            <div
                                style={{
                                    margin: "2vw",
                                    textAlign: "left",
                                }}
                                className="form-element"
                            >
                                <b>Phone Number:</b>
                                <input
                                    className="input-padded"
                                    type="text"
                                    onChange={(e) => {
                                        extraContact.phoneNumber =
                                            e.target.value.toUpperCase()
                                        setExtraContact(extraContact)
                                    }}
                                    value={extraContact.phoneNumber}
                                />
                            </div>
                            <div
                                style={{
                                    margin: "2vw",
                                    textAlign: "left",
                                }}
                                className="form-element"
                            >
                                <b>Studio Name:</b>
                                <input
                                    className="input-padded"
                                    type="text"
                                    onChange={(e) => {
                                        extraContact.studioName =
                                            e.target.value.toUpperCase()
                                        setExtraContact(extraContact)
                                    }}
                                    value={extraContact.studioName}
                                />
                            </div>
                            <div
                                style={{
                                    margin: "2vw",
                                    textAlign: "left",
                                }}
                                className="form-element"
                            >
                                <b>Studio Website:</b>
                                <input
                                    className="input-padded"
                                    type="text"
                                    onChange={(e) => {
                                        extraContact.studioSite =
                                            e.target.value.toUpperCase()
                                        setExtraContact(extraContact)
                                    }}
                                    value={extraContact.studioSite}
                                />
                            </div>
                        </>
                    )}
                </>
            }
            onClose={() => {
                if (isEmail(email)) {
                    const chair = state.state.progress.chair
                    if (!chair.contact) {
                        chair.contact = {}
                    }
                    const id = uuidv4()
                    chair.purchaseId = id
                    chair.contact.email = email
                    chair.contact.newsLetter = newsLetter

                    chair.contact.name = extraContact.name
                    chair.contact.phoneNumber = extraContact.phoneNumber
                    chair.contact.studioName = extraContact.studioName
                    chair.contact.studioSite = extraContact.studioSite

                    state.dispatch({
                        type: "CHAIR",
                        supressEvent: true,
                        payload: {
                            chair: chair,
                        },
                    })
                    props.setModel(null)
                    setInvalidEmail(false)
                    props.updateIt(
                        false,
                        chair.contact.studioName ? true : false
                    )
                    props.setHasEmail(true)
                } else {
                    setInvalidEmail(true)
                }
            }}
        />
    )
}

function SmallPrint() {
    const state = useGlobalState()

    return (
        <div className="smallPrint" style={{ textAlign: "center" }}>
            {state.state.progress.chair.legs.colorName === "wood" && (
                <i>
                    Stacklab will contact you with wood leg details after order
                    is placed
                </i>
            )}
        </div>
    )
}

function NeosProductViewLight(props) {
    return (
        <div className="product-view">
            <div id="main3dview" className="planview ">
                <h4>3D</h4>
                <Light3D chair={props.state.state.progress.chair} />
            </div>
        </div>
    )
}

export default function NeosProductView(props) {
    const state = useGlobalState()
    let mobile = false
    if (window.innerWidth < 850) {
        mobile = true
    }
    const [isTabletOrMobile, setIsTabletOrMobile] = useState(mobile)

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 850) {
                setIsTabletOrMobile(true)
            } else {
                setIsTabletOrMobile(false)
            }
        }
        window.addEventListener("resize", handleResize)
    })

    if (state.state.progress.chair.type === "pendent") {
        return (
            <div className="product-view">
                <NeosProductViewLight
                    state={state}
                    isTabletOrMobile={isTabletOrMobile}
                />
                <div className="pricing-view-container">
                    <CostView />
                </div>
            </div>
        )
    }
    return (
        <div className="product-view">
            <ChairMultiView width={!isTabletOrMobile ? "32" : "47"} />
            <div className="pricing-view-container">
                <CostView />
            </div>
        </div>
    )
}
