import React, { useEffect, useState } from "react"

import styles from "../css/home/nav.module.css"
import { ReactComponent as Logo } from "../images/Logo.svg"
import { ReactComponent as BentArrow } from "../images/bentArrow.svg"
import { useHistory, useLocation } from "react-router-dom"
import ErrorModel from "../components/ErrorModel"
import cross from "../images/iconCross.svg"

function Contact(props) {
    return (
        <div className={styles.contact}>
            <h1>Contact</h1>
            <div>
                <b>Email:</b>
                <br />
                <a href="mailto:service@stackabl.shop">service@stackabl.shop</a>
                <br />
                <br />
                <b>Phone:</b>
                <br />
                <a href="tel:+14167031310">416.703.1310</a>
                <br />
                <br />
                <b>Address:</b>
                <br />
                213 Sterling Rd, Toronto, ON M6R 2B2 <br />
                CANADA
            </div>
        </div>
    )
}

function MobileNav(props) {
    const history = useHistory()
    const location = useLocation()
    const [open, setOpen] = useState(false)

    return (
        <div className={styles.mobileNav}>
            <div
                className={styles.mobileLogo}
                onClick={() => {
                    history.push("/2021collection")
                }}
            >
                <Logo height={null} />
            </div>
            <div
                className={styles.mobilePlus}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                <img
                    className={open ? styles.open : null}
                    src={cross}
                    alt="open nav"
                />
            </div>
            {open && (
                <>
                    <div className={styles.mobileCopy}>
                        Introducing Stackabl's furniture and lighting
                        collections, turning waste into wonders.
                    </div>
                    <div className={styles.mobileLinks}>
                        <div
                            active={
                                location.pathname == "/about" ? "true" : null
                            }
                            onClick={() => {
                                history.push("/about")
                                setOpen(false)
                            }}
                            className={styles.navItem}
                        >
                            ABOUT
                        </div>
                        <div
                            active={
                                location.pathname == "/collections"
                                    ? "true"
                                    : null
                            }
                            onClick={() => {
                                history.push("/collections")
                                setOpen(false)
                            }}
                            className={styles.navItem}
                        >
                            COLLECTIONS
                        </div>
                        <div
                            active={
                                location.pathname == "/press" ? "true" : null
                            }
                            onClick={() => {
                                history.push("/press")
                                setOpen(false)
                            }}
                            className={styles.navItem}
                        >
                            PRESS
                        </div>
                        <div
                            active={
                                location.pathname == "/exhibitions"
                                    ? "true"
                                    : null
                            }
                            onClick={() => {
                                history.push("/exhibitions")
                                setOpen(false)
                            }}
                            className={styles.navItem}
                        >
                            EXHIBITIONS
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

const copy = (
    <>
        Introducing Stackabl's furniture and lighting collections, turning waste
        into wonders.
    </>
)

export default function Nav(props) {
    const [model, setModel] = useState(null)
    useEffect(() => {
        const oldMinHeight = document.body.style.minHeight
        const oldMaxHeight = document.body.style.maxHeight
        document.body.style.minHeight = "100vh"
        document.body.style.maxHeight = "100vh"

        const oldOverscrollBehaviorY = document.body.style.overscrollBehaviorY
        const oldPosition = document.body.style.position
        document.body.style.overscrollBehaviorY = "none"
        document.body.style.position = "fixed"

        return () => {
            document.body.style.minHeight = oldMinHeight
            document.body.style.maxHeight = oldMaxHeight
            document.body.style.overscrollBehaviorY = oldOverscrollBehaviorY
            document.body.style.position = oldPosition
        }
    }, [])
    const history = useHistory()
    const location = useLocation()

    let buildSee = {
        text: "BUILD YOUR OWN",
        action: () => {
            history.push("/types")
        },
    }
    if (location.pathname === "/types") {
        buildSee = {
            text: "SEE THE COLLECTION",
            action: () => {
                history.push("/collections")
            },
        }
    } else if (location.pathname === "/about") {
        buildSee = {
            text: "CONTACT US",
            action: () => {
                setModel(
                    <ErrorModel
                        noImage
                        onClose={() => {
                            setModel(null)
                        }}
                        message={<Contact />}
                    ></ErrorModel>
                )
            },
        }
    }

    return (
        <div className={styles.home}>
            {model}
            <div className={styles.left}>
                <div
                    className={styles.logoContainer}
                    onClick={() => {
                        history.push("/collections")
                    }}
                >
                    <Logo height={null} />
                </div>
                <div className={styles.nav}>
                    <div
                        active={location.pathname == "/about" ? "true" : null}
                        onClick={() => {
                            history.push("/about")
                        }}
                        className={styles.navItem}
                    >
                        ABOUT
                    </div>
                    <div
                        active={
                            location.pathname == "/collections" ? "true" : null
                        }
                        onClick={() => {
                            history.push("/collections")
                        }}
                        className={styles.navItem}
                    >
                        COLLECTIONS
                    </div>
                    <div
                        active={location.pathname == "/press" ? "true" : null}
                        onClick={() => {
                            history.push("/press")
                        }}
                        className={styles.navItem}
                    >
                        PRESS &amp; AWARDS
                    </div>
                    <div
                        active={
                            location.pathname == "/exhibitions" ? "true" : null
                        }
                        onClick={() => {
                            history.push("/exhibitions")
                        }}
                        className={styles.navItem}
                    >
                        EXHIBITIONS
                    </div>
                </div>
                <div className={styles.centerText}>{copy}</div>
            </div>

            <div id="content" className={styles.content}>
                {props.children}
            </div>
            <MobileNav />
            <div
                className={styles.buildSee}
                onClick={() => {
                    buildSee.action()
                }}
            >
                <span className={styles.bentArrow}>
                    <BentArrow />
                </span>
                <span>{buildSee.text}</span>
            </div>
        </div>
    )
}
