import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router"
import styles from "../css/home/collections.module.css"
import furnature from "../images/productImages/group/group2.jpg"
import lights from "../images/productImages/group/lgroup1.jpg"

export default function Collections(props) {
    const history = useHistory()
    return (
        <div className={styles.collections}>
            <div
                className={styles.cat}
                onClick={() => {
                    history.push("/lightcollections")
                }}
            >
                <img src={lights} />
                <label>lighting</label>
            </div>
            <div
                className={styles.cat + " " + styles.furnature}
                onClick={() => {
                    history.push("/2021collection")
                }}
            >
                <img src={furnature} />
                <label>furniture</label>
            </div>
        </div>
    )
}
