import React, { useEffect, useState } from "react"
import axios from "axios"

import Config from "../Config"

import styles from "../css/home/nav.module.css"

export default function Galery(props) {
    const [samples, setStamples] = useState([])
    useEffect(() => {
        axios
            .get(Config.apiBasePath + "chairSamples")
            .then((res) => {
                if (res.data.error) {
                    console.log("Code error")
                } else {
                    setStamples(res.data.chairs)
                    console.log(res.data.chairs)
                }
            })
            .catch((err) => {
                console.log("Other error")
            })
    }, [])
    return <div className={styles.galery}>foo</div>
}
