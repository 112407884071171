import { cloneDeep } from "lodash"

export const initialState = {
    type: "armBench",
    colors: [],
    pattern: 0,
    tuft: {
        radius: 1,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 20,
        depth: 20,
        blocks: [
            { layers: 6, color: "green" },
            { layers: 6, color: "blue" },
            { layers: 6, color: "green" },
        ],
    },
    leftArm: {
        width: 5,
        blocks: [
            { layers: 16, color: "yellow" },
            { layers: 16, color: "orange" },
        ],
    },
    rightArm: {
        width: 5,
        blocks: [
            { layers: 16, color: "orange" },
            { layers: 16, color: "yellow" },
        ],
    },

    legs: {
        height: 8,
        width: 5,
        color: "black",
    },
    radiusCorner: false,
}

export const emptyState = {
    type: "armBench",
    blockColors: [],
    blockColorsRGB: {},
    pattern: 0,

    tuft: {
        radius: 1,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 30,
        depth: 20,
        blocks: [{ layers: 18, color: "white" }],
    },
    leftArm: {
        width: 5,
        blocks: [{ layers: 32, color: "white" }],
    },
    rightArm: {
        width: 5,
        blocks: [{ layers: 32, color: "white" }],
    },

    legs: {
        height: 14,
        width: 4,
        color: "black",
    },
}

const optionOne = cloneDeep(emptyState)
const optionTwo = cloneDeep(emptyState)
optionTwo.seat.blocks[0].layers = 28
optionTwo.legs.height = 6

const optionThree = cloneDeep(emptyState)
optionThree.seat.blocks[0].layers = 48
optionThree.legs.height = 2

export const options = {
    "quarter height": optionOne,
    "half height": optionTwo,
    "full height": optionThree,
}

export function reducer(state, action) {
    switch (action.type) {
        case "ARM_BENCH":
            return { ...state, piece: action.payload }
        default:
            return state
    }
}
