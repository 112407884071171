import { cloneDeep } from "lodash"

export const initialState = {
    type: "sideChair",
    blockColors: [],
    blockColorsRGB: {},
    pattern: 0,
    tuft: {
        radius: 1,
        height: 0.24,
        color: "#F3F3F3",
    },
    seat: {
        width: 20,
        depth: 20,
        blocks: [
            { layers: 6, color: "green" },
            { layers: 6, color: "blue" },
            { layers: 6, color: "green" },
        ],
    },
    back: {
        depth: 5,
        blocks: [
            { layers: 36, color: "orange" },
            { layers: 26, color: "green" },
        ],
    },
    legs: {
        height: 8,
        width: 5,
        color: "#C4C4C4",
    },
    radiusCorner: false,
}

export const emptyState = {
    type: "sideChair",
    blockColors: [],
    blockColorsRGB: {},
    pattern: 0,
    tuft: {
        radius: 1,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 20,
        depth: 20,
        blocks: [{ layers: 18, color: "white" }],
    },
    back: {
        depth: 5,
        blocks: [{ layers: 62, color: "white" }],
    },
    legs: {
        height: 8,
        width: 4,
        color: "black",
    },
    radiusCorner: false,
}

const optionOne = cloneDeep(emptyState)
const optionTwo = cloneDeep(emptyState)
optionTwo.seat.blocks[0].layers = 28
optionTwo.legs.height = 6

const optionThree = cloneDeep(emptyState)
optionThree.seat.blocks[0].layers = 48
optionThree.legs.height = 2

export const options = {
    "quarter height": optionOne,
    "half height": optionTwo,
    "full height": optionThree,
}

export function reducer(state, action) {
    switch (action.type) {
        case "SIDE_CHAIR":
            return { ...state, piece: action.payload }
        default:
            return state
    }
}
