import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import LogoutButton from "../components/LogoutButton"
import { useAuth0 } from "@auth0/auth0-react"
import { useLocation } from "react-router"
import config from "../Config"
import "../css/admin.css"
import ErrorModel from "../components/ErrorModel"
import { ReactComponent as Logo } from "../images/Logo.svg"
import axios from "axios"
import seedrandom from "seedrandom"

function Purchases(props) {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
    const [purchases, setPurchases] = useState([])
    const [status, setStatus] = useState("paid")

    useEffect(() => {
        async function call() {
            const token = await getAccessTokenSilently({
                audience: `https://felt2.stacklab.ca`,
                scope: "*:*",
            })
            const url = config.apiBasePath + "admin/purchases?status=" + status
            console.log(url)
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    setPurchases(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        call()
    }, [setPurchases, getAccessTokenSilently, status])

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })

    return (
        <>
            status:
            <select
                value={status}
                onChange={(e) => {
                    setStatus(e.target.value)
                }}
            >
                <option value="prenew">prenew</option>

                <option value="new">new</option>
                <option value="paid">paid</option>
                <option value="inprogress">in progress</option>

                <option value="shipped">shipped</option>
            </select>
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>code</th>
                        <th>type</th>
                        <th>sub type</th>
                        <th>price</th>
                        <th>created</th>
                        <th>email</th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody>
                    {purchases.purchases &&
                        purchases.purchases.map(function (ro, ri) {
                            return (
                                <tr key={ri}>
                                    <td>
                                        <Link
                                            to={"/admin/purchase?id=" + ro.id}
                                        >
                                            {ro.id}
                                        </Link>
                                    </td>
                                    <td>{ro.code}</td>
                                    <td>{ro.chair.type}</td>
                                    <td>{ro.chair.subType}</td>
                                    <td>{formatter.format(ro.price / 100)}</td>
                                    <td>
                                        {ro.chair.contact
                                            ? ro.chair.contact.email
                                            : ""}
                                    </td>

                                    <td>
                                        {new Date(
                                            ro.createdAt
                                        ).toLocaleString()}
                                    </td>
                                    <td>{ro.status}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </>
    )
}

function AddCode(props) {
    const [code, setCode] = useState()
    const [email, setEmail] = useState()
    const [discount, setDiscount] = useState()
    const { getAccessTokenSilently } = useAuth0()

    async function submit() {
        const token = await getAccessTokenSilently({
            audience: `https://felt2.stacklab.ca`,
            scope: "*:*",
        })
        const url = config.apiBasePath + "admin/codes"
        axios
            .post(
                url,
                {
                    code,
                    email,
                    discount,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                props.onClose()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <ErrorModel
            onClose={() => props.onClose(null)}
            message={
                <div>
                    <div className="form-element">
                        <b>Code:</b>
                        <input
                            className="input-padded"
                            type="text"
                            value={code}
                            onChange={(e) => {
                                setCode(e.target.value)
                            }}
                        />
                    </div>
                    <div className="form-element">
                        <b>Email:</b>
                        <input
                            className="input-padded"
                            type="text"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </div>
                    <div className="form-element">
                        <b>Discount:</b>
                        <input
                            className="input-padded"
                            type="text"
                            value={discount}
                            onChange={(e) => {
                                setDiscount(e.target.value)
                            }}
                        />
                    </div>
                </div>
            }
            save
            buttons={[
                <button className="model-button" onClick={() => submit()}>
                    Insert
                </button>,
            ]}
        />
    )
}

function Codes(props) {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
    const [codes, setCodes] = useState([])
    const [model, setModel] = useState(null)
    async function call() {
        const token = await getAccessTokenSilently({
            audience: `https://felt2.stacklab.ca`,
            scope: "*:*",
        })
        const url = config.apiBasePath + "admin/codes"
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setCodes(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    async function update(code) {
        const token = await getAccessTokenSilently({
            audience: `https://felt2.stacklab.ca`,
            scope: "*:*",
        })
        const url = config.apiBasePath + "admin/codes"
        axios
            .put(url, code, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res)
                call()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        call()
    }, [getAccessTokenSilently])

    return (
        <>
            {model}
            <button
                onClick={() =>
                    setModel(
                        <AddCode
                            onClose={() => {
                                setModel(null)
                                window.location.reload()
                            }}
                        />
                    )
                }
            >
                Add Code
            </button>
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>code</th>
                        <th>email</th>
                        <th>discount</th>
                        <th>disabled</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    {codes.codes &&
                        codes.codes.map(function (ro, ri) {
                            return (
                                <tr key={ri}>
                                    <td>{ro.id}</td>
                                    <td>{ro.code}</td>
                                    <td>{ro.email}</td>
                                    <td>{ro.discount}</td>
                                    <td>{ro.disabled}</td>
                                    <th>
                                        <button
                                            onClick={() => {
                                                if (ro.disabled) {
                                                    ro.disabled = 0
                                                } else {
                                                    ro.disabled = 1
                                                }
                                                console.log(ro)
                                                update(ro)
                                            }}
                                        >
                                            {ro.disabled === 0 && <>disable</>}
                                            {ro.disabled === 1 && <>enable</>}
                                        </button>
                                    </th>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </>
    )
}

export default function Admin(props) {
    let Component = props.component ? props.component : null
    let title = props.title
    const location = useLocation()
    if (location.search === "?codes") {
        Component = Codes
        title = "Codes"
    }

    const print = new URLSearchParams(location.search).get("print")
    const css = print ? "admin print" : "admin"
    return (
        <div className={css}>
            {print && (
                <div className="logo">
                    <Logo />
                </div>
            )}
            <div className="menu">
                <Link to="/admin/">Purchases</Link>
                <Link to="/admin/?codes">Codes</Link>
            </div>
            <div className="header">
                <h1 className="title">{title}</h1>
                <div className="logout-button">
                    <LogoutButton />
                </div>
            </div>
            <div className="body">
                {Component && <Component />}
                {!Component && <Purchases print={print} />}
            </div>
        </div>
    )
}
