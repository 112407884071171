import { useState, useEffect } from "react"
import { Group, Rect, Shape, Line } from "react-konva"
import { cloneDeep } from "lodash"
import React from "react"
import Wood from "../../images/WoodMahoganyAfricanSanded0WEB.jpg"

import { identify } from "logrocket"

export const fivemm = 0.19685
export function inch(value, largestInch, size) {
    return (size / largestInch) * value
}

export function genericInch(chair, size) {
    const height =
        chair.legs.height +
        thicknessOfBlocks(chair.seat.blocks) +
        (chair.leftArm ? thicknessOfBlocks(chair.leftArm.blocks) : 0) +
        (chair.back ? thicknessOfBlocks(chair.back.blocks) : 0) +
        1 //Tuft height

    const largestInch = Math.max(height, chair.seat.width)
    return (i) => {
        return inch(i, largestInch, size)
    }
}

export function thicknessOfBlocks(blocks) {
    let size = 0
    for (const block of blocks) {
        if (block.type === "light") {
            size = size + block.layers * fivemm * 3
        } else if (block.type === "designerBoard") {
            size = size + block.layers * fivemm * 2
        } else {
            size = size + block.layers * fivemm
        }
    }
    return size
}

export function layersInBlocks(blocks) {
    let number = 0
    for (const block of blocks) {
        number = block.layers + number
    }

    return number
}

export function layersInBlocksByType(blocks, type) {
    let number = 0
    for (const block of blocks) {
        //== is intentional for undefined == null
        if (type == block.type) {
            number = block.layers + number
        }
    }

    return number
}

function explodeBlock(block, length) {
    const b = []
    for (let i = 0; i < length; i++) {
        b.push({ ...block, layers: 1 })
    }
    return b
}

export function explodeBlocks(blocks) {
    let b = []
    for (const bi of blocks) {
        b = b.concat(explodeBlock(bi, bi.layers))
    }
    return b
}

function CornerRect(props) {
    const _in = props.inch
    const chair = props.chair
    const block = props.block
    const fill = props.fill
    const height = props.height
    const currentY = props.y
    const strokeWidth = props.strokeWidth
    const width = props.width ? props.width : _in(chair.seat.width)
    let leftRaise = 0
    let rightRaise = 0

    if (props.left) {
        leftRaise = props.raise
    } else {
        rightRaise = props.raise
    }

    const curveFlatWidth = props.curveFlatWidth
        ? props.curveFlatWidth
        : chair.leftArm.width

    return (
        <>
            <Shape
                sceneFunc={(context, shape) => {
                    context.beginPath()
                    context.moveTo(0, currentY + leftRaise)
                    context.lineTo(_in(curveFlatWidth), currentY + leftRaise)

                    context.quadraticCurveTo(
                        _in(curveFlatWidth) + width / 6,
                        currentY + rightRaise,
                        width - _in(curveFlatWidth),
                        currentY + rightRaise
                    )
                    context.lineTo(width, currentY + rightRaise)
                    if (props.noCenterLine) {
                        context.stroke()
                        context.beginPath()
                        context.moveTo(
                            width,
                            currentY +
                                (props.flatbottom ? 0 : rightRaise) +
                                height
                        )
                    } else {
                        context.lineTo(
                            width,
                            currentY +
                                (props.flatbottom ? 0 : rightRaise) +
                                height
                        )
                    }
                    context.lineTo(
                        width - _in(curveFlatWidth),
                        currentY + (props.flatbottom ? 0 : rightRaise) + height
                    )
                    if (props.flatbottom) {
                        context.lineTo(
                            _in(curveFlatWidth),
                            currentY + leftRaise + height
                        )
                    } else {
                        context.quadraticCurveTo(
                            _in(curveFlatWidth) + width / 6,
                            currentY + rightRaise,
                            _in(curveFlatWidth),
                            currentY + leftRaise + height
                        )
                    }

                    context.lineTo(0, currentY + leftRaise + height)

                    if (props.noCenterLine) {
                        context.lineTo(0, currentY + leftRaise)

                        context.stroke()
                    } else {
                        context.closePath()
                        context.fillStrokeShape(shape)
                    }
                }}
                stroke="black"
                strokeWidth={strokeWidth}
                fill={fill}
                onMouseDown={() => {
                    if (props.onClick) {
                        props.onClick(props.blocks, block)
                    }
                }}
            />
        </>
    )
}

function DroopRect(props) {
    const _in = props.inch
    const chair = props.chair
    const block = props.block
    const fill = props.fill
    const height = props.height
    const currentY = props.y
    const strokeWidth = props.strokeWidth

    return (
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath()
                context.moveTo(0, currentY)
                context.lineTo(_in(chair.leftArm.width), currentY)
                context.quadraticCurveTo(
                    _in(chair.seat.width / 2),
                    _in(chair.seat.width * 0.09) + currentY,
                    _in(chair.seat.width) - _in(chair.rightArm.width),
                    currentY
                )
                context.lineTo(_in(chair.seat.width), currentY)
                context.lineTo(_in(chair.seat.width), height + currentY)
                context.lineTo(
                    _in(chair.seat.width) - _in(chair.rightArm.width),
                    height + currentY
                )
                context.quadraticCurveTo(
                    _in(chair.seat.width / 2),
                    _in(chair.seat.width * 0.09) + height + currentY,
                    _in(chair.rightArm.width),
                    height + currentY
                )
                context.lineTo(0, height + currentY)
                context.closePath()
                context.fillStrokeShape(shape)
            }}
            stroke="black"
            strokeWidth={strokeWidth}
            fill={fill}
            onMouseDown={() => {
                if (props.onClick) {
                    props.onClick(props.blocks, block)
                }
            }}
        />
    )
}

export function RectForBlocks(props) {
    let currentY = 0
    const blocks = []
    let stroke = 0
    let raise = 0
    let scaleY = 1
    let shiftY = 0
    const scaleX = props.flip ? -1 : 1
    const shiftX = props.flip ? props.width : 0
    const spacer = props.spacer ? props.spacer : 0
    let totalRaise = 0
    let bi = cloneDeep(props.blocks)
    const opLayers = props.opposingBlocks
        ? layersInBlocks(props.opposingBlocks)
        : 0
    const numLayers = layersInBlocks(props.blocks)

    if (props.inch(1) < 5) {
        stroke = 0
    }
    if (props.corner) {
        bi.reverse()
    }
    bi = explodeBlocks(bi)

    let rake = props.rake ? props.rake : 0

    const skipLayers = props.skipLayers ? props.skipLayers : 0
    let skipCount = 0

    for (const block of bi) {
        if (block.layers === 0) {
            continue
        }
        if (props.droop) {
            blocks.push(
                <DroopRect
                    inch={props.inch}
                    block={block}
                    y={currentY}
                    chair={props.chair}
                    onClick={props.onClick}
                    blocks={bi}
                    fill={block.color}
                    height={props.inch(
                        block.layers * (block.type === "designerBoard")
                            ? fivemm * 2
                            : fivemm
                    )}
                    strokeWidth={stroke}
                />
            )
        } else if (props.corner) {
            blocks.push(
                <CornerRect
                    inch={props.inch}
                    block={block}
                    y={currentY - props.inch(fivemm)}
                    chair={props.chair}
                    onClick={props.onClick}
                    blocks={props.blocks}
                    fill={block.color}
                    height={props.inch(
                        block.layers * (block.type === "designerBoard")
                            ? fivemm * 2
                            : fivemm
                    )}
                    strokeWidth={stroke}
                    raise={props.inch(raise * fivemm)}
                    width={props.width}
                    left
                    curveFlatWidth={props.curveFlatWidth}
                />
            )
            shiftY = props.inch(raise * fivemm) + currentY
            totalRaise = props.inch(raise * fivemm)

            if (skipLayers > skipCount) {
                skipCount++
            } else {
                if (opLayers > raise) {
                    raise++
                }
            }
        } else {
            if (!props.odd) {
                raise++

                if (props.opposingBlocks && raise > numLayers - opLayers) {
                    currentY = currentY + props.inch(fivemm)
                }
            }
            blocks.push(
                <Rect
                    x={0 - rake}
                    y={currentY}
                    height={props.inch(
                        block.layers * (block.type === "designerBoard")
                            ? fivemm * 2
                            : block.type == "light"
                            ? fivemm * 3
                            : fivemm
                    )}
                    stroke="black"
                    width={props.width + rake}
                    strokeWidth={stroke}
                    fill={block.type === "light" ? "white" : block.color}
                    onMouseDown={() => {
                        if (props.onClick) {
                            props.onClick(blocks, block)
                        }
                    }}
                />
            )
            if (props.odd) {
                raise++
                if (props.opposingBlocks && raise > numLayers - opLayers) {
                    currentY = currentY + props.inch(fivemm)
                }
            }
        }
        currentY =
            currentY +
            props.inch(
                block.layers * (block.type === "designerBoard")
                    ? fivemm * 2
                    : block.type === "light"
                    ? fivemm * 3
                    : fivemm
            ) +
            spacer

        if (props.rake) {
            rake = rake + props.rake
        }
    }

    let border
    if (props.droop) {
        border = (
            <DroopRect
                inch={props.inch}
                y={0}
                chair={props.chair}
                blocks={props.blocks}
                height={currentY}
                strokeWidth={1}
            />
        )
    } else if (props.corner) {
        border = (
            <Group scaleX={scaleX} x={shiftX}>
                <CornerRect
                    inch={props.inch}
                    y={0}
                    chair={props.chair}
                    blocks={props.blocks}
                    height={shiftY + props.inch(fivemm)}
                    width={
                        props.width
                            ? props.width
                            : props.inch(props.chair.seat.width)
                    }
                    strokeWidth={1}
                    raise={totalRaise}
                    left={false}
                    flatbottom
                    noCenterLine={props.noCenterLine}
                    curveFlatWidth={props.curveFlatWidth}
                />
            </Group>
        )
    } else if (props.rake) {
        border = (
            <BorderRake
                key="border"
                x={0}
                y={0}
                height={currentY}
                stroke="black"
                width={props.width}
                strokeWidth={1}
                rake={rake}
            />
        )
    } else {
        border = (
            <Rect
                key="border"
                x={0}
                y={0}
                height={currentY}
                stroke="black"
                width={props.width}
                strokeWidth={1}
            />
        )
    }

    if (props.corner) {
        scaleY = -1
    }

    return (
        <Group x={props.x} y={props.y}>
            <Group scaleX={scaleX} x={shiftX} scaleY={scaleY} y={shiftY}>
                {blocks}
            </Group>
            {!props.noBorder && border}
        </Group>
    )
}

function BorderRake(props) {
    const x = props.x
    const y = props.y
    const width = props.width
    const rake = props.rake
    const height = props.height

    return (
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath()
                context.moveTo(x, y)
                context.lineTo(x + width, y)
                context.lineTo(x + width, y + height)

                context.lineTo(x - rake, y + height)

                context.closePath()
                context.fillStrokeShape(shape)
            }}
            strokeWidth={1}
            stroke="black"
        />
    )
}

export function cornerRadius(chair, _in) {
    if (chair.type === "stool") {
        return _in(chair.seat.depth / 2)
    }
    if (chair.radiusCorner) {
        return _in(chair.legs.width / 2)
    }
    return 0
}

function WoodLegType1(props) {
    const fat = props.legType === 5 ? props.inch(2) : 0
    const multi = props.width < props.inch(5) ? 1 : 2
    return (
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath()
                context.moveTo(props.x, props.y)
                context.lineTo(props.x, props.y + props.inch(1.5))
                context.lineTo(
                    props.x + props.inch(0.5) * multi,
                    props.y + props.inch(1.5)
                )
                context.lineTo(
                    props.x + props.inch(0.5) * multi,
                    props.y + props.inch(2)
                )
                context.lineTo(
                    props.x + props.inch(1.5) * multi,
                    props.y + props.inch(2)
                )

                context.quadraticCurveTo(
                    props.x - fat,
                    props.y + props.inch(2),
                    props.x - fat,
                    props.y + props.inch(multi > 1 ? 5.8 : 4) + fat
                )

                context.lineTo(
                    props.x - fat,
                    props.y + props.height - props.inch(2 * multi) - fat
                )

                context.quadraticCurveTo(
                    props.x + props.width - props.inch(4 * multi) - fat,
                    props.y + props.height,
                    props.x + props.width / 2,
                    props.y + props.height
                )

                context.quadraticCurveTo(
                    props.x + props.width + fat,
                    props.y + props.height,
                    props.x + props.width + fat,
                    props.y + props.height - props.inch(2 * multi) - fat
                )

                context.lineTo(
                    props.x + props.width + fat,
                    props.y + props.inch(multi > 1 ? 7 : 4) + fat
                )

                context.quadraticCurveTo(
                    props.x + props.inch(multi > 1 ? 8.3 : 4) + fat,
                    props.y + props.inch(multi > 1 ? 1.8 : 2),

                    props.x + props.width - props.inch(1.5 * multi),
                    props.y + props.inch(2)
                )

                context.lineTo(
                    props.x + props.width - props.inch(0.5 * multi),
                    props.y + props.inch(2)
                )
                context.lineTo(
                    props.x + props.width - props.inch(0.5 * multi),
                    props.y + props.inch(1.5)
                )
                context.lineTo(
                    props.x + props.width - props.inch(0.5 * multi),
                    props.y + props.inch(1.5)
                )
                context.lineTo(props.x + props.width, props.y + props.inch(1.5))
                context.lineTo(props.x + props.width, props.y)
                context.lineTo(props.x, props.y)
                context.closePath()
                context.fillStrokeShape(shape)
            }}
            strokeWidth={1}
            stroke="black"
            fillPatternImage={props.wood}
        />
    )
}

function WoodLegType2(props) {
    const multi = props.width < props.inch(5) ? 1 : 2
    return (
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath()
                context.moveTo(props.x, props.y)
                context.lineTo(props.x, props.y + props.inch(2))
                context.lineTo(
                    props.x + props.inch(0.2 * multi),
                    props.y + props.inch(2)
                )
                context.lineTo(
                    props.x + props.inch(0.2 * multi),
                    props.y + props.inch(2.2)
                )
                context.lineTo(
                    props.x + props.inch(0.2 * multi),
                    props.y + props.inch(2.4)
                )
                context.lineTo(props.x, props.y + props.inch(2.4))

                context.lineTo(
                    props.x,
                    props.y + props.height - props.inch(2) * multi
                )

                context.quadraticCurveTo(
                    props.x + props.width - props.inch(4) * multi,
                    props.y + props.height,
                    props.x + props.width / 2,
                    props.y + props.height
                )

                context.quadraticCurveTo(
                    props.x + props.width,
                    props.y + props.height,
                    props.x + props.width,
                    props.y + props.height - props.inch(2) * multi
                )

                context.lineTo(props.x + props.width, props.y + props.inch(2.4))
                context.lineTo(
                    props.x + props.width - props.inch(0.2) * multi,
                    props.y + props.inch(2.4)
                )
                context.lineTo(
                    props.x + props.width - props.inch(0.2) * multi,
                    props.y + props.inch(2)
                )
                context.lineTo(props.x + props.width, props.y + props.inch(2))

                context.lineTo(props.x + props.width, props.y)
                context.lineTo(props.x, props.y)
                context.closePath()
                context.fillStrokeShape(shape)
            }}
            strokeWidth={1}
            stroke="black"
            fillPatternImage={props.wood}
        />
    )
}

function WoodLegTypeStepped(props) {
    let segHeight = props.inch(0.772)
    let legShape = [
        3.335, 2.867, 2.6, 2.3, 2.08, 2.04, 2.22, 2.511, 2.86, 3.23, 3.59, 3.88,
        4, 4, 3.88, 3.63, 3.3, 2.91, 2.56, 2.26, 2.05, 2,
    ]

    const legShape3Dim = [
        4, 4, 4, 4, 4, 4, 3.95, 3.92, 3.88, 3.8, 3.71, 3.6, 3.5, 3.35, 3.2,
        3.04, 2.9, 2.74, 2.6, 2.45, 2.33, 2.2, 2.13, 2.08, 2.04, 2, 2,
    ]

    if (props.legType === 3) {
        legShape = legShape3Dim
        segHeight = props.inch(0.65735)
    }

    if (props.legType === 3) {
        legShape.length = Math.ceil(props.height / segHeight)
    }

    const multi = props.width > props.inch(5) ? 2 : 1

    const modelHeight = legShape.length * segHeight
    const heightDiff = modelHeight - props.height
    const segNum = Math.floor(props.height / segHeight)
    const firstSegHeight =
        props.height % segHeight === 0 ? 0 : props.height - segNum * segHeight
    const firstSeg = Math.floor(legShape.length - segNum)
    let currentY = props.y

    return (
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath()
                context.rect(
                    props.x +
                        props.width / 2 -
                        props.inch(legShape[firstSeg] / 2) * multi,
                    currentY,
                    props.inch(legShape[firstSeg]) * multi,
                    firstSegHeight
                )
                currentY = currentY + firstSegHeight
                for (let p = firstSeg; p < legShape.length - 1; p++) {
                    context.rect(
                        props.x +
                            props.width / 2 -
                            props.inch(legShape[p] / 2) * multi,
                        currentY,
                        props.inch(legShape[p]) * multi,
                        segHeight
                    )
                    currentY = currentY + segHeight
                }
                context.closePath()
                context.fillStrokeShape(shape)
            }}
            strokeWidth={1}
            stroke="black"
            fillPatternImage={props.wood}
        />
    )
}
function WoodLeg(props) {
    if (props.legType === 2) {
        return <WoodLegType2 {...props} />
    }
    if (props.legType === 3 || props.legType === 4) {
        return <WoodLegTypeStepped {...props} />
    }
    if (props.legType === 5) {
        return <WoodLegType1 fat {...props} />
    }
    return <WoodLegType1 {...props} />
}

export function Leg(props) {
    const [wood, setWood] = useState(null)
    useEffect(() => {
        if (props.colorName === "wood") {
            const image = new window.Image()
            image.onload = () => {
                setWood(image)
            }
            if (props.fill === "wood_charcoal") {
                image.src = "/leg_colors/wood_charcoal.jpg"
            } else if (props.fill === "wood_natural") {
                image.src = "/leg_colors/wood_natural.jpg"
            } else if (props.fill === "wood_black") {
                image.src = "/leg_colors/wood_black.jpg"
            } else {
                image.src = Wood
            }
        } else {
            setWood(null)
        }
    }, [props.colorName, props.fill])
    if (props.colorName !== "woodOLDWAY" || props.neverRound) {
        if (props.legType) {
            return <WoodLeg wood={wood} {...props} />
        } else {
            return (
                <Rect
                    x={props.x}
                    y={props.y}
                    width={props.width}
                    height={props.height}
                    fill={wood ? null : props.fill}
                    strokeWidth={1}
                    stroke="black"
                    fillPatternImage={wood}
                />
            )
        }
    } else {
        return (
            <Shape
                sceneFunc={(context, shape) => {
                    context.beginPath()
                    context.moveTo(props.x, props.y)
                    context.lineTo(
                        props.x,
                        props.height + props.y - props.width / 4
                    )

                    context.quadraticCurveTo(
                        props.x + props.width / 2,
                        props.height + props.y + props.width / 4,
                        props.x + props.width,
                        props.y + props.height - props.width / 4
                    )

                    context.lineTo(props.x + props.width, props.y)
                    context.closePath()
                    context.fillStrokeShape(shape)
                }}
                strokeWidth={1}
                stroke="black"
                fillPatternImage={wood}
            />
        )
    }
}
