import React from "react"
import Alert from "../images/alert.svg"
import Save from "../images/save.svg"

export default function ErrorModel(props) {
    return (
        <div className={props.fullscreen ? "model full-screen" : "model"}>
            <div className="model-overlay"></div>
            <div className="model-body">
                <div className="model-content ">
                    {!props.noImage && (
                        <img
                            alt="alert"
                            className="model-image"
                            src={props.save ? Save : Alert}
                        />
                    )}
                    <div className="model-text">{props.message}</div>
                </div>
                {!props.hideButtons && (
                    <>
                        <div className="model-buttons">
                            {props.leftButtons}
                            <div className="model-button-blank">&nbsp;</div>

                            <button
                                onClick={() => {
                                    props.onClose()
                                }}
                                className="model-button"
                            >
                                {props.closeButtonLabel
                                    ? props.closeButtonLabel
                                    : "Close"}
                            </button>
                            {props.buttons}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
