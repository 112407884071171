import React from "react"
import { Stage, Layer, Rect, Group, Line, Shape } from "react-konva"
import ChairTop from "./ArmChairSide"
import { inch, thicknessOfBlocks, RectForBlocks, Leg } from "./utils"

export default function CornerChairFront(props) {
    const chair = props.chair
    const height =
        chair.legs.height +
        thicknessOfBlocks(chair.seat.blocks) +
        thicknessOfBlocks(chair.leftArm.blocks) +
        thicknessOfBlocks(chair.back.blocks) +
        1 //Tuft height

    const largestInch = Math.max(height, chair.seat.width)
    const _in = props.in
        ? props.in
        : (i) => {
              return inch(i, largestInch, props.size)
          }
    return (
        <Stage width={_in(chair.seat.width) + 1} height={_in(height) + 1}>
            <Layer>
                <Group
                    x={0}
                    y={_in(
                        thicknessOfBlocks(chair.back.blocks) +
                            thicknessOfBlocks(chair.leftArm.blocks) +
                            thicknessOfBlocks(chair.seat.blocks) +
                            chair.tuft.height
                    )}
                >
                    <Leg
                        x={0 + _in(0.5)}
                        y={0}
                        width={_in(chair.legs.width)}
                        height={_in(chair.legs.height)}
                        fill={chair.legs.color}
                        colorName={chair.legs.colorName}
                        inch={(i) => {
                            return _in(i)
                        }}
                        legType={chair.legs.legTypes[0]}
                    />

                    <Leg
                        x={_in(chair.seat.width - chair.legs.width) - _in(0.5)}
                        y={0}
                        width={_in(chair.legs.width)}
                        height={_in(chair.legs.height)}
                        fill={chair.legs.color}
                        colorName={chair.legs.colorName}
                        inch={(i) => {
                            return _in(i)
                        }}
                        legType={chair.legs.legTypes[2]}
                    />
                    {chair.legs.centerLeg && (
                        <Leg
                            x={_in(chair.seat.width / 2) - _in(0.5)}
                            y={0}
                            width={_in(chair.legs.width)}
                            height={_in(chair.legs.height)}
                            fill={chair.legs.color}
                            colorName={chair.legs.colorName}
                            inch={(i) => {
                                return _in(i)
                            }}
                            legType={chair.legs.legTypes[4]}
                        />
                    )}
                </Group>

                <Group>
                    {/*Tufts*/}
                    <Rect
                        x={_in(chair.leftArm.width / 2 - chair.tuft.radius)}
                        y={0}
                        width={_in(chair.tuft.radius * 2)}
                        height={_in(chair.tuft.height)}
                        fill={chair.tuft.color}
                        strokeWidth={1}
                        stroke="black"
                    />

                    <Rect
                        x={_in(
                            (chair.back.width
                                ? chair.back.width
                                : chair.seat.width) -
                                chair.leftArm.width / 2 -
                                chair.tuft.radius
                        )}
                        y={_in(thicknessOfBlocks(chair.leftArm.blocks))}
                        width={_in(chair.tuft.radius * 2)}
                        height={_in(chair.tuft.height)}
                        fill={chair.tuft.color}
                        strokeWidth={1}
                        stroke="black"
                    />
                </Group>
                <Group x={0} y={_in(chair.tuft.height)}>
                    <RectForBlocks
                        onClick={props.onClick}
                        x={0}
                        y={0}
                        blocks={chair.back.blocks}
                        inch={(i) => {
                            return _in(i)
                        }}
                        corner
                        chair={chair}
                        opposingBlocks={chair.leftArm.blocks}
                        width={
                            chair.back.width
                                ? _in(chair.back.width)
                                : _in(chair.seat.width)
                        }
                    />

                    <RectForBlocks
                        onClick={props.onClick}
                        x={0}
                        y={_in(
                            thicknessOfBlocks(chair.back.blocks) +
                                thicknessOfBlocks(chair.leftArm.blocks)
                        )}
                        blocks={chair.seat.blocks}
                        inch={(i) => {
                            return _in(i)
                        }}
                        width={_in(chair.seat.width)}
                    />
                    <RectForBlocks
                        x={0}
                        y={_in(
                            thicknessOfBlocks(chair.back.blocks) +
                                thicknessOfBlocks(chair.leftArm.blocks) +
                                thicknessOfBlocks(chair.seat.blocks)
                        )}
                        blocks={[{ layers: 1, color: chair.tuft.color }]}
                        inch={(i) => {
                            return _in(i)
                        }}
                        width={_in(chair.seat.width)}
                    />
                    <RectForBlocks
                        onClick={props.onClick}
                        x={0}
                        y={_in(thicknessOfBlocks(chair.back.blocks))}
                        blocks={chair.leftArm.blocks}
                        inch={(i) => {
                            return _in(i)
                        }}
                        width={_in(chair.leftArm.width)}
                    />
                    <Rect
                        x={0}
                        y={_in(
                            thicknessOfBlocks(chair.back.blocks) -
                                thicknessOfBlocks(chair.leftArm.blocks)
                        )}
                        width={_in(chair.leftArm.width)}
                        height={_in(thicknessOfBlocks(chair.leftArm.blocks))}
                        fill={chair.leftArm.blocks[0].color}
                        strokeWidth={1}
                        stroke="black"
                    />
                </Group>
            </Layer>
        </Stage>
    )
}
