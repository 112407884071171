import React from "react"

function componentToHex(c) {
    var hex = c.toString(16)
    return hex.length === 1 ? "0" + hex : hex
}

function _getAverageRGB(imgEl) {
    let blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement("canvas"),
        context = canvas.getContext && canvas.getContext("2d"),
        data,
        width,
        height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0

    if (!context) {
        return defaultRGB
    }

    height = canvas.height =
        imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height
    width = canvas.width =
        imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width
    context.drawImage(imgEl, 0, 0)

    data = context.getImageData(0, 0, width, height)
    length = data.data.length
    while ((i += blockSize * 4) < length) {
        ++count
        rgb.r += data.data[i]
        rgb.g += data.data[i + 1]
        rgb.b += data.data[i + 2]
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count)
    rgb.g = ~~(rgb.g / count)
    rgb.b = ~~(rgb.b / count)
    return rgb
}
export function getAverageRGB(imgEl) {
    const r = _getAverageRGB(imgEl)
    return "#" + componentToHex(r.r) + componentToHex(r.g) + componentToHex(r.b)
}

export function ColorPalette(props) {
    const colors = []
    const ext = props.extenstion ? "." + props.extenstion : ""
    const args = props.args ? props.args : ""
    for (const c of props.colors) {
        colors.push(
            <div
                onClick={(e) => {
                    props.colorSelected(
                        c,
                        getAverageRGB(
                            window.document.getElementById("color-" + c)
                        ),
                        e
                    )
                }}
                className="toggle compact simple-color rounded"
            >
                <img
                    crossOrigin="Anonymous"
                    alt={c}
                    id={"color-" + c}
                    src={props.baseUrl + c + ext + args}
                ></img>
            </div>
        )
    }

    return (
        <div
            className={"control color-selection"}
            style={props.width ? { width: props.width } : {}}
        >
            <h4 style={{ color: "black" }}>Color selection</h4>
            <div className="toggle-platform ">{colors}</div>
        </div>
    )
}
