import React, { useState } from "react"
import classNames from "classnames"
import { getAverageRGB } from "./ColorPalette"
import ReactTooltip from "react-tooltip"
export function SimpleColorSelector(props) {
    return (
        <div className="simple-color-selector toggle-platform">
            {props.colors.map((value, index) => {
                const color = Object.values(value)[0]
                const name = Object.keys(value)[0]
                return (
                    <div>
                        <div
                            key={index}
                            className={classNames(
                                "toggle compact simple-color rounded",
                                {
                                    selected: color === props.selected,
                                }
                            )}
                        >
                            <div
                                className="inside-color"
                                style={{ backgroundColor: color }}
                                onClick={() => props.onClick(value, name)}
                            ></div>
                        </div>
                        {props.showLabels && (
                            <div className="simple-color-selector label">
                                {name}
                            </div>
                        )}
                    </div>
                )
            })}
            {props.addWidget}
        </div>
    )
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
export function UrlColorSelector(props) {
    const [uid, _] = useState("color-" + Math.random())
    const ext = props.imageExtension ? "." + props.imageExtension : ""
    const args = props.args ? props.args : ""
    return (
        <div className="simple-color-selector toggle-platform">
            {props.colors.map((value, index) => {
                const url = props.baseUrl + value
                return (
                    <div
                        key={index}
                        className={classNames(
                            "toggle compact simple-color rounded",
                            { halfheight: props.halfHeight }
                        )}
                    >
                        <ReactTooltip
                            multiline={true}
                            type="dark"
                            effect="float"
                            place="top"
                        />
                        <div
                            className="inside-color"
                            data-tip={capitalizeFirstLetter(value)}
                            onClick={() =>
                                props.onClick(
                                    value,
                                    getAverageRGB(
                                        window.document.getElementById(
                                            uid + "-" + index
                                        )
                                    )
                                )
                            }
                        >
                            <img
                                crossOrigin="Anonymous"
                                id={uid + "-" + index}
                                src={url + ext + args}
                                alt={value}
                            />
                            {props.badge !== false && (
                                <div className="badge">
                                    {props.badge ? props.badge : "-"}
                                </div>
                            )}
                        </div>
                    </div>
                )
            })}
            {props.showAddWidget && props.addWidget}
        </div>
    )
}
