export const initialState = {
    step: "type",
    chair: null,
    type: null,
}

//Bandaid
function checkChair(chair) {
    if (chair.type === "pendent") {
        return true
    }
    if (chair.seat.blocks.layers) {
        return false
    }
    return true
}

function makeProgress(obj) {
    const clone = { ...obj }

    const chair = clone.chair

    delete clone.chair
    return {
        set chair(ch2) {
            this._chair = ch2
        },
        get chair() {
            return this._chair
        },
        ...clone,
        _chair: chair,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case "PROGRESS":
            return {
                ...state,
                progress: makeProgress({
                    ...state.progress,
                    step: action.payload.step,
                }),
            }
        case "CHAIR":
            if (!checkChair(action.payload.chair)) {
                return {
                    ...state,
                    progress: makeProgress({
                        ...state.progress,
                        chair: state.progress.chair,
                    }),
                }
            }
            if (!action.supressEvent) {
                setTimeout(() => {
                    window.dispatchEvent(
                        new CustomEvent("updateProgressChair", {
                            detail: "CHAIR",
                        })
                    )
                }, 1)
            }
            return {
                ...state,
                progress: makeProgress({
                    ...state.progress,
                    chair: { ...action.payload.chair },
                }),
            }
        case "CHAIR_PROGRESS":
            if (!action.supressEvent) {
                setTimeout(() => {
                    window.dispatchEvent(
                        new CustomEvent("updateProgressChair", {
                            detail: "CHAIR",
                        })
                    )
                }, 100)
            }
            return {
                ...state,
                progress: makeProgress({
                    ...state.progress,
                    ...action.payload,
                }),
            }

        case "NEW_CHAIR_PROGRESS":
            if (!action.supressEvent) {
                setTimeout(() => {
                    window.dispatchEvent(
                        new CustomEvent("updateProgressChair", {
                            detail: "CHAIR",
                        })
                    )
                }, 100)
            }
            return {
                ...state,
                progress: makeProgress({
                    ...state.progress,
                    ...action.payload,
                }),
            }
        default:
            return state
    }
}
