import React from "react"
import { Stage, Layer, Rect, Group } from "react-konva"
import {
    inch,
    thicknessOfBlocks,
    RectForBlocks,
    layersInBlocks,
    Leg,
    fivemm,
} from "./utils"

export default function BenchFront(props) {
    const chair = props.chair
    let height = chair.legs.height + thicknessOfBlocks(chair.seat.blocks) + 1 //Tuft height
    let extraPush = 0
    let tuftStart = 0

    const largestInch = Math.max(height, chair.seat.width)
    const _in = props.in
        ? props.in
        : (i) => {
              return inch(i, largestInch, props.size)
          }
    if (chair.type === "armBench") {
        height = height + thicknessOfBlocks(chair.leftArm.blocks)
        extraPush = thicknessOfBlocks(chair.leftArm.blocks)
    }
    return (
        <Stage width={_in(chair.seat.width) + 1} height={_in(height) + 1}>
            <Layer>
                <Group
                    x={0}
                    y={_in(
                        thicknessOfBlocks(chair.seat.blocks) +
                            chair.tuft.height +
                            extraPush
                    )}
                >
                    <Leg
                        x={_in(0.5)}
                        y={0}
                        width={_in(chair.legs.width)}
                        height={_in(chair.legs.height)}
                        fill={chair.legs.color}
                        colorName={chair.legs.colorName}
                        neverRound={chair.type === "stool"}
                        inch={(i) => {
                            return _in(i)
                        }}
                        legType={chair.legs.legTypes[0]}
                    />

                    <Leg
                        x={_in(chair.seat.width - chair.legs.width) - _in(0.5)}
                        y={0}
                        width={_in(chair.legs.width)}
                        height={_in(chair.legs.height)}
                        fill={chair.legs.color}
                        colorName={chair.legs.colorName}
                        neverRound={chair.type === "stool"}
                        inch={(i) => {
                            return _in(i)
                        }}
                        legType={chair.legs.legTypes[2]}
                    />

                    {chair.type === "armBench" && (
                        <Leg
                            x={_in(chair.seat.width - chair.legs.width) / 2}
                            y={0}
                            width={_in(chair.legs.width)}
                            height={_in(chair.legs.height)}
                            fill={chair.legs.color}
                            colorName={chair.legs.colorName}
                            inch={(i) => {
                                return _in(i)
                            }}
                            legType={chair.legs.legTypes[4]}
                        />
                    )}
                </Group>
                <Group x={0} y={_in(chair.tuft.height + extraPush)}>
                    {chair.type === "armBench" && (
                        <>
                            <RectForBlocks
                                x={0}
                                y={_in(
                                    -1 *
                                        thicknessOfBlocks(
                                            chair.leftArm.blocks
                                        ) +
                                        fivemm
                                )}
                                blocks={chair.seat.blocks}
                                inch={(i) => {
                                    return _in(i)
                                }}
                                width={_in(chair.seat.width) / 2}
                                corner
                                opposingBlocks={chair.leftArm.blocks}
                                chair={chair}
                                skipLayers={
                                    layersInBlocks(chair.seat.blocks) -
                                    layersInBlocks(chair.leftArm.blocks)
                                }
                                noCenterLine
                            />
                            <RectForBlocks
                                x={_in(chair.seat.width) / 2 - 1}
                                y={_in(
                                    -1 *
                                        thicknessOfBlocks(
                                            chair.leftArm.blocks
                                        ) +
                                        fivemm
                                )}
                                blocks={chair.seat.blocks}
                                inch={(i) => {
                                    return _in(i)
                                }}
                                width={_in(chair.seat.width) / 2 + 1}
                                corner
                                flip
                                opposingBlocks={chair.rightArm.blocks}
                                chair={chair}
                                skipLayers={
                                    layersInBlocks(chair.seat.blocks) -
                                    layersInBlocks(chair.rightArm.blocks)
                                }
                                noCenterLine
                            />
                        </>
                    )}
                    {chair.type !== "armBench" && (
                        <>
                            <RectForBlocks
                                x={0}
                                y={0}
                                blocks={chair.seat.blocks}
                                inch={(i) => {
                                    return _in(i)
                                }}
                                width={_in(chair.seat.width)}
                                chair={chair}
                            />
                        </>
                    )}
                    <RectForBlocks
                        x={0}
                        y={_in(thicknessOfBlocks(chair.seat.blocks))}
                        blocks={[{ layers: 1, color: chair.tuft.color }]}
                        inch={(i) => {
                            return _in(i)
                        }}
                        width={_in(chair.seat.width)}
                    />
                </Group>

                <Group>
                    {/*Tufts*/}

                    <Rect
                        x={_in(
                            chair.seat.width -
                                chair.legs.width / 2 -
                                chair.tuft.radius
                        )}
                        y={tuftStart}
                        width={_in(chair.tuft.radius * 2)}
                        height={_in(chair.tuft.height)}
                        fill={chair.tuft.color}
                        strokeWidth={1}
                        stroke="black"
                    />
                    <Rect
                        x={_in(chair.legs.width / 2 - chair.tuft.radius / 2)}
                        y={0}
                        width={_in(chair.tuft.radius * 2)}
                        height={_in(chair.tuft.height)}
                        fill={chair.tuft.color}
                        strokeWidth={1}
                        stroke="black"
                    />

                    {chair.type === "armBench" && (
                        <Rect
                            x={_in(
                                (chair.seat.width - chair.legs.width) / 2 +
                                    chair.tuft.radius
                            )}
                            y={_in(thicknessOfBlocks(chair.leftArm.blocks))}
                            width={_in(chair.tuft.radius * 2)}
                            height={_in(chair.tuft.height)}
                            fill={chair.tuft.color}
                            strokeWidth={1}
                            stroke="black"
                        />
                    )}
                </Group>

                {chair.type === "armBench" && (
                    <>
                        <RectForBlocks
                            x={0}
                            y={0 + _in(chair.tuft.height)}
                            blocks={chair.leftArm.blocks}
                            inch={(i) => {
                                return _in(i)
                            }}
                            width={_in(chair.leftArm.width)}
                            opposingBlocks={chair.seat.blocks}
                            odd
                        />
                        <RectForBlocks
                            x={
                                _in(chair.seat.width) -
                                _in(chair.rightArm.width)
                            }
                            y={0 + _in(chair.tuft.height)}
                            blocks={chair.rightArm.blocks}
                            inch={(i) => {
                                return _in(i)
                            }}
                            width={_in(chair.leftArm.width)}
                            opposingBlocks={chair.seat.blocks}
                            odd
                        />
                    </>
                )}
            </Layer>
        </Stage>
    )
}
