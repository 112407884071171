import React from "react"
import styles from "../css/home/about.module.css"

import UknownImage from "../images/unknownChair.jpg"
import Group1 from "../images/productImages/group/group1.jpg"
import Group2 from "../images/productImages/group/group2.jpg"
import Group3 from "../images/productImages/group/group3.jpg"
import Group4 from "../images/productImages/lights/LightFixtures-Stacklab1646_.jpg"

import aboutChair from "../images/aboutChair.svg"
import logoTopGap from "../images/logoTopGap.gif"
import FlashArrow from "./FlashArrow"

import Instagram from "../images/instagram.png"

import { icons, iconsDoubleWidth } from "./Icons"

function Type(props) {
    return (
        <div
            className={
                iconsDoubleWidth.indexOf(props.icon) !== -1
                    ? styles.typeDouble
                    : styles.type
            }
        >
            <img src={props.icon}></img>
        </div>
    )
}

export default function About(props) {
    return (
        <div className={styles.dim}>
            <div className={styles.aboutHead}>
                <div className={styles.imageCaption}>
                    <img src={Group1} />
                    <div className={styles.caption}>
                        Turn waste into wonders
                    </div>
                </div>
                <div className={styles.aboutChair}>
                    <img src={aboutChair} />
                </div>
            </div>
            <div className={styles.about}>
                <div className={styles.row}>
                    <div className={styles.content}>
                        ABOUT STACKABL
                        <br />
                        <br /> A new form of design that plays with
                        possibilities,  created by STACKLAB and Maison Gerard
                        <br />
                        <br />
                        <div className={styles.logoTopGap}>
                            <img src={logoTopGap} />
                        </div>
                        <br />
                        <br />
                        THE CONCEPT
                        <br />
                        Rethinking Resources
                        <br />
                        <br />
                        Creative reuse has shaped culture for centuries. From
                        the rich heritage of antique collecting to recent
                        innovations in recycling technology, reimagining what’s
                        possible with what’s already there presents an exciting
                        challenge to the design community. It is this shared
                        passion that lies at the heart of Stackabl.
                        <br />
                        <br />
                        <br />
                        <br />
                        WHAT IT IS
                        <br />
                        Immediate Impact
                        <br />
                        <br />
                        Stackabl is an innovative system for designing custom
                        furniture without the waste. Developed closely with
                        regional manufacturers, aided by algorithms and
                        robotics, the system identifies their remnant inventory,
                        puts it back through their own machines, and into the
                        hands of their own experts. By leveraging existing
                        regional resources, it empowers local economies while
                        curbing the carbon footprint.
                    </div>
                    <div className={styles.bareImage}>
                        <img className={styles.group2} src={Group2} />
                    </div>
                    <div className={styles.bareImage}>
                        <img src={Group3} />
                    </div>
                    <div className={styles.bareImage}>
                        <img src={Group4} />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.bigS}>S</div>

                    <div className={styles.content}>
                        WHY IT MATTERS
                        <br />
                        Turning waste into wonders
                        <br />
                        <br />
                        A simple configurator seamlessly integrates the designer
                        into the process. Its easy-to- use interface allows
                        users to turn high quality offcuts into custom furniture
                        according to their choices, from colors and patterns to
                        dimensions and densities. Real-time visualizations in 2D
                        and 3D as well as instant pricing feedback make for an
                        intuitive and transparent experience that frees the
                        imagination and opens doors to a world of possibilities.
                        <br />
                        <br />
                        <br />
                        THE COLLECTIONS
                        <br />
                        <br /> The inaugural Stackabl collection, launched in
                        2021, by Maison Gerard and STACKLAB is a playful
                        collection of furniture exploring how technology can
                        inform the future of design.
                        <br />
                        <br />
                        Created in Stackabl, a new, innovative system that turns
                        waste into wonders, each of the pieces is constructed by
                        regional specialists according to available resources.
                        In response to the colors, dimensions, and finishes
                        selected by the end user, layers of high-quality Merino
                        felt remnants are identified by algorithms and cut with
                        the aid of robotics. With no brief to draw from, other
                        than to work within the parameters set by the online
                        configurator, six hand-selected New York designers were
                        tasked with realizing their own visions. This resulted
                        in a striking array of characterful works – from simple
                        slipper chair to extravagant chaise lounge – that belong
                        to both past and future.
                        <br />
                        <br />
                        <br />
                        If these designer-configured furnishings are Stackabl’s
                        “haute couture” offerings, then the 2022 collection –
                        pendant lighting – is its “ready-to-wear” line. Made of
                        upcycled felt and aluminum, and powerful yet
                        energy-saving LEDs, the lights are easy to configure,
                        and users will have significantly more control over
                        price point, allowing for a broader audience to consume
                        responsibly.
                        <br />
                        <br />
                        <br />
                        Again, Stackabl took a holistic approach, designing both
                        the lights’ internal components and exterior elements
                        around the original system – reusing many of the same
                        components found in the furniture collection. Stackabl
                        leveraged its original network of regional suppliers and
                        manufacturers and, to illustrate just how easy the
                        configurator is to use as well as some of the possible
                        looks that can be achieved, tapped three designers to
                        configure three lights each. 
                        <br />
                        <br />
                        <br />
                        Stackabl’s body of work demonstrates the endless
                        possibilities at the intersection of design, technology,
                        and circular manufacturing, where the imagination takes
                        hold.
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.social}>
                    <a href="https://www.instagram.com/stack_abl/">
                        {" "}
                        <img src={Instagram} />
                    </a>
                </div>
                <div className={styles.breakArrow}>
                    <FlashArrow />
                </div>
                <div className={styles.signoff}>Turn waste into wonders.</div>
            </div>
        </div>
    )
}
