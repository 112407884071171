import React, { useState } from "react"

import UknownImage from "../images/unknownChair.jpg"

import RakiFront from "../images/productImages/raki/raki_front.jpg"
import RakiSide from "../images/productImages/raki/raki_side.jpg"
import RakiPerspective from "../images/productImages/raki/raki_perspective.jpg"
import RakiDetail1 from "../images/productImages/raki/raki_detail1.jpg"
import RakiDetail2 from "../images/productImages/raki/raki_detail2.jpg"
import RakiDetail3 from "../images/productImages/raki/raki_detail3.jpg"
import RakiDetail4 from "../images/productImages/raki/raki_detail4.jpg"
import RakiDetail5 from "../images/productImages/raki/raki_detail5.jpg"
import RakiDetail6 from "../images/productImages/raki/raki_detail6.jpg"
import RakiDetail7 from "../images/productImages/raki/raki_detail7.jpg"
import RakiDetail8 from "../images/productImages/raki/raki_detail8.jpg"
import RakiDetail9 from "../images/productImages/raki/raki_detail9.jpg"
import RakiDetail10 from "../images/productImages/raki/raki_detail10.jpg"
import RakiDetail11 from "../images/productImages/raki/raki_detail11.jpg"
import RakiDetail12 from "../images/productImages/raki/raki_detail12.jpg"
import RakiDetail13 from "../images/productImages/raki/raki_detail13.jpg"
import RakiDetail14 from "../images/productImages/raki/raki_detail14.jpg"
import RakiDetail15 from "../images/productImages/raki/raki_detail15.jpg"

import DulcesFront from "../images/productImages/dulces/dulces_front.jpg"
import DulcesSide from "../images/productImages/dulces/dulces_side.jpg"
import DulcesPerspective from "../images/productImages/dulces/dulces_perspective.jpg"
import DulcesDetail1 from "../images/productImages/dulces/dulces_detail1.jpg"
import DulcesDetail2 from "../images/productImages/dulces/dulces_detail2.jpg"
import DulcesDetail3 from "../images/productImages/dulces/dulces_detail3.jpg"
import DulcesDetail4 from "../images/productImages/dulces/dulces_detail4.jpg"
import DulcesDetail5 from "../images/productImages/dulces/dulces_detail5.jpg"
import DulcesDetail6 from "../images/productImages/dulces/dulces_detail6.jpg"

import MadameFront from "../images/productImages/madame/madame_front.jpg"
import MadameSide from "../images/productImages/madame/madame_side.jpg"
import MadamePerspective from "../images/productImages/madame/madame_perspective.jpg"
import MadameDetail1 from "../images/productImages/madame/madame_detail1.jpg"
import MadameDetail2 from "../images/productImages/madame/madame_detail2.jpg"
import MadameDetail3 from "../images/productImages/madame/madame_detail3.jpg"
import MadameDetail4 from "../images/productImages/madame/madame_detail4.jpg"
import MadameDetail5 from "../images/productImages/madame/madame_detail5.jpg"
import MadameDetail6 from "../images/productImages/madame/madame_detail6.jpg"
import MadameDetail7 from "../images/productImages/madame/madame_detail7.jpg"
import MadameDetail8 from "../images/productImages/madame/madame_detail8.jpg"
import MadameDetail9 from "../images/productImages/madame/madame_detail9.jpg"
import MadameDetail10 from "../images/productImages/madame/madame_detail10.jpg"
import MadameDetail11 from "../images/productImages/madame/madame_detail11.jpg"
import MadameDetail12 from "../images/productImages/madame/madame_detail12.jpg"
import MadameDetail13 from "../images/productImages/madame/madame_detail13.jpg"
import MadameDetail14 from "../images/productImages/madame/madame_detail14.jpg"
import MadameDetail15 from "../images/productImages/madame/madame_detail15.jpg"
import MadameDetail16 from "../images/productImages/madame/madame_detail16.jpg"
import MadameDetail17 from "../images/productImages/madame/madame_detail17.jpg"
import MadameDetail18 from "../images/productImages/madame/madame_detail18.jpg"
import MadameDetail19 from "../images/productImages/madame/madame_detail19.jpg"
import MadameDetail20 from "../images/productImages/madame/madame_detail20.jpg"

import FastLaneFront from "../images/productImages/fastlane/fastlane_front.jpg"
import FastLaneSide from "../images/productImages/fastlane/fastlane_side.jpg"
import FastLanePerspective from "../images/productImages/fastlane/fastlane_perspective.jpg"
import FastLaneDetail1 from "../images/productImages/fastlane/fastlane_detail1.jpg"
import FastLaneDetail2 from "../images/productImages/fastlane/fastlane_detail2.jpg"
import FastLaneDetail3 from "../images/productImages/fastlane/fastlane_detail3.jpg"
import FastLaneDetail4 from "../images/productImages/fastlane/fastlane_detail4.jpg"
import FastLaneDetail5 from "../images/productImages/fastlane/fastlane_detail5.jpg"
import FastLaneDetail6 from "../images/productImages/fastlane/fastlane_detail6.jpg"
import FastLaneDetail7 from "../images/productImages/fastlane/fastlane_detail7.jpg"
import FastLaneDetail8 from "../images/productImages/fastlane/fastlane_detail8.jpg"
import FastLaneDetail9 from "../images/productImages/fastlane/fastlane_detail9.jpg"
import FastLaneDetail10 from "../images/productImages/fastlane/fastlane_detail10.jpg"

import QuerelleFront from "../images/productImages/querelle/querelle_front.jpg"
import QuerelleSide from "../images/productImages/querelle/querelle_side.jpg"
import QuerellePerspective from "../images/productImages/querelle/querelle_perspective.jpg"
import QuerelleDetail1 from "../images/productImages/querelle/querelle_detail1.jpg"
import QuerelleDetail2 from "../images/productImages/querelle/querelle_detail2.jpg"
import QuerelleDetail3 from "../images/productImages/querelle/querelle_detail3.jpg"
import QuerelleDetail4 from "../images/productImages/querelle/querelle_detail4.jpg"
import QuerelleDetail5 from "../images/productImages/querelle/querelle_detail5.jpg"
import QuerelleDetail6 from "../images/productImages/querelle/querelle_detail6.jpg"
import QuerelleDetail7 from "../images/productImages/querelle/querelle_detail7.jpg"

import MaxineFront from "../images/productImages/maxine/maxine_front.jpg"
import MaxineSide from "../images/productImages/maxine/maxine_side.jpg"
import MaxinePerspective from "../images/productImages/maxine/maxine_perspective.jpg"
import MaxineDetail1 from "../images/productImages/maxine/maxine_detail1.jpg"
import MaxineDetail2 from "../images/productImages/maxine/maxine_detail2.jpg"
import MaxineDetail3 from "../images/productImages/maxine/maxine_detail3.jpg"
import MaxineDetail4 from "../images/productImages/maxine/maxine_detail4.jpg"
import MaxineDetail5 from "../images/productImages/maxine/maxine_detail5.jpg"
import MaxineDetail6 from "../images/productImages/maxine/maxine_detail6.jpg"
import MaxineDetail7 from "../images/productImages/maxine/maxine_detail7.jpg"
import MaxineDetail8 from "../images/productImages/maxine/maxine_detail8.jpg"
import MaxineDetail9 from "../images/productImages/maxine/maxine_detail9.jpg"
import MaxineDetail10 from "../images/productImages/maxine/maxine_detail10.jpg"
import MaxineDetail11 from "../images/productImages/maxine/maxine_detail11.jpg"
import MaxineDetail12 from "../images/productImages/maxine/maxine_detail12.jpg"
import MaxineDetail13 from "../images/productImages/maxine/maxine_detail13.jpg"
import MaxineDetail14 from "../images/productImages/maxine/maxine_detail14.jpg"

import Collection, { CollectionCarasol } from "./Collection"

//William Georgis Tons of Fun: c6655d3f-4dcc-44b7-a73a-07e351777266

const collectionItems = [
    {
        key: "raki",
        icon: "cornerChair",
        name: "Raki",
        unit: "Raki_AP",
        image: RakiFront,
        images: [
            RakiPerspective,
            RakiSide,
            RakiDetail3,
            RakiDetail5,
            RakiDetail7,
            RakiDetail1,
            RakiDetail2,

            RakiDetail8,
            RakiDetail9,
            RakiDetail11,
            RakiDetail12,
            RakiDetail14,
            RakiDetail15,
        ],
        tear: "/tear/raki.pdf",
        type: "Corner Chair",
        configured: "New York, USA | 9:38:59 AM on August 27, 2021",
        designed: "Alexandra Champalimaud | Champalimaud Design",
        blurb: `With a big character and a huge heart, this joyful corner chair is inspired by a dear
        family pet who brings an abundance of fun and whimsy wherever he goes. Raki is a
        humorous play on perspectives, offering new forms and conveying movement from
        every angle; the result of the designer’s fondness for “twirling it around” as a 3D render
        within the configurator. Embodying the spirit of the process is the contrasting back leg,
        whose curvy and sturdy form peeks out from underneath layers of graphite and rust,
        while the seat itself welcomes you with open arms. The resulting design is an intuitive,
        happy experiment in color, form, and materiality, effortlessly filling a generous interior
        with life and soul.`,
        dimensions: (
            <>
                Height <br />
                Overall 34”
                <br /> Arm 24”
                <br /> Seat 17” <br />
                <br />
                Width <br />
                Overall 27” <br />
                Seat 22”
                <br /> Depth Overall 24”
                <br /> Seat 19” <br />
                <br />
                Textile: Remnant Felt | Rost: 1 total, Graphit 67 total, Asche
                15 total 60 rec. PET | N/A Metal: Post Consumer Scrap Alum. |
                Black anodized
                <br />
                <br /> Wood: Maple | Mahogany
            </>
        ),
        chair: {
            org: "MG",
            unit: "Raki_AP",
            back: {
                depth: 5,
                width: 27,
                blocks: [
                    {
                        color: "#443a3e",
                        layers: 36,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 15,
                        colorName: "170 Asche",
                    },
                ],
            },
            legs: {
                color: "wood_mahogany",
                width: 4,
                height: 12.6693,
                legTypes: [1, 5, 1, 1],
                colorName: "wood",
                radiusCorner: true,
                fattyLegAllowed: [false, true, false, false],
            },
            seat: {
                depth: "24",
                width: "27",
                blocks: [
                    {
                        color: "#443a3e",
                        layers: 20,
                        colorName: "175 Graphit",
                    },
                    {
                        type: "designerBoard",
                        color: "#982f10",
                        layers: 1,
                        colorName: "105 Rost",
                    },
                ],
                supportsBoard: true,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
                colorName: "Black Oxide",
            },
            type: "cornerChair",
            title: "Corner Chair",
            leftArm: {
                width: 5,
                blocks: [
                    {
                        color: "#982f10",
                        layers: 19,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#443a3e",
                        layers: 15,
                        colorName: "175 Graphit",
                    },
                ],
            },
            pattern: "7",
            subType: "cornerChair",
            saveName: "CHAMPALIMAUD",
            blockColors: ["175 Graphit"],
            boardColors: [],
            radiusCorner: true,
            blockColorsRGB: {
                "175 Graphit": "#443a3e",
            },
            boardColorsRGB: {},
        },
    },
    {
        key: "dulces",
        icon: "diningChair",
        name: "Dulces",
        image: DulcesSide,
        images: [
            DulcesFront,
            DulcesPerspective,
            DulcesDetail3,
            DulcesDetail1,
            DulcesDetail2,

            DulcesDetail4,
            DulcesDetail5,
            DulcesDetail6,
        ],
        tear: "/tear/dulces.pdf",
        type: "Dining Chair",
        designed: "Laura Kirar | Laura Kirar Design",
        blurb: `Since moving to Mexico four years ago, Laura Kirar has radically changed her
        perspective on color. Her new appreciation for brighter shades comes through in
        Dulces, which means ‘candies’. Her chosen color configuration is a little something
        sweet to bring joy and flavor to the dining table. Embracing the geometric forms
        generated by the configurator, she layered in the material and color according to her
        
        typical process. Through ochre finishes and a hot pink accent, she evokes the colors of
        the local climate and culture, such as the work of the influential architect Luis Barragán,
        to arrive at a piece that is at once simple and striking enough to sweeten a variety of
        interiors, and bring its sunny disposition to any dinner party.`,
        unit: "Dulces_AP",
        configured: "Merida, Mexico | 4:29:40 PM on August 24, 2021",
        dimensions: (
            <>
                Height
                <br />
                Overall 33”
                <br />
                Arm N/A
                <br />
                Seat 18”
                <br />
                <br />
                Width
                <br />
                Overall 18”
                <br />
                Seat N/A
                <br />
                <br />
                Depth
                <br />
                Overall 23”
                <br />
                Seat 16”
                <br />
                <br />
                Textile:
                <br />
                Remnant Felt | Senf: 3 total, Graphit 86 total 60 rec. PET |
                Pink: 3 total
                <br />
                <br />
                Metal: Post Consumer Scrap Alum. | Champagne Anodized
                <br />
                <br />
                Wood: Maple | Natural
            </>
        ),
        chair: {
            org: "MG",
            unit: "Dulces_AP",

            back: {
                rake: 3,
                depth: 5,
                blocks: [
                    {
                        color: "#443a3d",
                        layers: 109,
                        colorName: "175 Graphit",
                    },
                ],
            },
            legs: {
                color: "wood_natural",
                width: 4,
                height: 14.1693,
                legTypes: [2, 2, 2, 2],
                colorName: "wood",
                widthFixed: true,
                fattyLegAllowed: [false, true, false, true],
            },
            seat: {
                depth: 24,
                width: 18,
                blocks: [
                    {
                        color: "#443a3d",
                        layers: 11,
                        colorName: "175 Graphit",
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 1,
                        colorName: "pink",
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 1,
                        colorName: "pink",
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 1,
                        colorName: "pink",
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 1,
                        colorName: "pink",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                ],
                supportsBoard: true,
            },
            tuft: {
                color: "#C7A365",
                height: 0.24,
                radius: 0.875,
                colorName: "Satin Bronze",
            },
            type: "sideChair",
            title: "Side Chair with Slope",
            pattern: 0,
            subType: "sideChairRake",
            saveName: "LAURA KIRAR",
            blockColors: ["175 Graphit"],
            boardColors: [],
            radiusCorner: true,
            blockColorsRGB: {
                "175 Graphit": "#443a3d",
            },
            boardColorsRGB: {},
        },
    },
    {
        key: "madame",
        icon: "chaise",
        name: "Madame",
        image: MadameFront,
        images: [
            MadameDetail14,
            MadameSide,
            MadameDetail15,
            MadameDetail16,
            MadameDetail1,
            MadameDetail2,
            MadameDetail3,
            MadameDetail4,
            MadameDetail6,
            MadameDetail7,
            MadameDetail8,
            MadameDetail9,
            MadameDetail10,
            MadameDetail17,
            MadameDetail18,
            MadameDetail19,
            MadameDetail20,
        ],
        tear: "/tear/madame.pdf",
        type: "Chaise Lounge",
        designed: "Jamie Drake and Caleb Anderson | Drake/Anderson",
        unit: "Madame_AP",
        configured: "New York, USA | 9:14:17 AM on August 20, 2021",
        blurb: `Named after Madame Récamier, the 19th-century French socialite noted for her
        extreme beauty and intellectual prowess as well as her salon, which drew literary and
        political luminaries of the time, this Modernist take on the chaise lounge invites
        conversation and stimulates thought. Its crimson hue and sloping shoulders recall the
        sensuality of neoclassical portraiture, while the slice of shocking pink lending a piquant
        
        contrast at the base is an ode to another famous Madame, Elsa Schiaparelli, the
        provocative fashion designer who popularized the bright shade in her 1940s couture
        creations. This bold, beautiful homage to two of history’s most alluring women would sit
        comfortably in any bedroom, or as a pair either side of a fireplace in opposing
        directions, to spark a tantalizing tête-à-tête.`,
        dimensions: (
            <>
                Height <br />
                Overall 34” <br />
                Arm 24”
                <br />
                Seat 17”
                <br />
                Width <br /> <br />
                Overall 66” <br /> Seat 61”
                <br />
                <br /> Depth
                <br /> Overall 27” <br /> Seat 22” <br /> <br /> Textile:
                Remnant Felt | Hellrot: 92 total 60 rec. PET | Pink: 5 total
                <br /> <br />
                Metal: Post Consumer Scrap Alum. | Champagne Anodized <br />
                <br />
                Wood: Maple | Black
            </>
        ),
        chair: {
            org: "MG",
            unit: "Madame_AP",

            back: {
                depth: 5,
                width: 38,
                blocks: [
                    {
                        color: "#95300d",
                        layers: 51,
                        colorName: "105 Rost",
                    },
                ],
            },
            legs: {
                color: "wood_black",
                width: 4,
                height: 12.6693,
                numLegs: 6,
                legTypes: [2, 2, 2, 2, 2, 2],
                centerLeg: true,
                colorName: "wood",
                widthFixed: true,
                radiusCorner: true,
            },
            seat: {
                depth: "27",
                width: 68,
                blocks: [
                    {
                        color: "#95300d",
                        layers: 14,
                        colorName: "105 Rost",
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 4,
                        colorName: "pink",
                    },
                ],
                supportsBoard: true,
            },
            tuft: {
                color: "#C7A365",
                height: 0.24,
                radius: 0.875,
                colorName: "Satin Bronze",
            },
            type: "cornerChair",
            title: "Chaise",
            leftArm: {
                width: 5,
                blocks: [
                    {
                        color: "#95300d",
                        layers: 34,
                        colorName: "105 Rost",
                    },
                ],
            },
            pattern: 0,
            subType: "chaise",
            saveName: "DRAKE/ANDERSON",
            blockColors: ["105 Rost"],
            boardColors: [],
            radiusCorner: true,
            blockColorsRGB: {
                "105 Rost": "#95300d",
            },
            boardColorsRGB: {},
        },
    },

    {
        key: "fastLane",
        icon: "loungeChair",
        name: "Fast Lane",
        image: FastLaneFront,
        images: [
            FastLanePerspective,
            FastLaneSide,
            FastLaneDetail3,
            FastLaneDetail4,
            FastLaneDetail1,
            FastLaneDetail2,

            FastLaneDetail5,
            FastLaneDetail7,
            FastLaneDetail9,
            FastLaneDetail10,
        ],
        tear: "/tear/fastlane.pdf",
        type: "Lounge Chair",
        designed: "Elena Frampton | Frampton Co",
        blurb: `Inspired by racing stripes and informed by a childhood immersed in L.A.’s car culture,
        Fast Lane expertly combines elegance and energy in equal measure. Designed in
        response to seasonal shifts, this sophisticated reimagining of the classic lounge chair
        showcases the celebrated designer’s eye for the unexpected. While its blue stripes and
        
        generous proportions recall beach house glamour, it’s the hot pink streak running
        through the middle of the seat that reveals its fun side. There’s more, too: look closer
        and you’ll notice one of the legs curves differently to the others. Consider pairing this
        captivating chair with Matthew Porter’s flying muscle car photography series for a true
        celebration of the nostalgia and romance of the car in American culture.`,
        unit: "Fast Lane_AP",
        configured: "New York, USA | 2:34:30 PM on August 25, 2021",
        dimensions: (
            <>
                Height
                <br />
                Overall 29”
                <br />
                Arm 23”
                <br />
                Seat 17”
                <br />
                <br />
                Width
                <br />
                Overall 29”
                <br />
                Seat 19”
                <br />
                <br />
                Depth
                <br />
                Overall 25”
                <br />
                Seat 20”
                <br />
                <br />
                Textile: Remnant Felt | Gletscher: 60 total , Petrol: 70 total
                60 rec. PET | Pink: 5 total
                <br />
                <br />
                Metal: Post Consumer Scrap Alum. | Black Anodized <br />
                <br />
                Wood: Maple | Black
            </>
        ),
        chair: {
            org: "MG",
            unit: "Fast Lane_AP",

            back: {
                depth: 5,
                blocks: [
                    {
                        color: "#7e90ae",
                        layers: 15,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#06476c",
                        layers: 15,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#7e90ae",
                        layers: 15,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#06476c",
                        layers: 14,
                        colorName: "308 Petrol",
                    },
                ],
            },
            legs: {
                color: "wood_black",
                width: 4,
                height: 10.7008,
                legTypes: [4, 3, 3, 3],
                colorName: "wood",
                widthFixed: true,
            },
            seat: {
                depth: 25,
                width: 29,
                blocks: [
                    {
                        color: "#7e90ae",
                        layers: 13,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#06476c",
                        layers: 11,
                        colorName: "308 Petrol",
                    },
                    {
                        type: "designerBoard",
                        color: "#fe71b0",
                        layers: 5,
                        colorName: "pink",
                    },
                    {
                        color: "#06476c",
                        layers: 11,
                        colorName: "308 Petrol",
                    },
                ],
                supportsBoard: true,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
                colorName: "Black Oxide",
            },
            type: "armChair",
            title: "Lounge Chair",
            leftArm: {
                width: 5,
                blocks: [
                    {
                        color: "#06476c",
                        layers: 7,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#7e90ae",
                        layers: 6,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#06476c",
                        layers: 6,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#7e90ae",
                        layers: 6,
                        colorName: "613 Gletscher",
                    },
                ],
            },
            pattern: "5",
            subType: "loungeChair",
            rightArm: {
                width: 5,
                blocks: [
                    {
                        color: "#06476c",
                        layers: 7,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#7e90ae",
                        layers: 6,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#06476c",
                        layers: 6,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#7e90ae",
                        layers: 6,
                        colorName: "613 Gletscher",
                    },
                ],
            },
            saveName: "BRISCOE+EVELIN",
            blockColors: ["308 Petrol", "613 Gletscher"],
            boardColors: ["pink"],
            radiusCorner: true,
            blockColorsRGB: {
                "308 Petrol": "#06476c",
                "613 Gletscher": "#7e90ae",
            },
            boardColorsRGB: {
                pink: "#fe71b0",
            },
        },
    },
    {
        key: "querelle",
        icon: "tonsOfFun",
        name: "Querelle",
        image: QuerelleSide,
        images: [
            QuerellePerspective,
            QuerelleFront,
            QuerelleDetail3,
            QuerelleDetail4,
            QuerelleDetail1,
            QuerelleDetail2,

            QuerelleDetail5,
            QuerelleDetail6,
            QuerelleDetail7,
        ],
        tear: "/tear/tonsoffun.pdf",
        type: "Club Chair | Tons of Fun",
        designed:
            "William Georgis and Ilya Mirgorodsky | Georgis & Mirgorodsky",
        blurb: `After an initial attempt to defy the configurator and get around its set limitations, Georgis
        allowed himself to completely submit to its demands and play along. He discovered that
        embracing its constraints sparked his imagination in new and unexpected ways,
        allowing him to overlay unintended meaning onto the object and imbue it with a piece of
        himself. The nautical stripe effect immediately led his mind to Querelle, the handsome
        
        Belgian sailor and queer cinema icon at the center of Rainer Werner Fassbinder’s 1982
        arthouse film. Based on the book by Jean Genet, the story is set in a French port town
        where sex, drugs and violence collide with our unlikely hero’s quest for identity. This
        commanding club chair brings undeniable sex appeal and presence to its environment.`,
        unit: "Querelle_AP",
        configured: "New York, USA | 10:55:39 AM on August 18, 2021",
        dimensions: (
            <>
                Height
                <br />
                Overall 31”
                <br />
                Arm N/A
                <br />
                Seat 17”
                <br />
                <br />
                Width
                <br />
                Overall 25”
                <br />
                Seat N/A
                <br />
                <br />
                Depth
                <br />
                Overall 30”
                <br />
                Seat 21”
                <br />
                <br />
                Textile:
                <br />
                Remnant Felt | Wollweiß: 55 total, Petrol 54 total 60 rec. PET |
                N/A
                <br />
                <br />
                Metal: Post Consumer Scrap Alum. | Black Anodized <br />
                <br />
                Wood: Maple | Natural
            </>
        ),
        chair: {
            org: "MG",
            unit: "Querelle_AP",

            back: {
                depth: 9,
                width: 20.5,
                blocks: [
                    {
                        color: "#06476c",
                        layers: 9,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d2cfca",
                        layers: 9,
                        colorName: "100 Wollweiß",
                    },
                    {
                        color: "#06476c",
                        layers: 9,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d2cfca",
                        layers: 9,
                        colorName: "100 Wollweiß",
                    },
                    {
                        color: "#06476c",
                        layers: 9,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d2cfca",
                        layers: 9,
                        colorName: "100 Wollweiß",
                    },
                    {
                        color: "#06476c",
                        layers: 9,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d2cfca",
                        layers: 8,
                        colorName: "100 Wollweiß",
                    },
                ],
            },
            legs: {
                color: "wood_natural",
                width: 8,
                height: 8.7323,
                legTypes: [2, 2, 2, 2],
                colorName: "wood",
                widthFixed: true,
            },
            seat: {
                depth: "30",
                width: "25",
                blocks: [
                    {
                        color: "#d2cfca",
                        layers: 6,
                        colorName: "100 Wollweiß",
                    },
                    {
                        color: "#06476c",
                        layers: 6,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d2cfca",
                        layers: 5,
                        colorName: "100 Wollweiß",
                    },
                    {
                        color: "#06476c",
                        layers: 5,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d2cfca",
                        layers: 5,
                        colorName: "100 Wollweiß",
                    },
                    {
                        color: "#06476c",
                        layers: 5,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d2cfca",
                        layers: 5,
                        colorName: "100 Wollweiß",
                    },
                    {
                        color: "#06476c",
                        layers: 5,
                        colorName: "308 Petrol",
                    },
                ],
                supportsBoard: true,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
                colorName: "Black Oxide",
            },
            type: "sideChair",
            title: "Tons of Fun",
            pattern: "6",
            subType: "tonsOfFun",
            saveName: "WILLIAM GEORGIS",
            blockColors: ["308 Petrol", "100 Wollweiß"],
            boardColors: [],
            radiusCorner: true,
            blockColorsRGB: {
                "308 Petrol": "#06476c",
                "100 Wollweiß": "#d2cfca",
            },
            boardColorsRGB: {},
        },
    },

    {
        key: "maxine",
        icon: "bench",
        name: "Maxine",
        image: MaxineFront,
        images: [
            MaxinePerspective,
            MaxineSide,
            MaxineDetail3,
            MaxineDetail4,
            MaxineDetail1,
            MaxineDetail2,

            MaxineDetail5,
            MaxineDetail7,
            MaxineDetail8,
            MaxineDetail9,
            MaxineDetail10,
            MaxineDetail11,
            MaxineDetail12,
            MaxineDetail13,
        ],
        tear: "/tear/maxine.pdf",
        type: "Bench",
        designed: "Benoist F. Drut | Maison Gerard",
        blurb: `Drut’s eye for eclecticism and exuberance is palpable in his design for the Maxine
        bench. The artistry and symmetry of eighteenth-century French royal furnishings, the
        energy and expertise of Parisian gallerist/decorator Madeleine Castaing, the Orientalist
        architecture of Frederic Church’s historical Hudson Valley home, and the impeccable
        style of 1950s English model, actress and designer Maxime de la Falaise (née Maxine
        
        Bailly) combine to provide the historical references that make this bench the perfect
        conversation piece. Effortlessly blending neoclassical lines with whimsical flourishes,
        Maxine sees Drut bringing his own, distinct flair to a beloved heritage.`,
        unit: "Maxine_AP",
        configured: "New York, USA | 5:26:50 PM on September 2, 2021",
        dimensions: (
            <>
                Height
                <br />
                Overall 21-1/2”
                <br />
                Arm 21-1/2”
                <br />
                Seat 17”
                <br />
                <br />
                Width
                <br />
                Overall 60”
                <br />
                Seat 45”
                <br />
                <br />
                Depth
                <br />
                Overall 18”
                <br />
                Seat 18”
                <br />
                <br />
                Textile:
                <br />
                Remnant Felt | Rost: 14 total, Senf 11 total, Gletscher 13
                total, Kiwi 11 total 60 rec. PET | Orange 2 total
                <br />
                <br />
                Metal: Post Consumer Scrap Alum. | Black Anodized
                <br />
                <br /> Wood: Maple | Natural
            </>
        ),

        chair: {
            org: "MG",
            unit: "Maxine_AP",

            legs: {
                color: "wood_natural",
                width: 4,
                height: 10.11025,
                numLegs: 6,
                legTypes: [1, 2, 1, 2, 3, 3],
                colorName: "wood",
                widthFixed: true,
            },
            seat: {
                depth: "18",
                width: "60",
                blocks: [
                    {
                        color: "#952f0d",
                        layers: 1,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#7e90ae",
                        layers: 1,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#952f0d",
                        layers: 1,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#7e90ae",
                        layers: 1,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 1,
                        colorName: "713 Kiwi",
                    },
                    {
                        color: "#7e90ae",
                        layers: 2,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#952f0d",
                        layers: 2,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#7e90ae",
                        layers: 1,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#952f0d",
                        layers: 1,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 2,
                        colorName: "713 Kiwi",
                    },
                    {
                        color: "#952f0d",
                        layers: 1,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#7e90ae",
                        layers: 2,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#7e90ae",
                        layers: 1,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 1,
                        colorName: "713 Kiwi",
                    },
                    {
                        color: "#af8a35",
                        layers: 2,
                        colorName: "274 Senf",
                    },
                    {
                        type: "designerBoard",
                        color: "#fd4f25",
                        layers: 1,
                        colorName: "orange",
                    },
                    {
                        type: "designerBoard",
                        color: "#fd4f25",
                        layers: 1,
                        colorName: "orange",
                    },
                    {
                        color: "#952f0d",
                        layers: 1,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 1,
                        colorName: "713 Kiwi",
                    },
                    {
                        color: "#7e90ae",
                        layers: 1,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 1,
                        colorName: "713 Kiwi",
                    },
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                ],
                minBoardDepth: 22,
                supportsBoard: true,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
                colorName: "Black Oxide",
            },
            type: "armBench",
            title: "Bench",
            leftArm: {
                width: 5,
                blocks: [
                    {
                        color: "#af8a35",
                        layers: 1,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#952f0d",
                        layers: 7,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 4,
                        colorName: "713 Kiwi",
                    },
                    {
                        color: "#7e90ae",
                        layers: 4,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#af8a35",
                        layers: 3,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#952f0d",
                        layers: 3,
                        colorName: "105 Rost",
                    },
                ],
            },
            pattern: "7",
            subType: "bench",
            rightArm: {
                width: 5,
                blocks: [
                    {
                        color: "#7e90ae",
                        layers: 4,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 4,
                        colorName: "713 Kiwi",
                    },
                    {
                        color: "#952f0d",
                        layers: 4,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#af8a35",
                        layers: 4,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#7e90ae",
                        layers: 3,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#8d9d3a",
                        layers: 3,
                        colorName: "713 Kiwi",
                    },
                ],
            },
            saveName: "BFD2",
            blockColors: ["274 Senf", "105 Rost", "713 Kiwi", "613 Gletscher"],
            boardColors: ["orange"],
            radiusCorner: true,
            blockColorsRGB: {
                "105 Rost": "#952f0d",
                "274 Senf": "#af8a35",
                "713 Kiwi": "#8d9d3a",
                "613 Gletscher": "#7e90ae",
            },
            boardColorsRGB: {
                orange: "#fd4f25",
            },
        },
    },
]

function expandOption(option) {
    if (!option) {
        return null
    }
    const options = [option]
    for (const o of option.images) {
        options.push({ ...option, image: o })
    }
    return options
}

export default function Showcase(props) {
    return <CollectionCarasol items={collectionItems} />
}

export function Gallery(props) {
    return (
        <Collection
            baseUrl={"/2021collection"}
            items={collectionItems}
            onClickCarasol={(item) => {
                return expandOption(item)
            }}
        />
    )
}
