import React, { useState } from "react"
import ErrorModel from "../components/ErrorModel"
import armChairExample from "../images/armchair_example.jpg"
import sideChairExample from "../images/sidechair_example.jpg"
import cornerChairExample from "../images/cornerchair_example.jpg"
import { ReactComponent as Logo } from "../images/Logo.svg"
import { useHistory } from "react-router-dom"

export default function Description(props) {
    const history = useHistory()
    const [model, setModel] = useState(false)
    let title = "Unknown"
    let image = null
    let descr = `Our configurator provides a platform for 
    users to convert regional supplies of off-cut felt, 
    recycled aluminum and wood into custom furniture.   
    `

    if (props.chair && props.chair.title) {
        title = props.chair.title
    } else {
        if (props.type === "armChair") {
            title = "Arm Chair"
            image = armChairExample
        }
        if (props.type === "cornerChair") {
            title = "Corner Chair"
            image = cornerChairExample
        }
        if (props.type === "sideChair") {
            title = "Chair"
            image = sideChairExample
        }
        if (props.type === "dayBed") {
            title = "Day Bed"
            image = null
        }
        if (props.type === "bench") {
            title = "Bench"
            image = null
        }
        if (props.type === "stool") {
            title = "Stool"
            image = null
        }
        if (props.type === "armBench") {
            title = "Armed Bench"
            image = null
        }
    }
    if (props.chair.descr) {
        descr = props.chair.descr
    }
    return (
        <div>
            {model && image && (
                <ErrorModel
                    message={
                        <img
                            style={{ width: "100%" }}
                            alt="example"
                            src={image}
                        />
                    }
                    onClose={() => {
                        setModel(false)
                    }}
                    noImage
                />
            )}
            <Logo
                onClick={() => {
                    history.push("/enter")
                }}
                style={{ cursor: "pointer", width: "7.5vw", paddingTop: "5vw" }}
            />
            <h1>{title}</h1>
            <p>{descr}</p>
            {image && (
                <div
                    className="link"
                    onClick={() => {
                        setModel(true)
                    }}
                >
                    Show Example
                </div>
            )}
        </div>
    )
}
