import React, { useState } from "react"

import UknownImage from "../images/unknownChair.jpg"

import Paco from "../images/productImages/lights/LightFixtures-Stacklab1637_.jpg"
import Paco1 from "../images/productImages/lights/LightFixtures-Stacklab1759_.jpg"
import Paco2 from "../images/productImages/lights/LightFixtures-Stacklab1760_.jpg"
import Paco3 from "../images/productImages/lights/LightFixtures-Stacklab1629_2.jpg"
import Paco4 from "../images/productImages/lights/LightFixtures-Stacklab1633_.jpg"
import Paco5 from "../images/productImages/lights/LightFixtures-Stacklab1634_.jpg"

import Luis from "../images/productImages/lights/LightFixtures-Stacklab1639_.jpg"
import Luis1 from "../images/productImages/lights/LightFixtures-Stacklab1767_.jpg"
import Luis2 from "../images/productImages/lights/LightFixtures-Stacklab1768_.jpg"
import Luis3 from "../images/productImages/lights/LightFixtures-Stacklab1634_.jpg"
import Luis4 from "../images/productImages/lights/LightFixtures-Stacklab1640_.jpg"
import Luis5 from "../images/productImages/lights/LightFixtures-Stacklab1633_.jpg"

import Hugo from "../images/productImages/lights/LightFixtures-Stacklab1642_.jpg"
import Hugo1 from "../images/productImages/lights/LightFixtures-Stacklab1762_.jpg"
import Hugo2 from "../images/productImages/lights/LightFixtures-Stacklab1763_.jpg"
import Hugo3 from "../images/productImages/lights/LightFixtures-Stacklab1629_2.jpg"
import Hugo4 from "../images/productImages/lights/LightFixtures-Stacklab1640_.jpg"
import Hugo5 from "../images/productImages/lights/LightFixtures-Stacklab1633_.jpg"

import HombreS from "../images/productImages/lights/LightFixtures-Stacklab1656_.jpg"
import HombreS1 from "../images/productImages/lights/LightFixtures-Stacklab1653_.jpg"

import HombreL from "../images/productImages/lights/LightFixtures-Stacklab1644_.jpg"
import HombreL1 from "../images/productImages/lights/LightFixtures-Stacklab1653_.jpg"
import HombreL2 from "../images/productImages/lights/LightFixtures-Stacklab1769_.jpg"
import HombreL3 from "../images/productImages/lights/LightFixtures-Stacklab1770_.jpg"

import Cigarette from "../images/productImages/lights/LightFixtures-Stacklab1739_.jpg"
import Cigarette1 from "../images/productImages/lights/LightFixtures-Stacklab1718_.jpg"
import Cigarette2 from "../images/productImages/lights/LightFixtures-Stacklab1717_.jpg"
import Cigarette3 from "../images/productImages/lights/LightFixtures-Stacklab1716_2.jpg"
import Cigarette4 from "../images/productImages/lights/LightFixtures-Stacklab1711_.jpg"
import Cigarette5 from "../images/productImages/lights/LightFixtures-Stacklab1713_.jpg"

import Test from "../images/productImages/lights/LightFixtures-Stacklab1753_.jpg"
import Test1 from "../images/productImages/lights/LightFixtures-Stacklab1755_.jpg"
import Test2 from "../images/productImages/lights/LightFixtures-Stacklab1717_.jpg"
import Test3 from "../images/productImages/lights/LightFixtures-Stacklab1716_2.jpg"
import Test4 from "../images/productImages/lights/LightFixtures-Stacklab1711_.jpg"
import Test5 from "../images/productImages/lights/LightFixtures-Stacklab1713_.jpg"

import Shell from "../images/productImages/lights/LightFixtures-Stacklab1750_.jpg"
import Shell1 from "../images/productImages/lights/LightFixtures-Stacklab1751_.jpg"
import Shell2 from "../images/productImages/lights/LightFixtures-Stacklab1717_.jpg"
import Shell3 from "../images/productImages/lights/LightFixtures-Stacklab1716_2.jpg"
import Shell4 from "../images/productImages/lights/LightFixtures-Stacklab1711_.jpg"
import Shell5 from "../images/productImages/lights/LightFixtures-Stacklab1713_.jpg"

import Collection, { CollectionCarasol } from "./Collection"

const collectionItems = [
    {
        key: "hombres",
        icon: 8,
        name: "Hombré – short",
        unit: "HomresShort_AP",
        image: HombreS,
        images: [HombreS1],
        type: "8",
        configured: "Toronto, CAN | 4:42:23 PM on April 11, 2022  ",
        designed: "Anthony Frank Keeler | ANTHONY FRANK KEELER DESIGN STUDIO ",
        blurb: `No stranger to the lighting world, Keeler worked for internationally 
        acclaimed interior design studios and innovative LED luminaire producers 
        before opening his own studio. Keeler, who helped Stackabl develop its first 
        pendant collection, chose a neutral colour selection and ombre effect to show 
        off the light’s verticality and versatility. “Arranging them in a cluster can 
        really define height and showcase the architecture of a space.” `,
        tear: "/tear/hombre_short.pdf",
        autoPrice: true,
        dimensions: (
            <>
                Length 18” Diameter: 8” <br />
                <br />
                Layers
                <br />
                1150 WeiB: 61
                <br /> 170 Asche: 98 <br />
                75 Graphit: 75 <br />
                <br />
                <br />
                Light
                <br />
                8" dia light: 4
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: White <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Clear <br />
                Cable length: Varies
            </>
        ),
        chair: {
            org: "SL",
            unit: "HombreShort_AP",

            tube: {
                blocks: [
                    {
                        color: "#d8d8d4",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 5,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#a29ea4",
                        layers: 3,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 3,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 3,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 3,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 3,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#453a3e",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                ],
                length: 24,
                diameter: 8,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            descr: "Our configurator provides a platform for users to convert regionalsupplies of off-cut felt, recycled aluminum and lights into custom fixtures.",
            light: {
                number: "4",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "8",
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: ["150 Weiß", "170 Asche", "175 Graphit"],
            boardColors: [],
            blockColorsRGB: {
                "150 Weiß": "#d8d8d4",
                "170 Asche": "#9f9b9f",
                "175 Graphit": "#453a3e",
            },
        },
    },
    {
        key: "hombrel",
        icon: "8",
        name: "Hombré - long",
        unit: "HomresLong_AP",
        image: HombreL,
        images: [HombreL1, HombreL2, HombreL3],
        type: "8",
        configured: "Toronto, CAN | 1:20:21 PM on April 11, 2022  ",
        designed: "Anthony Frank Keeler | ANTHONY FRANK KEELER DESIGN STUDIO ",
        blurb: `No stranger to the lighting world, Keeler worked for internationally 
        acclaimed interior design studios and innovative LED luminaire producers 
        before opening his own studio. Keeler, who helped Stackabl develop its first 
        pendant collection, chose a neutral colour selection and ombre effect to show 
        off the light’s verticality and versatility. “Arranging them in a cluster can 
        really define height and showcase the architecture of a space.” `,
        tear: "/tear/hombre_long.pdf",
        autoPrice: true,

        dimensions: (
            <>
                Length: 48” Diameter: 8” <br />
                <br />
                Layers
                <br />
                1150 WeiB: 61
                <br /> 170 Asche: 98 <br />
                75 Graphit: 75 <br />
                <br />
                <br />
                Light
                <br />
                8" dia light: 6
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: White <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Clear <br />
                Cable length: Varies
            </>
        ),
        autoPrice: true,

        chair: {
            org: "SL",
            unit: "HombreLong_AP",

            tube: {
                blocks: [
                    {
                        color: "#d8d8d4",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 5,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#a29ea4",
                        layers: 3,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#9f9b9f",
                        layers: 3,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 3,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 2,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#453a3e",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 3,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 3,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#a29ea4",
                        layers: 1,
                        colorName: "170 Asche",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#453a3e",
                        layers: 2,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#443a3d",
                        layers: 1,
                        colorName: "175 Graphit",
                    },
                ],
                length: 24,
                diameter: 8,
            },
            tuft: {
                color: "#474642",
                height: 0.24,
                radius: 0.875,
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            descr: "Our configurator provides a platform for users to convert regionalsupplies of off-cut felt, recycled aluminum and lights into custom fixtures.",
            light: {
                number: "4",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "8",
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: ["150 Weiß", "170 Asche", "175 Graphit"],
            boardColors: [],
            blockColorsRGB: {
                "150 Weiß": "#d8d8d4",
                "170 Asche": "#9f9b9f",
                "175 Graphit": "#453a3e",
            },
        },
    },
    {
        key: "2021ShotgunShell",
        icon: "5",
        name: "2021 – shotgun shell",
        unit: "2021ShotgunShell_AP",
        image: Shell,
        images: [Shell1, Shell2, Shell3, Shell4, Shell5],
        type: "5",
        configured: "New York, USA | 6:39:03 PM On April 12, 2022 ",
        designed: "Sarah Coleman",
        blurb: `Sarah Coleman is a New York City based visual artist who is inspired 
        by both high luxury and everyday items. Nostalgia and play drives much of her 
        subversive approach to making art. Leveraging designer materials, 
        playing off logos, and honoring legacy brand archives, 
        Coleman gives repurposed objects a chance at another life. To describe her 
        color selection, Coleman says, “They speak for themselves—they’re humorous.”  `,
        tear: "/tear/shotgun_shell.pdf",
        autoPrice: true,

        dimensions: (
            <>
                Length: 32” Diameter: 5” <br />
                <br />
                Layers <br />
                274 Senf: 33 <br />
                105 Rost: 116 <br />
                <br />
                <br />
                Light
                <br />
                5" dia light: 3
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: Gold <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Clear <br />
                Cable length: Varies
            </>
        ),
        chair: {
            org: "SL",
            unit: "2021ShotgunShell_AP",

            tube: {
                blocks: [
                    {
                        color: "#ae8933",
                        layers: 2,
                        colorName: "274 Senf",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#af8a35",
                        layers: 3,
                        colorName: "274 Senf",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#af8a35",
                        layers: 23,
                        colorName: "274 Senf",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#af8a35",
                        layers: 5,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#952f0d",
                        layers: 116,
                        colorName: "105 Rost",
                    },
                ],
                length: 30,
                diameter: 5,
            },
            tuft: {
                color: "#C7A365",
                height: 0.24,
                radius: 0.875,
                colorName: "Champagne",
            },
            type: "pendent",
            cable: {
                color: "#978E68",
                colorName: "Gold",
            },
            light: {
                number: 2,
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: 1,
            subType: "horizontalPendent",
            lightsOn: 0,
            saveName: "SARAH COLEMAN | JEFF FORREST",
            blockColors: ["274 Senf"],
            boardColors: [],
            blockColorsRGB: {
                "274 Senf": "#ae8933",
            },
        },
    },
    {
        key: "2020cigarette ",
        icon: "8",
        name: "2020 – cigarette",
        unit: "2020cigarette_AP",
        image: Cigarette,
        images: [Cigarette1, Cigarette2, Cigarette3, Cigarette4, Cigarette5],
        type: "8",
        configured: "New York, USA | 12:47:43 PM On April 13, 2022 ",
        designed: "Sarah Coleman",
        blurb: `Sarah Coleman is a New York City based visual artist who is inspired 
        by both high luxury and everyday items. Nostalgia and play drives much of her 
        subversive approach to making art. Leveraging designer materials, 
        playing off logos, and honoring legacy brand archives, 
        Coleman gives repurposed objects a chance at another life. To describe her 
        color selection, Coleman says, “They speak for themselves—they’re humorous.”  `,
        tear: "/tear/cigarette.pdf",
        autoPrice: true,

        dimensions: (
            <>
                Length: 48” Diameter: 8” <br />
                <br />
                Layers <br />
                175 Graphit: 35 <br />
                105 Rost: 4 <br />
                100 WollweiB: 120 <br />
                613 Gletscher: 1 <br />
                274 Senf: 82
                <br />
                <br />
                Light
                <br />
                8" dia light: 6
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: White <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Black and Champagne <br />
                Cable length: Varies
            </>
        ),
        chair: {
            org: "SL",
            unit: "2020cigarette_AP",
            tube: {
                blocks: [
                    {
                        color: "#453a3e",
                        layers: 34,
                        colorName: "175 Graphit",
                    },
                    {
                        color: "#952f0d",
                        layers: 8,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#952f0d",
                        layers: 8,
                        colorName: "105 Rost",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 4,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 21,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 36,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 29,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#7e90ae",
                        layers: 1,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#7e90ae",
                        layers: 1,
                        colorName: "613 Gletscher",
                    },
                    {
                        color: "#af8a35",
                        layers: 65,
                        colorName: "274 Senf",
                    },
                ],
                length: 42,
                diameter: 5,
            },
            tuft: {
                color: "#C7A365",
                height: 0.24,
                radius: 0.875,
                colorName: "Champagne",
            },
            type: "pendent",
            cable: {
                color: "#7A8284",
                colorName: "Clear",
            },
            light: {
                number: "5",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: 1,
            subType: "horizontalPendent",
            lightsOn: 0,
            blockColors: ["150 Weiß", "274 Senf", "175 Graphit", "105 Rost"],
            boardColors: [],
            blockColorsRGB: {
                "105 Rost": "#982f0d",
                "274 Senf": "#ae8933",
                "150 Weiß": "#d8d8d4",
                "175 Graphit": "#453a3e",
            },
        },
    },
    {
        key: "2022pregnancytest",
        icon: "5",
        name: "2022 – pregnancy test ",
        unit: "2022PregnancyTest_AP",
        image: Test,
        images: [Test1, Test2, Test3, Test4, Test5],
        type: "5",
        configured: "New York, USA | 6:54:03 PM On April 12, 2022 ",
        designed: "Sarah Coleman",
        blurb: `Sarah Coleman is a New York City based visual artist who is inspired 
        by both high luxury and everyday items. Nostalgia and play drives much of her 
        subversive approach to making art. Leveraging designer materials, 
        playing off logos, and honoring legacy brand archives, 
        Coleman gives repurposed objects a chance at another life. To describe her 
        color selection, Coleman says, “They speak for themselves—they’re humorous.”  `,
        tear: "/tear/pregency_test.pdf",
        autoPrice: true,

        dimensions: (
            <>
                Length: 48” Diameter: 5” <br />
                <br />
                Layers <br />
                308 Petrol: 66 <br />
                105 Rost: 3 <br />
                100 WollweiiB: 171 <br />
                <br />
                <br />
                Light
                <br />
                5" dia light: 6
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: Blue <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Clear <br />
                Cable length: Varies
            </>
        ),
        chair: {
            org: "SL",
            unit: "2022PregnancyTest_AP",
            tube: {
                blocks: [
                    {
                        color: "#06476c",
                        layers: 66,
                        colorName: "308 Petrol",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "100 WollweiiB",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#982f0d",
                        layers: 1,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "100 WollweiiB",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "100 WollweiiB",
                    },
                    {
                        color: "#952f0d",
                        layers: 2,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "100 WollweiiB",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 1,
                        colorName: "100 WollweiiB",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 2,
                        colorName: "100 WollweiiB",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 158,
                        colorName: "100 WollweiiB",
                    },
                ],
                length: 48,
                diameter: 5,
            },
            tuft: {
                color: "#E0DAC2",
                height: 0.24,
                radius: 0.875,
                colorName: "Clear",
            },
            type: "pendent",
            cable: {
                color: "#2E3C89",
                braided: true,
                colorName: "Blue",
            },
            light: {
                number: 2,
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: 1,
            subType: "horizontalPendent",
            lightsOn: 0,
            saveName: "SARAH COLEMAN | JEFF FORREST",
            blockColors: ["274 Senf"],
            boardColors: [],
            blockColorsRGB: {
                "274 Senf": "#ae8933",
            },
        },
    },

    {
        key: "paco",
        icon: "585",
        name: "Paco",
        unit: "Paco_AP",
        image: Paco,
        images: [Paco1, Paco2, Paco3, Paco4, Paco5],
        type: "585",
        configured: "Amsterdam, NL | 3:19:35 PM on April 10, 2022 ",
        designed: "Wisse Trooster",
        blurb: `Wisse Trooster is a passionately curious designer from The Netherlands
         and works with a variety of materials and in multiple disciplines. 
         “The lights match as a family,” Trooster says, “in fact, the names are from Donald Duck’s 
         triplet nephews. Fun, they are interesting on their own but come to life when placed together. 
         The close-to-black and white pattern seamlessly integrates the light discs and by keeping 
        the lamp in one color, the lights became well-balanced.” `,
        tear: "/tear/paco.pdf",
        autoPrice: true,

        dimensions: (
            <>
                Length: 13” Diameter: 8” <br />
                <br />
                Layers
                <br />
                308 Petrol: 27 <br /> 540 Ozean: 16 <br />
                150 WeiB: 13 <br />
                <br />
                <br />
                Light
                <br />
                5" dia light: 2 <br />
                8" dia light: 1
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: White <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Clear <br />
                Cable length: Varies
            </>
        ),
        chair: {
            org: "SL",
            unit: "Paco_AP",
            form: "585",

            tube: {
                blocks: [
                    {
                        color: "#05476b",
                        layers: 6,
                        colorName: "308 Petrol",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#06476c",
                        layers: 7,
                        colorName: "308 Petrol",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 4,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0b2339",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0c233a",
                        layers: 4,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#06476c",
                        layers: 7,
                        colorName: "308 Petrol",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#05476b",
                        layers: 7,
                        colorName: "308 Petrol",
                    },
                ],
                length: 12,
                diameter: 8,
            },
            tuft: {
                color: "#E0DAC2",
                height: 0.24,
                radius: 0.875,
                colorName: "Clear",
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            light: {
                number: "4",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            contact: {},
            pattern: "1",
            subType: "verticalPendent",
            lightsOn: 0,
            saveName: "WISSE TROOSTER",
            blockColors: ["150 Weiß", "540 Ozean"],
            boardColors: [],
            blockColorsRGB: {
                "150 Weiß": "#d8d8d4",
                "540 Ozean": "#0b2339",
            },
        },
    },
    {
        key: "luis",
        icon: "858",
        name: "Luis",
        unit: "Luis_AP",
        image: Luis,
        images: [Luis1, Luis2, Luis3, Luis4, Luis5],
        type: "858",
        configured: "Amsterdam, NL | 3:19:52 PM  on April 10, 2022 ",
        designed: "Wisse Trooster",
        blurb: `Wisse Trooster is a passionately curious designer from The Netherlands
         and works with a variety of materials and in multiple disciplines. 
         “The lights match as a family,” Trooster says, “in fact, the names are from Donald Duck’s 
         triplet nephews. Fun, they are interesting on their own but come to life when placed together. 
         The close-to-black and white pattern seamlessly integrates the light discs and by keeping 
        the lamp in one color, the lights became well-balanced.” `,
        tear: "/tear/luis.pdf",
        autoPrice: true,

        dimensions: (
            <>
                Length: 22” Diameter: 8” <br />
                <br />
                Layers
                <br />
                540 Ozean: 30 <br />
                150 WeiB: 24 <br />
                274 Senf: 47 <br />
                <br />
                <br />
                Light
                <br />
                5" dia light: 4 <br />
                8" dia light: 2
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: White <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Clear <br />
                Cable length: Varies
            </>
        ),
        chair: {
            org: "SL",
            unit: "Luis_AP",
            form: "858",
            tube: {
                blocks: [
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0b2339",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0b2339",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#af8a35",
                        layers: 3,
                        colorName: "274 Senf",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#ae8933",
                        layers: 12,
                        colorName: "274 Senf",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#af8a35",
                        layers: 12,
                        colorName: "274 Senf",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#af8a35",
                        layers: 13,
                        colorName: "274 Senf",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#af8a35",
                        layers: 7,
                        colorName: "274 Senf",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0b2339",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d8d8d4",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                ],
                length: 24,
                diameter: 5,
            },
            tuft: {
                color: "#E0DAC2",
                height: 0.24,
                radius: 0.875,
                colorName: "Clear",
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            light: {
                number: "8",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#FAFAFA",
            },
            contact: {},
            pattern: 1,
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: ["540 Ozean", "150 Weiß"],
            boardColors: [],
            blockColorsRGB: {
                "150 Weiß": "#d8d8d4",
                "540 Ozean": "#0b2339",
            },
        },
    },
    {
        key: "hugo",
        icon: "58",
        name: "Hugo",
        unit: "Hugo_AP",
        image: Hugo,
        images: [Hugo1, Hugo2, Hugo3, Hugo4, Hugo5],
        type: "58",
        configured: "Amsterdam, NL | 3:20:12 PM on April 10, 2022 ",
        designed: "Wisse Trooster",
        blurb: `Wisse Trooster is a passionately curious designer from The Netherlands
         and works with a variety of materials and in multiple disciplines. 
         “The lights match as a family,” Trooster says, “in fact, the names are from Donald Duck’s 
         triplet nephews. Fun, they are interesting on their own but come to life when placed together. 
         The close-to-black and white pattern seamlessly integrates the light discs and by keeping 
        the lamp in one color, the lights became well-balanced.” `,
        tear: "/tear/hugo.pdf",
        autoPrice: true,

        dimensions: (
            <>
                Length 18” Diameter: 8” <br />
                <br />
                Layers
                <br />
                105 Rost: 48 <br />
                540 Ozean: 16 <br />
                150 WeiB: 16 <br />
                <br />
                <br />
                Light
                <br />
                5" dia light: 4 <br />
                8" dia light: 2
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: White <br />
                Hanging hardware: Stainless steel
                <br />
                Plates and tufts: Clear <br />
                Cable length: Varies
            </>
        ),
        chair: {
            org: "SL",
            unit: "Hugo_AP",
            form: "58",
            tube: {
                blocks: [
                    {
                        color: "#982f0d",
                        layers: 4,
                        colorName: "105 Rost",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#982f0d",
                        layers: 9,
                        colorName: "105 Rost",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#982f0d",
                        layers: 14,
                        colorName: "105 Rost",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#982f0d",
                        layers: 14,
                        colorName: "105 Rost",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#952f0d",
                        layers: 7,
                        colorName: "105 Rost",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0b2339",
                        layers: 4,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0c233a",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 3,
                        colorName: "150 Weiß",
                    },
                    {
                        color: "#0b2339",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        type: "light",
                        color: "light",
                        layers: 1,
                        colorName: "light",
                    },
                    {
                        color: "#0b2339",
                        layers: 3,
                        colorName: "540 Ozean",
                    },
                    {
                        color: "#d7d7d3",
                        layers: 4,
                        colorName: "150 Weiß",
                    },
                ],
                length: 18,
                diameter: 5,
            },
            tuft: {
                color: "#E0DAC2",
                height: 0.24,
                radius: 0.875,
                colorName: "Clear",
            },
            type: "pendent",
            cable: {
                color: "#C6CFDD",
                colorName: "White",
            },
            light: {
                number: "6",
            },
            title: "Vertical Pendent",
            canopy: {
                color: "#F9f9f9",
                colorName: "White",
            },
            pattern: 1,
            subType: "verticalPendent",
            lightsOn: 0,
            blockColors: ["308 Petrol", "540 Ozean", "150 Weiß"],
            boardColors: [],
            blockColorsRGB: {
                "150 Weiß": "#d8d8d4",
                "540 Ozean": "#0b2339",
                "308 Petrol": "#05476b",
            },
        },
    },
]

function expandOption(option) {
    if (!option) {
        return null
    }
    const options = [option]
    for (const o of option.images) {
        options.push({ ...option, image: o })
    }
    return options
}

export default function LightGallery(props) {
    return (
        <Collection
            baseUrl={"/lightcollection"}
            items={collectionItems}
            onClickCarasol={(item) => {
                return expandOption(item)
            }}
        />
    )
}
