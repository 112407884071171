import React, {
    Suspense,
    useEffect,
    useRef,
    useMemo,
    useState,
    useLayoutEffect,
    useUpdate,
} from "react"
import { cloneDeep } from "lodash"

import {
    Canvas,
    useFrame,
    extend,
    useThree,
    useLoader,
} from "@react-three/fiber"
import * as THREE from "three"
import useDeepCompareEffect from "use-deep-compare-effect"
import { thicknessOfBlocks, layersInBlocks } from "./utils"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { explodeBlocks } from "./utils"

import feltBump from "../../images/FabricUpholsteryFeltMilled001_BUMP_3K.jpg"
import feltMaterial from "../../images/FabricUpholsteryFeltMilled001_AO_3K.jpg"

import wood from "../../images/WoodMahoganyAfricanSanded0WEB.jpg"

import wood_black from "../../images/wood_black.jpg"
import wood_charcoal from "../../images/wood_charcoal.jpg"
import wood_natural from "../../images/wood_natural.jpg"

import seedrandom from "seedrandom"
import { BlurPass, Resizer, KernelSize } from "postprocessing"

import {
    useGLTF,
    Backdrop,
    Stage,
    PresentationControls,
    useDepthBuffer,
    SpotLight,
} from "@react-three/drei"
import { usePinch } from "react-use-gesture"
import userEvent from "@testing-library/user-event"
import {
    Bloom,
    EffectComposer,
    SelectiveBloom,
    SSAO,
} from "@react-three/postprocessing"

import { pendentConfig } from "../../state/mg"

import realizeLayers from "../../lightRealize"
import useWidth from "../useWidth"

const acBack = "/3D/ArmChairBackOLD.glb"
const acBackLeft = "/3D/ArmChairBackLeft.glb"
const acBackRight = "/3D/ArmChairBackRight.glb"
const acBackRightCurve = "/3D/ArmChairBackRightCurve.glb"
const acBackLeftCurve = "/3D/ArmChairBackLeftCurve.glb"

const acBack4in = "/3D/ArmChairBack4inCurve.glb"
const acBack4inCurve = "/3D/ArmChairBack4inCurve.glb"
const acBackLeft4in = "/3D/ArmChairBackLeft4in.glb"
const acBackRight4in = "/3D/ArmChairBackRight4in.glb"
const acBackRight4inCurve = "/3D/ArmChairBackRight4inCurve.glb"
const acBackLeft4inCurve = "/3D/ArmChairBackLeft4inCurve.glb"

const acArm8in = "/3D/Arm8in.glb"
//const acArmLeft8in = "/3D/ArmLeft8in.glb"
const acArmLeftCurve8in = "/3D/ArmLeftCurve8in.glb"
//const acArmRight8in = "/3D/ArmRight8in.glb"
const acArmRightCurve8in = "/3D/ArmRightCurve8in.glb"
const acArm4in = "/3D/Arm4in.glb"
//const acArmLeft4in = "/3D/ArmLeft4in.glb"
const acArmLeftCurve4in = "/3D/ArmLeftCurve4in.glb"
//const acArmRight4in = "/3D/ArmRight4in.glb"
const acArmRightCurve4in = "/3D/ArmRightCurve4in.glb"

const acLegType1 = "/3D/legs/Type1_full.glb"
const acLegType2 = "/3D/legs/Type2_full.glb"
const acLegType3 = "/3D/legs/Type3_full.glb"
const acLegType4 = "/3D/legs/Type4_full.glb"

const acLegType18in = "/3D/legs/Type1_8in_full.glb"
const acLegType28in = "/3D/legs/Type2_8in_full.glb"
const acLegType38in = "/3D/legs/Type3_8in_full.glb"
const acLegType48in = "/3D/legs/Type4_8in_full.glb"

const acLegType5 = "/3D/legs/Type5_full.glb"

const acLegType1Top = "/3D/legs/Type1_Top.glb"
const acLegType1Middle = "/3D/legs/Type1_Center.glb"
const acLegType1Bottom = "/3D/legs/Type1_Bottom.glb"

function PreloadLegs(props) {
    useGLTF(acLegType1)
    useGLTF(acLegType2)
    useGLTF(acLegType3)
    useGLTF(acLegType4)
    useGLTF(acLegType5)

    useGLTF(acLegType18in)
    useGLTF(acLegType28in)
    useGLTF(acLegType38in)
    useGLTF(acLegType48in)
    return null
}

function Model(props) {
    let disposable = []

    function dispose() {
        for (const d of disposable) {
            d.dispose()
        }
        disposable = []
    }
    useEffect(() => {
        //only cleanrup
        return () => {
            dispose()
        }
    })

    let pscale = 1
    let zscale = 1
    let xscale = 1
    let yscale = 1
    let displayThing = 0
    const multiplier = props.multiplier ? props.multiplier : 39.3701
    if (props.scale) {
        pscale = props.scale
    }
    if (props.zscale) {
        zscale = props.zscale
    }
    if (props.xscale) {
        xscale = props.xscale
    }
    if (props.yscale) {
        yscale = props.yscale
    }

    let scale = props.arm
        ? [
              multiplier * pscale * xscale,
              multiplier * pscale * zscale,
              multiplier * props.multi * pscale * yscale,
          ]
        : [
              multiplier * props.multi * pscale * xscale,
              multiplier * pscale * zscale,
              multiplier * pscale * yscale,
          ]

    const _oc = useGLTF(props.objUrl)
    const cloneO = _oc.scene.clone()
    const material = new THREE.MeshStandardMaterial({
        roughness: 0.9,
        color: props.map ? null : props.color,
        metalness: props.metalness ? props.metalness : 0.2,
        bumpMap: props.texture,
        displacementMap: props.texture,
        displacementScale: 0.0015,
        bumpScale: 0.2,
        flatShading: false,
        wireframe: false,
        aoMapIntensity: 0,
        map: props.map ? props.map : null,
    })

    if (!props.useMaterial) {
        disposable.push(material)
        if (cloneO.material) {
            cloneO.material.dispose()
        }
        cloneO.material = null
    }
    if (props.useMaterial) {
        dispose()
        cloneO.traverse((object) => {
            if (object.isMesh) {
                //object.material.roughness = 1
                //object.material.metalness = 0
                object.frustumCulled = false

                if (props.color) {
                    object.material.color = new THREE.Color(props.color)
                }
            }
        })
    }

    cloneO.traverse((children) => {
        children.castShadow = true
        children.receiveShadow = true
        if (!props.useMaterial) {
            children.material = material
        }

        children.needsUpdate = true

        if (children.type === "Mesh") {
            let old = children.geometry
            children.geometry = children.geometry.clone()
            old.dispose()
            old = null

            disposable.push(children.geometry)
            disposable.push(children.material)
            if (props.curve) {
                mergeCurve(
                    children.geometry,
                    props.curve - 1,
                    props.curveFactor,
                    "z",
                    props.curveOffset
                )

                if (props.curveBoth) {
                    mergeCurve(
                        children.geometry,
                        props.curve - 1,
                        props.curveFactor * -1,
                        "z",
                        props.curveOffset * -1
                    )
                }
            }

            if (props.shorten && props.shortenStart) {
                proportionalZScale(
                    children.geometry,
                    props.shortenStart,
                    props.shorten,
                    props.lowestPosition ? props.lowestPosition : 0
                )
            }

            if (props.cut) {
                cutAbove(children.geometry, props.cut)
            }
        }
    })
    return (
        <mesh
            ref={props.meshRef ? props.meshRef : null}
            scale={scale}
            position={props.position}
            rotation={props.rotation ? props.rotation : [0, 0, 0]}
        >
            <primitive matrixAutoUpdate={true} object={cloneO} />
            {props.map && (
                <meshStandardMaterial
                    roughness={0.1}
                    attach="material"
                    map={props.map}
                    metalness={0.2}
                />
            )}
        </mesh>
    )
}

function mergeCurve(geo, sheets, factor = 20, plain = "z", middle = 0) {
    const array = geo.attributes.position.array

    let p = 1 //z
    if (plain === "x") {
        p = 0
    } else if (plain === "y") {
        p = 2
    }

    for (let i = 0; i < array.length; i = i + 3) {
        // const max = array[i + 1] + 39.3701 / fivemm

        array[i + p] =
            array[i + p] +
            (Math.tanh((array[i] + middle) * factor) + 1) * (0.0025 * sheets)
    }
}

function proportionalZScale(geo, above, down, lowest = 0) {
    const array = geo.attributes.position.array
    const p = 1
    for (let i = 0; i < array.length; i = i + 3) {
        if (array[i + p] > above) {
            if (array[i + p] - down > lowest) {
                array[i + p] = array[i + p] - down
            } else {
                array[i + p] = lowest
            }
        }
    }
}

function cutAbove(geo, above) {
    const array = geo.attributes.position.array
    const p = 1

    for (let i = 0; i < array.length; i = i + 3) {
        if (array[i] > above) {
            geo.attributes.position.array = array.slice(0, i)
            break
        }
    }
    console.log(geo.attributes.position.array)
}

function ArmBlockLayer(props) {
    let left, right, middle, modelSize, cornerDepth
    cornerDepth = 0
    if (props.width > 7) {
        left = acArmLeftCurve8in
        right = acArmRightCurve8in
        middle = acArm8in
        modelSize = 25
        if (props.rad > 0) {
            cornerDepth = 5
        }
    } else {
        left = acArmLeftCurve4in
        right = acArmRightCurve4in
        middle = acArm4in
        modelSize = 30.5
        if (props.rad > 0) {
            cornerDepth = 3.1
        }
    }

    const ref = useRef()

    useLayoutEffect(() => {
        if (ref.current && props.rotate && !ref.current.__rotated) {
            ref.current.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2)
            ref.current.__rotated = true
        }
    }, [props.rotate])

    return (
        <group ref={ref}>
            {props.rad > 0 && (
                <>
                    <Model
                        key={right}
                        multi={1}
                        objUrl={right}
                        position={[
                            props.position[0],
                            props.position[1] + props.curve * fivemm,
                            props.position[2] - (37 - props.depth) / 2,
                        ]}
                        texture={props.texture}
                        color={props.color}
                        material={props.material}
                    />
                    <Model
                        key={left}
                        multi={1}
                        objUrl={left}
                        position={[
                            props.position[0],
                            props.position[1],
                            props.position[2] + (37 - props.depth) / 2,
                        ]}
                        texture={props.texture}
                        color={props.color}
                        material={props.material}
                    />
                </>
            )}
            <Model
                key={middle}
                objUrl={middle}
                position={props.position}
                multi={(props.depth - cornerDepth * 2) / modelSize}
                texture={props.texture}
                color={props.color}
                material={props.material}
                arm
                curve={props.curve + 1}
                curveFactor={props.width > 7 ? 20 : 15}
            />
        </group>
    )
}

function ArmChairBackLayer(props) {
    let left, right, middle, sideWidth
    if (props.depth > 7) {
        left = props.rad > 0 ? acBackLeftCurve : acBackLeft
        right = props.rad > 0 ? acBackRightCurve : acBackRight
        middle = acBack

        sideWidth = 12
    } else {
        left = props.rad > 0 ? acBackLeft4inCurve : acBackLeft4in
        right = props.rad > 0 ? acBackRight4inCurve : acBackRight4in
        middle = props.rad > 0 ? acBack4inCurve : acBack4in
        sideWidth = 3.2
    }
    return (
        <>
            <Model
                key={middle}
                objUrl={middle}
                position={props.position}
                multi={(props.width - sideWidth) / (37 - sideWidth)}
                texture={props.texture}
                color={props.color}
                material={props.material}
                curve={props.curve + 1}
                curveFactor={props.width > 7 ? 20 : 15}
            />

            <Model
                key={left}
                multi={1}
                objUrl={left}
                position={[
                    props.position[0] + (37 - props.width) / 2,
                    props.position[1],
                    props.position[2],
                ]}
                texture={props.texture}
                color={props.color}
                material={props.material}
            />
            <Model
                key={right}
                multi={1}
                objUrl={right}
                position={[
                    props.position[0] - (37 - props.width) / 2,
                    props.position[1],
                    props.position[2],
                ]}
                texture={props.texture}
                color={props.color}
                material={props.material}
            />
        </>
    )
}

function SeatLayer(props) {
    const ref = useRef()
    const gRef = useRef()
    useLayoutEffect(() => {
        if (ref.current && !ref.current.__rotated) {
            ref.current.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2)
            ref.current.__rotated = true
        }
    }, [])

    useLayoutEffect(() => {
        if (gRef.current && !gRef.current.__rotated && props.rotate) {
            gRef.current.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI * 1.5)
            gRef.current.__rotated = true
        }
    }, [props.rotate])

    return (
        <group ref={gRef}>
            <Model
                meshRef={ref}
                key={acArm8in}
                objUrl={acArm8in}
                position={props.position}
                multi={(props.width - 10) / 24.8}
                texture={props.texture}
                color={new THREE.Color(props.color).convertSRGBToLinear()}
                material={props.material}
                xscale={props.depth / 9}
                arm
                curve={props.curve + 1}
                curveFactor={28}
                curveOffset={-0.223}
                curveBoth={props.curveBoth}
            />
            <Block3d
                position={[
                    props.position[0] + 2.5 - props.width / 2,
                    props.position[1] +
                        (props.curveBoth ? props.curve * fivemm : 0),
                    props.position[2] / 2,
                ]}
                width={5}
                depth={props.depth}
                block={{ layers: 1, color: props.color }}
                rad={props.rad}
                counter={1}
                totalBlockSize={1}
                material={props.material}
                texture={props.texture}
                radSide="right"
            />
            <Block3d
                position={[
                    props.position[0] - 2.5 + props.width / 2,
                    props.position[1] + props.curve * fivemm,
                    props.position[2] / 2,
                ]}
                width={5}
                depth={props.depth}
                block={{ layers: 1, color: props.color }}
                rad={props.rad}
                counter={1}
                totalBlockSize={1}
                material={props.material}
                texture={props.texture}
                radSide="left"
            />
        </group>
    )
}

extend({ OrbitControls })

const fivemm = 0.19685

function CameraControls(props) {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls component.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls
    const {
        camera,
        gl: { domElement },
    } = useThree()
    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef()
    useFrame((state) => {
        controls.current.update()
        if (props.center) {
            controls.current.target.set(
                props.center[0],
                props.center[1],
                props.center[3]
            )
        }
    })

    return <orbitControls ref={controls} args={[camera, domElement]} />
}

function Block3dSet(props) {
    const [s, setS] = useState(null)
    useEffect(() => {
        setTimeout(() => setS(1))
    }, [])

    let bottom = 0
    let rakeDiff = 0
    let startRake = 0

    if (props.rake) {
        rakeDiff = props.rake / layersInBlocks(props.blocks)
    }

    const i = []
    const pos = [...props.position]
    let counter = 1
    for (let b of props.blocks) {
        i.push(
            <>
                <Block3d
                    position={[
                        pos[0],
                        b.type === "light" ? pos[1] - fivemm : pos[1],
                        pos[2] - startRake / 2,
                    ]}
                    width={
                        b.diameter
                            ? b.diameter
                            : b.type === "light"
                            ? props.width - 0.3
                            : props.width
                    }
                    depth={props.depth + startRake}
                    block={b}
                    rake={rakeDiff}
                    rad={props.rad}
                    armChairBack={props.armChairBack}
                    arm={props.arm}
                    seat={props.seat}
                    curve={true}
                    counter={counter}
                    totalBlockSize={layersInBlocks(props.blocks)}
                    lift={props.lift}
                    liftOffset={props.liftOffset}
                    rotate={props.rotate}
                    armLift={props.armLift}
                    curveBoth={props.curveBoth}
                    stool={props.stool}
                    circle={props.circle}
                    light={b.type === "light" ? true : false}
                    lightBodyRefs={props.lightBodyRefs}
                />
                {b.type === "light_off" && (
                    <EmbededLight
                        lightRefs={props.lightRefs}
                        color="white"
                        intensity={20}
                        position={[pos[0], pos[1], pos[2]]}
                        depthBuffer={props.depthBuffer}
                    />
                )}
            </>
        )
        counter = counter + layersInBlocks([b])

        if (props.rake) {
            startRake = counter * rakeDiff
        }
        if (b.type === "light") {
            pos[1] = pos[1] - 3 * fivemm * b.layers
        } else {
            pos[1] =
                pos[1] -
                (b.type === "designerBoard" ? fivemm * 2 : fivemm) * b.layers
        }
    }
    return i

    //return items
}

/*
function splitLine(ctx, sx, sy, ex, ey, splits = 2) {
    const xs = (ex - sx) / splits
    const ys = (ey - sy) / splits

    let cy = sy,
        cx = sx

    for (let i = 0; i < splits; i++) {
        //ctx.lineTo(cx, -1 * cy)
        ctx.lineTo(cx, cy)

        cy = cy + ys
        cx = cx + xs
        //ctx.lineTo(cx, -1 * cy)
        ctx.lineTo(cx, cy)
    }
}
*/

function roundedRect(
    x,
    y,
    width,
    height,
    radius,
    side = "both",
    stool = false
) {
    let [lrad, rrad] = [radius, radius]

    if (side === "left") {
        rrad = 0.0001
    }
    if (side === "right") {
        lrad = 0.0001
    }
    const ctx = new THREE.Shape()
    ctx.moveTo(x, y + rrad)
    ctx.lineTo(x, y + height - rrad)

    //Back left
    ctx.quadraticCurveTo(
        x,
        y + height - (stool ? height / 24 : 0),
        x + rrad,
        y + height
    )
    ctx.lineTo(x + width - lrad, y + height)

    ctx.quadraticCurveTo(
        x + width,
        y + height - (stool ? height / 24 : 0),
        x + width,
        y + height - lrad
    )
    ctx.lineTo(x + width, y + lrad)

    ctx.quadraticCurveTo(
        x + width,
        y + (stool ? width / 24 : 0),
        x + width - lrad,
        y
    )
    ctx.lineTo(x + rrad, y)

    ctx.quadraticCurveTo(x, y + (stool ? width / 24 : 0), x, y + rrad)

    return ctx
}

/*
function bender(geometry, axis, angle) {
    let theta = 0
    if (angle !== 0) {
        const v = geometry.attributes.position.array
        for (let i = 0; i < v.length; i += 3) {
            let x = v[i]
            let y = v[i + 1]
            let z = v[i + 2]

            switch (axis) {
                case "x":
                    theta = z * angle
                    break
                case "y":
                    theta = x * angle
                    break
                case "sag":
                    theta = y * angle
                    break
                default:
                    //z
                    theta = x * angle
                    break
            }
            let sinTheta = Math.sin(theta)
            let cosTheta = Math.cos(theta)
            switch (axis) {
                case "x":
                    v[i] = x
                    v[i + 1] = (y - 1.0 / angle) * cosTheta + 1.0 / angle
                    v[i + 2] = -(y - 1.0 / angle) * sinTheta
                    break
                case "y":
                    v[i] = -(z - 1.0 / angle) * sinTheta
                    v[i + 1] = y
                    v[i + 2] = (z - 1.0 / angle) * cosTheta + 1.0 / angle
                    break
                default:
                    //z
                    v[i] = -(y - 1.0 / angle) * sinTheta
                    v[i + 1] = (y - 1.0 / angle) * cosTheta + 1.0 / angle
                    v[i + 2] = z
                    break
            }
            geometry.attributes.position.needsUpdate = true
        }
    }
}
*/

function Layer({
    width,
    depth,
    height,
    position,
    color,
    texture,
    rad,
    bend,
    material,
    circle,
    light,
    ...props
}) {
    const geo = useRef()
    const meshRef = useRef()
    useEffect(() => {
        if (props.lightBodyRefs && light) {
            props.lightBodyRefs.current.push(meshRef)
        }
        return () => {
            if (props.lightBodyRef && light) {
                const i = props.lightBodyRefs.current.indexOf(meshRef)
                props.lightBodyRefs.current.splice(i, 1)
            }
        }
    }, [props.lightBodyRefs])
    const shape = useMemo(() => {
        if (circle) {
            // return new THREE.CylinderGeometry(width / 2, width / 2, height, 32)
        } else {
            return roundedRect(
                -width / 2,
                -depth / 2,
                width,
                depth,
                rad + 0.0001,
                props.radSide,
                props.stool
            )
        }
    }, [width, depth, rad, props.radSide])

    const check = geo.current ? geo.current.__ipass : null
    useEffect(() => {
        if (geo.current) {
            if (!geo.current.__ipass) {
                geo.current.__ipass = true
            }
        }
    }, [check])

    return (
        <mesh
            position={[position[0], position[1], position[2]]}
            rotation={[circle ? 0 : 1.57079633, 0, 0]}
            castShadow
            receiveShadow
            ref={meshRef}
        >
            {!circle && (
                <extrudeGeometry
                    ref={geo}
                    receiveShadow
                    args={[
                        shape,
                        {
                            bevelEnabled: false,
                            depth: height,
                            steps: 1,
                            curveSegments: 40,
                        },
                    ]}
                />
            )}
            {circle && (
                <cylinderBufferGeometry
                    attach="geometry"
                    args={[width / 2, width / 2, height, 80]}
                />
            )}
            <meshStandardMaterial
                receiveShadow
                roughness={0.9}
                attach="material"
                color={new THREE.Color(color).convertSRGBToLinear()}
                metalness={0}
                bumpMap={texture}
                bumpScale={0.5}
                displacementMap={props.texture}
                displacementScale={0.0015}
                flatShading={false}
                wireframe={false}
                opacity={light ? 0.95 : 1}
                transparent={light ? true : false}
            />
        </mesh>
    )
}

function Block3d(props) {
    const texture = useLoader(THREE.TextureLoader, feltBump)
    const material = useLoader(THREE.TextureLoader, feltMaterial)

    useEffect(() => {
        //for disposal
        return () => {
            texture.dispose()
            material.dispose()
        }
    })

    texture.encoding = THREE.sRGBEncoding
    material.encoding = THREE.sRGBEncoding

    const { width, depth, block, position, rad } = props

    let rake = props.rake ? props.rake : 0
    const lift = props.lift ? props.lift : 5
    const i = []
    let count = 0
    let moreCount = 0
    const eb = explodeBlocks([block])
    for (const b of eb) {
        const rnd = seedrandom(count + props.counter + position[0])

        if (props.armChairBack || props.armLift) {
            if (count + props.counter > props.totalBlockSize - lift) {
                moreCount =
                    count + props.counter - (props.totalBlockSize - lift + 1)
            }
        }
        if (props.armChairBack) {
            i.push(
                <ArmChairBackLayer
                    key={count}
                    position={[
                        position[0] + rnd() / 8,
                        position[1] + (count + moreCount) * -fivemm,
                        position[2] + rnd() / 8,
                    ]}
                    width={width}
                    depth={depth}
                    color={new THREE.Color(block.color).convertSRGBToLinear()}
                    rad={rad}
                    texture={texture}
                    material={material}
                    arm={props.arm}
                    rotate={props.rotate}
                />
            )
        } else if (props.arm || props.seat) {
            let liftOffset = 0
            if (props.liftOffset) {
                liftOffset = props.liftOffset
            }
            let curve = 0
            if (count + props.counter < lift + liftOffset && props.curve) {
                if (liftOffset > count + props.counter) {
                    curve = lift
                } else {
                    curve = lift - (count + props.counter) + liftOffset
                }
            }

            if (props.seat) {
                i.push(
                    <SeatLayer
                        key={count}
                        position={[
                            position[0] + rnd() / 8,
                            position[1] + (count + moreCount) * -fivemm,
                            position[2] + rnd() / 8,
                        ]}
                        width={width}
                        depth={depth}
                        color={block.color}
                        rad={rad}
                        texture={texture}
                        material={material}
                        arm={props.arm}
                        rotate={props.rotate}
                        curve={curve}
                        curveBoth={props.curveBoth}
                        doubleThick={block.type === "designerBoard"}
                    />
                )
            } else {
                i.push(
                    <ArmBlockLayer
                        key={count}
                        position={[
                            position[0] + rnd() / 8,
                            position[1] + (count + moreCount) * -fivemm,
                            position[2] + rnd() / 8,
                        ]}
                        width={width}
                        depth={depth}
                        color={new THREE.Color(
                            block.color
                        ).convertSRGBToLinear()}
                        rad={rad}
                        texture={texture}
                        material={material}
                        arm={props.arm}
                        curve={curve}
                        rotate={props.rotate}
                    />
                )
            }
        } else {
            let height = fivemm
            if (block.type === "designerBoard") {
                height = 2 * fivemm
            }
            if (block.type === "light") {
                height = 3 * fivemm
            }
            i.push(
                <Layer
                    key={count}
                    height={height}
                    width={width}
                    depth={depth + rake}
                    position={[
                        position[0] + rnd() / 8,
                        position[1] - (count + moreCount) * fivemm,
                        position[2] - rake / 2 + rnd() / 8,
                    ]}
                    color={props.light ? "#efebd8" : block.color}
                    texture={texture}
                    rad={rad}
                    material={material}
                    rotate={props.rotate}
                    curve={props.curve}
                    radSide={props.radSide}
                    stool={props.stool}
                    circle={props.circle}
                    light={props.light}
                    lightBodyRefs={props.lightBodyRefs}
                />
            )
        }
        count++
        if (block.type === "designerBoard") {
            moreCount++
        }
        rake = rake + (props.rake ? props.rake : 0)
    }

    return i
    // return items
}

function WoodLegGeneric(props) {
    let wt = wood_natural
    if (props.legs.color === "charcoal") {
        wt = wood_charcoal
    } else if (props.legs.color === "black") {
        wt = wood_black
    }

    const texture = useLoader(THREE.TextureLoader, wt)

    return (
        <mesh
            ref={props.meshRef ? props.meshRef : null}
            position={props.position}
        >
            <cylinderBufferGeometry
                args={[
                    props.legs.width / 2,
                    props.legs.width / 2,
                    props.legs.height,
                    32,
                ]}
            />

            <meshStandardMaterial
                roughness={0.1}
                attach="material"
                map={texture}
                metalness={0.2}
            />
        </mesh>
    )
}

function computeUVs(geometry) {
    geometry.computeBoundingBox()

    var max = geometry.boundingBox.max,
        min = geometry.boundingBox.min
    var offset = new THREE.Vector2(0 - min.x, 0 - min.y)
    var range = new THREE.Vector2(max.x - min.x, max.y - min.y)
    var faces = geometry.faces
    var vertices = geometry.vertices

    geometry.faceVertexUvs[0] = []

    for (var i = 0, il = faces.length; i < il; i++) {
        var v1 = vertices[faces[i].a],
            v2 = vertices[faces[i].b],
            v3 = vertices[faces[i].c]

        geometry.faceVertexUvs[0].push([
            new THREE.Vector2(
                (v1.x + offset.x) / range.x,
                (v1.y + offset.y) / range.y
            ),
            new THREE.Vector2(
                (v2.x + offset.x) / range.x,
                (v2.y + offset.y) / range.y
            ),
            new THREE.Vector2(
                (v3.x + offset.x) / range.x,
                (v3.y + offset.y) / range.y
            ),
        ])
    }
    geometry.uvsNeedUpdate = true
}

function WoodLegFull(props) {
    let wt = wood_natural
    if (props.legs.color === "wood_charcoal") {
        wt = wood_charcoal
    } else if (props.legs.color === "wood_black") {
        wt = wood_black
    } else if (props.legs.color === "wood_mahogany") {
        wt = wood
    }

    const texture = useLoader(THREE.TextureLoader, wt)
    if (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.repeat.set(10, 10)
        texture.anisotropy = 16
    }

    const p = props.position
    let legHeight = 17.78
    let shortenStart = 6
    let lowestPosition = 5

    let legType = [acLegType28in, acLegType2]

    let cut = false
    if (props.legs.legTypes[props.legPosition] === 1) {
        legType = [acLegType18in, acLegType1]
    }
    if (props.legs.legTypes[props.legPosition] === 3) {
        legType = [acLegType38in, acLegType3]
        shortenStart = 0.1
        lowestPosition = 0.1
    }
    if (props.legs.legTypes[props.legPosition] === 4) {
        legType = [acLegType48in, acLegType4]
        shortenStart = 15
        lowestPosition = 15
    }
    if (props.legs.legTypes[props.legPosition] === 5) {
        legType = [null, acLegType5]
        legHeight = 13.9193
    }

    const diff = legHeight - props.legs.height

    return (
        <Model
            key={wt}
            multi={1}
            meshRef={props.meshRef ? props.meshRef : null}
            objUrl={props.legs.width === 8 ? legType[0] : legType[1]}
            position={[p[0], p[1] - props.legs.height / 2, p[2]]}
            texture={texture}
            map={texture}
            multiplier={1}
            shorten={diff}
            shortenStart={shortenStart}
            lowestPosition={lowestPosition}
            cut={cut}
        />
    )
}

function WoodLeg(props) {
    const texture = useLoader(THREE.TextureLoader, wood)
    const p = props.position
    const bottomSize = 0.336746
    const topSize = 0.540808
    const middleSize = 25
    const totalSize = bottomSize + middleSize + topSize

    let multi = (props.legs.height - bottomSize / 2 - topSize / 2) / middleSize
    if (props.legs.width < 5) {
        multi = multi * 1.3 //WTF?
    }
    return (
        <>
            <Model
                multi={1}
                objUrl={acLegType1Middle}
                position={[
                    p[0],
                    p[1] - props.legs.height / 2 + bottomSize * 6,
                    p[2],
                ]}
                texture={texture}
                map={texture}
                xscale={0.5}
                yscale={0.5}
                zscale={multi * 0.5}
            />
            <Model
                multi={1}
                objUrl={acLegType1Top}
                position={[p[0], p[1] + props.legs.height / 2, p[2]]}
                texture={texture}
                map={texture}
                scale={0.5}
            />
            <Model
                meshRef={props.meshRef ? props.meshRef : null}
                multi={1}
                objUrl={acLegType1Bottom}
                position={[p[0], p[1] - props.legs.height / 2, p[2]]}
                texture={texture}
                map={texture}
                scale={0.5}
            />
        </>
    )
}

function Leg(props) {
    let wt = wood_natural
    if (props.legs.color === "wood_charcoal") {
        wt = wood_charcoal
    } else if (props.legs.color === "wood_black") {
        wt = wood_black
    } else if (props.legs.color === "wood_mahogany") {
        wt = wood
    }
    const texture = useLoader(THREE.TextureLoader, wt)

    if (props.legs.colorName === "wood" && !props.noStyle) {
        return <WoodLegFull {...props} />
    }
    return (
        <mesh
            ref={props.meshRef ? props.meshRef : null}
            position={props.position}
        >
            <cylinderBufferGeometry
                args={[
                    props.legs.width / 2,
                    props.legs.width / 2,
                    props.legs.height,
                    32,
                ]}
            />
            <meshStandardMaterial
                roughness={0.1}
                attach="material"
                metalness={0.2}
                texture={texture}
                map={texture}
            />
        </mesh>
    )
}

function Tuft(props) {
    return (
        <mesh
            ref={props.meshRef ? props.meshRef : null}
            position={props.position}
        >
            <cylinderBufferGeometry
                args={[
                    props.tuft.radius,
                    props.tuft.radius,
                    props.thickness ? props.thickness : 0.05,
                    32,
                ]}
            />
            <meshStandardMaterial
                roughness={0.1}
                attach="material"
                color={props.tuft.color}
                metalness={0.2}
            />
        </mesh>
    )
}

function Loading() {
    return (
        <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]}>
            <sphereBufferGeometry attach="geometry" args={[1, 16, 16]} />
            <meshBasicMaterial
                attach="material"
                color="white"
                transparent
                opacity={0.6}
                roughness={1}
                metalness={0}
            />
        </mesh>
    )
}

function fitCameraToSelection(camera, controls, selection, fitOffset = 1.3) {
    const box = new THREE.Box3()

    for (const object of selection) {
        if (object.current) {
            box.expandByObject(object.current)
        }
    }

    const size = box.getSize(new THREE.Vector3())
    const center = box.getCenter(new THREE.Vector3())

    const maxSize = Math.max(size.x, size.y, size.z)
    const fitHeightDistance =
        maxSize / (2 * Math.atan((Math.PI * camera.fov) / 450))
    const fitWidthDistance = fitHeightDistance / camera.aspect
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance)

    const direction = controls.current.target
        .clone()
        .sub(camera.position)
        .normalize()
        .multiplyScalar(distance)

    controls.current.maxDistance = distance * 10
    controls.current.target.copy(center)

    camera.near = distance / 100
    camera.far = distance * 100
    camera.updateProjectionMatrix()
    camera.position.copy(controls.current.target).sub(direction)

    controls.current.update()
}

const Dolly = (props) => {
    useEffect(() => {
        document.addEventListener("gesturestart", (e) => e.preventDefault())
        document.addEventListener("gesturechange", (e) => e.preventDefault())
    }, [])
    const zoom = useRef(1)
    const pinch = usePinch(
        (opts) => {
            zoom.current = 1.3 - opts.offset[0] / 400
        },
        {
            domTarget: props.canvasRef,
            distanceBounds: { min: 0, max: 300 },
            transform: ([x, y]) => [x, y],
        }
    )

    useFrame(({ camera }) => {
        fitCameraToSelection(
            camera,
            props.controls,
            props.children,
            zoom.current
        )
    })
    return null
}

function Controls(props) {
    const {
        camera,
        gl: { domElement },
    } = useThree()
    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = props.meshRef
    return (
        <orbitControls
            enableRotate={false}
            enableZoom={false}
            enablePan={false}
            ref={controls}
            args={[camera, domElement]}
            autoRotate={props.autoRotate ? true : false}
            target={[0, 0, 0]}
        />
    )
}

//This function is a total mess and should be refactored.
// We should have peices (seat, back, arm) and assemble them
export function SimpleChair(props) {
    const bottomRightLeg = props.bottomRightLeg
    const bottomLeftLeg = props.bottomLeftLeg

    const topLeftTuft = props.topLeftTuft
    const topRightTuft = props.topRightTuft
    return (
        <>
            {props.chair.back &&
                props.chair.type !== "armChair" &&
                props.chair.type !== "cornerChair" && (
                    <Block3dSet
                        width={Number(props.chair.seat.width)}
                        depth={Number(props.chair.back.depth)}
                        position={[
                            0,
                            thicknessOfBlocks(props.chair.back.blocks),
                            props.chair.seat.depth / 2 -
                                props.chair.back.depth / 2,
                        ]}
                        blocks={props.chair.back.blocks}
                        rad={
                            props.chair.radiusCorner
                                ? (props.chair.legs.width + 2) / 2
                                : 0
                        }
                        rake={props.chair.back.rake ? props.chair.back.rake : 0}
                        lift={props.chair.type === "dayBed" ? 22 : 0}
                        armLift={props.chair.type === "dayBed"}
                    />
                )}
            {(props.chair.type === "armChair" ||
                props.chair.type === "armBench") && (
                <>
                    {props.chair.type === "armChair" && (
                        <Block3dSet
                            width={Number(props.chair.seat.width)}
                            depth={Number(props.chair.back.depth)}
                            position={[
                                0,
                                thicknessOfBlocks(props.chair.back.blocks) +
                                    thicknessOfBlocks(
                                        props.chair.leftArm.blocks
                                    ),
                                props.chair.seat.depth / 2 -
                                    props.chair.back.depth / 2,
                            ]}
                            blocks={props.chair.back.blocks}
                            rad={
                                props.chair.radiusCorner
                                    ? (props.chair.legs.width + 2) / 2
                                    : 0
                            }
                            armChairBack
                        />
                    )}
                    <Block3dSet
                        width={Number(props.chair.leftArm.width)}
                        depth={Number(props.chair.seat.depth)}
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.leftArm.width / 2,
                            thicknessOfBlocks(props.chair.leftArm.blocks),
                            0,
                        ]}
                        blocks={props.chair.leftArm.blocks}
                        rad={
                            props.chair.radiusCorner
                                ? (props.chair.legs.width + 2) / 2
                                : 0
                        }
                        arm={props.chair.type === "armChair"}
                        armLift={props.chair.type === "armBench"}
                        lift={props.chair.type === "armBench" ? 22 : 5}
                    />
                    <Block3dSet
                        width={Number(props.chair.rightArm.width)}
                        depth={Number(props.chair.seat.depth)}
                        position={[
                            (props.chair.seat.width / 2 -
                                props.chair.leftArm.width / 2) *
                                -1,
                            thicknessOfBlocks(props.chair.rightArm.blocks),
                            0,
                        ]}
                        blocks={props.chair.rightArm.blocks}
                        rad={
                            props.chair.radiusCorner
                                ? (props.chair.legs.width + 2) / 2
                                : 0
                        }
                        arm={props.chair.type === "armChair"}
                        armLift={props.chair.type === "armBench"}
                        lift={props.chair.type === "armBench" ? 22 : 5}
                    />
                </>
            )}
            {props.chair.type === "cornerChair" && (
                <>
                    <Block3dSet
                        width={Number(props.chair.leftArm.width)}
                        depth={Number(props.chair.seat.depth)}
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.leftArm.width / 2,
                            thicknessOfBlocks(props.chair.leftArm.blocks),
                            0,
                        ]}
                        blocks={props.chair.leftArm.blocks}
                        rad={
                            props.chair.radiusCorner
                                ? (props.chair.legs.width + 2) / 2
                                : 0
                        }
                        arm={true}
                        lift={layersInBlocks(props.chair.leftArm.blocks)}
                    />
                    <Block3dSet
                        width={Number(props.chair.leftArm.width)}
                        depth={Number(
                            props.chair.back.width
                                ? props.chair.back.width
                                : props.chair.seat.width
                        )}
                        position={[
                            props.chair.seat.depth / -2 +
                                props.chair.leftArm.width / 2,
                            thicknessOfBlocks(props.chair.back.blocks),
                            props.chair.back.width
                                ? (props.chair.seat.width -
                                      props.chair.back.width) /
                                  2
                                : 0,
                        ]}
                        blocks={props.chair.back.blocks}
                        rad={
                            props.chair.radiusCorner
                                ? (props.chair.legs.width + 2) / 2
                                : 0
                        }
                        arm={true}
                        lift={layersInBlocks(props.chair.leftArm.blocks) - 1}
                        liftOffset={
                            layersInBlocks(props.chair.back.blocks) -
                            layersInBlocks(props.chair.leftArm.blocks)
                        }
                        rotate={90}
                    />

                    <Tuft
                        meshRef={topLeftTuft}
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                1,

                            thicknessOfBlocks(props.chair.back.blocks) +
                                thicknessOfBlocks(props.chair.leftArm.blocks) +
                                0.05 / 2,

                            (props.chair.seat.depth -
                                props.chair.legs.width -
                                props.chair.tuft.radius) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />

                    <Tuft
                        position={[
                            -props.chair.seat.width / 2 +
                                props.chair.legs.width / 2 +
                                1,

                            props.chair.back.width
                                ? 0.05 / 2
                                : thicknessOfBlocks(props.chair.back.blocks) +
                                  0.05 / 2,

                            (props.chair.seat.depth -
                                props.chair.legs.width -
                                props.chair.tuft.radius) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    {props.chair.back.width && (
                        <>
                            <Tuft
                                position={[
                                    0,

                                    thicknessOfBlocks(props.chair.back.blocks) +
                                        0.05 / 2,

                                    (props.chair.seat.depth -
                                        props.chair.legs.width -
                                        props.chair.tuft.radius) /
                                        2,
                                ]}
                                tuft={props.chair.tuft}
                            />

                            <Tuft
                                position={[
                                    0,
                                    0.05 / 2,

                                    (-1 * props.chair.seat.depth +
                                        props.chair.legs.width +
                                        props.chair.tuft.radius) /
                                        2,
                                ]}
                                tuft={props.chair.tuft}
                            />
                        </>
                    )}
                    <Tuft
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                1 / 2,

                            thicknessOfBlocks(props.chair.leftArm.blocks) +
                                0.05 / 2,

                            (-props.chair.seat.depth +
                                props.chair.legs.width +
                                props.chair.tuft.radius) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                </>
            )}
            {/*Magic*/}
            {props.chair.type !== "dayBed" && (
                <Block3dSet
                    width={Number(props.chair.seat.width)}
                    depth={Number(props.chair.seat.depth)}
                    position={[0, 0, 0]}
                    blocks={props.chair.seat.blocks}
                    rad={
                        props.chair.radiusCorner
                            ? props.chair.type === "stool"
                                ? props.chair.seat.depth / 2
                                : (props.chair.legs.width + 2) / 2
                            : 0
                    }
                    seat={
                        props.chair.type === "armBench" ||
                        props.chair.type === "dayBed"
                    }
                    lift={props.chair.type === "armBench" ? 22 : 0}
                    curveBoth={props.chair.type === "armBench"}
                    stool={props.chair.type === "stool"}
                />
            )}
            {props.chair.type === "dayBed" && (
                <Block3dSet
                    width={Number(props.chair.seat.depth)}
                    depth={Number(props.chair.seat.width)}
                    position={[0, 0, 0]}
                    blocks={props.chair.seat.blocks}
                    rad={
                        props.chair.radiusCorner
                            ? (props.chair.legs.width + 2) / 2
                            : 0
                    }
                    seat
                    lift={22}
                    rotate={90}
                />
            )}
            <Block3dSet
                width={Number(props.chair.seat.width)}
                depth={Number(props.chair.seat.depth)}
                position={[0, -thicknessOfBlocks(props.chair.seat.blocks), 0]}
                blocks={[{ color: props.chair.tuft.color, layers: 1 }]}
                rad={
                    props.chair.radiusCorner
                        ? props.chair.type === "stool"
                            ? props.chair.seat.depth / 2
                            : (props.chair.legs.width + 2) / 2
                        : 0
                }
                stool={props.chair.type === "stool"}
            />
            {props.chair.type !== "stool" && (
                <>
                    <Leg
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            props.chair.seat.depth / 2 -
                                props.chair.legs.width / 2 -
                                0.5,
                        ]}
                        legs={props.chair.legs}
                        legPosition={1}
                    />

                    <Leg
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            props.chair.seat.depth / 2 -
                                props.chair.legs.width / 2 -
                                0.5,
                        ]}
                        legs={props.chair.legs}
                        legPosition={3}
                    />

                    <Leg
                        meshRef={bottomLeftLeg}
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            (-1 * props.chair.seat.depth) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,
                        ]}
                        legs={props.chair.legs}
                        legPosition={0}
                    />

                    <Leg
                        meshRef={bottomRightLeg}
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            (-1 * props.chair.seat.depth) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,
                        ]}
                        legs={props.chair.legs}
                        legPosition={2}
                    />
                </>
            )}
            {props.chair.type === "stool" && (
                <>
                    <Leg
                        meshRef={bottomRightLeg}
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            0,
                        ]}
                        legs={props.chair.legs}
                        noStyle
                    />
                    <Leg
                        meshRef={bottomLeftLeg}
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            0,
                        ]}
                        legs={props.chair.legs}
                        noStyle
                    />
                </>
            )}
            {props.chair.type === "dayBed" && (
                <>
                    <Leg
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            0,
                        ]}
                        legs={props.chair.legs}
                        legPosition={4}
                    />
                    <Leg
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            0,
                        ]}
                        legs={props.chair.legs}
                        legPosition={5}
                    />
                    <Tuft
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                1,

                            0.05 / 2,

                            0,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    <Tuft
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,

                            0.05 / 2,

                            0,
                        ]}
                        tuft={props.chair.tuft}
                    />
                </>
            )}
            {(props.chair.type === "armBench" ||
                props.chair.legs.centerLeg) && (
                <>
                    <Leg
                        position={[
                            0,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            (-1 * props.chair.seat.depth) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,
                        ]}
                        legs={props.chair.legs}
                        legPosition={4}
                    />
                    <Leg
                        position={[
                            0,

                            -thicknessOfBlocks(props.chair.seat.blocks) -
                                props.chair.legs.height / 2,

                            props.chair.seat.depth / 2 -
                                props.chair.legs.width / 2 -
                                0.5,
                        ]}
                        legs={props.chair.legs}
                        legPosition={5}
                    />
                    <Tuft
                        meshRef={topLeftTuft}
                        position={[
                            0,

                            0.05 / 2,

                            (-1 * props.chair.seat.depth) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    <Tuft
                        meshRef={topRightTuft}
                        position={[
                            0,

                            0.05 / 2,

                            props.chair.seat.depth / 2 -
                                props.chair.legs.width / 2 -
                                0.5,
                        ]}
                        tuft={props.chair.tuft}
                    />
                </>
            )}
            {props.chair.back &&
                props.chair !== "armChair" &&
                props.chair.type !== "cornerChair" && (
                    <>
                        <Tuft
                            meshRef={topRightTuft}
                            position={[
                                (-1 * props.chair.seat.width) / 2 +
                                    props.chair.legs.width / 2 +
                                    1,

                                thicknessOfBlocks(props.chair.back.blocks) +
                                    0.05 / 2,

                                (props.chair.seat.depth -
                                    props.chair.legs.width -
                                    props.chair.tuft.radius) /
                                    2,
                            ]}
                            tuft={props.chair.tuft}
                        />
                        <Tuft
                            meshRef={topLeftTuft}
                            position={[
                                props.chair.seat.width / 2 -
                                    props.chair.legs.width / 2 -
                                    1,

                                thicknessOfBlocks(props.chair.back.blocks) +
                                    0.05 / 2,

                                (props.chair.seat.depth -
                                    props.chair.legs.width -
                                    props.chair.tuft.radius) /
                                    2,
                            ]}
                            tuft={props.chair.tuft}
                        />
                    </>
                )}
            {!props.chair.back && (
                <>
                    <Tuft
                        meshRef={topRightTuft}
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                1,

                            0.05 / 2,
                            props.chair.type === "stool"
                                ? 0
                                : (props.chair.seat.depth -
                                      props.chair.legs.width -
                                      props.chair.tuft.radius) /
                                  2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    <Tuft
                        meshRef={topLeftTuft}
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                1,

                            0.05 / 2,
                            props.chair.type === "stool"
                                ? 0
                                : (props.chair.seat.depth -
                                      props.chair.legs.width -
                                      props.chair.tuft.radius) /
                                  2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                </>
            )}
            {props.chair.type !== "stool" && (
                <>
                    <Tuft
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                1,

                            0.33 / 2,
                            (-1 * props.chair.seat.depth +
                                props.chair.legs.width +
                                props.chair.tuft.radius * 2) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    <Tuft
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                1,

                            0.33 / 2,

                            (-1 * props.chair.seat.depth +
                                props.chair.legs.width +
                                props.chair.tuft.radius * 2) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                </>
            )}
            {props.chair.type === "armBench" && (
                <>
                    <Tuft
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            thicknessOfBlocks(props.chair.leftArm.blocks),
                            (-1 * props.chair.seat.depth +
                                props.chair.legs.width +
                                props.chair.tuft.radius * 2) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    <Tuft
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,
                            thicknessOfBlocks(props.chair.leftArm.blocks),

                            (-1 * props.chair.seat.depth +
                                props.chair.legs.width +
                                props.chair.tuft.radius * 2) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    <Tuft
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            thicknessOfBlocks(props.chair.leftArm.blocks),
                            props.chair.seat.depth / 2 -
                                props.chair.legs.width / 2,
                        ]}
                        tuft={props.chair.tuft}
                    />

                    <Tuft
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,
                            thicknessOfBlocks(props.chair.leftArm.blocks),
                            props.chair.seat.depth / 2 -
                                props.chair.legs.width / 2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                </>
            )}
            {props.chair.type === "armChair" && (
                <>
                    <Tuft
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            thicknessOfBlocks(props.chair.leftArm.blocks) +
                                0.025,
                            (-1 * props.chair.seat.depth +
                                props.chair.legs.width +
                                props.chair.tuft.radius * 2) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                    <Tuft
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,
                            thicknessOfBlocks(props.chair.leftArm.blocks) +
                                0.025,

                            (-1 * props.chair.seat.depth +
                                props.chair.legs.width +
                                props.chair.tuft.radius * 2) /
                                2,
                        ]}
                        tuft={props.chair.tuft}
                    />

                    <Tuft
                        meshRef={topLeftTuft}
                        position={[
                            (-1 * props.chair.seat.width) / 2 +
                                props.chair.legs.width / 2 +
                                0.5,

                            thicknessOfBlocks(props.chair.leftArm.blocks) +
                                thicknessOfBlocks(props.chair.back.blocks),
                            props.chair.seat.depth / 2 -
                                props.chair.back.depth / 2,
                        ]}
                        tuft={props.chair.tuft}
                    />

                    <Tuft
                        meshRef={topRightTuft}
                        position={[
                            props.chair.seat.width / 2 -
                                props.chair.legs.width / 2 -
                                0.5,

                            thicknessOfBlocks(props.chair.leftArm.blocks) +
                                thicknessOfBlocks(props.chair.back.blocks),
                            props.chair.seat.depth / 2 -
                                props.chair.back.depth / 2,
                        ]}
                        tuft={props.chair.tuft}
                    />
                </>
            )}
        </>
    )
}

function World() {
    const { scene } = useThree()

    const loader = new THREE.DataTextureLoader()
    loader.setPath("/3D/")
    // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
    const texture = loader.load("world.hdr")
    // Set the scene background property to the resulting texture.
    scene.background = texture
    return null
}

function CleanUp(props) {
    //renderer.dispose()
    const { scene } = useThree()

    const cleanMaterial = (material) => {
        material.dispose()

        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === "object" && "minFilter" in value) {
                value.dispose()
            }
        }
    }

    scene.traverse((object) => {
        if (!object.isMesh) return

        object.geometry.dispose()

        if (object.material.isMaterial) {
            cleanMaterial(object.material)
        } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material)
        }
    })

    return null
}

function ThreeDFrame(props) {
    const controls = useRef()

    const canvasRef = useRef()

    //Fix a dumb bug with the initial render
    const [s, setS] = useState(null)
    useDeepCompareEffect(() => {
        setTimeout(() => setS(Math.random()), 10)
    }, [props.object])

    return (
        <div
            ref={canvasRef}
            className={props.className ? props.className : "chair3d"}
            style={{ cursor: "grab" }}
        >
            <Canvas
                sRGB={true}
                colorManagement={true}
                camera={{ fov: 45, position: [-41.5, 25.2, -21.235] }}
                dpi={window.devicePixelRatio}
            >
                <CleanUp key={s} />
                <ambientLight intensity={0.5} />
                <spotLight
                    position={[-50, 50, -50]}
                    angle={0.5}
                    penumbra={0.5}
                    intensity={1}
                />
                <pointLight position={[100, 100, -150]} intensity={0.5} />
                {props.rotate !== true && (
                    <CameraControls
                        center={props.cameraCenter ? props.cameraCenter : null}
                    />
                )}
                <Suspense fallback={<Loading />}>
                    <PreloadLegs />
                    <Controls autoRotate={props.rotate} meshRef={controls} />
                    {props.dollyChildren && (
                        <Dolly
                            children={props.dollyChildren}
                            controls={controls}
                            canvasRef={canvasRef}
                        />
                    )}
                    {props.children}
                </Suspense>
            </Canvas>
        </div>
    )
}
export function Chair3D(props) {
    const bottomRightLeg = useRef()
    const bottomLeftLeg = useRef()

    const topLeftTuft = useRef()
    const topRightTuft = useRef()

    let chair = props.chair

    return (
        <ThreeDFrame
            dollyChildren={[
                bottomLeftLeg,
                bottomRightLeg,
                topLeftTuft,
                topRightTuft,
            ]}
            className={props.className}
            object={chair}
        >
            <SimpleChair
                chair={chair}
                bottomRightLeg={bottomRightLeg}
                bottomLeftLeg={bottomLeftLeg}
                topLeftTuft={topLeftTuft}
                topRightTuft={topRightTuft}
            />
        </ThreeDFrame>
    )
}

function Cable(props) {
    const position = props.position
    const height = useLoader(
        THREE.TextureLoader,
        "/3D/textures/MetalCable/Metal_Stainless_Steel_Cable_001_height.png"
    )
    const normals = useLoader(
        THREE.TextureLoader,
        "/3D/textures/MetalCable/Metal_Stainless_Steel_Cable_001_normal.jpg"
    )
    const colors = useLoader(
        THREE.TextureLoader,
        "/3D/textures/MetalCable/Metal_Stainless_Steel_Cable_001_basecolor.jpg"
    )
    if (height && normals && colors) {
        height.wrapS = height.wrapT = THREE.RepeatWrapping
        height.repeat.x = 1
        height.repeat.y = 200
        height.offset.x = 0
        height.offset.y = 0

        normals.wrapS = normals.wrapT = THREE.RepeatWrapping

        colors.wrapS = colors.wrapT = THREE.RepeatWrapping
    }
    return (
        <mesh
            ref={props.meshRef}
            position={[position[0], position[1], position[2]]}
            //rotation={[circle ? 0 : 1.57079633, 0, 0]}
            castShadow
            receiveShadow
        >
            <cylinderBufferGeometry
                attach="geometry"
                args={[0.05, 0.05, props.height, 32]}
            />
            {height && normals && colors && (
                <meshStandardMaterial
                    roughness={0.01}
                    attach="material"
                    color={"white"}
                    map={colors}
                    metalness={0.8}
                    normalMap={normals}
                    displacementMap={height}
                    displacementScale={0.01}
                />
            )}
        </mesh>
    )
}
function LightCamera(props) {
    useFrame((state) => {
        state.camera.zoom = 1.25
        state.camera.updateProjectionMatrix()
    })
    return null
}

function LightStage(props) {
    const effectsOn = props.lightsOn
    const defaultRotation = useMemo(() => {
        if (props.type === "verticalPendent") {
            return props.side ? [-1.5, 0, 0] : [0, 0, 0]
        }
        return props.side ? [0, 1.4, 0.2] : [0, 0, 0]
    }, [props.side, props.type])

    const width = useWidth()
    let style = {
        height: "100vh",
        maxHeight: "50vw",
        position: "fixed",
        left: props.left ? props.left : "50vw",
        top: props.top ? props.top : "125px",
        width: props.width ? props.width : "50vw",
        cursor: "grab",
    }
    if (width <= 850) {
        style = {
            height: "70vw",
            position: "fixed",
            left: props.left ? props.left : "0vw",
            top: props.top ? props.top : "3vw",
            width: props.width ? props.width : "100vw",
            cursor: "grab",
            touchAction: "none",
        }
    }

    return (
        <div
            className={props.style ? "" : "chair3d"}
            style={props.style ? props.style : style}
        >
            <Canvas
                shadows
                sRGB={true}
                colorManagement={true}
                dpi={window.devicePixelRatio}
            >
                <Suspense fallback={<Loading />}>
                    <LightCamera />
                    <color attach="background" args={["white"]} />

                    <Stage
                        shadows
                        adjustCamera
                        intensity={1}
                        environment={null}
                        preset="rembrandt"
                        makeDefault
                        scale={1}
                        contactShadow={false}
                    >
                        <PresentationControls
                            global={true} // Spin globally or by dragging the model
                            cursor={true} // Whether to toggle cursor style on drag
                            snap={true} // Snap-back to center (can also be a spring config)
                            speed={1.75} // Speed factor
                            zoom={1.75} // Zoom factor when half the polar-max is reached
                            rotation={defaultRotation} // Default rotation
                            polar={[-Math.PI / 6, Math.PI / 3]} // Vertical limits
                            azimuth={[-Math.PI / 2, Math.PI / 2]} // Horizontal limits
                            config={{ mass: 0.5, tension: 100, friction: 10 }} // Spring config
                        >
                            {props.children}
                        </PresentationControls>
                    </Stage>
                    <EffectComposer>
                        {effectsOn && (
                            <SelectiveBloom
                                key={effectsOn.current}
                                lights={props.lightRefs.current} // ⚠️ REQUIRED! all relevant lights
                                selectionLayer={1} // selection layer
                                selection={props.lightBodyRefs.current}
                                intensity={1} // The bloom intensity.
                                blurPass={undefined} // A blur pass.
                                width={Resizer.AUTO_SIZE} // render width
                                height={Resizer.AUTO_SIZE} // render height
                                kernelSize={KernelSize.MEDIUM} // blur kernel size
                                luminanceThreshold={0.2} // luminance threshold. Raise this value to mask out darker elements in the scene.
                                luminanceSmoothing={0.1} // smoothness of the luminance threshold. Range is [0, 1]
                            />
                        )}
                    </EffectComposer>
                </Suspense>
            </Canvas>
        </div>
    )
}

function LightBody(props) {
    const depthBuffer = useDepthBuffer({ frames: 1 })
    const lastSubtype = useRef("verticalPendent")
    const ref = useRef()
    console.log(props.chair)
    useEffect(() => {
        if (
            props.chair.subType === "horizontalPendent" &&
            lastSubtype.current !== "horizontalPendent"
        ) {
            ref.current.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI / 2)
        }
        if (
            props.chair.subType === "verticalPendent" &&
            lastSubtype.current !== "verticalPendent"
        ) {
            ref.current.rotateOnAxis(new THREE.Vector3(0, 0, 1), -Math.PI / 2)
        }
        lastSubtype.current = props.chair.subType
    }, [props.chair.subType])

    const newBlock = props.chair.skipRealizer
        ? props.chair.tube.blocks
        : realizeLayers(
              props.chair.tube.blocks,
              pendentConfig[props.chair.form]
          )

    return (
        <group ref={ref}>
            <Block3dSet
                width={Number(props.chair.tube.diameter)}
                position={[
                    0,
                    thicknessOfBlocks(props.chair.tube.blocks) / 2,
                    0,
                ]}
                blocks={newBlock}
                circle={true}
                depthBuffer={depthBuffer}
                lightRefs={props.lightRefs}
                lightBodyRefs={props.lightBodyRefs}
            />
            {props.chair.subType !== "verticalPendent" && (
                <Tuft
                    position={[
                        0,
                        thicknessOfBlocks(props.chair.tube.blocks) / 2 + 0.2,
                        0,
                    ]}
                    tuft={props.chair.tuft}
                    thickness={0.25}
                />
            )}
            <Tuft
                position={[
                    0,
                    props.chair.tube.blocks[0].type === "designerBoard"
                        ? thicknessOfBlocks(props.chair.tube.blocks) / 2 + 0.25
                        : thicknessOfBlocks(props.chair.tube.blocks) / 2 + 0.1,
                    0,
                ]}
                tuft={{
                    radius: props.chair.tube.blocks[0].diameter
                        ? props.chair.tube.blocks[0].diameter / 2 - 0.5
                        : props.chair.tube.diameter
                        ? props.chair.tube.diameter / 2 - 0.5
                        : 3.5,
                    color: props.chair.tuft.color,
                }}
            />
            <Tuft
                meshRef={props.bottomTuft}
                position={[
                    0,
                    -thicknessOfBlocks(props.chair.tube.blocks) / 2 + 0.05,
                    0,
                ]}
                tuft={{
                    radius: props.chair.tube.blocks[0].diameter
                        ? props.chair.tube.blocks[0].diameter / 2 - 0.5
                        : props.chair.tube.diameter
                        ? props.chair.tube.diameter / 2 - 0.5
                        : 3.5,
                    color: props.chair.tuft.color,
                }}
            />
            <Tuft
                meshRef={props.bottomTuft}
                position={[
                    0,
                    -thicknessOfBlocks(props.chair.tube.blocks) / 2 - 0.0001,
                    0,
                ]}
                tuft={props.chair.tuft}
                thickness={0.25}
            />
        </group>
    )
}

function EmbededLight({ vec = new THREE.Vector3(), ...props }) {
    const light = useRef()
    useEffect(() => {
        if (props.lightRefs) {
            props.lightRefs.current.push(light)
        }
        return () => {
            const i = props.lightRefs.current.indexOf(light)
            props.lightRefs.current.splice(i, 1)
        }
    }, [props.lightRefs])
    return (
        <pointLight
            ref={light}
            penumbra={0.25}
            distance={6}
            angle={8}
            attenuation={1}
            anglePower={4}
            {...props}
        />
    )
}

function LightCabeling(props) {
    if (props.chair.subType === "verticalPendent") {
        return (
            <>
                <Cable
                    meshRef={props.topTuft}
                    position={[
                        0,
                        thicknessOfBlocks(props.chair.tube.blocks) / 2 + 15 / 2,
                        0,
                    ]}
                    height={15}
                    width={0.1875}
                />
                <Model
                    key={"Wire"}
                    multi={1}
                    objUrl={
                        props.chair.cable.braided
                            ? "/3D/Wire.glb"
                            : "/3D/WirePlain.glb"
                    }
                    position={[
                        1,
                        thicknessOfBlocks(props.chair.tube.blocks) / 2,
                        0,
                    ]}
                    rotation={[0, 0, 0]}
                    useMaterial
                    color={props.chair.cable.color}
                />

                <Model
                    multiplier={0.0328084}
                    key={"LightCanopy"}
                    multi={1}
                    objUrl={"/3D/LightCanopy.glb"}
                    position={[
                        0,
                        thicknessOfBlocks(props.chair.tube.blocks) / 2 + 15,
                        0,
                    ]}
                    texture={props.texture}
                    color={props.chair.canopy.color}
                    metalness={0.4}
                    material={props.material}
                    rotation={[-Math.PI / 2, 0, Math.PI / 2]}
                />
                <Model
                    multiplier={0.0328084}
                    key={"CableGrapple"}
                    multi={1}
                    objUrl={"/3D/CableGrapple.glb"}
                    position={[
                        0,
                        thicknessOfBlocks(props.chair.tube.blocks) / 2 + 0.1,
                        0,
                    ]}
                    color={props.chair.tuft.color}
                    metalness={0.2}
                    rotation={[-Math.PI / 2, 0, 0]}
                />
            </>
        )
    } else {
        const ca = props.chair.altCablePosition ? 2.4 : 3
        return (
            <>
                <Cable
                    meshRef={props.topTuft}
                    position={[
                        -thicknessOfBlocks(props.chair.tube.blocks) / ca,
                        15 / 2,
                        0,
                    ]}
                    height={15}
                    width={0.1875}
                />
                <Cable
                    meshRef={props.topTuft}
                    position={[
                        thicknessOfBlocks(props.chair.tube.blocks) / ca,
                        15 / 2,
                        0,
                    ]}
                    height={15}
                    width={0.1875 / 2}
                />
                <Model
                    multiplier={0.0328084}
                    key={"LightCanopy"}
                    multi={1}
                    objUrl={"/3D/LightCanopy.glb"}
                    position={[
                        -thicknessOfBlocks(props.chair.tube.blocks) / ca,
                        15,
                        0,
                    ]}
                    texture={props.texture}
                    color={props.chair.tuft.color}
                    metalness={1}
                    material={props.material}
                    rotation={[-Math.PI / 2, 0, Math.PI / 2]}
                />
                <Model
                    key={"Wire"}
                    multi={1}
                    objUrl={
                        props.chair.cable.braided
                            ? "/3D/Wire.glb"
                            : "/3D/WirePlain.glb"
                    }
                    position={[
                        -thicknessOfBlocks(props.chair.tube.blocks) / ca + 1,
                        0,
                        0,
                    ]}
                    rotation={[0, 0, 0]}
                    useMaterial
                    color={props.chair.cable.color}
                />
            </>
        )
    }
}

export function Light3D(props) {
    let chair = props.chair
    const topTuft = useRef()
    const bottomTuft = useRef()
    const lightRefs = useRef([])
    const lightBodyRefs = useRef([])

    return (
        <div style={props.style ? {} : { paddingTop: "100px" }}>
            <LightStage
                style={props.style}
                lightsOn={chair.lightsOn}
                lightRefs={lightRefs}
                lightBodyRefs={lightBodyRefs}
                width={props.width}
                left={props.left}
                side={props.side}
                top={props.top}
                type={chair.subType}
            >
                <LightCabeling chair={chair} topTuft={topTuft} />

                <LightBody
                    lightRefs={lightRefs}
                    lightBodyRefs={lightBodyRefs}
                    bottomTuft={bottomTuft}
                    {...props}
                />
            </LightStage>
        </div>
    )
}
