import React, { useState } from "react"
import Collection from "./Collection"

import WS from "../images/exhibition/wintershow/one.jpeg"
import WS2 from "../images/exhibition/wintershow/two.jpeg"
import WS3 from "../images/exhibition/wintershow/three.jpeg"
import WS4 from "../images/exhibition/wintershow/four.jpeg"

import SA from "../images/exhibition/salon/one.jpeg"
import SA2 from "../images/exhibition/salon/two.jpeg"
import SA3 from "../images/exhibition/salon/three.jpeg"
import SA4 from "../images/exhibition/salon/four.jpeg"

import SA_2 from "../images/exhibition/salon2/one.jpg"
import SA_2_2 from "../images/exhibition/salon2/two.jpg"
import SA_2_3 from "../images/exhibition/salon2/three.jpg"
import SA_2_4 from "../images/exhibition/salon2/four.jpg"
import SA_2_5 from "../images/exhibition/salon2/five.jpg"

import FA from "../images/exhibition/faust/one.jpg"
import FA2 from "../images/exhibition/faust/two.jpg"
import FA3 from "../images/exhibition/faust/three.jpg"
import FA4 from "../images/exhibition/faust/four.jpg"
import FA5 from "../images/exhibition/faust/five.jpg"
import FA6 from "../images/exhibition/faust/six.jpg"
import FA7 from "../images/exhibition/faust/seven.jpg"
import FA8 from "../images/exhibition/faust/eight.jpg"

import MS from "../images/exhibition/mindy/one.jpg"
import MS2 from "../images/exhibition/mindy/two.jpg"
import MS3 from "../images/exhibition/mindy/three.jpg"
import MS4 from "../images/exhibition/mindy/four.jpg"
import MS5 from "../images/exhibition/mindy/five.jpg"
import MS6 from "../images/exhibition/mindy/six.jpg"

import KIKO from "../images/exhibition/kiko/one.jpeg"
import KIKO2 from "../images/exhibition/kiko/two.jpeg"
import KIKO3 from "../images/exhibition/kiko/three.jpeg"

import LOBBY from "../images/exhibition/lobby/one.jpeg"
import LOBBY2_ from "../images/exhibition/lobby/two_.jpeg"
import LOBBY3_ from "../images/exhibition/lobby/three_.jpeg"

import LOBBY4_ from "../images/exhibition/lobby/four_.jpeg"
import LOBBY5_ from "../images/exhibition/lobby/five_.jpeg"
import LOBBY6_ from "../images/exhibition/lobby/six_.jpeg"
import LOBBY7_ from "../images/exhibition/lobby/seven_.jpeg"
import LOBBY8_ from "../images/exhibition/lobby/eight_.jpeg"
import LOBBY9_ from "../images/exhibition/lobby/nine_.jpeg"
import LOBBY10_ from "../images/exhibition/lobby/ten_.jpeg"
import LOBBY11_ from "../images/exhibition/lobby/eleven_.jpeg"
import LOBBY12_ from "../images/exhibition/lobby/twelve_.jpeg"
import LOBBY13_ from "../images/exhibition/lobby/thirteen_.jpeg"

import styles from "../css/home/collection.module.css"

const collectionItems = [
    {
        key: "lobby",
        icon: false,
        name: "STACKABL x The Lobby via DesignTO",
        image: LOBBY,
        images: [
            LOBBY2_,
            LOBBY3_,
            LOBBY4_,
            LOBBY5_,
            LOBBY6_,
            LOBBY7_,
            LOBBY8_,
            LOBBY9_,
            LOBBY10_,
            LOBBY11_,
            LOBBY12_,
            LOBBY13_,
        ],
        showPrefix: false,
        removePOS: true,

        type: (
            <>
                The Lobby, Toronto <br />
                January 20 – March 24, 2023
            </>
        ),
        blurb: (
            <>
                STACKABL celebrated its fourth product reveal – the
                inhouse-configured Arcilla pendant collection – at Toronto’s The
                Lobby by Heaps Estrin. Hosted by The Lobby, the exclusive wine +
                cheese event culminated in a salon-style speaking engagement
                between STACKABL founder, Jeff Forrest, and interior designer,
                Tommy Smythe. The two discussed Forrest’s journey as a designer,
                the studio’s process, and two topics near and dear to the
                founder: the circular economy and collectible design. The new
                pendants, glowing in The Lobby’s Yonge Street–facing storefront,
                remain on view 24/7 until March 24, 2023.
            </>
        ),
    },
    {
        key: "kiko",
        icon: false,
        name: "Kiko Lopez: Smoke and Mirrors",
        image: KIKO,
        images: [KIKO2, KIKO3],
        showPrefix: false,
        removePOS: true,

        type: (
            <>
                New Works in Glass and Crystal <br />
                Maison Gerard, New York City <br />
                October 12 – November 15, 2022
            </>
        ),
        blurb: (
            <>
                Kiko Lopez is a Miami-based, Puerto Rican artist. One of the few
                artisans working today in the 18th century technique of
                hand-silvering, Lopez transforms crystal and glass into
                timeless, contemporary art. For the last 12 years he has focused
                on fabricating tableau mirrors—or what he calls “shadow
                drawings”—manipulating applications of watery, silver mixtures
                of glass to create reflective surfaces rich with gradations of
                shadow, color, and patina-like impressions. STACKABL is honoured
                to have been selected to show its Madame Chaise amongst the work
                of such a celebrated artist.
            </>
        ),
    },
    {
        key: "salon2",
        icon: false,
        name: "Salon Art + Design via Maison Gerard",
        image: SA_2,
        images: [SA_2_2, SA_2_5, SA_2_3],
        showPrefix: false,
        removePOS: true,

        type: (
            <>
                10th Anniversary Edition <br />
                Park Avenue Armory, New York City <br />
                November 10 – 14, 2022
            </>
        ),
        blurb: (
            <>
                <a href="https://www.thesalonny.com">Salon Art + Design</a>,
                presents the world’s best design – vintage, modern and
                contemporary – enhanced by blue-chip 20th century art and
                features leading art and design galleries from all over the
                world, spotlighting the trends of collectible design.
                <br />
                <br /> The Salon’s inclusivity and willingness to consider both
                fine and decorative art in the context of contemporary life is
                predicated on the belief that today, more than ever, designers
                and collectors create environments rather than collect objects.
                The success of the Salon lies in the quality of its exhibiting
                galleries, the extremely international flavor of the material,
                and an eclecticism that is highly sought by today’s collectors
                and tastemakers. <br />
                <br />
                <br /> SSTACKABL was honoured to have been selected by its
                gallery partner, Maison Gerard, to showcase its Hombre pendant
                collection, configured by Canadian lighting designer,{" "}
                <a href="https://www.anthonyfrankkeeler.com">
                    Anthony Frank Keeler.
                </a>
            </>
        ),
        showPrefix: false,
    },
    {
        key: "mindy",
        icon: false,
        name: "Composition & Layout: Contemporary Design And Objects via Mindy Solomon Gallery",
        image: MS,
        images: [MS2, MS3, MS4, MS5, MS6],
        showPrefix: false,
        removePOS: true,
        type: (
            <>
                Mindy Solomon Gallery
                <br />
                Miami, Florida
                <br />
                September 17 – October 22, 2022
            </>
        ),
        blurb: (
            <div>
                “Composition &amp; Layout: Contemporary Design &amp; Objects”
                highlights a selection of furniture, lighting, textiles, and
                objects culled from 28 artisans and designers from across North
                America, Latin America, Europe, Asia, New Zealand, and
                Australia. With a focus on the handmade, viewers will be able to
                experience a tactile exploration of form and function from a
                gallerist’s perspective.
                <br />
                <br />
                At this show, the studio launched its Stackabl x Mindy Solomon
                lighting collection. Made up of eight unique designs, these new
                pendants nod to a time and place: America’s roaring 1920s,
                exuberant ‘40s, and their Art Deco backdrops. The aesthetic
                movement was about craftsmanship and rich materials, as well as
                bold geometries, sun-saturated colours, and linear details – all
                which Miami Beach continues to embrace.
                <br />
                <br />
                The pendants salute Miami Beach’s vitality, which is especially
                noted in its hospitality landmarks. Named for iconic Art Deco
                hotels, each design captures their spirit with bold blocks of 60
                per cent recycled PET board, Merino wool offcuts, and an
                intermittent pattern of recessed LED lights. Clusters are
                available, too, including the show-stopping Baer. Made up of
                five pendants, this medley honours the late Barbara Baer
                Capitman, the community activist and author who led the effort
                to preserve Miami Beach's historic Art Deco district in the
                1970s. The pendants are available for purchase through{" "}
                <a href="https://mindysolomon.com/">Mindy Solomon</a> and{" "}
                <a href="https://www.stackabl.shop/collections">Stackabl</a>.
                <br />
                <br />
                Photos by: Zachary Balber
            </div>
        ),
    },
    {
        key: "faust",
        icon: false,
        name: "Faust Exhibition",
        image: FA2,
        images: [FA, FA3, FA4, FA5, FA6, FA7, FA8],
        showPrefix: false,
        removePOS: true,
        type: (
            <>
                Nike 21 Mercer
                <br />
                21 Mercer Street
                <br />
                New York, New York
                <br />
                August 27 -- September 31, 2022
            </>
        ),
        blurb: (
            <div>
                <a href="https://www.faustnewyork.com/">FAUST</a> is a New
                York-based artist. Initially receiving recognition for his
                public art, the formal qualities of FAUST's murals explore scale
                and placement to challenge how viewers perceive graffiti. The
                poise and artistry of FAUST's hand style elevates a gesture
                deriving from vandalism to a masterful calligraphic work of art,
                transforming any surface it adorns.
                <br />
                <br />
                Breaking with conventions, tags that were more common on a
                street corner are amplified to an architectural scale. The
                poignant and site-specific words that comprise each mural engage
                in public discourse and resonate with the community - whether
                it's on his hometown streets of Manhattan, or internationally -
                from Moscow to Medellin.
                <br />
                <br />
                FAUST's work has appealed to audiences far beyond the street,
                garnering five Type Directors Club awards and the prestigious
                Young Guns Award from the Art Directors Club. Clients range from
                Nike to Tiffany &amp; Co., a testament to his work transcending
                demographics, resonating with authenticity and style.
                <br />
                <br />
                Stackabl was pleased to be selected to help stage 21 Mercer
                Street, New York City, where FAUST released his limited-edition
                Nike x Faust SB Dunk High-top shoe. There, the studio showed
                three of its new{" "}
                <a href="https://www.stackabl.shop/lightcollection?hombrel">
                    Hombre pendants{" "}
                </a>
                to a discerning crowd of collectors. <br />
            </div>
        ),
    },
    {
        key: "wintershow",
        icon: false,
        name: "The Winter Show",
        image: WS,
        images: [WS2, WS3, WS4],
        showPrefix: false,
        removePOS: true,

        type: (
            <>
                660 Madison Avenue at 61st Street <br />
                New York, New York <br />
                April 1 – 10, 2022
            </>
        ),
        blurb: (
            <>
                <a href="https://www.thewintershow.org">The Winter Show</a> is
                the leading art, antiques, and design fair in America, featuring
                many of the world's top experts in the fine and decorative arts.
                “The Fair” was established in the mid-1950s as a benefit for
                East Side House Settlement and, by the end of that decade, had
                firmly established itself as the leading event of its kind in
                the United States.
                <br />
                <br /> Over the course of its 68-year history, the Fair has been
                held at the historic Park Avenue Armory for all but three years.
                Following the postponement, the Show presented a
                once-in-a-lifetime special edition – The Winter Show 2022 in
                Spring. Sixty-plus international dealers showcased a dynamic
                selection of works spanning over 5,000 years of fine art,
                decorative objects, and jewelry from antiquity through the
                present day. Each object was reviewed by a vetting committee,
                comprised of experts from the United States and Europe.
                <br />
                <br /> Stackabl was honoured to not only show its inaugural
                furniture collection inside the Armory alongside gallery partner
                <a href="https://www.maisongerard.com">Maison Gerard</a>, but to
                have been hand-selected by interior designer{" "}
                <a href="http://www.younghuh.com">Young Huh</a> to dress the
                windows of the former Barney's flagship, promoting the event.{" "}
            </>
        ),
    },
    {
        key: "salon",
        icon: false,
        name: "Salon Art + Design via Maison Gerard",
        image: SA,
        images: [SA2, SA3, SA4],
        showPrefix: false,
        removePOS: true,

        type: (
            <>
                10th Anniversary Edition <br />
                Park Avenue Armory, New York City <br />
                November 11 – 15, 2021
            </>
        ),
        blurb: (
            <>
                <a href="https://www.thesalonny.com">Salon Art + Design</a>,
                produced by Sanford L. Smith + Associates, return for its 10th
                Anniversary to the Park Avenue Armory in New York City.
                Presenting the world's best design - vintage, modern and
                contemporary - enhanced by blue-chip 20th century art, the Salon
                featured leading art and design galleries from all over the
                world, spotlighting the trends of collectible design.
                <br />
                <br /> The Salon's inclusivity and willingness to consider both
                fine and decorative art in the context of contemporary life is
                predicated on the belief that today, more than ever, designers
                and collectors create environments rather than collect objects.
                The success of the Salon lies in the quality of its exhibiting
                galleries, the extremely international flavor of the material,
                and an eclecticism that is highly sought by today's collectors
                and tastemakers. <br />
                <br />
                Appealing to seasoned and young collectors alike, Salon offers
                an extensive yet curated range of pieces, ensuring something for
                everyone. The Salon is a vetted fair and the only international
                fair of this caliber to combine styles, genres, and periods
                cutting a universal and timeless swath.
                <br />
                <br /> Stackabl was honoured to present its inaugural furniture
                collection alongside its gallery partner,
                <a href="https://www.maisongerard.com">Maison Gerard</a>. Six
                seating options were showcased, designed by{" "}
                <a href="https://www.champalimaud.design">
                    Champalimaud Design
                </a>
                , <a href="https://laurakirar.com">Laura Kirar</a>,{" "}
                <a href="https://www.framptonco.com">Frampton Co</a>,{" "}
                <a href="https://gma.nyc">Georgis &amp; Mirgorodsky</a>,
                <a href="https://www.drakeanderson.com">Drake/Anderson</a>, and{" "}
                <a href="https://www.maisongerard.com/collection/benoist-f-drut-with-stackabl">
                    Benoist F. Drut.
                </a>
            </>
        ),
        showPrefix: false,
    },
]

function expandOption(option) {
    if (!option) {
        return null
    }
    const options = [option]
    for (const o of option.images) {
        options.push({ ...option, image: o })
    }
    return options
}

export default function Exhibitions(props) {
    return (
        <Collection
            baseUrl={"/exhibitions"}
            items={collectionItems}
            onClickCarasol={(item) => {
                return expandOption(item)
            }}
        />
    )
}
