import { v4 as uuidv4 } from "uuid"

let is = {
    cart: [],
}

if (window.sessionStorage.getItem("cart")) {
    is = JSON.parse(window.sessionStorage.getItem("cart"))
}

export const initialState = {
    ...is,
}

export function reducer(state, action) {
    const cart = [...state.cart.cart]

    switch (action.type) {
        case "CART_INITIALIZE":
            const r = {
                ...state,
                cart: { cart: [] },
            }
            window.sessionStorage.setItem("cart", JSON.stringify(r.cart))
            return r
        case "CART_ADD":
            cart.push({ ...action.payload, key: uuidv4() })
            const rval = {
                ...state,
                cart: { cart: cart },
            }
            window.sessionStorage.setItem("cart", JSON.stringify(rval.cart))
            return rval
        case "CART_REMOVE":
            const i = cart.findIndex((e) => e.key === action.payload.key)

            cart.splice(i, 1)
            const rval2 = {
                ...state,
                cart: { cart: cart },
            }
            window.sessionStorage.setItem("cart", JSON.stringify(rval2.cart))
            return rval2
        case "CART_UPDATE":
            const i2 = cart.findIndex(
                (e) => e.chair.key === action.payload.chair.key
            )

            cart[i2].qty = action.payload.qty
            const rval3 = {
                ...state,
                cart: { cart: cart },
            }
            window.sessionStorage.setItem("cart", JSON.stringify(rval3.cart))
            return rval3

        default:
            return state
    }
}
