import React, { useState, useEffect } from "react"
import {
    Top,
    Bottom,
    Side,
    Front,
} from "../components/illustrations/Illustrations"
import { useGlobalState } from "../state/index"

import Carousel, { Dots } from "@brainhubeu/react-carousel"
import { Chair3D } from "../components/illustrations/SideChair3D"

import "@brainhubeu/react-carousel/lib/style.css"
import "../css/neos/carousel.css"

export default function ChairMultiView(props) {
    const state = useGlobalState()
    const chair = state.state.progress.chair

    const [value, _setValue] = useState(0)
    const [auto, setAuto] = useState(true)

    const [slides, setSlides] = useState([])

    function setValue(v) {
        if (v === 4) {
            setAuto(false)
        } else {
            setAuto(true)
        }
        _setValue(v)
    }

    useEffect(() => {
        const func = (e) => {
            if (auto) {
                if (e.detail === "top") {
                    setValue(3)
                }
                if (e.detail === "front") {
                    setValue(0)
                }
                if (e.detail === "side") {
                    setValue(1)
                }
                if (e.detail === "right") {
                    setValue(1)
                }
                if (e.detail === "left") {
                    setValue(2)
                }
                if (e.detail === "default") {
                    if (chair.type === "dayBed") {
                        setValue(1)
                    } else {
                        setValue(0)
                    }
                }
            }
        }
        window.addEventListener("viewChairSide", func, false)

        return () => {
            window.removeEventListener("viewChairSide", func, false)
        }
    }, [auto])

    useEffect(() => {
        if (chair.type === "dayBed") {
            setValue(1)
        }
    }, [chair.type])

    useEffect(() => {
        const sides = [
            <div id="mainfrontview" className="planview">
                <h4>Front</h4>
                <Front vw={props.width} type={chair.type} chair={chair}></Front>
            </div>,
            <div className="planview">
                <h4>Right Side</h4>
                <Side vw={props.width} type={chair.type} chair={chair}></Side>
            </div>,
            <div className="planview">
                <h4>Left Side</h4>
                <Side
                    right
                    vw={props.width}
                    type={chair.type}
                    chair={chair}
                ></Side>
            </div>,
            <div className="planview">
                <h4>Top</h4>
                <Top vw={props.width} type={chair.type} chair={chair}></Top>
            </div>,
        ]

        sides.push(
            <div id="main3dview" className="planview ">
                <h4>3D</h4>
                <Chair3D chair={state.state.progress.chair} />
            </div>
        )

        setSlides(sides)
    }, [chair, props.width, state.state.progress.chair])

    return (
        <div>
            <Carousel
                draggable={false}
                value={value}
                slides={slides}
                onChange={setValue}
            />

            <div style={{ margin: "auto", width: "100%", textAlign: "center" }}>
                <>
                    <button
                        className={value < 4 ? "selected" : ""}
                        onClick={() => {
                            setValue(0)
                        }}
                    >
                        2D
                    </button>
                    <button
                        onClick={() => {
                            setValue(4)
                        }}
                        className={value === 4 ? "selected" : ""}
                    >
                        3D
                    </button>
                </>

                <Dots
                    value={value}
                    onChange={setValue}
                    number={slides.length}
                />
            </div>
        </div>
    )
}
