import React from "react"
import { useGlobalState } from "../state/index"

import Type1 from "../images/legs/type1.svg"
import Type2 from "../images/legs/type2.svg"
import Type3 from "../images/legs/type3.svg"
import Type4 from "../images/legs/type4.svg"
import Fatty from "../images/legs/fatty.svg"

import Type1_4in from "../images/legs/type1_4in.svg"
import Type2_4in from "../images/legs/type2_4in.svg"
import Type3_4in from "../images/legs/type3_4in.svg"
import Type4_4in from "../images/legs/type4_4in.svg"

import classNames from "classnames"
import { UrlColorSelector } from "../components/SimpleColorSelector"

import { cloneDeep } from "lodash"

function LegsToggle(props) {
    const images =
        props.width === 4
            ? [Type1_4in, Type2_4in, Type3_4in, Type4_4in]
            : [Type1, Type2, Type3, Type4]

    const width = props.width === 4 ? "49%" : "80%"
    return (
        <div className="toggle-platform">
            <div
                onClick={() => {
                    props.setType(1)
                }}
                className={classNames(
                    "toggle",
                    "clickable",
                    "quarter",
                    "tall",
                    {
                        selected: props.type === 1,
                    }
                )}
            >
                <span className="image">
                    <img
                        style={{ width: width }}
                        alt="Type 1"
                        src={images[0]}
                    />
                </span>
            </div>
            <div
                onClick={() => {
                    props.setType(2)
                }}
                className={classNames(
                    "toggle",
                    "clickable",
                    "quarter",
                    "tall",
                    {
                        selected: props.type === 2,
                    }
                )}
            >
                <span className="image">
                    <img
                        style={{ width: width }}
                        alt="Type 2"
                        src={images[1]}
                    />
                </span>
            </div>
            <div
                onClick={() => {
                    props.setType(3)
                }}
                className={classNames(
                    "toggle",
                    "clickable",
                    "quarter",
                    "tall",
                    {
                        selected: props.type === 3,
                    }
                )}
            >
                <span className="image">
                    <img
                        style={{ width: width }}
                        alt="Type 3"
                        src={images[2]}
                    />
                </span>
            </div>
            <div
                onClick={() => {
                    props.setType(4)
                }}
                className={classNames(
                    "toggle",
                    "clickable",
                    "quarter",
                    "tall",
                    {
                        selected: props.type === 4,
                    }
                )}
            >
                <span className="image">
                    <img
                        style={{ width: width }}
                        alt="Type 4"
                        src={images[3]}
                    />
                </span>
            </div>

            {props.supportsFatty && (
                <>
                    <div
                        onClick={() => {
                            props.setType(5)
                        }}
                        className={classNames(
                            "toggle",
                            "clickable",
                            "quarter",
                            "tall",
                            "alone",
                            {
                                selected: props.type === 5,
                            }
                        )}
                    >
                        <span className="image">
                            <img
                                style={{ width: "100%" }}
                                alt="Fatty"
                                src={Fatty}
                            />
                        </span>
                    </div>
                    <div className="toggle tall quarter blank "></div>
                    <div className="toggle  tall quarter blank"></div>
                    <div className="toggle  tall quarter blank"></div>
                </>
            )}
        </div>
    )
}

function setType(chair, dispatch, pos, type) {
    const c = cloneDeep(chair)

    c.legs.legTypes[pos] = type

    dispatch({
        type: "CHAIR",
        supressEvent: true,
        payload: {
            chair: c,
        },
    })
}

function LegColors(props) {
    const legs = props.legs
    const d = ["wood_black", "wood_mahogany", "wood_natural"]
    return (
        <div className="simple-color-selector toggle-platform">
            {d.map((value, index) => {
                const img = value
                return (
                    <div>
                        <div
                            key={index}
                            className={classNames(
                                "toggle compact simple-color rounded",
                                {
                                    selected: img === legs.color,
                                }
                            )}
                        >
                            <img
                                alt={img}
                                src={"/leg_colors/" + img + ".jpg"}
                                className="inside-color"
                                onClick={() => props.onClick(img)}
                            ></img>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default function WoodLegs(props) {
    const state = useGlobalState()
    const legTypes = state.state.progress.chair.legs.legTypes
    const chair = state.state.progress.chair
    let fattyLegAllowed = chair.legs.fattyLegAllowed
    if (chair.legs.width !== 4) {
        fattyLegAllowed = null
    }

    if (state.state.progress.chair.legs.numLegs === 2) {
        return (
            <>
                <h4>Wood colors</h4>
                <LegColors
                    onClick={(color) => {
                        const c = cloneDeep(state.state.progress.chair)

                        c.legs.color = color

                        state.dispatch({
                            type: "CHAIR",
                            supressEvent: true,
                            payload: {
                                chair: c,
                            },
                        })
                    }}
                    legs={state.state.progress.chair.legs}
                />
            </>
        )
    }
    return (
        <div>
            <h4>Wood colors</h4>
            <LegColors
                onClick={(color) => {
                    const c = cloneDeep(state.state.progress.chair)

                    c.legs.color = color

                    state.dispatch({
                        type: "CHAIR",
                        supressEvent: true,
                        payload: {
                            chair: c,
                        },
                    })
                }}
                legs={state.state.progress.chair.legs}
            />
            <h4>
                {state.state.progress.chair.legs.numLegs === 2
                    ? "Left"
                    : "Left Front"}
            </h4>

            <LegsToggle
                width={chair.legs.width}
                type={legTypes[0]}
                setType={(t) => {
                    window.dispatchEvent(
                        new CustomEvent("viewChairSide", {
                            detail: "left",
                        })
                    )
                    setType(chair, state.dispatch, 0, t)
                }}
                supportsFatty={fattyLegAllowed ? fattyLegAllowed[0] : false}
            />
            <h4>
                {state.state.progress.chair.legs.numLegs === 2
                    ? "Right"
                    : "Left Rear"}
            </h4>
            <LegsToggle
                width={chair.legs.width}
                type={legTypes[1]}
                setType={(t) => {
                    setType(chair, state.dispatch, 1, t)
                    window.dispatchEvent(
                        new CustomEvent("viewChairSide", {
                            detail: "left",
                        })
                    )
                }}
                supportsFatty={fattyLegAllowed ? fattyLegAllowed[1] : false}
            />

            {state.state.progress.chair.legs.numLegs === 6 && (
                <>
                    <h4>Center Front</h4>
                    <LegsToggle
                        width={chair.legs.width}
                        type={legTypes[4]}
                        setType={(t) => {
                            setType(chair, state.dispatch, 4, t)
                            if (state.state.progress.chair.type == "dayBed") {
                                window.dispatchEvent(
                                    new CustomEvent("viewChairSide", {
                                        detail: "left",
                                    })
                                )
                            }
                        }}
                        supportsFatty={
                            fattyLegAllowed ? fattyLegAllowed[4] : false
                        }
                    />
                    <h4>Center Rear</h4>
                    <LegsToggle
                        width={chair.legs.width}
                        type={legTypes[5]}
                        setType={(t) => {
                            setType(chair, state.dispatch, 5, t)
                            if (state.state.progress.chair.type == "dayBed") {
                                window.dispatchEvent(
                                    new CustomEvent("viewChairSide", {
                                        detail: "right",
                                    })
                                )
                            }
                        }}
                        supportsFatty={
                            fattyLegAllowed ? fattyLegAllowed[5] : false
                        }
                    />
                </>
            )}
            {state.state.progress.chair.legs.numLegs !== 2 && (
                <>
                    <h4>Right Front</h4>
                    <LegsToggle
                        width={chair.legs.width}
                        type={legTypes[2]}
                        setType={(t) => {
                            setType(chair, state.dispatch, 2, t)
                            window.dispatchEvent(
                                new CustomEvent("viewChairSide", {
                                    detail: "right",
                                })
                            )
                        }}
                        supportsFatty={
                            fattyLegAllowed ? fattyLegAllowed[2] : false
                        }
                    />
                    <h4>Right Rear</h4>
                    <LegsToggle
                        width={chair.legs.width}
                        type={legTypes[3]}
                        setType={(t) => {
                            setType(chair, state.dispatch, 3, t)
                            window.dispatchEvent(
                                new CustomEvent("viewChairSide", {
                                    detail: "right",
                                })
                            )
                        }}
                        supportsFatty={
                            fattyLegAllowed ? fattyLegAllowed[3] : false
                        }
                    />
                </>
            )}
        </div>
    )
}
