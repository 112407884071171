import React, { useState } from "react"

import UknownImage from "../images/unknownChair.jpg"

import A585 from "../images/productImages/lightssl/585_1.jpg"
import A585_2 from "../images/productImages/lightssl/585_2.jpg"
import A585_3 from "../images/productImages/lightssl/585_3.jpg"
import A585_4 from "../images/productImages/lightssl/585_4.jpg"
import A585_5 from "../images/productImages/lightssl/585_5.jpg"
import A585_6 from "../images/productImages/lightssl/585_6.jpg"
import A585_7 from "../images/productImages/lightssl/585_7.jpg"
import A585_8 from "../images/productImages/lightssl/585_8.jpg"

import A858_1 from "../images/productImages/lightssl/858_1.jpg"
import A858_2 from "../images/productImages/lightssl/858_2.jpg"
import A858_3 from "../images/productImages/lightssl/858_3.jpg"
import A858_4 from "../images/productImages/lightssl/858_4.jpg"
import A858_5 from "../images/productImages/lightssl/858_5.jpg"
import A858_6 from "../images/productImages/lightssl/858_6.jpg"
import A858_7 from "../images/productImages/lightssl/858_7.jpg"

import A8_1 from "../images/productImages/lightssl/8_1.jpg"
import A8_2 from "../images/productImages/lightssl/8_2.jpg"
import A8_3 from "../images/productImages/lightssl/8_3.jpg"
import A8_4 from "../images/productImages/lightssl/8_4.jpg"

import A5_1 from "../images/productImages/lightssl/5_1.jpg"
import A5_2 from "../images/productImages/lightssl/5_2.jpg"
import A5_3 from "../images/productImages/lightssl/5_3.jpg"
import A5_4 from "../images/productImages/lightssl/5_4.jpg"
import A5_5 from "../images/productImages/lightssl/5_5.jpg"
import A5_6 from "../images/productImages/lightssl/5_6.jpg"
import A5_7 from "../images/productImages/lightssl/5_7.jpg"

import AC_1 from "../images/productImages/lightssl/AC_1.jpg"
import AC_2 from "../images/productImages/lightssl/AC_2.jpg"
import AC_3 from "../images/productImages/lightssl/AC_3.jpg"
import AC_4 from "../images/productImages/lightssl/AC_4.jpg"
import AC_5 from "../images/productImages/lightssl/AC_5.jpg"
import AC_6 from "../images/productImages/lightssl/AC_6.jpg"
import AC_7 from "../images/productImages/lightssl/AC_7.jpg"
import AC_8 from "../images/productImages/lightssl/AC_8.jpg"

import Collection, { CollectionCarasol } from "./Collection"

const collectionItems = [
    {
        key: "arcilla585",
        icon: "585",
        name: "Arcilla | 585",
        unit: "Arcilla_585",
        image: A585,
        images: [A585_2, A585_3, A585_4, A585_5],
        type: "585",
        configured: "Somewhere in Texas | 3:17 pm on December 13, 2022 ",
        designed: "STACKABL",
        blurb: `Stackabl’s third pendant collection – available individually 
        and in clusters – was inspired during a pilgrimage taken by the design studio’s founder, 
        Jeff Forrest. Escaping Toronto’s dreary 2022 winter weather,
         Forrest took a road trip southwest to Texas, seeing works by some of his art and architecture heroes,
          including Donald Judd, Louis Kahn, and Richard Serra, as he went. An inspiring expedition,
           it was the rich palette of the landscape he passed through that impressed upon him the most. These luminaires
        – in vivacious clay, earthy oat, and charcoal tones – are sure to transport you somewhere soothing, 
        from the comfort of your own homes.” `,
        tear: "/tear/a585.pdf",
        autoPrice: false,
        price: "USD$2,408.60",

        dimensions: (
            <>
                Height 22” Diameter: 8” <br />
                <br />
                Layers
                <br />
                WollWeiB: 68 <br />
                Oat: 22 <br />
                <br />
                <br />
                Light
                <br />
                8" dia light: 2<br />
                Light temp: 3000K
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: Off-White <br />
                Hanging hardware: Stainless steel
                <br />
                Cable length: Varies
            </>
        ),
    },

    {
        key: "arcilla585c",
        icon: "585",
        name: "Arcilla | 585 Cluster",
        unit: "Arcilla_585c",
        image: A585_7,
        images: [A585_6, A585_7, A585_8, A585_2],
        /*chair: {
            org: "SL",
            unit: "Arcilla_585c",
            form: "5",
            skipRealizer: true,
            cartImage: A585_7,
            subType: "Arcilla_585c",
            type: "basic",
            infos: {
                Height: '22"',
                Diameter: '8"',
            },
        },*/

        type: "585",
        configured: "Somewhere in Texas | 3:17 pm on December 13, 2022 ",
        designed: "STACKABL",
        blurb: `Stackabl’s third pendant collection – available individually 
        and in clusters – was inspired during a pilgrimage taken by the design studio’s founder, 
        Jeff Forrest. Escaping Toronto’s dreary 2022 winter weather,
         Forrest took a road trip southwest to Texas, seeing works by some of his art and architecture heroes,
          including Donald Judd, Louis Kahn, and Richard Serra, as he went. An inspiring expedition,
           it was the rich palette of the landscape he passed through that impressed upon him the most. These luminaires
        – in vivacious clay, earthy oat, and charcoal tones – are sure to transport you somewhere soothing, 
        from the comfort of your own homes.`,
        tear: "/tear/a585c.pdf",
        autoPrice: false,
        price: "USD$7,225.81",
        removePOS: false,
        dimensions: (
            <>
                Height 22” Diameter: 8” <br />
                <br />
                Layers
                <br />
                WollWeiB: 68 <br />
                Oat: 22 <br />
                <br />
                <br />
                Light
                <br />
                8" dia light: 2<br />
                Light temp: 3000K
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: Off-White <br />
                Hanging hardware: Stainless steel
                <br />
                Cable length: Varies
            </>
        ),
    },
    {
        key: "arcilla858",
        icon: "858",
        name: "Arcilla | 858",
        unit: "Arcilla_858",
        image: A858_1,
        images: [A858_2, A858_3, A858_4, A858_5, A858_6, A858_7],
        type: "858",
        configured: "Somewhere in Texas | 7:52 pm on December 14, 2022 ",
        designed: "STACKABL",
        blurb: `Stackabl’s third pendant collection – available individually 
        and in clusters – was inspired during a pilgrimage taken by the design studio’s founder, 
        Jeff Forrest. Escaping Toronto’s dreary 2022 winter weather,
         Forrest took a road trip southwest to Texas, seeing works by some of his art and architecture heroes,
          including Donald Judd, Louis Kahn, and Richard Serra, as he went. An inspiring expedition,
           it was the rich palette of the landscape he passed through that impressed upon him the most. These luminaires
        – in vivacious clay, earthy oat, and charcoal tones – are sure to transport you somewhere soothing, 
        from the comfort of your own homes. `,
        tear: "/tear/a858.pdf",
        autoPrice: false,
        price: "USD$4,243.44",

        dimensions: (
            <>
                Height 36” Diameter: 8” <br />
                <br />
                Layers
                <br />
                Felt: Weib: 175
                <br />
                PET: N/A <br />
                <br />
                <br />
                Light
                <br />
                8" dia light: 4<br />
                5” dia: 2 <br />
                Light temp: 3000K
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: Off-White <br />
                Hanging hardware: Stainless steel
                <br />
                Cable length: Varies
            </>
        ),
    },

    {
        key: "arcilla8",
        icon: "8",
        name: "Arcilla | 8",
        unit: "Arcilla_8",
        image: A8_1,
        images: [A8_2, A8_3, A8_4],
        type: "8",
        configured: "Somewhere in Texas | 7:52 pm on December 14, 2022 ",
        designed: "STACKABL",
        blurb: `Stackabl’s third pendant collection – available individually 
        and in clusters – was inspired during a pilgrimage taken by the design studio’s founder, 
        Jeff Forrest. Escaping Toronto’s dreary 2022 winter weather,
         Forrest took a road trip southwest to Texas, seeing works by some of his art and architecture heroes,
          including Donald Judd, Louis Kahn, and Richard Serra, as he went. An inspiring expedition,
           it was the rich palette of the landscape he passed through that impressed upon him the most. These luminaires
        – in vivacious clay, earthy oat, and charcoal tones – are sure to transport you somewhere soothing, 
        from the comfort of your own homes. `,
        tear: "/tear/a8.pdf",
        autoPrice: false,
        price: "USD$2,004.24",

        dimensions: (
            <>
                Height 18” Diameter: 8” <br />
                <br />
                Layers
                <br />
                Felt: N/A
                <br />
                PET: Salmon: 27 <br /> Oat: 16
                <br />
                <br />
                <br />
                Light
                <br />
                8" dia light: 1<br />
                <br />
                Light temp: 3000K
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: Off-White <br />
                Hanging hardware: Stainless steel
                <br />
                Cable length: Varies
            </>
        ),
    },
    {
        key: "arcilla5",
        icon: "5",
        name: "Arcilla | 5",
        unit: "Arcilla_5",
        image: A5_6,
        images: [A5_2, A5_3, A5_4, A5_5, A5_1, A5_7],
        type: "5",
        configured: "Somewhere in Texas | 9:08 am on December 15, 2022  ",
        designed: "STACKABL",
        blurb: `Stackabl’s third pendant collection – available individually 
        and in clusters – was inspired during a pilgrimage taken by the design studio’s founder, 
        Jeff Forrest. Escaping Toronto’s dreary 2022 winter weather,
         Forrest took a road trip southwest to Texas, seeing works by some of his art and architecture heroes,
          including Donald Judd, Louis Kahn, and Richard Serra, as he went. An inspiring expedition,
           it was the rich palette of the landscape he passed through that impressed upon him the most. These luminaires
        – in vivacious clay, earthy oat, and charcoal tones – are sure to transport you somewhere soothing, 
        from the comfort of your own homes. `,
        tear: "/tear/a5.pdf",
        autoPrice: false,
        price: "USD$3,598.25",

        dimensions: (
            <>
                Length 60” Diameter: 5” <br />
                <br />
                Layers
                <br />
                Felt: N/A
                <br />
                PET: Salmon: 57 <br /> Oat: 36
                <br />
                <br />
                <br />
                Light
                <br />
                5" dia light: 4<br />
                <br />
                Light temp: 3000K
                <br />
                <br />
                Parts <br />
                Canopy color: White
                <br /> Wire color: Off-White <br />
                Hanging hardware: Stainless steel
                <br />
                Cable length: Varies
            </>
        ),
    },
    {
        key: "arcilla_cluster",
        icon: "5",
        name: "Arcilla | Cluster",
        unit: "Arcilla_Cluster",
        image: AC_5,
        images: [AC_4, AC_2, AC_3, AC_6, AC_7, AC_8],
        // type: "5",
        configured: "Somewhere in Texas | 8:37 pm on December 16, 2022",
        designed: "STACKABL",
        blurb: `Stackabl’s third pendant collection – available individually 
        and in clusters – was inspired during a pilgrimage taken by the design studio’s founder, 
        Jeff Forrest. Escaping Toronto’s dreary 2022 winter weather,
         Forrest took a road trip southwest to Texas, seeing works by some of his art and architecture heroes,
          including Donald Judd, Louis Kahn, and Richard Serra, as he went. An inspiring expedition,
           it was the rich palette of the landscape he passed through that impressed upon him the most. These luminaires
        – in vivacious clay, earthy oat, and charcoal tones – are sure to transport you somewhere soothing, 
        from the comfort of your own homes. `,
        tear: "/tear/ac.pdf",
        autoPrice: false,
        price: "USD$9,845.94",

        dimensions: (
            <>
                Arcilla | 5:
                <br />
                Dimensions
                <br />
                Length: 60” 
                <br />
                Diameter: 5”
                <br />
                <br />
                Layers
                <br />
                Felt: Weib: 110
                <br />
                <br />
                PET: <br />
                Salmon: 57 <br />
                Oat: 36
                <br />
                <br />
                Lights <br />
                5” dia: 4<br />
                Light temp: 3000K
                <br />
                <br />
                Parts
                <br />
                Metal finish: Bronze
                <br />
                Power Cable: Off white
                <br />
                Canopy: White
                <br />
                <br />
                Arcilla | 8<br />
                Dimensions
                <br />
                Height: 18” 
                <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                Felt: N/A
                <br />
                PET: <br />
                Salmon: 27 <br />
                Oat: 16
                <br />
                <br />
                Lights
                <br />
                8” dia: 1<br />
                Light temp: 3000K
                <br />
                <br />
                Parts
                <br />
                Metal finish: Bronze
                <br />
                Power Cable: off white
                <br />
                Canopy: White
                <br />
                <br />
                Arcilla | 858
                <br />
                Dimensions
                <br />
                Height: 36” 
                <br />
                Diameter: 8”
                <br />
                <br />
                Layers
                <br />
                Felt: Weib: 175
                <br />
                PET: N/A
                <br />
                <br />
                Light: 
                <br />
                8” dia: 4<br />
                5” dia: 2 <br />
                Light temp: 3000K
                <br />
                <br />
                Parts
                <br />
                Metal finish: Bronze
                <br />
                Power Cable: Off white
                <br />
                Canopy: White
            </>
        ),
    },
]

function expandOption(option) {
    if (!option) {
        return null
    }
    const options = [option]
    for (const o of option.images) {
        options.push({ ...option, image: o })
    }
    return options
}

export default function LightGalleryStacklab(props) {
    return (
        <Collection
            baseUrl={"/lightcollectionsl"}
            items={collectionItems}
            onClickCarasol={(item) => {
                return expandOption(item)
            }}
        />
    )
}
