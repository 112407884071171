import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router"

import styles from "../css/home/collection.module.css"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import FlashArrow from "./FlashArrow"
import { icons, iconsDoubleWidth } from "./Icons"
import { CheckoutModal, fetchPrice } from "../layouts/NeosProductView"

import { useGlobalState } from "../state"
import { v4 as uuidv4 } from "uuid"
import Config from "../Config"

function CollectionDetails(props) {
    const obj = props.object

    return (
        <div className={styles.collectionDetails}>
            <img
                className={styles.productImage}
                alt={obj.designed}
                src={obj.image}
            />
            <div className={styles.collectionInfoPanel}>
                <div className={styles.title}>{obj.name}</div>
                <div className={styles.type}>{obj.type}</div>
                <hr />
                {obj.showPrefix !== false && (
                    <div className={styles.designedBy}>DESIGNED BY</div>
                )}
                <div className={styles.type}>{obj.designed}</div>
                <hr />
                <div className={styles.blurb}>{obj.blurb}</div>
                <hr />
                <button className={styles.viewButton}>ACQUIRE</button>
            </div>
        </div>
    )
}

function AutoPrice(props) {
    const [price, setPrice] = useState(0)
    useEffect(() => {
        fetchPrice(props.chair, "", setPrice)
    }, [props.chair])
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })
    return (
        <>
            {price == 0
                ? "..."
                : "USD" +
                  formatter.format(price / 100) +
                  " + shipping & handling "}
        </>
    )
}

function CollectionItem(props) {
    const obj = props.object
    const history = useHistory()

    return (
        <div
            className={styles.collectionItem}
            onClick={() => {
                props.onClick()
            }}
            style={obj.noLink === true ? { cursor: "default" } : {}}
        >
            <img
                className={styles.productImage}
                alt={obj.designed}
                src={obj.image}
            />
            <div className={styles.summaryArea}>
                <div className={styles.textBlock}>
                    <div className={styles.title}>
                        <div
                            style={{
                                display: "inline",
                                paddingRight: obj.icon ? ".5vw" : 0,
                                maxWidth: "5vw",
                            }}
                            className={styles.iconContainer}
                        >
                            {obj.icon && (
                                <img
                                    alt="icon"
                                    className="iconImage"
                                    src={icons[obj.icon]}
                                />
                            )}
                        </div>
                        <b>{obj.name}</b>
                    </div>
                    <div className={styles.type}>
                        {obj.showPrefix !== false && <>Type:</>} {obj.type}
                    </div>

                    <div className={styles.type}>
                        {obj.showPrefix !== false && <>By:</>} {obj.designed}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function CollectionCarasol(props) {
    const state = useGlobalState()
    useEffect(() => {
        document.getElementById("content").scrollTo(0, 0)
    }, [])
    const collectionItems = props.items
    console.log(collectionItems[0])
    const [obj, setObj] = useState(collectionItems[0])
    const [value, setValue] = useState(0)
    const [modal, setModal] = useState(null)

    const _setValue = (value) => {
        if (value < 0) {
            value = collectionItems.length - 1
        }
        if (value > collectionItems.length - 1) {
            value = 0
        }
        setValue(value)
        setObj(collectionItems[value])
    }
    const onChange = (value) => {
        _setValue(value)
    }

    return (
        <>
            {modal}
            <div className={styles.collectionDetails}>
                <Carousel value={value} onChange={onChange}>
                    {collectionItems.map((o) => {
                        return (
                            <img
                                className={styles.productImage}
                                alt={obj.designed}
                                src={obj.image}
                            />
                        )
                    })}
                </Carousel>

                <div className={styles.collectionInfoPanel}>
                    <div className={styles.title}>{obj.name}</div>
                    <div className={styles.type}>{obj.type}</div>
                    {obj.icon && (
                        <div className={styles.icon}>
                            <img src={icons[obj.icon]} />
                        </div>
                    )}
                    {!obj.removePOS && (
                        <>
                            <div className={styles.designedBy}>BY</div>
                            <div className={styles.type}>{obj.designed}</div>
                            <div className={styles.designedBy}>CONFIGURED</div>
                            <div className={styles.type}>{obj.configured}</div>
                            {obj.price && (
                                <>
                                    <div className={styles.designedBy}>
                                        PRICE
                                    </div>

                                    <div className="price">
                                        {obj.price} + shipping and handling
                                    </div>
                                </>
                            )}
                            {obj.autoPrice && (
                                <>
                                    <div className={styles.designedBy}>
                                        PRICE
                                    </div>

                                    <div className="price">
                                        <AutoPrice chair={obj.chair} />
                                    </div>
                                </>
                            )}
                            {obj.tear && (
                                <>
                                    <div className={styles.designedBy}>
                                        SPECIFICATIONS
                                    </div>

                                    <div className="price">
                                        <a href={obj.tear}>
                                            download tear sheet
                                        </a>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <br />
                    <div
                        className={
                            obj.removePOS ? styles.blurbNOPOS : styles.blurb
                        }
                    >
                        <span>{obj.blurb}</span>
                        <br />
                        <br />
                        {!obj.removePOS && <>Dimensions:</>}
                        <br /> <br />
                        <span>{obj.dimensions}</span>
                    </div>
                    <div className={styles.buttonArea}>
                        {obj.price && (
                            <div className="inquire">
                                {false && (
                                    <form
                                        id="inqureform"
                                        action={
                                            "mailto:" +
                                            Config.inquireEmail +
                                            "?subject=Inquire Re: " +
                                            obj.unit
                                        }
                                    >
                                        <button
                                            onClick={(e) => {
                                                document
                                                    .getElementById(
                                                        "inquireform"
                                                    )
                                                    .submit()
                                            }}
                                        >
                                            Inquire
                                        </button>
                                    </form>
                                )}
                                [{" "}
                                <a
                                    href={
                                        "mailto:" +
                                        Config.inquireEmail +
                                        "?subject=Inquire Re: " +
                                        obj.unit
                                    }
                                >
                                    INQUIRE
                                </a>{" "}
                                ]
                            </div>
                        )}

                        {!obj.removePOS && !obj.price && (
                            <button
                                onClick={() => {
                                    obj.chair.key = uuidv4()
                                    state.dispatch({
                                        type: "CART_ADD",
                                        payload: {
                                            chair: obj.chair,
                                            qty: 1,
                                        },
                                    })
                                    setModal(
                                        <CheckoutModal
                                            chair={obj.chair}
                                            onClose={() => {
                                                setModal(null)
                                            }}
                                            onDelete={(chair) => {
                                                state.dispatch({
                                                    type: "CART_REMOVE",
                                                    payload: {
                                                        chair: obj.chair,
                                                    },
                                                })
                                            }}
                                        />
                                    )
                                }}
                                className={styles.viewButton}
                            >
                                ADD TO CART
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.arrows}>
                    <div
                        className={styles.arrowLeft}
                        onClick={() => {
                            _setValue(value - 1)
                        }}
                    >
                        <FlashArrow />
                    </div>
                    <div
                        className={styles.arrowRight}
                        onClick={() => {
                            _setValue(value + 1)
                        }}
                    >
                        <FlashArrow />
                    </div>
                </div>
            </div>
        </>
    )
}
export default function Collection(props) {
    const collectionItems = props.items

    const [details, setDetails] = useState(null)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        setDetails(
            props.onClickCarasol(
                collectionItems.find((i) => "?" + i.key === location.search)
            )
        )
    }, [location.search, collectionItems])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (details) {
        return <CollectionCarasol items={details} />
    }

    return (
        <div className={styles.collection}>
            {collectionItems.map((object, i) => {
                return (
                    <CollectionItem
                        object={object}
                        onClick={() => {
                            setDetails(props.onClickCarasol(object))
                            if (object.link) {
                                window.location = object.link
                            } else if (object.noLink !== true) {
                                history.push(props.baseUrl + "?" + object.key)
                            }
                        }}
                    />
                )
            })}
        </div>
    )
}
