import React, { useState, useEffect } from "react"
import { useGlobalState } from "../state/index"
import Square from "../images/square.svg"
import Rounded from "../images/rounded.svg"

import { cloneDeep } from "lodash"
import classNames from "classnames"

export default function NeosLegs(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair

    function setRounded(bool) {
        const chair = cloneDeep(globalState.state.progress.chair)
        chair.radiusCorner = bool
        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: chair,
            },
        })
        window.dispatchEvent(
            new CustomEvent("viewChairSide", { detail: "top" })
        )
    }

    return (
        <div className="toggle-platform">
            <div
                onClick={() => {
                    setRounded(true)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.radiusCorner === true,
                })}
            >
                <span className="label">Rounded</span>
                <span className="image">
                    <img
                        alt="Rounded"
                        style={
                            chair.radiusCorner === true
                                ? { filter: "invert(100%)" }
                                : {}
                        }
                        src={Rounded}
                    />
                </span>
            </div>
            <div
                onClick={() => {
                    setRounded(false)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.radiusCorner === false,
                })}
            >
                <span className="label">Squared</span>
                <span className="image">
                    <img
                        alt="Squeared"
                        style={
                            chair.radiusCorner === false
                                ? { filter: "invert(100%)" }
                                : {}
                        }
                        src={Square}
                    />
                </span>
            </div>
        </div>
    )
}
