import React, { useState, useEffect } from "react"

import { ReactComponent as Logo } from "../images/Logo.svg"
import aboutChair from "../images/aboutChair.svg"
import { icons } from "./Icons"

import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import config from "../Config"
import styles from "../css/home/conference.module.css"
import { Chair3D } from "../components/illustrations/SideChair3D"
import { Front, Side } from "../components/illustrations/Illustrations"
import dateFormat from "dateformat"
import { useOverallDimensions, FracInch } from "../layouts/DimensionControls"

const copy = [
    <>
        ABOUT STACKABL
        <br />
        <br /> A new form of design that plays with possibilities,  created by
        STACKLAB and Maison Gerard
    </>,
    <>
        THE CONCEPT
        <br />
        Rethinking Resources
        <br />
        <br />
        Creative reuse has shaped culture for centuries. From the rich heritage
        of antique collecting to recent innovations in recycling technology,
        reimagining what’s possible with what’s already there presents an
        exciting challenge to the design community. It is this shared passion
        that lies at the heart of Stackabl.
    </>,
    <>
        WHAT IT IS
        <br />
        Immediate Impact
        <br />
        <br />
        Stackabl is an innovative system for designing custom furniture without
        the waste. Developed closely with regional manufacturers, aided by
        algorithms and robotics, the system identifies their remnant inventory,
        puts it back through their own machines, and into the hands of their own
        experts. By leveraging existing regional resources, it empowers local
        economies while curbing the carbon footprint.
    </>,
    <>
        WHY IT MATTERS
        <br />
        Turning waste into wonders
        <br />
        <br />A simple configurator seamlessly integrates the designer into the
        process. Its easy-to- use interface allows users to turn high quality
        offcuts into custom furniture according to their choices, from colors
        and patterns to dimensions and densities. Real-time visualizations in 2D
        and 3D as well as instant pricing feedback make for an intuitive and
        transparent experience that frees the imagination and opens doors to a
        world of possibilities.
    </>,
    <>
        THE 2021 COLLECTION
        <br />
        <br /> Introducing the inaugural Stackabl collection
        <br />
        By Maison Gerard and STACKLAB featuring six distinguished designers
        turning waste into wonders
        <br />
        <br />
        Maison Gerard presents a playful collection of furniture that explores
        how technology can inform the future of design, featuring unique pieces
        by select New York designers.
    </>,
    <>
        Created in Stackabl, a new, innovative system that turns waste into
        wonders, each of the pieces is constructed by regional specialists
        according to available resources. In response to the colors, dimensions,
        and finishes selected, layers of high quality felt offcuts are
        identified by algorithms and cut with the aid of robotics. With no brief
        to draw from, other than to work within the parameters set by the
        configurator, the designers were free to realize their own visions,
        resulting in a striking array of characterful works that belong to both
        past and future.
    </>,
    <>
        Pulling from a vast range of historical, contemporary, and personal
        references, from Louis XVI to Brad Davis and many more unlikely muses
        in-between, this body of work demonstrates the endless possibilities at
        the intersection of design, technology and circular manufacturing, where
        the imagination takes hold.
    </>,
]

function InfoNugget(props) {
    if (!props.value) {
        return null
    }
    console.log(props)
    return (
        <div className={styles.nugget}>
            <div className={styles.nuggetName}>{props.name}</div>
            <div className={styles.nuggetValue}>{props.value}</div>
        </div>
    )
}

function layers(blocks) {
    let felt = 0
    let pet = 0
    for (const f of blocks) {
        if (f.type === "designerBoard") {
            pet = pet + f.layers
        } else {
            felt = felt + f.layers
        }
    }

    return { felt, pet }
}

function na(value) {
    if (value) {
        return <span className={styles.inches}>{FracInch(value)}</span>
    }
    return "N/A"
}

function Info(props) {
    const chair = props.chair.chair
    const purchase = props.chair
    const { seatHeight, seatDepth, seatWidth, depth, width, height } =
        useOverallDimensions(chair)
    console.log(layers(chair.seat.blocks))
    return (
        <div className={styles.info}>
            <InfoNugget name="TYPE" value={chair.title} />
            <InfoNugget name="UNIT" value={chair.unit} />
            <InfoNugget
                name="CREATED AT"
                value={dateFormat(
                    purchase.createdAt,
                    "dddd, mmmm dS, yyyy, h:MM:ss TT"
                )}
            />
            <div className={styles.nugget}>
                <div className={styles.nuggetName}>DIMENSIONS</div>
            </div>
            <div className={styles.nugget}>
                <div className={styles.nuggetName}>HEIGHT</div>
                <div className={styles.nuggetValue}>Overall {na(height)}</div>
                <div className={styles.nuggetValue}>Seat {na(seatHeight)}</div>
            </div>
            <div className={styles.nugget}>
                <div className={styles.nuggetName}>WIDTH</div>
                <div className={styles.nuggetValue}>Overall {na(width)}</div>
                <div className={styles.nuggetValue}>Seat {na(seatWidth)}</div>
            </div>
            <div className={styles.nugget}>
                <div className={styles.nuggetName}>DEPTH</div>
                <div className={styles.nuggetValue}>Overall {na(depth)}</div>
                <div className={styles.nuggetValue}>Seat {na(seatDepth)}</div>
            </div>
            <div className={styles.nugget}>
                <div className={styles.nuggetName}>TEXTILE</div>
            </div>
            <div className={styles.nugget}>
                <div className={styles.nuggetName}>SEAT</div>
                <div className={styles.nuggetValue}>
                    PET Layers {layers(chair.seat.blocks).pet}
                </div>
                <div className={styles.nuggetValue}>
                    Felt Layers {layers(chair.seat.blocks).felt}
                </div>
            </div>
            {chair.back && (
                <div className={styles.nugget}>
                    <div className={styles.nuggetName}>BACK</div>
                    <div className={styles.nuggetValue}>
                        PET Layers {layers(chair.back.blocks).pet}
                    </div>
                    <div className={styles.nuggetValue}>
                        Felt Layers {layers(chair.back.blocks).felt}
                    </div>
                </div>
            )}
            {chair.leftArm && (
                <div className={styles.nugget}>
                    <div className={styles.nuggetName}>LEFT ARM</div>
                    <div className={styles.nuggetValue}>
                        PET Layers {layers(chair.leftArm.blocks).pet}
                    </div>
                    <div className={styles.nuggetValue}>
                        Felt Layers {layers(chair.leftArm.blocks).felt}
                    </div>
                </div>
            )}
            {chair.rightArm && (
                <div className={styles.nugget}>
                    <div className={styles.nuggetName}>RIGHT ARM</div>
                    <div className={styles.nuggetValue}>
                        PET Layers {layers(chair.rightArm.blocks).pet}
                    </div>
                    <div className={styles.nuggetValue}>
                        Felt Layers {layers(chair.rightArm.blocks).felt}
                    </div>
                </div>
            )}
        </div>
    )
}

export default function Converence(props) {
    const [chairs, setChairs] = useState([])
    const [chair, setChair] = useState({ chair: null })
    const [copyIndex, setCopyIndex] = useState(0)
    const [copyOutIndex, setCopyOutIndex] = useState(1)

    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        async function call() {
            const token = await getAccessTokenSilently({
                audience: `https://felt2.stacklab.ca`,
                scope: "*:*",
            })
            const url = config.apiBasePath + "admin/purchases?status=new"
            console.log(url)
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    console.log("Set Chairs")
                    console.log(res.data)
                    setChairs(res.data.purchases)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        call()
        const copyTimer = setInterval(() => {}, 1000 * 60 * 5)
        return () => {
            clearInterval(copyTimer)
        }
    }, [setChairs, getAccessTokenSilently])

    useEffect(() => {
        const copyTimer = setInterval(() => {
            if (copyIndex === copy.length - 1) {
                setCopyOutIndex(copy.length - 1)
                setCopyIndex(0)
            } else {
                setCopyOutIndex(copyIndex)
                setCopyIndex(copyIndex + 1)
            }
        }, 15000)

        return () => {
            clearInterval(copyTimer)
        }
    }, [copyIndex])

    useEffect(() => {
        function randomChair() {
            console.log("Slectiong?")
            const i = Math.floor(Math.random() * chairs.length - 1)
            console.log(i)
            if (chairs[i]) {
                setChair(chairs[i])
            }
        }
        const copyTimer = setInterval(() => {
            randomChair()
        }, 17000)
        randomChair()

        return () => {
            clearInterval(copyTimer)
        }
    }, [chairs])

    return (
        <div className={styles.conference}>
            <div className={styles.logo}>
                <Logo />
            </div>
            <div key={copyIndex}>
                <div className={styles.copy + " " + styles.fadeIn}>
                    {copy[copyIndex]}
                </div>

                <div className={styles.copy + " " + styles.fadeOut}>
                    {copy[copyOutIndex]}
                </div>
            </div>
            <div className={styles.aboutChair}>
                <img alt="avbout chair" src={aboutChair}></img>
            </div>
            {chair.chair && (
                <>
                    <div className={styles.chair}>
                        <div className={styles.chairTopLeft}>
                            <Front
                                chair={chair.chair}
                                type={chair.chair.type}
                                vw={10}
                            ></Front>
                        </div>
                        <div className={styles.chairTopRight}>
                            <Side
                                chair={chair.chair}
                                type={chair.chair.type}
                                vw={10}
                            ></Side>
                        </div>
                        <div className={styles.chair3d}>
                            <Chair3D rotate={true} chair={chair.chair} />
                        </div>
                    </div>
                    <Info chair={chair} />
                </>
            )}
        </div>
    )
}
