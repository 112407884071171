import React, { useEffect } from "react"

import "./css/app.css"
import "./state/index"
import { StateProvider, useGlobalState } from "./state/index"
import { Neos } from "./pages/Neos"
import { NeosMG } from "./pages/Neos"

import {
    BrowserRouter,
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom"
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history"
import ProtectedRoute from "./components/ProtectedRoute"
import Admin from "./layouts/Admin"
import Purchase from "./layouts/Purchase"
import Nav from "./home/Nav"
import Splash from "./home/Splash"
import Gallery from "./home/Gallery"
import Showcase, { Gallery as CollectionGallery } from "./home/Showcase"
import LightGallery from "./home/LightShowcase"
import Types, { JailType } from "./home/Types"
import About from "./home/About"
import ReactGA from "react-ga"
import Config from "./Config"
import Payment from "./layouts/Payment"
import Exhibitions from "./home/Exhibitions"

import Converence from "./home/Conference"
import Press from "./home/Press"
import Collections from "./home/Collections"
import Cart from "./layouts/Cart"
import CartBadge from "./components/CartBadge"
import { LightGalleryMS } from "./home/ShowcaseMS"
import LightCollections from "./home/LIghtColletions"
import LightGalleryStacklab from "./home/LightShowcaseStacklab"
import ReactTooltip from "react-tooltip"
import SaveRestore from "./components/SaveRestore"

const trackingId = Config.googleMetricsId
if (trackingId) {
    ReactGA.initialize(trackingId)
}

function AdminPurchase(props) {
    return <Admin component={Purchase} title="Purchase" />
}

function AdminPurchases(props) {
    return <Admin title="Purchases" />
}

function Redirect(props) {
    const history = useHistory()
    useEffect(() => {
        history.push("/2021collection?" + props.tag)
    }, [props.tag])

    return null
}

function App() {
    const location = useLocation()

    useEffect(() => {
        if (trackingId) {
            ReactGA.pageview(location.pathname)
        }
    }, [location])
    const state = useGlobalState()
    return (
        <Auth0ProviderWithHistory>
            {state.state.cart.cart.length > 0 && <CartBadge />}
            <Switch>
                <Route path="/cart">
                    <Cart />
                </Route>
                <Route path="/2021/:type">
                    <NeosMG />
                </Route>
                <Route path="/mg/:type">
                    <NeosMG />
                </Route>
                <ProtectedRoute
                    exact
                    path="/admin"
                    component={AdminPurchases}
                />

                <ProtectedRoute
                    exact
                    path="/admin/purchase"
                    component={AdminPurchase}
                />
                <ProtectedRoute
                    exact
                    path="/admin/conference"
                    component={Converence}
                />
                <Route path="/gallery">
                    <Nav>
                        <Gallery></Gallery>
                    </Nav>
                </Route>
                <Route path="/2021collection">
                    <Nav>
                        <CollectionGallery />
                    </Nav>
                </Route>
                <Route path="/lightcollection">
                    <Nav>
                        <LightGallery />
                    </Nav>
                </Route>
                <Route path="/lightcollectionsl">
                    <Nav>
                        <LightGalleryStacklab />
                    </Nav>
                </Route>
                <Route path="/lightcollectionms">
                    <Nav>
                        <LightGalleryMS />
                    </Nav>
                </Route>
                <Route path="/lightcollections">
                    <Nav>
                        <LightCollections />
                    </Nav>
                </Route>
                <Route path="/collections">
                    <Nav>
                        <Collections />
                    </Nav>
                </Route>
                <Route path="/2021">
                    <Nav>
                        <Showcase />
                    </Nav>
                </Route>
                <Route path="/types">
                    <Nav>
                        <Types />
                    </Nav>
                </Route>
                <Route path="/enter">
                    <JailType />
                </Route>
                <Route path="/exhibitions">
                    <Nav>
                        <Exhibitions />
                    </Nav>
                </Route>
                <Route path="/about">
                    <Nav>
                        <About />
                    </Nav>
                </Route>
                <Route path="/press">
                    <Nav>
                        <Press />
                    </Nav>
                </Route>
                <Route path="/raki">
                    <Redirect tag="raki" />
                </Route>
                <Route path="/dulces">
                    <Redirect tag="dulces" />
                </Route>
                <Route path="/madame">
                    <Redirect tag="madame" />
                </Route>
                <Route path="/fastlane">
                    <Redirect tag="fastLane" />
                </Route>
                <Route path="/querelle">
                    <Redirect tag="querelle" />
                </Route>
                <Route path="/maxine">
                    <Redirect tag="maxine" />
                </Route>
                <Route path="/payment">
                    <Payment />
                </Route>
                <Route path="/save">
                    <SaveRestore />
                </Route>
                <Route path="/">
                    <Splash />
                </Route>
            </Switch>
        </Auth0ProviderWithHistory>
    )
}

function GRoute(props) {
    return (
        <BrowserRouter>
            <StateProvider>
                <App />
            </StateProvider>
        </BrowserRouter>
    )
}

export default GRoute
