const Config = {
    comingSoon: false,
    saveFeature: true,
    chairs: {
        armChair: {
            name: "Arm Chair",
            dimensions: '18 2/4 x 29 9/16 x 32 5/16"',
            startingPrice: "3,000",
        },
        bench: {
            name: "Bench",
            dimensions: '18 x 32 x 16" H',
            startingPrice: "3,000",
        },
        sideChair: {
            name: "Side Chair",
            dimensions: '18 3/4 x 29 9/16 x 32 5/16" H',
            startingPrice: "3,000",
        },
    },
    swatchBaseUrl: "http://localhost:1234/remnants/colors/swatch/",
    boardBaseUrl: "/designer_board/",
    apiBasePath: "http://localhost:1234/",
    googleMetricsId: null,

    /*
    powderCoatColors: [
        { "Army Green 25": "#777466" },
        { "Army Green 50": "#6A6454" },
        { "Blue Grey 25": "#696C6F" },
        { "Blue Grey 50": "#54585C" },
        { "Pink Grey 25": "#C9A086" },
        { "Pink Grey 50": "#B39782" },
        { "Sage 25": "#68693F" },
        { "Sage 50": "#6E6E4D" },
        { Black: "#000000" },
    ],*/
    powderCoatColors: [
        { Clear: "#E0DAC2" },
        { Black: "#474642" },
        { Champagne: "#C7A365" },
    ],
    logRocket: false,
    stripe: false,
    palette: {
        red: "#CB3000",
        yellow: "#F6B200",
        green: "#8EBA00",
        blue: "#00496B",
        almostBlack: "#00496B",
        middleGrey: "#4E4C4A",
        lightGrey: "#66605D",
        almostWhite: "#F1ECE8",
    },
    cableColors: [
        { Gold: "#978E68" },
        { Black: "#18171C" },
        { White: "#F9f9f9" },
        { Orange: "#B72D2C" },
        { Tan: "#A58B64" },
        { Blue: "#2E3C89" },
        { Clear: "#7A8284" },
        { Chestnut: "#736A62" },
    ],
    canopyColors: [{ Black: "#020202" }, { White: "#F9f9f9" }],
    cableBraided: {},
    prePurchaseEmail: false,
    inquireEmail: "service@stackabl.shop",
    dataDog: {
        enabled: false,
        applicationId: "3220c3b3-94be-469a-9c84-5510c166cc62",
        clientToken: "pub300038f80d54c97c7206306748e400bd",
        service: "stackabl",
        sessionReplayRecording: true,
    },
}
if (process.env.NODE_ENV === "production") {
    Config.swatchBaseUrl =
        "https://felt-be-3hewk.ondigitalocean.app/remnants/colors/swatch/"
    Config.apiBasePath = "https://felt-be-3hewk.ondigitalocean.app/"
    Config.logRocket = true
    Config.stripe = false
    Config.googleMetricsId = "UA-213490565-1"
}

export default Config
