import Logo from "../images/Logo.svg"
import "../css/neos/cart.css"
import { useGlobalState } from "../state"
import { Chair3D, Light3D } from "../components/illustrations/SideChair3D"
import { OverallDimensions } from "./DimensionControls"
import { useEffect, useMemo, useState } from "react"
import { fetchPrice, fetchPriceForCart } from "./NeosProductView"
import { v4 as uuidv4 } from "uuid"

import { cloneDeep } from "lodash"
import axios from "axios"
import Config from "../Config"
function CartDetails(props) {
    const gState = useGlobalState()
    const [code, _setCode] = useState("")
    function setCode(c) {
        props.setCode(c)
        _setCode(c)
    }
    const [state, setState] = useState()
    const [id, setId] = useState(null)

    const [name, setName] = useState(
        props.progress.contact && props.progress.contact.name
            ? props.progress.contact.name
            : ""
    )
    const [email, setEmail] = useState(
        props.progress.contact && props.progress.contact.email
            ? props.progress.contact.email
            : ""
    )

    const [phoneNumber, setPhoneNumber] = useState(
        props.progress.contact && props.progress.contact.phoneNumber
            ? props.progress.contact.phoneNumber
            : ""
    )
    const [address1, setAddress1] = useState(
        props.progress.contact && props.progress.contact.address1
            ? props.progress.contact.address1
            : ""
    )
    const [address2, setAddress2] = useState(
        props.progress.contact && props.progress.contact.address2
            ? props.progress.contact.address2
            : ""
    )
    const [city, setCity] = useState(
        props.progress.contact && props.progress.contact.city
            ? props.progress.contact.city
            : ""
    )
    const [province, setProvince] = useState(
        props.progress.contact && props.progress.contact.state
            ? props.progress.contact.state
            : ""
    )
    const [price, setPrice] = useState("")
    const [agreeToTerms, setAgreeToTerms] = useState(0)
    const [showApplyDesigner, setShowApplyDesigner] = useState(
        props.progress.codeRequestSent && props.progress.contact.codeRequestSent
            ? props.progress.contact.codeRequestSent
            : ""
    )
    const [studioSite, setStudioSite] = useState(
        props.progress.contact && props.progress.contact.studioSite
            ? props.progress.contact.studioSite
            : ""
    )
    const [studioName, setStudioName] = useState(
        props.progress.contact && props.progress.contact.studioName
            ? props.progress.contact.studioName
            : ""
    )
    const [zip, setZip] = useState(
        props.progress.contact && props.progress.contact.zip
            ? props.progress.contact.zip
            : ""
    )

    function shipIt() {
        setState("shipping")
        const c = cloneDeep(props.progress)
        let id = null

        if (c.purchaseId) {
            id = c.purchaseId
        } else {
            id = uuidv4()
        }
        c.saveName = name

        c.contact = {
            ...c.contact,
            name,
            email,
            phoneNumber,
            studioSite,
            studioName,
            address1,
            address2,
            city,
            state: province,
            zip,
        }
        let path = Config.apiBasePath + "purchase"
        if (Config.stripe) {
            path = Config.apiBasePath + "purchase?takePayment=1"
        }

        axios
            .post(path, {
                chair: c,
                chairType: "__CART__",
                cart: gState.state.cart,

                code: code.trim(),
                id: id,
                status: "new",
                update: c.purchaseId ? true : false,
            })
            .then((res) => {
                if (res.data.error) {
                    setState("codeError")
                    console.log("Code error")
                    console.log(res.data.error)
                } else {
                    setState("shipped")
                    props.setCartState("shipped")
                    setId(res.data.id)

                    gState.dispatch({ type: "CART_INITIALIZE" })
                }
                if (res.headers["x-payment-url"]) {
                    window.location.href = res.headers["x-payment-url"]
                }
            })
            .catch((err) => {
                console.log({ message: "Other error", err })
                setState("error")
            })
    }
    return (
        <>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>Full Name:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setName(e.target.value.toUpperCase())
                    }}
                    value={name}
                />
            </div>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>Email:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setEmail(e.target.value.toUpperCase())
                    }}
                    value={email}
                />
            </div>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>Phone Number:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setPhoneNumber(e.target.value.toUpperCase())
                    }}
                    value={phoneNumber}
                />
            </div>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>Address:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setAddress1(e.target.value.toUpperCase())
                    }}
                    value={address1}
                />
            </div>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>Address 2:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setAddress2(e.target.value.toUpperCase())
                    }}
                    value={address2}
                />
            </div>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>City:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setCity(e.target.value.toUpperCase())
                    }}
                    value={city}
                />
            </div>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>State/Province:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setProvince(e.target.value.toUpperCase())
                    }}
                    value={province}
                />
            </div>
            <div
                style={{ margin: "2vw", textAlign: "left" }}
                className="form-element"
            >
                <b>Zip/Postal:</b>
                <input
                    className="input-padded"
                    type="text"
                    onChange={(e) => {
                        setZip(e.target.value.toUpperCase())
                    }}
                    value={zip}
                />
            </div>
            {!showApplyDesigner && (
                <>
                    <div
                        style={{ textAlign: "right" }}
                        className="form-element"
                    >
                        <span
                            style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                width: "100%",
                                textAlign: "right",
                            }}
                            onClick={() => {
                                setShowApplyDesigner(true)
                                setCode("")
                            }}
                        >
                            Request designer code
                        </span>
                    </div>
                    <div className="form-element">
                        <b>Designer Code:</b>
                        <input
                            className="input-padded"
                            type="text"
                            onChange={(e) => {
                                setCode(e.target.value.toUpperCase())
                            }}
                            placeholder="not required"
                            value={code}
                        />
                    </div>
                </>
            )}
            {showApplyDesigner && (
                <>
                    {props.progress.contact &&
                        !props.progress.contact.codeRequestSent && (
                            <div
                                style={{
                                    textAlign: "right",
                                }}
                                className="form-element"
                            >
                                <span
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        width: "100%",
                                        textAlign: "right",
                                    }}
                                    onClick={() => {
                                        setShowApplyDesigner(false)
                                    }}
                                >
                                    I have a design code
                                </span>
                            </div>
                        )}
                    <div className="form-element">
                        <b>Studio Name:</b>
                        <input
                            className="input-padded"
                            type="text"
                            onChange={(e) => {
                                setStudioName(e.target.value.toUpperCase())
                            }}
                            placeholder="not required"
                            value={studioName}
                        />
                    </div>
                    <div className="form-element">
                        <b>Studio Website:</b>
                        <input
                            className="input-padded"
                            type="text"
                            onChange={(e) => {
                                setStudioSite(e.target.value.toUpperCase())
                            }}
                            placeholder="not required"
                            value={studioSite}
                        />
                    </div>
                </>
            )}
            <div className="form-element terms">
                <input
                    className="terms-check"
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={(e) => {
                        console.log(e.target.checked)
                        if (e.target.checked) {
                            setAgreeToTerms(1)
                        } else {
                            setAgreeToTerms(0)
                        }
                    }}
                />
                I agree to the&nbsp;
                <a href="/terms.pdf" target="_blank">
                    Terms and Conditions
                </a>
                -{" "}
            </div>
            {state === "codeError" && (
                <div style={{ margin: "2vw", textAlign: "center" }}>
                    <b>
                        There was an error with the form. Please check the entry
                        and try again
                    </b>
                </div>
            )}
            {state === "error" && (
                <div style={{ margin: "2vw", textAlign: "center" }}>
                    <b>An error occured. Please try again</b>
                </div>
            )}
            {state === "terms" && (
                <div style={{ margin: "2vw", textAlign: "center" }}>
                    <b>You must agree to the terms</b>
                </div>
            )}
            {state !== "shipping" && (
                <div className="form-element button">
                    <button
                        onClick={() => {
                            if (!agreeToTerms) {
                                setState("terms")
                            } else {
                                shipIt()
                            }
                            //shipIt()
                        }}
                        style={{ width: "50%", margin: 0 }}
                    >
                        Checkout
                    </button>
                </div>
            )}
        </>
    )
}

function CartItem(props) {
    const [price, setPrice] = useState("...")
    fetchPrice(props.chair.chair, props.code ? props.code : "", (price) => {
        setPrice(price)
    })

    return (
        <div className="cartItem">
            <div className="rendering">
                {props.chair.chair.type === "basic" && (
                    <img
                        style={{
                            width: "200px",
                            padding: "10px",
                        }}
                        src={props.chair.chair.cartImage}
                        alt="cart "
                    />
                )}
                {props.chair.chair.type === "pendent" && (
                    <Light3D
                        style={{
                            width: "200px",
                            height: "200px",
                        }}
                        chair={props.chair.chair}
                    />
                )}
                {props.chair.chair.type !== "pendent" &&
                    props.chair.chair.type !== "basic" && (
                        <div
                            style={{
                                width: "200px",
                                height: "200px",
                            }}
                        >
                            <Chair3D chair={props.chair.chair} className=" " />
                        </div>
                    )}
            </div>
            <div className="details">
                <OverallDimensions chair={props.chair.chair}>
                    <div
                        className="flex-h spread"
                        style={{ textAlign: "right" }}
                    >
                        <b>Price</b>
                        USD
                        {price === "..." && <>...</>}
                        {price !== "..." &&
                            (
                                (price / 100) *
                                (props.chair.qty ? props.chair.qty : 1)
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                            })}
                        <br />+ shipping and handling
                    </div>
                    <div
                        className="flex-h spread"
                        style={{ textAlign: "right" }}
                    >
                        <b>Leadtime</b>
                        "8-12 weeks"
                    </div>
                    <div className="flex-h spread">
                        <b>Quantity</b>
                        <select
                            onChange={(e) => {
                                props.onQtyChange(e.target.value)
                            }}
                            x
                        >
                            <option selected={props.chair.qty == 1} value="1">
                                1
                            </option>
                            <option selected={props.chair.qty == 2} value="2">
                                2
                            </option>
                            <option selected={props.chair.qty == 3} value="3">
                                3
                            </option>
                            <option selected={props.chair.qty == 4} value="4">
                                4
                            </option>
                            <option selected={props.chair.qty == 5} value="5">
                                5
                            </option>
                            <option selected={props.chair.qty == 6} value="6">
                                6
                            </option>
                            <option selected={props.chair.qty == 7} value="7">
                                7
                            </option>
                            <option selected={props.chair.qty == 8} value="8">
                                8
                            </option>
                            <option selected={props.chair.qty == 9} value="9">
                                9
                            </option>
                            <option selected={props.chair.qty == 10} value="10">
                                10
                            </option>
                            <option selected={props.chair.qty == 11} value="11">
                                11
                            </option>
                        </select>
                    </div>
                    <div className="flex-h spread">
                        <b> </b>

                        <button
                            onClick={() => props.onItemDelete(props.chair)}
                            className="model-button model-button-warning"
                        >
                            delete
                        </button>
                    </div>
                </OverallDimensions>
            </div>
        </div>
    )
}

export default function Cart(props) {
    const state = useGlobalState()
    const [cartState, setCartState] = useState("pending")

    const [price, setPrice] = useState("...")
    const [code, setCode] = useState()

    const cartContents = state.state.cart.cart
    console.log(cartContents)
    useEffect(() => {
        window.scrollTo(1, 0)
    }, [])
    const items = useMemo(() => {
        fetchPriceForCart(state.state.cart, code ? code : "", (price) => {
            setPrice(price)
        })
        const items = []

        for (const item of cartContents) {
            items.push(
                <CartItem
                    code={code}
                    onQtyChange={(num) => {
                        state.dispatch({
                            type: "CART_UPDATE",
                            payload: {
                                chair: item.chair,
                                qty: num,
                            },
                        })
                    }}
                    onItemDelete={(chair) => {
                        state.dispatch({
                            type: "CART_REMOVE",
                            payload: {
                                chair: chair,
                            },
                        })
                    }}
                    chair={item}
                />
            )
        }
        return items
    }, [cartContents, code])

    return (
        <div className="cart">
            <div className="header">
                <img src={Logo} className="logo" />
            </div>
            {cartContents.length === 0 && cartState !== "shipped" && (
                <div className="empty">
                    <h1>There are no items in your cart</h1>
                    <a href="/enter">Continue home</a>
                </div>
            )}
            {cartState === "shipped" && (
                <div className="empty">
                    <h1>Thank You</h1>
                    <p>
                        A Stackabl team member will contact you within two
                        business days to arange payment and shipping
                    </p>
                    <a href="/">Continue home</a>
                </div>
            )}
            {cartContents.length > 0 && cartState !== "shipped" && (
                <div className={"cartBody"}>
                    <div className="items">
                        {items}
                        <div className="total">
                            <label>Total:</label>
                            {price === "..." && <>...</>}
                            {price !== "..." && (
                                <span>
                                    USD
                                    {(price / 100).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}
                                    <br />+ shipping and handling
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="info">
                        <div className="heading">Shopping Cart</div>
                        <CartDetails
                            setCartState={setCartState}
                            progress={state.state.progress}
                            cart={state.state.cart}
                            setCode={setCode}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
