import React, { useState } from "react"

import Config from "../Config"
import "../css/modal.css"

export default function YesNoModal(props) {
    return (
        <div className="model">
            <div className="model-overlay"></div>
            <div className="model-body">
                <div className="handle-close">
                    <span onClick={props.close}>X</span>
                </div>
                <div className="model-text">
                    <p>{props.message}</p>
                </div>
                <div className="actions">
                    <button onClick={props.onYes}>Yes</button>
                    <button onClick={props.onNo}>No</button>
                </div>
            </div>
        </div>
    )
}
