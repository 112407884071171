import React from "react"
import { Stage, Layer, Rect, Circle, Group } from "react-konva"
import { inch, cornerRadius } from "./utils"

export default function SideChairTop(props) {
    const chair = props.chair
    const largestInch = Math.max(chair.seat.depth, chair.seat.width)

    const _in = props.in
        ? props.in
        : (i) => {
              return inch(i, largestInch, props.size)
          }

    return (
        <Stage
            height={_in(chair.seat.width) + 1}
            width={_in(chair.seat.depth) + 1}
            rotation={-90}
            offset={{ x: _in(chair.seat.width), y: 0 }}
        >
            <Layer>
                <Rect
                    x={0}
                    y={0}
                    width={_in(chair.seat.width)}
                    height={_in(chair.seat.depth)}
                    fill={chair.seat.blocks[0].color}
                    stroke="black"
                    strokeWidth={1}
                    cornerRadius={cornerRadius(chair, _in)}
                />

                {/* Back */}
                {chair.back.blocks[0].layers > 0 && (
                    <Rect
                        x={0}
                        y={0}
                        width={_in(chair.seat.width)}
                        height={_in(chair.back.depth)}
                        fill={chair.back.blocks[0].color}
                        stroke="black"
                        strokeWidth={1}
                        cornerRadius={cornerRadius(chair, _in)}
                    />
                )}

                {/*Tufts*/}
                <Circle
                    x={_in(chair.back.depth / 2)}
                    y={_in(chair.back.depth / 2)}
                    radius={_in(chair.tuft.radius)}
                    fill={chair.tuft.color}
                    stroke="black"
                    strokeWidth={1}
                />
                <Circle
                    x={_in(chair.seat.width) - _in(chair.back.depth / 2)}
                    y={_in(chair.back.depth / 2, largestInch, props.size)}
                    radius={_in(chair.tuft.radius)}
                    fill={chair.tuft.color}
                    stroke="black"
                    strokeWidth={1}
                />

                <Circle
                    x={_in(chair.back.depth / 2)}
                    y={_in(chair.seat.depth - chair.back.depth / 2)}
                    radius={_in(chair.tuft.radius)}
                    fill={chair.tuft.color}
                    stroke="black"
                    strokeWidth={1}
                />
                <Circle
                    x={_in(chair.seat.width) - _in(chair.back.depth / 2)}
                    y={_in(chair.seat.depth - chair.back.depth / 2)}
                    radius={_in(chair.tuft.radius, largestInch, props.size)}
                    fill={chair.tuft.color}
                    stroke="black"
                    strokeWidth={1}
                />

                {chair.type === "dayBed" && (
                    <>
                        <Circle
                            x={_in(chair.back.depth / 2)}
                            y={_in(chair.seat.depth / 2 + chair.tuft.radius)}
                            radius={_in(chair.tuft.radius)}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />
                        <Circle
                            x={
                                _in(chair.seat.width) -
                                _in(chair.back.depth / 2)
                            }
                            y={_in(chair.seat.depth / 2 + chair.tuft.radius)}
                            radius={_in(
                                chair.tuft.radius,
                                largestInch,
                                props.size
                            )}
                            fill={chair.tuft.color}
                            stroke="black"
                            strokeWidth={1}
                        />
                    </>
                )}
            </Layer>
        </Stage>
    )
}
