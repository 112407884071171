import React, { useState, useEffect } from "react"
import { useGlobalState } from "../state/index"
import LegsDiagram from "../images/legsize.svg"
import { cloneDeep } from "lodash"
import classNames from "classnames"

export default function NeosLegs(props) {
    const globalState = useGlobalState()
    const chair = globalState.state.progress.chair

    function setInch(num) {
        const legs = cloneDeep(globalState.state.progress.chair)
        legs.legs.width = num
        if (num === 8) {
            if (legs.rightArm) {
                legs.rightArm.width = 9
            }

            if (legs.leftArm) {
                legs.leftArm.width = 9
            }
            if (legs.back) {
                legs.back.depth = 9
            }
            if (legs.seat.width < 36) {
                legs.seat.width = 27
            }
            if (legs.seat.depth < 24) {
                legs.seat.depth = 24
            }
            for (let i = 0; i < legs.legs.legTypes.length; i++) {
                if (legs.legs.legTypes[i] === 5) {
                    legs.legs.legTypes[i] = 1
                }
            }
        }
        if (num === 4) {
            if (legs.rightArm) {
                legs.rightArm.width = 5
            }
            if (legs.leftArm) {
                legs.leftArm.width = 5
            }
            if (legs.back) {
                legs.back.depth = 5
            }
        }
        globalState.dispatch({
            type: "CHAIR",
            payload: {
                chair: legs,
            },
        })
        window.dispatchEvent(
            new CustomEvent("viewChairSide", { detail: "default" })
        )
        window.dispatchEvent(new CustomEvent("invalidateTemplate"))
    }

    return (
        <div className="toggle-platform">
            <div
                onClick={() => {
                    console.log(4)
                    setInch(4)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.legs.width === 4,
                })}
            >
                <span className="label">4"</span>
                <span className="image">
                    <img
                        style={{ width: "25%" }}
                        alt="4 inch"
                        src={LegsDiagram}
                    />
                </span>
            </div>
            <div
                onClick={() => {
                    setInch(8)
                }}
                className={classNames("toggle", "clickable", {
                    selected: chair.legs.width === 8,
                })}
            >
                <span className="label">8"</span>
                <span className="image">
                    <img alt="8 inch" src={LegsDiagram} />
                </span>
            </div>
        </div>
    )
}
