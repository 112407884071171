import React, { useState, useEffect } from "react"
import NeosProductView from "../layouts/NeosProductView"
import { cloneDeep } from "lodash"
import { useGlobalState, emptyState } from "../state/index"
import {
    slipperChair as mgSlipperChair,
    sideChair as mgSideChair,
    sideChairRake as mgSideChairRake,
    headChairRake as mgHeadChairRake,
    headChair as mgHeadChair,
    loungeChair as mgLoungeChair,
    tonsOfFun as mgTonsOfFun,
    cornerChair as mgCornerChair,
    dayBed as mgDayBed,
    bench as mgBench,
    tickTack as mgTickTack,
    chaise as mgChaise,
    theDev as mgTheDev,
    pendent,
    pendent858,
    pendent585,
    pendent58,
} from "../state/mg"
import { useParams } from "react-router-dom"
import NeosTools from "../layouts/NeosTools"
import "../css/neos/layout.css"

function MGUrlSelector(props) {
    const state = useGlobalState()
    ///const location = useParams()
    const { type } = useParams()
    useEffect(() => {
        if (type) {
            if (type.toLowerCase() === "slipperchair") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgSlipperChair),
                        step: "legs",
                    },
                })
            }
            if (type.toLowerCase() === "sidechair") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgSideChair),
                        step: "legs",
                    },
                })
            }

            if (
                type.toLowerCase() === "diningchair" ||
                type.toLowerCase() === "diningchairrake"
            ) {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgSideChairRake),
                        step: "legs",
                    },
                })
            }

            if (
                type.toLowerCase() === "headchair" ||
                type.toLowerCase() === "headchairrake"
            ) {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgHeadChairRake),
                        step: "legs",
                    },
                })
            }

            if (type.toLowerCase() === "loungechair") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgLoungeChair),
                        step: "legs",
                    },
                })
            }
            if (type.toLowerCase() === "tonsoffun") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgTonsOfFun),
                        step: "legs",
                    },
                })
            }
            if (type.toLowerCase() === "cornerchair") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgCornerChair),
                        step: "legs",
                    },
                })
            }
            if (type.toLowerCase() === "daybed") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgDayBed),
                        step: "legs",
                    },
                })
            }
            if (type.toLowerCase() === "bench") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgBench),
                        step: "legs",
                    },
                })
            }
            if (type.toLowerCase() === "tictac") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgTickTack),
                        step: "legs",
                    },
                })
            }
            if (type.toLowerCase() === "chaise") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(mgChaise),
                        step: "legs",
                    },
                })
            }
            if (
                type.toLowerCase() === "pendent" ||
                type.toLowerCase() === "pendant"
            ) {
                if (window.location.search === "?858") {
                    state.dispatch({
                        type: "CHAIR_PROGRESS",
                        payload: {
                            chair: cloneDeep(pendent858),
                            step: "legs",
                        },
                    })
                } else if (window.location.search === "?585") {
                    state.dispatch({
                        type: "CHAIR_PROGRESS",
                        payload: {
                            chair: cloneDeep(pendent585),
                            step: "legs",
                        },
                    })
                } else if (window.location.search === "?58") {
                    state.dispatch({
                        type: "CHAIR_PROGRESS",
                        payload: {
                            chair: cloneDeep(pendent58),
                            step: "legs",
                        },
                    })
                } else {
                    state.dispatch({
                        type: "CHAIR_PROGRESS",
                        payload: {
                            chair: cloneDeep(pendent),
                            step: "legs",
                        },
                    })
                }
            }
        }
    }, [type])

    return null
}

function UrlSelector(props) {
    const state = useGlobalState()
    ///const location = useParams()
    const { type } = useParams()
    useEffect(() => {
        if (type) {
            if (type === "armChair") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(emptyState.armChair),
                        step: "legs",
                    },
                })
            }
            if (type === "cornerChair") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(emptyState.cornerChair),
                        step: "legs",
                    },
                })
            }
            if (type === "sideChair" || type === "chair") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(emptyState.sideChair),
                        step: "legs",
                    },
                })
            }
            if (type === "dayBed") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(emptyState.dayBed),
                        step: "legs",
                    },
                })
            }
            if (type === "bench") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(emptyState.bench),
                        step: "legs",
                    },
                })
            }
            if (type === "stool") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(emptyState.stool),
                        step: "legs",
                    },
                })
            }
            if (type === "armBench") {
                state.dispatch({
                    type: "CHAIR_PROGRESS",
                    payload: {
                        chair: cloneDeep(emptyState.armBench),
                        step: "legs",
                    },
                })
            }
        }
    }, [type])

    return null
}

export function ToolsView(props) {
    return (
        <>
            <div className="main-left">
                <NeosTools />
            </div>
            <div className="main-right">
                <NeosProductView />
            </div>
        </>
    )
}

export function Neos(props) {
    const state = useGlobalState()
    return (
        <div className="main-view">
            <UrlSelector />
            {state.state.progress.chair && <ToolsView />}
        </div>
    )
}

export function NeosMG(props) {
    const state = useGlobalState()
    return (
        <div className="main-view">
            <MGUrlSelector />
            {state.state.progress.chair && <ToolsView />}
        </div>
    )
}
