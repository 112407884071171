import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import config from "../Config"
import "../css/admin.css"
import { useLocation, Link } from "react-router-dom"
import { OverallDimensions } from "../layouts/DimensionControls"
import { Top, Side } from "../components/illustrations/Illustrations"
import { Chair3D, Light3D } from "../components/illustrations/SideChair3D"
import axios from "axios"
import { genericInch } from "../components/illustrations/utils"
import Config from "../Config"
function useQuery() {
    return new URLSearchParams(useLocation().search)
}
function Item(props) {
    return (
        <div className="flex-h spread">
            <b>{props.label}</b>
            <span>
                <span className="inches">
                    <span>{props.value}</span>
                </span>
            </span>
        </div>
    )
}

function Block(props) {
    return (
        <div>
            {props.blocks.map((o, i) => (
                <Item key={i} label={o.colorName} value={o.layers} />
            ))}
        </div>
    )
}

function LegMetal(props) {
    const legTypes = ["Torpedo", "Bullet", "Schwarzenegger", "Monroe", "Fatty"]
    const chair = props.chair

    if (!chair.legs) {
        return null
    }

    const canopyColor = chair.tuft.color
    const metalColor = Object.keys(Config.powderCoatColors).find((key) => {
        const kk = Object.keys(Config.powderCoatColors[key])[0]
        return Config.powderCoatColors[key][kk] === canopyColor
    })

    const wordColor = Object.keys(Config.powderCoatColors[metalColor])[0]
    return (
        <div>
            <h4>Leg + Metal</h4>
            <Item label="Height" value={chair.legs.height} />
            <Item label="Width" value={chair.legs.width} />
            <Item label="Color" value={chair.legs.color} />
            <Item label="Color Name" value={chair.legs.colorName} />
            <Item label="Metal Color" value={wordColor} />

            <Item
                label="Left Front"
                value={legTypes[chair.legs.legTypes[0] - 1]}
            />

            <Item
                label="Left Rear"
                value={legTypes[chair.legs.legTypes[1] - 1]}
            />
            {chair.legs.numLegs !== 2 && (
                <>
                    <Item
                        label="Right Front"
                        value={legTypes[chair.legs.legTypes[2] - 1]}
                    />
                    <Item
                        label="Right Rear"
                        value={legTypes[chair.legs.legTypes[3] - 1]}
                    />
                </>
            )}
            {chair.legs.numLegs === 6 && (
                <>
                    <Item
                        label="Center Front"
                        value={legTypes[chair.legs.legTypes[4] - 1]}
                    />
                    <Item
                        label="Center Rear"
                        value={legTypes[chair.legs.legTypes[5] - 1]}
                    />
                </>
            )}
        </div>
    )
}

function Parts(props) {
    const legTypes = ["Torpedo", "Bullet", "Schwarzenegger", "Monroe", "Fatty"]
    const chair = props.chair
    return (
        <div>
            {chair.tube && false && (
                <Part
                    name="Light Body"
                    depth={chair.tube.diameter}
                    width={chair.tube.diameter}
                    blocks={chair.tube.blocks}
                />
            )}
            {chair.back && (
                <Part
                    name="Back"
                    depth={chair.back.depth}
                    width={chair.seat.width}
                    blocks={chair.back.blocks}
                />
            )}
            {chair.leftArm && (
                <Part
                    name="Left Arm"
                    depth={chair.seat.depth}
                    width={chair.leftArm.width}
                    blocks={chair.leftArm.blocks}
                />
            )}
            {chair.rightArm && (
                <Part
                    name="Right Arm"
                    depth={chair.seat.depth}
                    width={chair.rightArm.width}
                    blocks={chair.rightArm.blocks}
                />
            )}
            {chair.seat && (
                <Part
                    name="Seat"
                    depth={chair.seat.depth}
                    width={chair.seat.width}
                    blocks={chair.seat.blocks}
                />
            )}
            <LegMetal {...props} />
        </div>
    )
}

function Part(props) {
    return (
        <div>
            <h4>{props.name}</h4>
            <Item label="Depth" value={props.depth} />
            <Item label="Width" value={props.width} />
            <h4>{props.name} colors </h4>
            <Block blocks={props.blocks} />
        </div>
    )
}

function PurchaceStatusUpdate(props) {
    const { getAccessTokenSilently } = useAuth0()

    async function setStatus(status) {
        const token = await getAccessTokenSilently({
            audience: `https://felt2.stacklab.ca`,
            scope: "*:*",
        })
        const url = config.apiBasePath + "admin/purchase/" + props.id
        axios
            .patch(
                url,
                { status: status },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                props.setPurchase(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="action-buttons">
            {props.status !== "inprogress" && (
                <button onClick={() => setStatus("inprogress")}>
                    in progress
                </button>
            )}
            {props.status !== "shipped" && (
                <button onClick={() => setStatus("shipped")}>shipped</button>
            )}
        </div>
    )
}

function ColorSummary(props) {
    const chair = props.chair
    const colors = chair.blockColors

    const items = []

    for (const c of colors) {
        console.log(c)
        items.push(
            <div className="flex-h spread">
                <b>{c}</b>
            </div>
        )
    }
    return <div>{items}</div>
}

function PurchaseItem(props) {
    const purchase = props.purchase
    const print = props.print
    const imgSize = props.imgSize
    const query = props.query
    const setPurchase = props.setPurchase
    const chair = props.chair ? props.chair : props.purchase.chair

    const gin =
        print && chair.type !== "pendent"
            ? genericInch(chair, imgSize)
            : undefined

    return (
        <>
            {!print && (
                <Link
                    to={
                        "/admin/purchase?id=" +
                        query.get("id") +
                        "&print=1" +
                        (props.itemKey ? "&key=" + props.itemKey : "")
                    }
                >
                    Print
                </Link>
            )}
            <div className={"two-column " + (print ? "print-area" : "")}>
                <div className="first-column">
                    {!props.hideOrderInfo && (
                        <>
                            <span className="control">
                                <h4>Order Info</h4>

                                <Item label="id" value={purchase.id} />
                                <Item label="code" value={purchase.code} />

                                <Item
                                    label="created"
                                    value={new Date(
                                        purchase.createdAt
                                    ).toLocaleString()}
                                />
                                {!print && (
                                    <Item
                                        label="status"
                                        value={purchase.status}
                                    />
                                )}

                                <Item
                                    label=""
                                    value={" + shipping and handling"}
                                />
                                <PurchaceStatusUpdate
                                    setPurchase={setPurchase}
                                    id={purchase.id}
                                    status={purchase.status}
                                />
                            </span>
                            {purchase.chair.contact && (
                                <>
                                    <h4>Contact</h4>
                                    <Item
                                        label="name"
                                        value={purchase.chair.contact.name}
                                    />
                                    <Item
                                        label="email"
                                        value={purchase.chair.contact.email}
                                    />
                                    <Item
                                        label="phone number"
                                        value={
                                            purchase.chair.contact.phoneNumber
                                        }
                                    />
                                    <Item
                                        label="address1"
                                        value={purchase.chair.contact.address1}
                                    />{" "}
                                    <Item
                                        label="address2"
                                        value={purchase.chair.contact.address2}
                                    />
                                    <Item
                                        label="city"
                                        value={purchase.chair.contact.city}
                                    />
                                    <Item
                                        label="state"
                                        value={purchase.chair.contact.state}
                                    />
                                    <Item
                                        label="zip"
                                        value={purchase.chair.contact.zip}
                                    />
                                    <Item
                                        label="newsletter"
                                        value={
                                            purchase.chair.contact.newsLetter
                                                ? "true"
                                                : "false"
                                        }
                                    />
                                    <Item
                                        label="studio name"
                                        value={
                                            purchase.chair.contact.studioName
                                        }
                                    />
                                    <Item
                                        label="studio site"
                                        value={
                                            purchase.chair.contact.studioSite
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}

                    <div className="print-break-after">
                        <h4>Item Info</h4>
                        {props.itemPrice && (
                            <Item
                                label="price"
                                value={
                                    (
                                        props.itemPrice.price / 100
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    }) + " USD"
                                }
                            />
                        )}
                        <Item label="leadtime" value={"8-12 weeks"} />
                        {props.quantity && (
                            <Item label="quantity" value={props.quantity} />
                        )}
                        <Item label="type" value={chair.type} />
                        <Item label="sub type" value={chair.subType} />
                        <h4>Dimensions</h4>

                        <OverallDimensions
                            chair={chair}
                            fullLayersDescr={!print}
                        />
                        {!print && <Parts chair={chair} />}
                    </div>
                    {print && chair.type !== "pendent" && (
                        <div className="print-group">
                            <LegMetal chair={chair} />
                        </div>
                    )}

                    {props.showFooter && (
                        <>
                            <h4>Total</h4>

                            <Item
                                label="total price"
                                value={
                                    (purchase.price / 100).toLocaleString(
                                        "en-US",
                                        {
                                            style: "currency",
                                            currency: "USD",
                                        }
                                    ) + " USD"
                                }
                            />
                        </>
                    )}
                </div>
                <div className="second-column">
                    {chair.type === "pendent" && (
                        <div className="purchase-images">
                            <Light3D
                                chair={chair}
                                width={print ? "5in" : "60vw"}
                                height={print ? "5in" : "60vw"}
                                style={{
                                    position: "realtive",
                                    width: "60vw",
                                    height: "60vw",
                                }}
                            />
                            <Light3D
                                chair={chair}
                                width={print ? "5in" : "60vw"}
                                height={print ? "5in" : "60vw"}
                                side={true}
                                top={"110vh"}
                            />
                        </div>
                    )}
                    {chair.type !== "pendent" && chair.type !== "basic" && (
                        <div className="purchase-images">
                            <div
                                style={{
                                    paddingTop: "200px",
                                    height: print ? "4in" : "90vh",
                                    width: print
                                        ? imgSize + "px"
                                        : imgSize + "vw",
                                }}
                                className="3d"
                            >
                                <Chair3D chair={chair} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {print && (
                <div style={{ width: "40%" }}>
                    <h3>Design Approval Sign-Off</h3> I have carefully assessed
                    the specifications for the above order.
                    <br />
                    <br /> _________ The Order deliverables are accepted.
                    <br />
                    <br /> _________The Order is accepted pending the issues
                    noted <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br /> _________ The Order is not accepted (for the reasons
                    provided)
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    Name: __________________________________________ <br />
                    <br /> <br />
                    <br />
                    <br />
                    Date: __________________________________________
                </div>
            )}
        </>
    )
}

function Purchase(props) {
    let query = useQuery()
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
    const [purchase, setPurchase] = useState(null)
    const [message, setMessage] = useState("Loading")
    const location = useLocation()
    const itemKey = query.get("key")

    const print = new URLSearchParams(location.search).get("print")
    const imgSize = print ? 400 : 45

    useEffect(() => {
        async function call() {
            const token = await getAccessTokenSilently({
                audience: `https://felt2.stacklab.ca`,
                scope: "*:*",
            })
            const url = config.apiBasePath + "admin/purchase/" + query.get("id")
            console.log(url)
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    setPurchase(res.data)
                    console.log(res.data)
                })
                .catch((err) => {
                    setMessage("An error occured. Please try again")
                })
        }
        call()
    }, [])
    if (purchase && purchase.chairType === "__CART__") {
        const items = []
        let ic = 1
        for (const i of purchase.cart.cart) {
            if (itemKey && itemKey !== i.chair.key) {
                continue
            }
            items.push(
                <PurchaseItem
                    key={i.chair.key}
                    itemKey={i.chair.key}
                    purchase={purchase}
                    print={print}
                    imgSize={imgSize}
                    query={query}
                    setPurchase={setPurchase}
                    chair={i.chair}
                    hideOrderInfo={ic !== 1}
                    itemPrice={i.price}
                    quantity={i.qty}
                    showFooter={ic === purchase.cart.cart.length}
                />
            )
            ic++
        }
        return <div>{items}</div>
    } else if (purchase) {
        return (
            <PurchaseItem
                purchase={purchase}
                itemKey={purchase.chair.id}
                print={print}
                imgSize={imgSize}
                query={query}
                setPurchase={setPurchase}
                chair={purchase.chair}
            />
        )
    } else {
        return <div>{message}</div>
    }
}

export default function Admin(props) {
    return (
        <div className="admin">
            <div className="body">
                <Purchase />
            </div>
        </div>
    )
}
