import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./css/global.css"
import Config from "./Config"

import { datadogRum } from "@datadog/browser-rum"

if (!!Config.dataDog.enabled) {
    datadogRum.init({
        applicationId: Config.dataDog.applicationId,
        clientToken: Config.dataDog.clientToken,
        site: "datadoghq.com",
        service: Config.dataDog.service,

        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
    })
    datadogRum.startSessionReplayRecording()
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
