export const slipperChair = {
    type: "sideChair",
    org: "MG",
    subType: "slipperChair",
    title: "Slipper Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,
    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 16,
        depth: 19,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        rake: 0,
        blocks: [{ layers: 40, color: "white" }],
    },
    legs: {
        height: 18 - 22 * 0.19685,
        width: 4,
        widthFixed: true,
        color: "wood_natural",
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
    },
}

export const sideChair = {
    type: "sideChair",
    org: "MG",
    subType: "sideChair",
    title: "Dining Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 18,
        depth: 21,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        rake: 0,
        blocks: [{ layers: 109, color: "white" }],
    },
    legs: {
        height: 18.5 - 22 * 0.19685,
        width: 4,
        widthFixed: true,
        color: "wood_natural",
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
        fattyLegAllowed: [false, true, false, true],
    },
}

export const sideChairRake = {
    type: "sideChair",
    org: "MG",
    subType: "sideChairRake",
    title: "Dining Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 16,
        depth: 24,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        blocks: [{ layers: 109, color: "white" }],
        rake: 3,
    },
    legs: {
        height: 18.5 - 22 * 0.19685,
        width: 4,
        widthFixed: true,
        color: "wood_natural",
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
        fattyLegAllowed: [false, true, false, true],
    },
}

export const theDev = {
    type: "sideChair",
    org: "MG",
    subType: "sideChairRake",
    title: "Dining Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 18,
        depth: 24,
        blocks: [{ layers: 20, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        blocks: [{ layers: 74, color: "white" }],
        rake: 2,
    },
    legs: {
        height: 18.5 - 20 * 0.19685,
        width: 4,
        widthFixed: true,
        color: "wood_natural",
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
        fattyLegAllowed: [false, true, false, true],
    },
}

export const headChair = {
    type: "sideChair",
    org: "MG",
    subType: "headChair",
    title: "Head Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 18,
        depth: 21,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        blocks: [{ layers: 175, color: "white" }],
    },
    legs: {
        height: 18.5 - 22 * 0.19685,
        width: 4,
        widthFixed: true,
        color: "wood_natural",
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
        fattyLegAllowed: [false, true, false, true],
    },
}

export const headChairRake = {
    type: "sideChair",
    org: "MG",
    subType: "headChairRake",
    title: "Head Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 18,
        depth: 25.75,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        blocks: [{ layers: 175, color: "white" }],
        rake: 4.75,
    },
    legs: {
        height: 18.5 - 22 * 0.19685,
        width: 4,
        widthFixed: true,

        color: "wood_natural",
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
        fattyLegAllowed: [false, true, false, true],
    },
}

export const loungeChair = {
    type: "armChair",
    org: "MG",
    subType: "loungeChair",
    title: "Lounge Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 29,
        depth: 25,
        blocks: [{ layers: 35, color: "white" }],
        supportsBoard: true,
    },
    leftArm: {
        width: 5,
        blocks: [{ layers: 25, color: "white" }],
    },
    rightArm: {
        width: 5,
        blocks: [{ layers: 25, color: "white" }],
    },
    back: {
        depth: 5,
        blocks: [{ layers: 56, color: "white" }],
    },
    legs: {
        height: 17 - 35 * 0.19685,
        width: 4,
        color: "wood_natural",
        widthFixed: true,
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
    },
}

export const tonsOfFun = {
    type: "sideChair",
    org: "MG",
    subType: "tonsOfFun",
    title: "Club Chair | Tons of Fun",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 20,
        depth: 28,
        blocks: [{ layers: 42, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 9,
        blocks: [{ layers: 71, color: "white" }],
    },
    legs: {
        height: 17 - 42 * 0.19685,
        width: 8,
        widthFixed: true,
        color: "wood_natural",
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
    },
}

export const cornerChair = {
    type: "cornerChair",
    org: "MG",
    subType: "cornerChair",
    title: "Corner Chair",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 24,
        depth: 24,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        blocks: [{ layers: 51, color: "white" }],
    },
    legs: {
        height: 17 - 22 * 0.19685,
        width: 4,
        color: "wood_natural",
        radiusCorner: true,
        colorName: "wood",
        legTypes: [2, 2, 2, 2],
        fattyLegAllowed: [false, true, false, false],
    },
    leftArm: {
        width: 5,
        blocks: [{ layers: 34, color: "white" }],
    },
}

export const dayBed = {
    type: "dayBed",
    org: "MG",
    subType: "dayBed",
    title: "Day Bed",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 26,
        depth: 68,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
        minBoardDepth: 22,
    },
    back: {
        depth: 5,
        blocks: [{ layers: 22, color: "white" }],
    },
    legs: {
        height: 15 - 22 * 0.19685,
        width: 4,
        color: "wood_natural",
        widthFixed: true,
        colorName: "wood",
        legTypes: [2, 2, 2, 2, 2, 2],
        numLegs: 6,
    },
}

export const bench = {
    type: "armBench",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    org: "MG",
    subType: "bench",
    title: "Bench",
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 50,
        depth: 16,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
        minBoardDepth: 22,
    },
    leftArm: {
        width: 5,
        blocks: [{ layers: 22, color: "white" }],
    },
    rightArm: {
        width: 5,
        blocks: [{ layers: 22, color: "white" }],
    },

    legs: {
        height: 17 - 22 * 0.19685,
        width: 4,
        color: "wood_natural",
        widthFixed: true,
        colorName: "wood",
        legTypes: [2, 2, 2, 2, 2, 2],
        numLegs: 6,
    },
}

export const tickTack = {
    type: "stool",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    org: "MG",
    subType: "tickTack",
    title: "TicTac",

    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 18.5,
        depth: 10,
        blocks: [{ layers: 29, color: "white" }],
        supportsBoard: true,
    },

    legs: {
        height: 18 - 29 * 0.19685,
        width: 8,
        color: "wood_natural",
        widthFixed: true,
        colorName: "wood",
        legTypes: [0, 0],
        numLegs: 2,
    },
}

export const chaise = {
    type: "cornerChair",
    org: "MG",
    subType: "chaise",
    title: "Chaise",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    pattern: 0,
    radiusCorner: true,

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "black",
    },
    seat: {
        width: 68,
        depth: 24,
        blocks: [{ layers: 22, color: "white" }],
        supportsBoard: true,
    },
    back: {
        depth: 5,
        width: 36.5,
        blocks: [{ layers: 51, color: "white" }],
    },
    legs: {
        height: 17 - 22 * 0.19685,
        width: 4,
        color: "wood_natural",
        radiusCorner: true,
        colorName: "wood",
        centerLeg: true,
        widthFixed: true,
        legTypes: [2, 2, 2, 2, 2, 2],
        numLegs: 6,
    },

    leftArm: {
        width: 5,
        blocks: [{ layers: 34, color: "white" }],
    },
}

export const pendentConfig = {
    tube: {
        name: "tube",
        lengths: [6, 12, 18, 24, 30, 36, 42, 48],
        shape: "n",
        allowDiameterChange: true,
        lights: {
            6: {
                8: [
                    {
                        start: 3,
                    },
                    {
                        start: 6,
                    },
                    {
                        start: 9,
                    },
                    {
                        start: 12,
                    },
                    {
                        start: 15,
                    },
                    {
                        start: 18,
                    },
                    {
                        start: 21,
                    },
                    {
                        start: 24,
                    },
                ],
            },
        },
    },
    58: {
        name: "58",
        lengths: [8.5, 17.25, 25.75],
        shape: "nN",
        allowDiameterChange: false,
        lights: {
            8.5: {
                1: [
                    {
                        start: 43 - 9,
                    },
                ],
                2: [
                    {
                        start: 13,
                    },
                    {
                        start: 43 - 9,
                    },
                ],
                3: [
                    {
                        start: 6,
                    },
                    {
                        start: 18,
                    },
                    {
                        start: 43 - 9,
                    },
                ],
                4: [
                    {
                        start: 5,
                    },
                    {
                        start: 14,
                    },
                    {
                        start: 22,
                    },
                    {
                        start: 43 - 8,
                    },
                ],
                5: [
                    {
                        start: 5,
                    },
                    {
                        start: 14,
                    },
                    {
                        start: 22,
                    },
                    {
                        start: 43 - 5,
                    },
                    {
                        start: 43 - 12,
                    },
                ],
                6: [
                    {
                        start: 3,
                    },
                    {
                        start: 10,
                    },
                    {
                        start: 17,
                    },
                    {
                        start: 24,
                    },
                    {
                        start: 43 - 5,
                    },
                    {
                        start: 43 - 12,
                    },
                ],
            },
            17.25: {
                1: [
                    {
                        start: 88 - 16,
                    },
                ],
                2: [
                    {
                        start: 30,
                    },
                    {
                        start: 88 - 16,
                    },
                ],
                3: [
                    {
                        start: 16,
                    },
                    {
                        start: 43,
                    },
                    {
                        start: 88 - 16,
                    },
                ],
                4: [
                    {
                        start: 16,
                    },
                    {
                        start: 29,
                    },
                    {
                        start: 43,
                    },
                    {
                        start: 88 - 16,
                    },
                ],
                5: [
                    {
                        start: 16,
                    },
                    {
                        start: 29,
                    },
                    {
                        start: 43,
                    },
                    {
                        start: 86 - 9,
                    },
                    {
                        start: 86 - 20,
                    },
                ],
                6: [
                    {
                        start: 9,
                    },
                    {
                        start: 21,
                    },
                    {
                        start: 33,
                    },
                    {
                        start: 45,
                    },
                    {
                        start: 86 - 9,
                    },
                    {
                        start: 86 - 20,
                    },
                ],
            },
            25.75: {
                1: [
                    {
                        start: 131 - 24,
                    },
                ],
                2: [
                    {
                        start: 43,
                    },
                    {
                        start: 131 - 24,
                    },
                ],
                3: [
                    {
                        start: 28,
                    },
                    {
                        start: 58,
                    },
                    {
                        start: 131 - 24,
                    },
                ],
                4: [
                    {
                        start: 28,
                    },
                    {
                        start: 58,
                    },
                    {
                        start: 131 - 14,
                    },
                    {
                        start: 131 - 33,
                    },
                ],
                5: [
                    {
                        start: 21,
                    },
                    {
                        start: 42,
                    },
                    {
                        start: 63,
                    },
                    {
                        start: 131 - 14,
                    },
                    {
                        start: 131 - 33,
                    },
                ],
                6: [
                    {
                        start: 21,
                    },
                    {
                        start: 42,
                    },
                    {
                        start: 63,
                    },
                    {
                        start: 131 - 12,
                    },
                    {
                        start: 131 - 24,
                    },
                    {
                        start: 131 - 36,
                    },
                ],
            },
        },
    },
    858: {
        name: "858",
        lengths: [12.25, 22.5, 36.5],
        allowDiameterChange: false,
        shape: "NnN",
        lights: {
            12.25: {
                2: [
                    {
                        start: 7,
                    },
                    {
                        start: 60 - 7,
                    },
                ],
                3: [
                    {
                        start: 7,
                    },
                    {
                        start: 30,
                    },

                    {
                        start: 60 - 7,
                    },
                ],
                4: [
                    {
                        start: 3,
                    },
                    {
                        start: 12,
                    },
                    {
                        start: 60 + 2 - 15,
                    },
                    {
                        start: 60 - 4,
                    },
                ],
                5: [
                    {
                        start: 3,
                    },
                    {
                        start: 12,
                    },
                    {
                        start: 60 / 2,
                    },
                    {
                        start: 60 + 2 - 15,
                    },
                    {
                        start: 60 - 4,
                    },
                ],
                6: [
                    {
                        start: 3,
                    },
                    {
                        start: 11,
                    },
                    {
                        start: 23,
                    },
                    {
                        start: 36,
                    },
                    {
                        start: 60 + 3 - 15,
                    },
                    {
                        start: 60 - 4,
                    },
                ],
            },
            22.5: {
                2: [
                    {
                        start: 14,
                    },
                    {
                        start:
                            Math.floor((114 / 4) * 3) +
                            Math.floor(114 / 4) / 2 -
                            1,
                    },
                ],
                3: [
                    {
                        start: 14,
                    },
                    {
                        start: Math.floor(114 / 2),
                    },
                    {
                        start:
                            Math.floor((114 / 4) * 3) +
                            Math.floor(114 / 4) / 2 -
                            1,
                    },
                ],
                4: [
                    {
                        start: Math.floor(28 / 4),
                    },
                    {
                        start: Math.ceil((28 / 4) * 3),
                    },
                    {
                        start: 112 - Math.ceil((28 / 4) * 3),
                    },
                    {
                        start: 112 - Math.floor(28 / 4),
                    },
                ],
                5: [
                    {
                        start: Math.floor(28 / 4),
                    },
                    {
                        start: Math.ceil((28 / 4) * 3),
                    },
                    {
                        start: 116 / 2,
                    },
                    {
                        start: 112 - Math.ceil((28 / 4) * 3),
                    },
                    {
                        start: 112 - Math.floor(28 / 4),
                    },
                ],
                6: [
                    {
                        start: Math.floor(28 / 4),
                    },
                    {
                        start: Math.ceil((28 / 4) * 3),
                    },
                    {
                        start: 58 - 12,
                    },
                    {
                        start: 58 + 12,
                    },
                    {
                        start: 112 - Math.ceil((28 / 4) * 3),
                    },
                    {
                        start: 112 - Math.floor(28 / 4),
                    },
                ],
            },
            36.5: {
                2: [
                    {
                        start: Math.floor(185 / 8),
                    },
                    {
                        start:
                            Math.floor((185 / 4) * 3) +
                            Math.floor(184 / 4) / 2 -
                            1,
                    },
                ],
                3: [
                    {
                        start: Math.floor(185 / 8),
                    },
                    {
                        start: Math.floor(185 / 2),
                    },
                    {
                        start:
                            Math.floor((185 / 4) * 3) +
                            Math.floor(185 / 4) / 2 -
                            1,
                    },
                ],
                4: [
                    {
                        start: Math.floor(Math.floor(185 / 4) / 4),
                    },
                    {
                        start: Math.ceil((Math.floor(185 / 4) / 4) * 3),
                    },
                    {
                        start: 185 - Math.ceil((Math.floor(185 / 4) / 4) * 3),
                    },
                    {
                        start: 185 - Math.floor(Math.floor(185 / 4) / 4),
                    },
                ],
                5: [
                    {
                        start: Math.floor(Math.floor(185 / 4) / 4),
                    },
                    {
                        start: Math.ceil((Math.floor(185 / 4) / 4) * 3),
                    },
                    {
                        start: Math.floor(185 / 2),
                    },
                    {
                        start: 185 - Math.ceil((Math.floor(185 / 4) / 4) * 3),
                    },
                    {
                        start: 185 - Math.floor(Math.floor(185 / 4) / 4),
                    },
                ],
                6: [
                    {
                        start: Math.floor(Math.floor(185 / 4) / 4),
                    },
                    {
                        start: Math.ceil((Math.floor(185 / 4) / 4) * 3),
                    },
                    {
                        start: Math.floor(185 / 2) - Math.floor(185 / 8),
                    },
                    {
                        start: Math.floor(185 / 2) + Math.floor(185 / 8),
                    },
                    {
                        start: 185 - Math.ceil((Math.floor(185 / 4) / 4) * 3),
                    },
                    {
                        start: 185 - Math.floor(Math.floor(185 / 4) / 4),
                    },
                ],
            },
        },
    },
    585: {
        name: "585",
        lengths: [12, 16.5, 22],
        allowDiameterChange: false,
        shape: "nNn",
        lights: {
            12: {
                2: [
                    {
                        start: 23,
                    },
                    {
                        start: 23 + 15,
                    },
                ],
                3: [
                    {
                        start: 8,
                    },
                    {
                        start: 61 - 8,
                    },
                    {
                        start: 30,
                    },
                ],
                4: [
                    {
                        start: 4,
                    },
                    {
                        start: 16 - 4,
                    },
                    {
                        start: 23,
                    },
                    {
                        start: 23 + 16,
                    },
                    {
                        start: 60 - 3,
                    },
                    {
                        start: 60 - 10,
                    },
                ],
                5: [
                    {
                        start: 16 - 8,
                    },
                    {
                        start: 23,
                    },
                    {
                        start: 23 + 8,
                    },
                    {
                        start: 23 + 16,
                    },
                    {
                        start: 60 - 6,
                    },
                ],
                6: [
                    {
                        start: 4,
                    },
                    {
                        start: 16 - 4,
                    },
                    {
                        start: 23,
                    },

                    {
                        start: 23 + 16,
                    },
                    {
                        start: 60 - 3,
                    },
                    {
                        start: 60 - 10,
                    },
                ],
            },
            16.5: {
                2: [
                    {
                        start: 42 - 12,
                    },
                    {
                        start: 42 + 9,
                    },
                ],
                3: [
                    {
                        start: 10,
                    },
                    {
                        start: 40,
                    },
                    {
                        start: 84 - 13,
                    },
                ],
                4: [
                    {
                        start: 10,
                    },
                    {
                        start: 42 - 12,
                    },
                    {
                        start: 42 + 9,
                    },
                    {
                        start: 84 - 13,
                    },
                ],
                5: [
                    {
                        start: 10,
                    },
                    {
                        start: 42 - 12,
                    },
                    {
                        start: 42 - 1,
                    },
                    {
                        start: 42 + 9,
                    },
                    {
                        start: 84 - 13,
                    },
                ],
                6: [
                    {
                        start: 5,
                    },
                    {
                        start: 15,
                    },
                    {
                        start: 42 - 12,
                    },
                    {
                        start: 42 + 9,
                    },
                    {
                        start: 84 - 13 - 5,
                    },
                    {
                        start: 84 - 13 + 5,
                    },
                ],
            },
            22: {
                2: [
                    {
                        start: 40,
                    },
                    {
                        start: 40 + 28,
                    },
                ],
                3: [
                    {
                        start: 15,
                    },
                    {
                        start: 55,
                    },
                    {
                        start: 111 - 15,
                    },
                ],
                4: [
                    {
                        start: 15,
                    },
                    {
                        start: 55 - 14,
                    },
                    {
                        start: 55 + 13,
                    },
                    {
                        start: 111 - 15,
                    },
                ],
                5: [
                    {
                        start: 15,
                    },
                    {
                        start: 55 - 16,
                    },
                    {
                        start: 55,
                    },
                    {
                        start: 55 + 15,
                    },
                    {
                        start: 111 - 15,
                    },
                ],
                6: [
                    {
                        start: 7,
                    },
                    {
                        start: 15 + 6,
                    },
                    {
                        start: 55 - 14,
                    },
                    {
                        start: 55 + 13,
                    },
                    {
                        start: 111 - 8,
                    },
                    {
                        start: 111 - 15 - 7,
                    },
                ],
            },
        },
    },
}

export const sampleColors = {
    blockColors: ["100 Wollweiß", "150 Weiß", "170 Asche"],
    blockColorsRGB: {
        "100 Wollweiß": "#d2cfca",
        "150 Weiß": "#9F9CA1",
        "170 Asche": "#98979E",
        "175 Graphit": "#463E41",
    },
    boardColors: ["sand dollar"],
    boardColorsRGB: {
        "sand dollar": "#828075",
    },
}

const descr = `Our configurator provides a platform for users to convert regional supplies of 5mm-thick off-cut Merino felt, 10mm-thick PET (water bottle plastic), recycled aluminum, and LED lights into custom fixtures.`

export const pendent = {
    type: "pendent",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    ...sampleColors,
    lightsOn: 0,
    pattern: 1,
    org: "SL",
    subType: "verticalPendent",
    title: "Pendant Light",
    wireLength: 5,
    form: "tube",
    config: { ...pendentConfig["tube"] },

    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "#474642",
    },
    descr: descr,
    canopy: { color: "#F9f9f9" },
    cable: { color: "#F9f9f9" },
    light: { number: 2, temp: 2200 },
    tube: {
        length: 6,
        diameter: 5,
        blocks: [
            { layers: 8, color: "white" },
            { layers: 1, color: "white", type: "light" },
            { layers: 8, color: "white" },
            { layers: 1, color: "white", type: "light" },
            { layers: 8, color: "white" },
        ],
    },
}

export const pendent858 = {
    type: "pendent",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    ...sampleColors,
    lightsOn: 0,
    pattern: 1,
    org: "SL",
    subType: "verticalPendent",
    title: "Pendant Light | 858",
    wireLength: 5,
    form: "858",
    config: { ...pendentConfig[858] },
    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "#474642",
    },
    descr: descr,

    canopy: { color: "#F9f9f9" },
    cable: { color: "#F9f9f9" },
    light: { number: 2, temp: 2200 },
    tube: {
        length: 12.25,
        diameter: 8,
        blocks: [
            {
                layers: 7,
                colorName: "white",
            },
            {
                layers: 1,
                color: "light",
                colorName: "light",
                type: "light",
            },
            {
                layers: 43,
                colorName: "white",
            },
            {
                layers: 1,
                color: "light",
                colorName: "light",
                type: "light",
            },
            {
                layers: 6,
                colorName: "white",
            },
        ],
    },
}

export const pendent585 = {
    type: "pendent",
    blockColors: [],
    blockColorsRGB: {},
    boardColors: [],
    boardColorsRGB: {},
    ...sampleColors,
    lightsOn: 0,
    pattern: 1,
    org: "SL",
    subType: "verticalPendent",
    title: "Pendant Light | 585",
    wireLength: 5,
    form: "585",
    config: { ...pendentConfig[585] },
    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "#474642",
    },
    descr: descr,

    canopy: { color: "#F9f9f9" },
    cable: { color: "#F9f9f9" },
    light: { number: 2, temp: 2200 },
    tube: {
        length: 12,
        diameter: 5,
        blocks: [
            {
                layers: 12,
                color: "white",
                diameter: 5,
            },
            {
                layers: 1,
                color: "white",
                type: "light",
                diameter: 5,
            },
            {
                layers: 2,
                color: "white",
                diameter: 5,
            },
            {
                layers: 10,
                color: "white",
                diameter: 8,
            },
            {
                layers: 1,
                color: "white",
                type: "light",
                diameter: 8,
            },
            {
                layers: 19,
                color: "white",
                diameter: 8,
            },
            {
                layers: 15,
                color: "white",
                diameter: 5,
            },
        ],
    },
}

export const pendent58 = {
    type: "pendent",
    blockColors: [],
    blockColorsRGB: {},

    boardColors: [],
    boardColorsRGB: {},
    ...sampleColors,
    lightsOn: 0,
    pattern: 1,
    org: "SL",
    subType: "verticalPendent",
    title: "Pendant Light | 58",
    wireLength: 5,
    form: "58",
    config: { ...pendentConfig[58] },
    tuft: {
        radius: 0.875,
        height: 0.24,
        color: "#474642",
    },
    descr: descr,

    canopy: { color: "#F9f9f9" },
    cable: { color: "#F9f9f9" },
    light: { number: 1, temp: 2200 },
    tube: {
        length: 8.5,
        diameter: 5,
        blocks: [
            {
                diameter: 5,
                layers: 21,
                color: "white",
            },
            {
                diameter: 5,
                layers: 1,
                color: "light",
                colorName: "light",
                type: "light",
            },
            {
                diameter: 8,
                layers: 19,
                color: "white",
            },
        ],
    },
}
