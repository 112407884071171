import FlashArrow from "./FlashArrow"
import IconLoungeChair from "../images/iconLoungeChair.svg"
import IconLoungeChairWhite from "../images/iconLoungeChairWhite.svg"

import IconCornerChair from "../images/iconCornerChair.svg"
import IconBench from "../images/iconBench.svg"
import IconTicTac from "../images/iconTicTac.svg"
import IconSideChair from "../images/iconSideChair.svg"
import IconChaise from "../images/iconChaise.svg"
import IconHeadChair from "../images/iconHeadChair.svg"
import IconTonsOfFun from "../images/iconTonsOfFun.svg"
import IconSlipperChair from "../images/iconSlipperChair.svg"
import IconDayBed from "../images/iconDayBed.svg"

import Icon5 from "../images/icon5.svg"
import Icon5White from "../images/icon5white.svg"
import Icon8 from "../images/icon8.svg"
import Icon8White from "../images/icon8white.svg"
import Icon58 from "../images/icon58.svg"
import Icon58White from "../images/icon58white.svg"
import Icon858 from "../images/icon858.svg"
import Icon858white from "../images/icon858white.svg"
import Icon585 from "../images/icon585.svg"
import Icon585White from "../images/icon585white.svg"

const icons = {
    loungeChair: IconLoungeChair,
    loungeChairWhite: IconLoungeChairWhite,

    cornerChair: IconCornerChair,
    bench: IconBench,
    ticTac: IconTicTac,
    diningChair: IconSideChair,
    chaise: IconChaise,
    headChair: IconHeadChair,
    tonsOfFun: IconTonsOfFun,
    slipperChair: IconSlipperChair,
    dayBed: IconDayBed,
    5: Icon5,
    "5White": Icon5White,
    8: Icon8,
    "8White": Icon8White,
    58: Icon58,
    "58White": Icon58White,
    585: Icon585,

    "585White": Icon585White,

    858: Icon858,
    "858White": Icon858white,
}

const iconsDoubleWidth = [icons.chaise, icons.bench, icons.dayBed]

export { icons, iconsDoubleWidth }
