import React, { useEffect, useState } from "react"

export default function Payment(props) {
    const state = window.top.location.hash.substr(1)
    let heading = "Payment Failed"
    let body =
        "Your payment failed. Don't worry, Stackbl support will contact you within two business days to sort it out."

    if (state === "success") {
        heading = "Payment Success"
        body =
            "Thanks for your order. Payment was received successfully. Stackbl ordering will contact you in two business days to arrange delivery."
    }

    return (
        <div className="paymentconfirm model-body">
            <h1>{heading}</h1>
            <div className="body">{body}</div>
            <br />
            <div className="model-buttons">
                <button
                    className="model-button"
                    onClick={() => {
                        window.location = "/"
                    }}
                >
                    Return home
                </button>
            </div>
        </div>
    )
}
