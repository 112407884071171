import React, { useEffect, useState } from "react"

import ArmChairBottom from "./ArmChairBottom"
import ArmChairTop from "./ArmChairTop"
import ArmChairFront from "./ArmChairFront"
import ArmChairSide from "./ArmChairSide"

import BenchBottom from "./BenchBottom"
import BenchTop from "./BenchTop"
import BenchFront from "./BenchFront"
import BenchSide from "./BenchSide"

import SideChairBottom from "./SideChairBottom"
import SideChairTop from "./SideChairTop"

import SideChairFront from "./SideChairFront"
import SideChairSide from "./SideChairSide"

import CornerChairFront from "./CornerChairFront"
import CornerChairSide from "./CornerChairSide"
import CornerChairTop from "./CornerChairTop"
import PendentFront from "./PendentFront"

export function Sizer(props) {
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        function handleResize() {
            setSize(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)

        return function cleanup() {
            window.removeEventListener("resize", handleResize)
        }
    })
    if (!props.vw) {
        return props.children
    }

    const childrenWithExtraProp = React.Children.map(
        props.children,
        (child) => {
            return React.cloneElement(child, {
                size: (size / 100) * props.vw,
            })
        }
    )

    return childrenWithExtraProp
}

export function Top(props) {
    switch (props.type) {
        case "armChair":
            return (
                <Sizer {...props}>
                    <ArmChairTop {...props} />
                </Sizer>
            )
        case "cornerChair":
            return (
                <Sizer {...props}>
                    <CornerChairTop {...props} />
                </Sizer>
            )
        case "sideChair":
            return (
                <Sizer {...props}>
                    <SideChairTop {...props} />
                </Sizer>
            )
        case "dayBed":
            return (
                <Sizer {...props}>
                    <SideChairTop {...props} />
                </Sizer>
            )
        case "bench":
            return (
                <Sizer {...props}>
                    <BenchTop {...props} />
                </Sizer>
            )
        case "stool":
            return (
                <Sizer {...props}>
                    <BenchTop {...props} />
                </Sizer>
            )
        case "armBench":
            return (
                <Sizer {...props}>
                    <BenchTop {...props} />
                </Sizer>
            )
        default:
            throw Error("No matching type")
    }
}

export function Bottom(props) {
    switch (props.type) {
        case "armChair":
            return (
                <Sizer {...props}>
                    <ArmChairBottom {...props} />
                </Sizer>
            )
        case "cornerChair":
            return (
                <Sizer {...props}>
                    <ArmChairBottom {...props} />
                </Sizer>
            )
        case "sideChair":
            return (
                <Sizer {...props}>
                    <SideChairBottom {...props} />
                </Sizer>
            )
        case "dayBed":
            return (
                <Sizer {...props}>
                    <SideChairBottom {...props} />
                </Sizer>
            )
        case "bench":
            return (
                <Sizer {...props}>
                    <BenchBottom {...props} />
                </Sizer>
            )
        case "stool":
            return (
                <Sizer {...props}>
                    <BenchBottom {...props} />
                </Sizer>
            )
        case "armBench":
            return (
                <Sizer {...props}>
                    <BenchBottom {...props} />
                </Sizer>
            )
        default:
            throw Error("No matching type")
    }
}

export function Side(props) {
    switch (props.type) {
        case "armChair":
            return (
                <Sizer {...props}>
                    <ArmChairSide {...props} />
                </Sizer>
            )
        case "cornerChair":
            return (
                <Sizer {...props}>
                    <CornerChairSide {...props} />
                </Sizer>
            )
        case "sideChair":
            return (
                <Sizer {...props}>
                    <SideChairSide {...props} />
                </Sizer>
            )
        case "dayBed":
            return (
                <Sizer {...props}>
                    <SideChairSide {...props} />
                </Sizer>
            )
        case "bench":
            return (
                <Sizer {...props}>
                    <BenchSide {...props} />
                </Sizer>
            )
        case "stool":
            return (
                <Sizer {...props}>
                    <BenchSide {...props} />
                </Sizer>
            )
        case "armBench":
            return (
                <Sizer {...props}>
                    <BenchSide {...props} />
                </Sizer>
            )
        default:
            throw Error("No matching type")
    }
}

export function Front(props) {
    switch (props.type) {
        case "armChair":
            return (
                <Sizer {...props}>
                    <ArmChairFront {...props} />
                </Sizer>
            )
        case "cornerChair":
            return (
                <Sizer {...props}>
                    <CornerChairFront {...props} />
                </Sizer>
            )
        case "sideChair":
            return (
                <Sizer {...props}>
                    <SideChairFront {...props} />
                </Sizer>
            )
        case "dayBed":
            return (
                <Sizer {...props}>
                    <SideChairFront {...props} />
                </Sizer>
            )
        case "bench":
            return (
                <Sizer {...props}>
                    <BenchFront {...props} />
                </Sizer>
            )
        case "stool":
            return (
                <Sizer {...props}>
                    <BenchFront {...props} />
                </Sizer>
            )
        case "armBench":
            return (
                <Sizer {...props}>
                    <BenchFront {...props} />
                </Sizer>
            )
        case "pendent":
            return (
                <Sizer {...props}>
                    <PendentFront {...props} />
                </Sizer>
            )
        default:
            throw Error("No matching type")
    }
}
